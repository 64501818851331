import { formatRawAsLocal } from "../../../utils/datetime";
import { Wrapper, DidNotEnter, LastUpdated } from "./styles";
import { IStandingsInformation } from "./types";

const StandingsInformation = ({
  didNotPlay,
  standings,
}: IStandingsInformation) => {
  return (
    <Wrapper>
      {didNotPlay && (
        <DidNotEnter>
          DNP: This manager didn't enter the challenge this Gameweek
        </DidNotEnter>
      )}
      {standings && standings.lastUpdatedData && (
        <LastUpdated>
          Last updated: {formatRawAsLocal(standings.lastUpdatedData)} (local
          time)
        </LastUpdated>
      )}
    </Wrapper>
  );
};

export default StandingsInformation;
