import StatusIcon from "../../img/icons/status-mono.svg?react";
import SuccessIcon from "../../img/icons/success.svg?react";
import { StyledAlert } from "./styles";
import { IAlert } from "./types";

const Alert = ({ $type, $copnr = null, $align, children }: IAlert) => {
  return (
    <StyledAlert $type={$type} $copnr={$copnr} $align={$align}>
      {$type === "success" && <SuccessIcon />}
      {$type === "error" && <StatusIcon />}
      {$copnr !== null && <StatusIcon />}
      {children}
    </StyledAlert>
  );
};

export default Alert;
