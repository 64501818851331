import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ElementDialogButton from "../components/ElementDialogButton";
import ElementFilter from "../components/ElementFilter";
import ElementInTable from "../components/ElementInTable";
import ElementSort from "../components/ElementSort";
import { ElementRow, ElementTable } from "../components/ElementTable";
import { FieldCol, FieldCols } from "../components/FieldRenderers";
import HelmetHead from "../components/HelmetHead";
import { GridItem, GridWrapper, SidebarGrid } from "../components/Layout";
import ScoutNav from "../components/ScoutNav";
import Title from "../components/Title";
import { AppDispatch, RootState } from "../rtk-core/src/app/store";
import {
  IElementControls,
  IElementDataFromControls,
  getElementControls,
  getElementsFromControls,
  updateElementControls,
  updateElementControlsAndMaxCost,
} from "../rtk-core/src/features/elements";
import { ITeamsById, getTeamsById } from "../rtk-core/src/features/teams";

const NewsStatus = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 6%;
  }
`;

const NewsElement = styled.th`
  width: 39%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 38%;
  }
`;

const NewsNews = styled.th`
  width: 51%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 56%;
  }
`;

const NewsCell = styled.td`
  white-space: normal;
`;

interface IPropsFromState {
  elements: IElementDataFromControls;
  controls: IElementControls;
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  updateControls: (controls: IElementControls) => void;
  updateControlsAndMaxCost: (controls: IElementControls) => void;
}

type Props = IPropsFromState & IPropsFromDispatch;

export const News: React.FC<Props> = ({
  elements,
  controls,
  teamsById,
  updateControls,
  updateControlsAndMaxCost,
}) => {
  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateControls({
      ...controls,
      filter: e.target.value,
    });
  };

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateControls({
      ...controls,
      sort: e.target.value,
    });
  };

  useEffect(() => {
    updateControlsAndMaxCost({
      ...controls,
      filter: "all",
      sort: "news_added",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GridWrapper>
      <SidebarGrid $hasMargin={true}>
        <GridItem>
          <Title>The Scout</Title>
        </GridItem>
        <GridItem>
          <div className="-mt-3">
            <ScoutNav />
          </div>
        </GridItem>

        <HelmetHead
          title="Fantasy Premier League Player Availability, Injuries &amp; Eligibility | Fantasy Premier League"
          description="To view player availability including injuries and eligibility for all Fantasy Premier League players, or select by position or team, visit the official website of the Premier League."
        />
        <GridItem $colSpan={1}>
          <form>
            <FieldCols>
              <FieldCol>
                <ElementFilter handleFilterChange={handleFilterChange} />
              </FieldCol>
              <FieldCol>
                <ElementSort
                  handleSortChange={handleSortChange}
                  sortOptions={[
                    { label: "Most recently added", value: "news_added" },
                    {
                      label: "Chance of playing",
                      value: "chance_of_playing_next_round",
                    },
                  ]}
                />
              </FieldCol>
            </FieldCols>
          </form>
        </GridItem>
        <GridItem $colSpan={1} $gridColStart={1}>
          <ElementTable>
            <thead>
              <tr>
                <NewsStatus scope="col">&nbsp;</NewsStatus>
                <NewsElement scope="col">Player</NewsElement>
                <NewsNews scope="col">News</NewsNews>
              </tr>
            </thead>
            <tbody>
              {elements.data
                .filter((e) => e.news)
                .map((e) => (
                  <ElementRow key={e.id}>
                    <td>
                      <ElementDialogButton elementId={e.id} variant="list" />
                    </td>
                    <td>
                      <ElementInTable element={e} team={teamsById[e.team]} />
                    </td>
                    <NewsCell>{e.news}</NewsCell>
                  </ElementRow>
                ))}
            </tbody>
          </ElementTable>
        </GridItem>
      </SidebarGrid>
    </GridWrapper>
  );
};

export { News as NewsTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  controls: getElementControls(state),
  elements: getElementsFromControls(state),
  teamsById: getTeamsById(state),
});

const mapDispatchToProps = (dispatch: AppDispatch): IPropsFromDispatch => ({
  updateControls: (controls) => dispatch(updateElementControls(controls)),
  updateControlsAndMaxCost: (controls) =>
    dispatch(updateElementControlsAndMaxCost(controls)),
});

export default connect(mapStateToProps, mapDispatchToProps)(News);
