import _ from "lodash";
import { size } from "polished";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useBlocker, useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import Alert from "../components/Alert";
import BoldLinkButton from "../components/BoldLinkButton";
import Button from "../components/Button";
import CannotEnterEvent from "../components/CannotEnterEvent";
import Copy from "../components/Copy";
import Dialog from "../components/Dialog";
import DialogBody from "../components/DialogBody";
import DialogHeader from "../components/DialogHeader";
import ElementList from "../components/ElementList";
import EntryScoreboard from "../components/EntryScoreboard";
import EventNavigator from "../components/EventNavigator";
import Fixtures from "../components/Fixtures";
import { ArrowRight, ControlArrowRight } from "../components/Icons";
import {
  GridItem,
  GridWrapper,
  Main,
  TwoCol50Grid,
  Wrapper,
} from "../components/Layout";
import LinkButton from "../components/LinkButton";
import PitchFormation from "../components/PitchFormation";
import SquadActionBar from "../components/SquadActionBar";
import ManageTeamContext from "../contexts/ManageTeamContext";
import ManageTeamErrorModal from "../dialogs/ManageTeamErrorDialog";
import PlayerRemovalDialog from "../dialogs/PlayerRemovalDialog";
import UnsavedChangesDialog from "../dialogs/UnsavedChangesDialog";
import { useAppDispatch, useAppSelector } from "../rtk-core/src/app/hooks";
import { RootState } from "../rtk-core/src/app/store";
import { getEntry } from "../rtk-core/src/features/entries";
import { IEvent, getActiveEvent } from "../rtk-core/src/features/events";
import { doFetchLatestEventData } from "../rtk-core/src/features/events/thunks";
import { getRules } from "../rtk-core/src/features/game";
import {
  cancelFormationChange,
  changeFormation,
  changeFormationNoSub,
  getActiveFormation,
  getErrors,
  getHasBench,
  getManageEntryEventPoints,
  getMyFormationProposed,
  getPendingRemovals,
  getPicksProposed,
  getPossibleFormationsFromPicks,
  getProposedElements,
  hasTeamChanged,
  reset,
  setActiveFormation,
} from "../rtk-core/src/features/manage";
import { IPlayer, getPlayerData } from "../rtk-core/src/features/player";
import { getTeamsById } from "../rtk-core/src/features/teams";
import { getEventUrlForEntry } from "../utils/entries";

const PusherWrap = styled.div`
  position: relative;
  overflow-x: hidden;
`;

interface IStyledProps {
  $isSidebarVisible: boolean;
}

const Pusher = styled.div<IStyledProps>`
  position: relative;
  transition: all 300ms;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: flex;
    gap: ${(props) => props.theme.space[3]};
  }

  ${(props) =>
    props.$isSidebarVisible &&
    css`
      transform: translate3d(-100%, 0, 0);

      @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
        transform: none;
      }
    `};
`;

const PusherSecondary = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(100%, 0, 0);
  overflow-y: auto;
  padding: 2rem 0;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    position: static;
    transform: none;
    width: calc(100% / 3);
  }
`;

const ButtonWrap = styled.div`
  margin-right: 1.5rem;
  text-align: right;
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: none;
  }
`;

export const EventTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.heavy};
  font-size: 3rem;
  font-style: normal;
  font-weight: 800;
  line-height: 4.7rem;
  color: #37003c;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    font-size: 3.2rem;
  }
`;

export const SpacingContainer = styled.div`
  padding-top: 3.2rem;
`;

export const AlertLinkButton = styled(LinkButton)`
  text-decoration: underline;
`;

export const SaveDialogControls = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.space[5]};
`;

export const SaveDialogArrow = styled(ArrowRight)`
  ${size("15px")};
  margin-left: 0.5rem;
`;

export const SaveDialogCopy = styled.div`
  font-size: ${(props) => props.theme.fontSizes[3]};
`;

export interface ISaveDialogProps {
  handleHide: () => void;
}

const SaveDialog: React.FC<ISaveDialogProps> = ({ handleHide }) => {
  const { saveState } = useContext(ManageTeamContext);
  const navigate = useNavigate();

  const activeEvent = useAppSelector(getActiveEvent);

  const onCreateLeague = () => {
    navigate("/leagues/create/classic");
  };

  const onJoinLeague = () => {
    navigate("/leagues/join/private");
  };

  const onViewLeagues = () => {
    navigate(`/leagues/${activeEvent.id}`);
  };

  return (
    <Dialog closeDialog={handleHide}>
      <DialogHeader closeDialog={handleHide}>
        Your squad has been saved
      </DialogHeader>
      <DialogBody isPadded={true}>
        {saveState === "initial" && (
          <Copy>
            <SaveDialogCopy>
              Now you have selected your squad you can now join or create a
              league.
            </SaveDialogCopy>
            <SaveDialogControls>
              <div className="mb-2">
                <Button
                  $isFullWidth
                  $stretch
                  $variant="primary"
                  onClick={onCreateLeague}
                >
                  Create a league
                  <SaveDialogArrow />
                </Button>
              </div>
              <div className="mb-2">
                <Button
                  $isFullWidth
                  $stretch
                  $variant="primary"
                  onClick={onJoinLeague}
                >
                  Join a league
                  <SaveDialogArrow />
                </Button>
              </div>
              <div className="mb-2">
                <Button
                  $isFullWidth
                  $stretch
                  $variant="secondary"
                  onClick={handleHide}
                >
                  Back to my squad
                  <SaveDialogArrow />
                </Button>
              </div>
            </SaveDialogControls>
          </Copy>
        )}
        {saveState === "create" && (
          <Copy>
            <SaveDialogCopy>
              Changes to your squad have been saved successfully.
            </SaveDialogCopy>
            <SaveDialogControls>
              <div className="mb-2">
                <Button
                  $isFullWidth
                  $stretch
                  $variant="secondary"
                  onClick={onCreateLeague}
                >
                  Create a new league
                  <SaveDialogArrow />
                </Button>
              </div>
              <div className="mb-2">
                <Button
                  $isFullWidth
                  $stretch
                  $variant="primary"
                  onClick={onViewLeagues}
                >
                  View my current leagues
                  <SaveDialogArrow />
                </Button>
              </div>
            </SaveDialogControls>
          </Copy>
        )}
      </DialogBody>
    </Dialog>
  );
};

const ManageTeam: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const activeEvent = useAppSelector(getActiveEvent);
  const player = useAppSelector(getPlayerData) as IPlayer;
  const entry = useAppSelector((state: RootState) =>
    player && player.entry ? getEntry(state, player.entry) : null
  );

  const hasBench = useAppSelector(getHasBench);
  const hasChanged = useAppSelector(hasTeamChanged);
  const proposedElements = useAppSelector(getProposedElements);

  // useBlocker allows us to intercept navigation if a condition is met. Here we use
  // hasChanged so that if team changes have been made we ask the user to confirm
  // they are happy for changes to be lost
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      proposedElements.length > 0 &&
      hasChanged &&
      currentLocation.pathname !== nextLocation.pathname
  );

  const { pathname, state } = useLocation();

  const {
    createEntryData,
    isValid,
    saveState,
    setSaveState,
    errorMessage,
    setErrorMessage,
  } = useContext(ManageTeamContext);

  /* Sidebar logic for when viewing on mobile */
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const hideRef = useRef<HTMLButtonElement>(null);
  const showRef = useRef<HTMLButtonElement>(null);

  const handleHideSidebar = () => setIsSidebarVisible(false);
  const handleShowSidebar = () => setIsSidebarVisible(true);

  const handleTransitionEnd = (e: React.TransitionEvent) => {
    // Just handle the Pusher transition
    if (e.target === e.currentTarget) {
      isSidebarVisible ? hideRef.current!.focus() : showRef.current!.focus();
    }
  };

  /* Formation handling/setting */
  const activeFormation = useAppSelector(getActiveFormation)!;
  const proposedFormation = useAppSelector(getMyFormationProposed);

  const formations = useAppSelector((state) =>
    getPossibleFormationsFromPicks(state, activeEvent.id)
  );

  const onFormationChange = useCallback(
    (newFormation: string) => {
      if (hasBench) {
        dispatch(setActiveFormation(newFormation));
        dispatch(changeFormation());
      } else {
        dispatch(changeFormationNoSub(newFormation));
      }
    },
    [dispatch, hasBench]
  );

  const pendingRemovals = useAppSelector(getPendingRemovals);
  const picks = useAppSelector(getPicksProposed);

  useEffect(() => {
    proposedFormation && onFormationChange(proposedFormation);
  }, [proposedFormation, onFormationChange]);

  // Reset saved once a team change is made
  useEffect(() => {
    if (hasChanged) {
      setSaveState(null);
    }
  }, [hasChanged, setSaveState]);

  // Reset saved on pathname change
  useEffect(() => {
    if (state !== "initial") {
      setSaveState(null);
    }
  }, [pathname, setSaveState, state]);

  useEffect(() => {
    dispatch(doFetchLatestEventData());
    dispatch(reset());
  }, [dispatch]);

  const rules = useAppSelector((state) => getRules(state, activeEvent.id));
  const teamsById = useAppSelector(getTeamsById);
  const errors = useAppSelector((state) => getErrors(state, activeEvent.id));
  const isBudgetExceeded = Boolean(errors.budgetExceeded);

  const entryPoints = useAppSelector((state) =>
    getManageEntryEventPoints(state, activeEvent.id)
  );

  // Handle initial entry creation
  if (!player.entry) {
    if (!activeEvent.can_enter) {
      // We shouldn't be here so let the top level handle it
      window.location.replace("/");
    } else if (!isValid()) {
      // createEntryData is invalid
      navigate("/manage/create");
    }
  }

  const getEventUrl = (event: IEvent) => {
    return getEventUrlForEntry(entry!, event, player);
  };

  if (!activeEvent.released) {
    return (
      <GridWrapper>
        <div className="my-3">
          <EventNavigator getEventUrl={getEventUrl} />
        </div>
        <TwoCol50Grid>
          <GridItem>
            <CannotEnterEvent />
          </GridItem>
          <GridItem>
            <Fixtures eventId={activeEvent.id} />
          </GridItem>
        </TwoCol50Grid>
      </GridWrapper>
    );
  }

  if (activeEvent.finished) {
    navigate(`/entry/${player.entry}/event/${activeEvent.id}`);
  }

  // if picks haven't been initialised yet
  if (_.isEmpty(picks)) {
    return null;
  }

  let budgetExceeded: React.ReactNode = null;
  if (isBudgetExceeded) {
    budgetExceeded = (
      <div className="mb-2">
        <Alert $type="error">Budget exceeded</Alert>
      </div>
    );
  }

  return (
    <Wrapper>
      {errorMessage && (
        <ManageTeamErrorModal
          errorMessage={errorMessage}
          closeDialog={() => setErrorMessage(null)}
        />
      )}
      {blocker.state === "blocked" && (
        <UnsavedChangesDialog
          handleConfirm={() => blocker.state === "blocked" && blocker.proceed()}
          handleHide={() => blocker.state === "blocked" && blocker.reset()}
        />
      )}
      {(saveState === "create" || saveState === "initial") && (
        <SaveDialog handleHide={() => setSaveState(null)} />
      )}

      {pendingRemovals.length > 0 && (
        <PlayerRemovalDialog
          elementType={pendingRemovals[0]}
          handleHide={() => dispatch(cancelFormationChange())}
        />
      )}

      {!player.entry ? (
        // if no entry we hide the EventNavigator
        <SpacingContainer />
      ) : (
        <div className="my-3">
          <EventNavigator getEventUrl={getEventUrl} />
        </div>
      )}
      <EntryScoreboard points={entryPoints} />
      <PusherWrap>
        <Pusher
          $isSidebarVisible={isSidebarVisible}
          onTransitionEnd={handleTransitionEnd}
        >
          <Main>
            <ButtonWrap>
              <BoldLinkButton onClick={handleShowSidebar} ref={showRef}>
                Player List
                <div className="ml-1">
                  <ControlArrowRight color="primary" />
                </div>
              </BoldLinkButton>
            </ButtonWrap>
            {saveState === "fail" && (
              <div className="mb-2">
                <Alert $type="error">
                  Error saving your team{" "}
                  <AlertLinkButton onClick={() => window.location.reload()}>
                    Reload
                  </AlertLinkButton>
                </Alert>
              </div>
            )}
            {saveState === "update" && (
              <div className="mb-2">
                <Alert $type="success">Your team has been saved.</Alert>
              </div>
            )}
            {budgetExceeded && budgetExceeded}
            {errors.overTeamLimit && rules && (
              <div className="mb-2">
                <Alert $type="error">
                  {`Too many players selected from ${errors.overTeamLimit
                    .map((team: string | number) => teamsById[team].name)
                    .join(", ")} (max. ${rules.squad_team_limit})`}
                </Alert>
              </div>
            )}
            <div className="mb-6">
              <PitchFormation
                formation={activeFormation}
                picks={picks}
                showSidebar={() => setIsSidebarVisible(true)}
              />
            </div>
            <SquadActionBar
              createEntryData={createEntryData}
              formation={activeFormation}
              formations={formations}
              onChange={onFormationChange}
            />
            <Fixtures eventId={activeEvent.id} />
          </Main>
          <PusherSecondary>
            <ElementList hideRef={hideRef} hideSidebar={handleHideSidebar} />
          </PusherSecondary>
        </Pusher>
      </PusherWrap>
    </Wrapper>
  );
};

export default ManageTeam;
