import React, { useState } from "react";
import LeagueDeleteDiaglog from "../../dialogs/LeagueDeleteDialog";
import { IPrivateLeagueAdmin } from "../../rtk-core/src/features/leagues";
import Button from "../Button";
import SubHeading from "../SubHeading";

interface IOwnProps {
  league: IPrivateLeagueAdmin;
}

const Delete: React.FC<IOwnProps> = ({ league }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  return (
    <>
      {showDeleteDialog && (
        <LeagueDeleteDiaglog
          league={league}
          handleHide={() => setShowDeleteDialog(false)}
        />
      )}

      <SubHeading>Delete League</SubHeading>
      <div className="my-2">
        <p>
          To delete the league, click on the button below. The players entered
          in the league will still exist and can enter other leagues if desired.
        </p>
      </div>
      {/* <Button onClick={() => remove(league.id)}>Delete league</Button> */}
      <Button onClick={() => setShowDeleteDialog(true)}>Delete league</Button>
    </>
  );
};

export default Delete;
