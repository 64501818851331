import styled from "styled-components";
import ChallengeBlock, { ChallengeBlockBody } from "../ChallengeBlock";
import { Lockup } from "./types";

export const ChallengeHeroBlock = styled(ChallengeBlock)`
  overflow: auto;
  padding: 0;
`;

export const ChallengeHeroBlockBody = styled(ChallengeBlockBody)`
  margin: ${({ theme }) => `${theme.space[4]} ${theme.space[2]}`};
  font-size: ${(props) => props.theme.fontSizes[3]};
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    min-height: 217px;
    margin: ${({ theme }) =>
      `${theme.space[6]} 50% ${theme.space[6]} ${theme.space[6]}`};
    font-size: ${(props) => props.theme.fontSizes[5]};
    text-align: left;
  }
`;

export const StyledHero = styled.div<Lockup>`
  padding-top: 40%;
  background-image: ${(props) => `url(${props.$lockupPath})`};
  background-position: center top ${(props) => props.theme.space[4]};
  background-size: 72%;
  background-repeat: no-repeat;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    overflow: auto;
    padding: 0;
    background-position: right 7% bottom;
    background-size: 450px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[5]}) {
    background-position: right 12% bottom;
  }
`;

export const ChallengeEvent = styled.div`
  margin-bottom: ${(props) => props.theme.space[1]};
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[3]};
`;

export const ChallengeName = styled.div`
  margin-bottom: ${(props) => props.theme.space[3]};
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: ${(props) => props.theme.fontSizes[6]};
  line-height: 1;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    font-size: 3.9rem;
  }
`;

export const ChallengeDetail = styled.div`
  margin-top: ${(props) => props.theme.space[6]};
`;
