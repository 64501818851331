import { size } from "polished";
import styled from "styled-components";
import BaseGamechanger from "../../img/icons/gamechanger.svg?react";

export const EntryScoreboardWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[4]};

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    flex-direction: row;
  }
`;

export const EntryScoreboardItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: ${({ theme }) => `${theme.space[4]}`};
  border-radius: ${(props) => props.theme.radii[2]};
  background-image: linear-gradient(to right, #05f182, #00ece3);

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    flex: 1;
    justify-content: space-between;
    padding: ${(props) => props.theme.space[6]};
  }
`;

export const EntryInfoWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => `${theme.space[3]}`};
`;

export const EntryTeamName = styled.h3`
  margin-bottom: ${(props) => props.theme.space[1]};
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: ${(props) => props.theme.space[6]};
  line-height: 1;
`;

export const EntryInfoHeader = styled.div`
  display: flex;
  font-size: ${(props) => props.theme.fontSizes[3]};
`;

export const EntryInfoHeading = styled.h4`
  margin-right: 0.4rem;
  font-family: ${(props) => props.theme.fonts.regular};
`;

export const EntryInfoPlayer = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
`;

export const InfoBoard = styled.div`
  border-radius: ${(props) => props.theme.radii[2]};
  background-color: ${(props) => props.theme.colors.purpleDark};
  color: white;
`;

export const EntryInfoStats = styled.div`
  display: flex;
`;

export const PrimaryStat = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex: 0 0 37%;
  padding: ${(props) => props.theme.space[4]};
  text-align: center;
`;

export const PrimaryStatHeading = styled.h4`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: ${(props) => props.theme.fontSizes[2]};
`;

export const PrimaryStatValue = styled.div`
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: 4rem;
`;

export const SecondaryEntryStats = styled.div`
  flex: 0 0 63%;
  padding: ${(props) => props.theme.space[4]};
`;

export const ChallengeHeading = styled.h3`
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: ${(props) => props.theme.fontSizes[5]};
`;

export const ChallengedDescriptionWrap = styled.div`
  display: flex;
  gap: ${(props) => props.theme.space[1]};
`;

export const ChallengeDescription = styled.p`
  font-size: ${(props) => props.theme.fontSizes[3]};
`;

export const ChallengeStats = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[3]};
  padding: ${({ theme }) => `0 ${theme.space[4]}`};
  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    flex-direction: row;
  }
`;

export const ChallengeStatsBoard = styled.div`
  border-radius: ${(props) => props.theme.radii[2]};
  background-color: rgba(255, 255, 255, 0.4);
`;

export const ChallengeEventId = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
`;

export const GameChangerIcon = styled(BaseGamechanger)`
  ${size(14)};
  min-width: 14px;
  margin-top: 4px;
`;

export const ChallengeDetailWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${(props) => props.theme.space[1]};
  margin-bottom: ${(props) => props.theme.space[6]};
  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    margin-bottom: ${(props) => props.theme.space[0]};
  }
`;
