import styled from "styled-components";

export const StyledPanel = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.purple10};
  border-radius: ${({ theme }) => theme.radii[3]};
`;

export const PanelHeading = styled.h3`
  padding: ${({ theme }) => theme.space[4]};
  border-top-left-radius: ${({ theme }) => theme.radii[3]};
  border-top-right-radius: ${({ theme }) => theme.radii[3]};
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.fantasy},
    ${({ theme }) => theme.colors.blueLight}
  );
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[5]};
  color: ${({ theme }) => theme.colors.primary};
`;

export const StyledPanelBody = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.space[3]} 0;
`;

export const StyledPanelFooter = styled.div`
  padding: ${({ theme }) => theme.space[4]};
  border-top: 1px solid ${({ theme }) => theme.colors.purple10};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  text-align: left;
`;
