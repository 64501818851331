import { useSelector } from "react-redux";
import { useAppSelector } from "../../rtk-core/src/app/hooks";
import { RootState } from "../../rtk-core/src/app/store";
import { getElement } from "../../rtk-core/src/features/elements";
import { getActiveEvent } from "../../rtk-core/src/features/events";
import {
  getElementEventOpponents,
  getFixturesForEventByTeam,
} from "../../rtk-core/src/features/fixtures";
import { ElementMultiFixtures, ElementSingleFixture } from "./styles";
import { IMultiProps } from "./types";

export const FixturesForElement: React.FC<IMultiProps> = ({ elementId }) => {
  const activeEvent = useAppSelector(getActiveEvent);
  const element = useSelector((state: RootState) =>
    getElement(state, elementId)
  );
  const fixturesForEventByTeam = useAppSelector((state) =>
    getFixturesForEventByTeam(state, activeEvent.id)
  );
  const fixtures = fixturesForEventByTeam[element.team];
  const elementEventOpponents = useAppSelector((state) =>
    fixtures?.length ? getElementEventOpponents(state, element, fixtures) : []
  );

  if (!element) {
    return null;
  }

  if (elementEventOpponents.length === 1) {
    return (
      <ElementSingleFixture>{elementEventOpponents[0]}</ElementSingleFixture>
    );
  }

  if (elementEventOpponents.length > 1) {
    return (
      <ElementMultiFixtures>
        {elementEventOpponents.join(", ")}
      </ElementMultiFixtures>
    );
  }

  return <div>-</div>;
};
export default FixturesForElement;
