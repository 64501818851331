import { useAppSelector } from "../../rtk-core/src/app/hooks";
import { getElementFixtureOpponent } from "../../rtk-core/src/features/fixtures";
import { difficultyLabels } from "../../utils/fixtures";
import { VisuallyHidden } from "../Utils";
import { StyledOpponent } from "./styles";
import { IOpponent } from "./types";

const ElementFixtureOpponent = ({ element, fixture }: IOpponent) => {
  const elementFixtureOpponent = useAppSelector((state) =>
    getElementFixtureOpponent(state, element, fixture)
  );
  const isHome = element.team === fixture.team_h;
  const difficultyIndex = isHome
    ? fixture.team_h_difficulty
    : fixture.team_a_difficulty;
  const difficultyLabel = difficultyIndex
    ? difficultyLabels[difficultyIndex]
    : "";
  return (
    <StyledOpponent $difficulty={difficultyLabel}>
      {elementFixtureOpponent}
      <VisuallyHidden>{difficultyLabel}</VisuallyHidden>
    </StyledOpponent>
  );
};

export default ElementFixtureOpponent;
