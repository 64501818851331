import { rgba } from "polished";
import styled from "styled-components";

export const PitchCardButton = styled.button`
  padding: 4px;
  border: 0;
  border-radius: ${(props) => props.theme.radii[2]};
  background-color: transparent;

  &:hover,
  &:focus {
    border-style: solid;
    border-color: #fff;
  }

  &:hover {
    border-width: 2px;
    padding: 2px;
  }

  &:focus {
    padding: 0;
    border-width: 4px;
    outline: 0;
  }
`;

export const PitchCard = styled.div`
  width: 6rem;
  padding: 1px;
  box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2);
  min-height: 7.8rem;
  border-radius: ${(props) => props.theme.radii[1]};
  background-color: ${(props) => props.theme.colors.darkGreen};
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: 1rem;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 10.6rem;
    min-height: 13.2rem;
  }
`;

export const PitchPlaceholder = styled(PitchCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};
  margin: auto;
  padding: ${(props) => props.theme.space[1]};
  border-color: ${rgba("#fff", 0.3)};
  color: #fff;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding: ${(props) => props.theme.space[3]};
  }
`;

export const HiddenDescription = styled.div`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 1rem;
  color: ${rgba("#fff", 0.6)};
  line-height: 1.2;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    font-size: ${(props) => props.theme.fontSizes[0]};
  }
`;
