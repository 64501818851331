import * as React from "react";
import { ITeam } from "../../rtk-core/src/features/teams";
import styled from "styled-components";

const StyledBadge = styled.img`
  display: inline-block;
  vertical-align: middle;
`;

const badgePath = "https://resources.premierleague.com/premierleague/badges/70";
const prefix = "t";

interface IProps {
  isPresentation?: boolean;
  sizes: string;
  team: ITeam;
}

const Badge: React.FC<IProps> = ({ isPresentation, sizes, team }) => {
  const code = team.code || 0;

  if (!code) {
    return null;
  }

  const path = `${badgePath}/${prefix}${code}`;

  const imgProps = {
    alt: team.name,
    role: "",
    sizes,
    src: `${path}.png`,
    srcSet: `${path}.png 70w, ${path}@x2.png 140w`,
  };

  if (isPresentation) {
    imgProps.alt = "";
    imgProps.role = "presentation";
  }

  return <StyledBadge {...imgProps} />;
};

export default Badge;
