import RankLevels from "./RankLevels";
import RankGraph from "./RankGraph";
import RankInfo from "./RankInfo";
import { StyledRanksExplained } from "./styles";

const RanksExplained = () => (
  <StyledRanksExplained>
    <RankInfo
      description="After playing five Gameweeks, you will receive a rank badge based on your performance. This rank will keep updating week to week, taking into a number of performance factors."
      isPrimary={true}
      heading="Rank Badges"
    >
      <RankGraph />
    </RankInfo>

    <RankInfo
      description="Each rank badge has five stars of progress before promotion to the next rank badge."
      heading="Rank Badge Stars"
    >
      <RankLevels rankType="ranked" />
    </RankInfo>

    <RankInfo
      description="As you play your first 5 Gameweeks, you will countdown to earning your rank badge."
      heading="Earn Your Rank"
    >
      <RankLevels rankType="unranked" />
    </RankInfo>
  </StyledRanksExplained>
);

export default RanksExplained;
