import { apiSlice } from "./apiSlice";
import { ICupDetailData, ICupDetailTransformed } from "../cup";

export const cupApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchEntryCupDetail: builder.query<ICupDetailTransformed, number>({
      query: (entryId: number) => `entry/${entryId}/cup/`,
      transformResponse: (response: ICupDetailData, meta, entryId) => {
        return {
          id: entryId,
          data: response,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useFetchEntryCupDetailQuery } = cupApi;
