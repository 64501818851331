import styled from "styled-components";

export const StyledRanksExplained = styled.div`
  border-radius: 16px;
  overflow: hidden;

  // Hide system-settings scroll bar on large screens
  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    ::-webkit-scrollbar {
      visibility: hidden;
      width: 0;
    }
  }
`;
