import * as React from "react";
import styled from "styled-components";
import { BoldLinkStyles } from "../BoldLink";
import { ChevronRight } from "../Chevrons";

const StyledBoldHyperlink = styled.a`
  ${BoldLinkStyles}
`;

export interface IProps {
  children: React.ReactNode;
  href: string;
  className?: string;
}

export const BoldHyperlink: React.FC<IProps> = ({
  children,
  href,
  className = "",
}) => (
  <StyledBoldHyperlink className={className} href={href}>
    {children}
    <ChevronRight />
  </StyledBoldHyperlink>
);

export default BoldHyperlink;
