import * as React from "react";
import { IServerError } from "../../rtk-core/src/features/global";
import Copy from "../Copy";
import { Main, Wrapper } from "../Layout";

interface IProps {
  error: IServerError;
}

const HomeOrHelp = () => (
  <p>
    You can return to the <a href="/">home page</a> and try again or{" "}
    <a href="/help">contact us</a> for further help.
  </p>
);

const renderError = (e: IServerError) => {
  switch (e.code) {
    case 401:
    case 403:
      return (
        <Copy>
          <h4>Permission denied</h4>
          <p>
            Sorry, but you don't have permission to perform the request action.
          </p>
          <HomeOrHelp />
        </Copy>
      );
    case 404:
      return (
        <Copy>
          <h4>Page not found</h4>
          <p>Sorry, but the page you were looking for can't be found.</p>
          <HomeOrHelp />
        </Copy>
      );
    case 502:
    case 503:
    case 504:
      return (
        <Copy>
          <h4>Unavailable</h4>
          <p>
            Sorry, but we are unable to load the requested page at this time.
          </p>
          <p>Please try again in a few minutes.</p>
        </Copy>
      );
    default:
      return (
        <Copy>
          <h2>Error</h2>
          <p>
            Sorry, but there has been an unexpected error processing your
            request.
          </p>
          <HomeOrHelp />
        </Copy>
      );
  }
};

const ServerError: React.FC<IProps> = ({ error }) => {
  return (
    <Wrapper>
      <Main>{renderError(error)}</Main>
    </Wrapper>
  );
};

export default ServerError;
