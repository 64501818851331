import { createAsyncThunk } from "@reduxjs/toolkit";
import { IThunkApi } from "../../app/types";
import { entriesApi } from "../api/entries";
import { getActiveEvent } from "../events";
import { getActiveEntry } from "./selectors";
import { IUpdateEntryData } from "./types";

export const updateEntry = createAsyncThunk<void, IUpdateEntryData, IThunkApi>(
  "entries/updateEntry",
  async (data: IUpdateEntryData, { dispatch, getState }) => {
    const activeEntry = getActiveEntry(getState());
    const activeEvent = getActiveEvent(getState());
    await dispatch(entriesApi.endpoints.updateEntry.initiate(data)).then(() => {
      dispatch(
        entriesApi.endpoints.fetchEntrySummary.initiate(
          {
            eventId: activeEvent.id,
            entryId: activeEntry!,
          },
          { forceRefetch: true }
        )
      );
    });
  }
);

export const unsubscribeEntry = createAsyncThunk<void, string, IThunkApi>(
  "entries/updateEntry",
  async (signature: string, { dispatch, getState }) => {
    const activeEntry = getActiveEntry(getState());
    const activeEvent = getActiveEvent(getState());
    await dispatch(
      entriesApi.endpoints.unsubcribeEntry.initiate(signature)
    ).then(() =>
      dispatch(
        entriesApi.endpoints.fetchEntrySummary.initiate(
          {
            eventId: activeEvent.id,
            entryId: activeEntry!,
          },
          { forceRefetch: true }
        )
      )
    );
  }
);
