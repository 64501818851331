import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../rtk-core/src/app/store";
import { getPlayerData } from "../../rtk-core/src/features/player/playerSlice";
import { IPlayer } from "../../rtk-core/src/features/player/types";
import Button from "../Button";
import { FieldWrap, InputField, TextAreaField } from "../FieldRenderers";

// For report offensive name
interface IOwnProps {
  message?: string;
  subject?: string;
}

interface IPropsFromState {
  canCancel?: boolean;
  player: IPlayer | null;
}

export type Props = IOwnProps & IPropsFromState;

const ContactUs: React.FC<Props> = ({
  message,
  player,
  subject,
  canCancel = false,
}) => {
  const [state, setState] = React.useState({
    nameState: player ? `${player.first_name} ${player.last_name}` : "",
    emailState: player ? player.email : "",
    subjectState: subject ? subject : "",
    messageState: message ? message : "",
    isSubmitting: false,
  });

  let navigate = useNavigate();

  const { nameState, emailState, subjectState, messageState, isSubmitting } =
    state;
  const disabled =
    !(nameState && emailState && subjectState && messageState) || isSubmitting;
  const submitButton = (
    <Button type="submit" disabled={disabled}>
      {isSubmitting ? "Please wait..." : "Send Message"}
    </Button>
  );

  const handleNameChange = (e: React.FormEvent<HTMLInputElement>) =>
    setState({ ...state, nameState: e.currentTarget.value });

  const handleEmailChange = (e: React.FormEvent<HTMLInputElement>) =>
    setState({ ...state, emailState: e.currentTarget.value });

  const handleSubjectChange = (e: React.FormEvent<HTMLInputElement>) =>
    setState({ ...state, subjectState: e.currentTarget.value });

  const handleMessageChange = (e: React.FormEvent<HTMLTextAreaElement>) =>
    setState({ ...state, messageState: e.currentTarget.value });

  const handleSubmit = () => {
    setState({ ...state, isSubmitting: true });
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      {!player && (
        <div className="mb-4">
          <p>
            If you have an account please login before sending your message. If
            you are unable to login please provide as many details as possible
            to help us find your account.
          </p>
        </div>
      )}
      <form
        action="https://ismfg.sirportly.com/remote_form/1715"
        method="post"
        onSubmit={handleSubmit}
      >
        <FieldWrap>
          <InputField
            name="ticket[name]"
            id="ismSFName"
            label="Your name:"
            value={nameState}
            readonly={player ? true : false}
            onChange={handleNameChange}
          />
        </FieldWrap>
        <FieldWrap>
          <InputField
            name="ticket[email]"
            type="email"
            id="ismSFEmail"
            label="Your email:"
            value={emailState}
            readonly={player ? true : false}
            onChange={handleEmailChange}
          />
        </FieldWrap>
        <FieldWrap>
          <InputField
            name="ticket[subject]"
            id="ismSFSubject"
            label="Subject:"
            value={subjectState}
            readonly={subject ? true : false}
            onChange={handleSubjectChange}
          />
        </FieldWrap>
        <FieldWrap>
          <TextAreaField
            name="ticket[message]"
            id="ismSFMessage"
            label="Your message:"
            value={messageState}
            onChange={handleMessageChange}
            rows={10}
          />
        </FieldWrap>
        {canCancel ? (
          <div className="flex">
            {submitButton}
            <div className="ml-4">
              <Button onClick={goBack} $variant="tertiary">
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <>{submitButton}</>
        )}
      </form>
    </>
  );
};

export { ContactUs as ContactUsTest };

const mapStateToProps = (state: RootState) => ({
  player: getPlayerData(state),
});

export default connect(mapStateToProps)(ContactUs);
