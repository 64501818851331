import { ArrowRight } from "../../Icons";
import { rgba, size } from "polished";
import styled from "styled-components";

export const RankArrow = styled(ArrowRight)`
  ${size(16)};
  fill: ${(props) => rgba(props.theme.colors.primary, 0.4)};
`;

export const RankInfoBadgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RankLevelsRanksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 720px;
  width: 800px;
  margin: 1.6rem auto;
  padding: 0 0.8rem;
`;

export const RankInfoWrapper = styled.div`
  overflow: scroll;

  // Padding and hidden overflow added to prevent
  // system-settings scroll bar from obscuring content.
  overflow-y: hidden;
  padding-bottom: ${(props) => props.theme.space[2]};

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    padding-bottom: ${(props) => props.theme.space[1]};
  }
`;
