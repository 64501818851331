import { format } from "date-fns";
import moment from "moment";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../rtk-core/src/app/hooks";
import { getElementStatsByIdentifier } from "../../rtk-core/src/features/elementStats";
import {
  getElementsById,
  showElementSummary,
} from "../../rtk-core/src/features/elements";
import { getEventsById } from "../../rtk-core/src/features/events";
import {
  IFixtureDeadline,
  getFixturesByEntrySubmissionDeadline,
} from "../../rtk-core/src/features/fixtures";
import { ChevronDown, ChevronRight } from "../Chevrons";
import Copy from "../Copy";
import Dialog from "../Dialog";
import DialogBody from "../DialogBody";
import DialogHeader from "../DialogHeader";
import DialogManager from "../DialogManager";
import Fixture from "../Fixture/Fixture";
import FixtureDeadline from "../FixtureDeadline";
import {
  DeadlineFixtureList,
  DeadlineInformationWrapper,
  ElementButton,
  FinalDeadline,
  FinalDeadlineDate,
  FixtureButton,
  FixtureDay,
  FixtureDayHeading,
  FixtureDealineButton,
  FixtureItem,
  FixtureList,
  FixtureStat,
  FixtureStatBody,
  FixtureStatHeading,
  FixtureStatItem,
  FixtureStatList,
  FixtureStatsWrap,
  FixturesHeader,
  Heading,
  IconWrap,
  KOTimeFixtures,
  StyledFixtures,
} from "./styles";
import {
  FixtureStatProps,
  IFixtureDeadlineDialogProps,
  IFixtureStatsForTeamProps,
} from "./types";

const FixtureDeadlineDialog: React.FC<IFixtureDeadlineDialogProps> = ({
  handleHide,
}) => (
  <Dialog closeDialog={handleHide}>
    <DialogHeader closeDialog={handleHide}>Deadlines explained</DialogHeader>
    <DialogBody isPadded={true}>
      <Copy>
        <p>
          Once a player's team has started their first match of the Gameweek
          they will be locked from any changes.
        </p>
        <p>
          Changes to your team can be made at any time up until this point with
          players left to play.
        </p>
      </Copy>
    </DialogBody>
  </Dialog>
);

const FixtureStatsForTeam: React.FC<IFixtureStatsForTeamProps> = ({
  elementsById,
  showElementDialog,
  stats,
}) => (
  <FixtureStatList>
    {stats
      .filter((fs) => elementsById[fs.element])
      .map((fs) => (
        <FixtureStatItem key={fs.element}>
          <ElementButton onClick={(e: any) => showElementDialog(fs.element)}>
            {elementsById[fs.element].web_name}
          </ElementButton>{" "}
          ({fs.value})
        </FixtureStatItem>
      ))}
  </FixtureStatList>
);

const FixtureStats: React.FC<FixtureStatProps> = ({
  elementsById,
  id,
  isOpen = false,
  showElementDialog,
  stats,
  statsByIdentifier,
}) => {
  // Our render stats should contain only stats with data and a maximum
  // of 5 per team of BPS
  const renderStats = stats
    .filter((stat) => stat.h.length || stat.a.length)
    .map((stat) =>
      stat.identifier === "bps"
        ? {
            identifier: stat.identifier,
            h: stat.h.slice(0, 5),
            a: stat.a.slice(0, 5),
          }
        : stat
    );
  return (
    <FixtureStatsWrap isOpen={isOpen} id={id}>
      <ul>
        {renderStats.map((stat) => (
          <FixtureStat key={stat.identifier}>
            <FixtureStatHeading>
              {statsByIdentifier[stat.identifier].label}
            </FixtureStatHeading>
            <FixtureStatBody>
              <FixtureStatsForTeam
                stats={stat.h}
                elementsById={elementsById}
                showElementDialog={showElementDialog}
              />
              <FixtureStatsForTeam
                stats={stat.a}
                elementsById={elementsById}
                showElementDialog={showElementDialog}
              />
            </FixtureStatBody>
          </FixtureStat>
        ))}
      </ul>
    </FixtureStatsWrap>
  );
};

// Used to generate unique ids for collapsible content / aria controls
let lastId = 0;
const newId = (prefix = "ism-id") => `${prefix}${++lastId}`;

interface IFixturesProps {
  eventId: number;
}

type IProps = IFixturesProps & Partial<typeof defaultProps>;

const Fixtures: React.FC<IProps> = ({ eventId }: IProps) => {
  const dispatch = useAppDispatch();

  const elementsById = useAppSelector(getElementsById);
  const event = useAppSelector((state) =>
    eventId ? getEventsById(state)[eventId] : null
  );
  const statsByIdentifier = useAppSelector(getElementStatsByIdentifier);

  const [isOpen, setIsOpen] = useState<Record<string, boolean>>({});

  const currentTime = moment();
  // May have to be put within a useEffect
  const ariaId = newId("ism-collapsible-");

  const showElementDialog = (elementId: number) => {
    dispatch(showElementSummary(elementId));
  };

  const toggle = (fixtureId: number) => {
    setIsOpen({
      ...isOpen,
      [fixtureId]: !isOpen[fixtureId],
    });
  };

  const eventFixtureGroups = useAppSelector((state) =>
    getFixturesByEntrySubmissionDeadline(state, eventId)
  );

  if (!event) {
    return null;
  }

  const allFixtureDeadlines: IFixtureDeadline[] = eventFixtureGroups.flatMap(
    (fd) => fd.fixtureDeadlines || []
  );

  const upcomingDeadlines = allFixtureDeadlines
    .filter((fd) => new Date(fd.deadline) > new Date())
    .sort((a, b) => {
      if (new Date(a.deadline).getTime() < new Date(b.deadline).getTime())
        return -1;
      if (new Date(a.deadline).getTime() > new Date(b.deadline).getTime())
        return 1;
      return 0;
    });

  const nextDeadline = upcomingDeadlines[0] || null;
  const finalDeadline = allFixtureDeadlines.find(
    (fd) => fd.eventDeadlineId === "Final Deadline"
  );

  return (
    <StyledFixtures>
      <FixturesHeader>
        <Heading>{`${event.name} Fixtures`}</Heading>
        <DeadlineInformationWrapper>
          {finalDeadline && (
            <>
              <FinalDeadline>Final Deadline</FinalDeadline>
              <FinalDeadlineDate>
                {format(finalDeadline.deadline, "EEE dd MMM HH:mm")}
              </FinalDeadlineDate>
            </>
          )}
        </DeadlineInformationWrapper>
        <DialogManager
          render={(showDialog, handleShow, handleHide) => (
            <>
              <FixtureDealineButton onClick={handleShow}>
                Deadlines explained
                <ChevronRight />
              </FixtureDealineButton>
              {showDialog && <FixtureDeadlineDialog handleHide={handleHide} />}
            </>
          )}
        />
      </FixturesHeader>
      <FixtureList>
        {eventFixtureGroups.map((group, index) => (
          <React.Fragment key={`${group.date}`}>
            <FixtureDayHeading
              key={index}
              $isPassed={
                index === 0
                  ? false
                  : nextDeadline
                  ? new Date(group.date) < new Date(nextDeadline.deadline)
                  : false
              }
              $isToCome={
                index === 0
                  ? false
                  : finalDeadline
                  ? new Date(group.date) < new Date(finalDeadline.deadline)
                  : false
              }
            >
              <time
                key={format(group.date, "EEEE d MMMM yyyy")}
                dateTime={group.date.toISOString()}
              >
                {format(group.date, "EEEE d MMMM yyyy")}
              </time>
            </FixtureDayHeading>
            <FixtureDay key={format(group.date, "EEEE d MMMM yyyy")}>
              {group.fixtureDeadlines.map((fixtureDeadline, index) => {
                const isPassed = currentTime.isAfter(fixtureDeadline.deadline);
                const isFinal =
                  fixtureDeadline.eventDeadlineId === "Final Deadline";
                const isPastDeadline =
                  fixtureDeadline.eventDeadlineId === null || isFinal;
                const isNext = nextDeadline === fixtureDeadline;
                const isDeadline =
                  fixtureDeadline.eventDeadlineId !== (null || "");
                return (
                  <KOTimeFixtures
                    key={`koTime_${index}`}
                    $isFinal={isFinal}
                    $isNext={isNext}
                    $isPassed={isPassed}
                    $isToCome={isPastDeadline}
                    $isDeadline={isDeadline}
                  >
                    {fixtureDeadline.eventDeadlineId && (
                      <FixtureDeadline
                        key={`deadline_${index}`}
                        isNext={isNext}
                        deadlineText={`${fixtureDeadline.eventDeadlineId}`}
                        deadlineTime={fixtureDeadline.deadline}
                      />
                    )}
                    <DeadlineFixtureList key={`deadlineFixtureList_${index}`}>
                      {fixtureDeadline.fixtures.map((f, i: number) => (
                        <React.Fragment key={f.id}>
                          {f.started && f.team_h_score !== null ? (
                            <FixtureItem key={`fixtureItem_${i}`}>
                              <FixtureButton
                                key={`fixtureButton_${i}`}
                                onClick={() => toggle(f.id)}
                                isOpen={isOpen[f.id]}
                                aria-expanded={isOpen[f.id] ? true : false}
                                aria-controls={ariaId}
                              >
                                <IconWrap key={`icon_${i}`}>
                                  <ChevronDown key={`chevronDown_${i}`} />
                                </IconWrap>
                                <Fixture key={`fixture_${i}`} fixture={f} />
                              </FixtureButton>
                              <FixtureStats
                                key={`fixtureStats_${i}`}
                                stats={f.stats}
                                elementsById={elementsById}
                                showElementDialog={showElementDialog}
                                statsByIdentifier={statsByIdentifier}
                                isOpen={isOpen[f.id]}
                                id={ariaId}
                              />
                            </FixtureItem>
                          ) : (
                            <FixtureItem key={`fixtureItem_${i}`}>
                              <Fixture key={`fixture_${i}`} fixture={f} />
                            </FixtureItem>
                          )}
                        </React.Fragment>
                      ))}
                    </DeadlineFixtureList>
                  </KOTimeFixtures>
                );
              })}
            </FixtureDay>
          </React.Fragment>
        ))}
      </FixtureList>
    </StyledFixtures>
  );
};

const defaultProps = {
  useLinks: false,
};

Fixtures.defaultProps = defaultProps;

export { Fixtures as FixturesTest };

export default Fixtures;
