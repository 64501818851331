import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { RootState } from "../../app/store";
import {
  IEntryEvent,
  IEntryHistorySummary,
  IEntryHistorySummaryData,
  IEntryPhaseStanding,
  IFetchEntryPhaseStandingsData,
  IFetchEntryPhaseStandingsResponse,
  IFetchEntryPicksData,
  IFetchEntryPicksResponse,
  IFetchEntryQueryParams,
  IFetchEntrySummaryResponse,
  IFetchEntryTransfersResponse,
  ITransfer,
  IUpdateEntryData,
} from "../entries";
import { getPhasesByStartAndStopEventId } from "../phases";
import { apiSlice } from "./apiSlice";

export const entriesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchEntrySummary: builder.query<
      IFetchEntrySummaryResponse,
      IFetchEntryQueryParams
    >({
      queryFn: async (
        { entryId, phaseId, eventId },
        queryApi,
        extraOptions,
        baseQuery
      ) => {
        let url = `entry/${entryId}/`;
        const params = new URLSearchParams();
        if (eventId) {
          params.append("event", eventId.toString());
          const state = queryApi.getState() as RootState;
          if (phaseId) {
            params.append("phase", phaseId.toString());
          } else {
            const activePhase = getPhasesByStartAndStopEventId(
              state,
              eventId,
              eventId
            )[0];
            params.append("phase", activePhase.id.toString());
          }
        }

        if (params.toString()) {
          url += `?${params}`;
        }

        const response = await baseQuery({
          url,
          method: "GET",
        });

        return response.data
          ? {
              data: {
                id: entryId,
                data: response.data,
                eventId,
              } as IFetchEntrySummaryResponse,
            }
          : {
              error: response.error as FetchBaseQueryError,
            };
      },
    }),
    fetchEntryHistory: builder.query<IEntryHistorySummary, number>({
      query: (entryId: number) => ({
        url: `entry/${entryId}/history/`,
        method: "GET",
      }),
      transformResponse: (
        response: IEntryHistorySummaryData,
        meta,
        entryId
      ) => {
        return {
          id: entryId,
          data: response,
        };
      },
    }),
    fetchEntryEventPicks: builder.query<
      IFetchEntryPicksResponse,
      IFetchEntryPicksData
    >({
      query: ({ entryId, eventId }) => ({
        url: `entry/${entryId}/event/${eventId}/picks/`,
        method: "GET",
      }),
      transformResponse: (
        response: IEntryEvent,
        meta,
        { entryId, eventId }
      ) => {
        return {
          entry: entryId,
          event: eventId,
          data: response,
        };
      },
    }),
    fetchEntryTransfers: builder.query<IFetchEntryTransfersResponse, number>({
      query: (entryId: number) => ({
        url: `entry/${entryId}/transfers/`,
        method: "GET",
      }),
      transformResponse: (response: ITransfer[], meta, entryId) => {
        return {
          id: entryId,
          data: response,
        };
      },
    }),
    fetchEntryPhaseStandings: builder.query<
      IFetchEntryPhaseStandingsResponse,
      IFetchEntryPhaseStandingsData
    >({
      query: ({ entryId, phaseId }) => ({
        url: `entry/${entryId}/phase/${phaseId}/standings/`,
        method: "GET",
      }),
      transformResponse: (
        response: IEntryPhaseStanding[],
        meta,
        { entryId, phaseId }
      ) => {
        return {
          entry: entryId,
          phase: phaseId,
          data: response,
        };
      },
    }),
    updateEntry: builder.query<object, IUpdateEntryData>({
      query: (data: IUpdateEntryData) => ({
        url: "entry-update/",
        method: "PUT",
        body: data,
      }),
    }),
    unsubcribeEntry: builder.query<object, string>({
      query: (signature: string) => ({
        url: "entry/unsubscribe/",
        method: "POST",
        body: { signature },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchEntrySummaryQuery,
  useFetchEntryHistoryQuery,
  useFetchEntryEventPicksQuery,
  useFetchEntryPhaseStandingsQuery,
  useFetchEntryTransfersQuery,
  useUnsubcribeEntryQuery,
  useUpdateEntryQuery,
  useLazyFetchEntrySummaryQuery,
  useLazyFetchEntryEventPicksQuery,
  useLazyUpdateEntryQuery,
  useLazyUnsubcribeEntryQuery,
  useLazyFetchEntryHistoryQuery,
} = entriesApi;
