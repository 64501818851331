import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../rtk-core/src/app/store";
import {
  IElementStat,
  getElementStatsByIdentifier,
} from "../../../rtk-core/src/features/elementStats";
import {
  getElement,
  getHistory,
} from "../../../rtk-core/src/features/elements";
import { getTeamsById } from "../../../rtk-core/src/features/teams";
import { getStatAbbr } from "../../../utils/elementStats";
import Tooltip, { TooltipLabel } from "../../Tooltip";
import HistoryRow from "./HistoryRow";
import { HistoryTable } from "./styles";
import { IEventHistoryTable } from "./types";

const EventHistoryTable: React.FC<IEventHistoryTable> = ({
  elementId,
  expandedResults,
}) => {
  const statsByName: Record<string, IElementStat> = useSelector(
    getElementStatsByIdentifier
  );
  const teamsById = useSelector(getTeamsById);
  const fullHistory = useSelector((state: RootState) =>
    getHistory(state, elementId)
  );
  const history = expandedResults ? fullHistory : fullHistory.slice(-5);
  const element = useSelector((state: RootState) =>
    getElement(state, elementId)
  );

  if (!element) {
    return null;
  }

  return (
    <HistoryTable $isBold={false} $hasDividers={false}>
      <thead>
        <tr>
          <th scope="col">GW</th>
          <th scope="col">Opponent</th>
          <th scope="col">Result</th>
          <th scope="col">Points</th>
          <th scope="col">
            <Tooltip content={statsByName["minutes"].label}>
              <TooltipLabel>{getStatAbbr("minutes", statsByName)}</TooltipLabel>
            </Tooltip>
          </th>
        </tr>
      </thead>
      <tbody>
        {[...history].reverse().map((h) => (
          <HistoryRow
            key={h.fixture}
            history={h}
            teamsById={teamsById}
            statsByName={statsByName}
          />
        ))}
      </tbody>
    </HistoryTable>
  );
};

export default EventHistoryTable;
