import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { bootstrapApi } from "../api/bootstrap";
import { IGetBootstrapStaticResponse } from "../bootstrap";
import { IGame, IRules, IScoring, ISettings } from "./types";

const initialState: IGame = {
  rules: null,
  settings: null,
  scoring: null,
  total_players: 0,
};

const game = createSlice({
  name: "game",
  initialState,
  reducers: {
    addGameRules: (state, action: PayloadAction<IRules>) => ({
      ...state,
      rules: action.payload,
    }),
    addGameSettings: (state, action: PayloadAction<ISettings>) => ({
      ...state,
      settings: action.payload,
    }),
    addGameScoring: (state, action: PayloadAction<IScoring>) => ({
      ...state,
      scoring: action.payload,
    }),
    addTotalPlayers: (state, action: PayloadAction<number>) => ({
      ...state,
      total_players: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      bootstrapApi.endpoints.getBootstrapStatic.matchFulfilled,
      (state, action: PayloadAction<IGetBootstrapStaticResponse>) =>
        game.caseReducers.addGameRules(state, {
          ...action,
          payload: action.payload.game_config.rules,
        })
    );
    builder.addMatcher(
      bootstrapApi.endpoints.getBootstrapStatic.matchFulfilled,
      (state, action: PayloadAction<IGetBootstrapStaticResponse>) =>
        game.caseReducers.addGameSettings(state, {
          ...action,
          payload: action.payload.game_config.settings,
        })
    );
    builder.addMatcher(
      bootstrapApi.endpoints.getBootstrapStatic.matchFulfilled,
      (state, action: PayloadAction<IGetBootstrapStaticResponse>) =>
        game.caseReducers.addGameScoring(state, {
          ...action,
          payload: action.payload.game_config.scoring,
        })
    );
    builder.addMatcher(
      bootstrapApi.endpoints.getBootstrapStatic.matchFulfilled,
      (state, action: PayloadAction<IGetBootstrapStaticResponse>) =>
        game.caseReducers.addTotalPlayers(state, {
          ...action,
          payload: action.payload.total_players,
        })
    );
  },
});

export const {
  addGameRules,
  addTotalPlayers,
  addGameSettings,
  addGameScoring,
} = game.actions;

export default game.reducer;
