import { rgba } from "polished";
import styled from "styled-components";

export const StyledScoreboardStat = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${(props) => props.theme.space[2]} 0;

  &:not(:first-child) {
    border-style: solid;
    border-width: 1px 0 0;
    border-image: linear-gradient(
        270deg,
        ${rgba("white", 0)} 0%,
        ${rgba("white", 0.25)} 50%,
        ${rgba("white", 0)} 100%
      )
      1;
  }
`;

export const ScoreboardStatHeading = styled.h4`
  flex: 1;
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: ${(props) => props.theme.fontSizes[0]};
  font-size: ${(props) => props.theme.fontSizes[0]};
`;

export const ScoreboardStatValue = styled.div`
  flex: 1;
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[3]};
  text-align: right;
`;
