import { useContext, useEffect } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import WebViewContext from "../contexts/WebViewContext";
import "../index.css";
import { useAppDispatch, useAppSelector } from "../rtk-core/src/app/hooks";
import { useLazyGetEventsQuery } from "../rtk-core/src/features/api/events";
import {
  doEntryPerEventBootstrap,
  getEntryEventBootstrapped,
} from "../rtk-core/src/features/bootstrap/";
import {
  getElementDialog,
  showElementSummary,
} from "../rtk-core/src/features/elements";
import { getEntry } from "../rtk-core/src/features/entries";
import { getActiveEvent } from "../rtk-core/src/features/events";
import { getServerError } from "../rtk-core/src/features/global";
import { getPlayerData } from "../rtk-core/src/features/player";
import { getPlayerComp } from "../utils/player-comps";
import "../webview.css";
import AutoJoinDialog from "./AutoJoinDialog";
import Dialog from "./Dialog";
import ElementInfo from "./ElementInfo";
import FixtureBroadcasters from "./FixtureBroadcasters";
import GameHeader from "./GameHeader";
import HeadlineLogo from "./HeadlineLogo";
import { Wrapper } from "./Layout";
import Link from "./Link";
import Loading from "./Loading";
import Navigation from "./Navigation";
import ServerError from "./ServerError/ServerError";
import StyledPlayerComp from "./StyledPlayerComp";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { pathname, search } = useLocation();
  const { entryId, eventId } = useParams();
  const [searchParams] = useSearchParams();

  const { isWebView, setIsWebView } = useContext(WebViewContext);

  const activeEvent = useAppSelector(getActiveEvent);
  const entryEventBootstrapped = useAppSelector(getEntryEventBootstrapped);
  const elementDialog = useAppSelector(getElementDialog);
  const player = useAppSelector(getPlayerData);
  const playerEntry = useAppSelector((state) =>
    player && player.entry ? getEntry(state, player?.entry) : null
  );
  const serverError = useAppSelector(getServerError);

  const [getEventsTrigger] = useLazyGetEventsQuery();

  // If the pathname is root we navigate to the appropriate Home URL
  if (pathname === "/") {
    // This is needed as activeEvent isn't set until bootstrap is finished
    if (activeEvent) {
      navigate({ pathname: `home`, search }, { replace: true });
    }
  }

  useEffect(() => {
    if (searchParams.has("webview")) {
      setIsWebView(true);
    }
  }, [searchParams, setIsWebView]);

  useEffect(() => {
    dispatch(doEntryPerEventBootstrap({ entryId, eventId }));
  }, [dispatch, entryId, eventId]);

  useEffect(() => {
    if (activeEvent && (activeEvent.is_current || activeEvent.is_next)) {
      getEventsTrigger(undefined, false);
    }
  }, [activeEvent, getEventsTrigger, pathname]);

  const handleDialogClose = () => {
    dispatch(showElementSummary(0));
  };

  if (serverError) {
    return (
      <>
        <GameHeader>
          <Wrapper>
            <StyledPlayerComp $images={getPlayerComp()}>
              <h1>
                <HeadlineLogo
                  id="ism-game-title"
                  title="Fantasy Challenge"
                  $isWebView={isWebView}
                />
              </h1>
              <Navigation />
            </StyledPlayerComp>
          </Wrapper>
        </GameHeader>
        <ServerError error={serverError} />
      </>
    );
  }

  const loading = !(player && player.entry
    ? Boolean(playerEntry)
    : activeEvent);

  return !loading ? (
    <>
      <GameHeader>
        <Wrapper>
          <StyledPlayerComp $images={getPlayerComp()}>
            <h1>
              <Link to={`/home/${activeEvent.id}`}>
                <HeadlineLogo
                  id="ism-game-title"
                  title="Fantasy Challenge"
                  $isWebView={isWebView}
                />
              </Link>
            </h1>
            <Navigation />
          </StyledPlayerComp>
        </Wrapper>
      </GameHeader>
      {!entryEventBootstrapped ? (
        <Loading />
      ) : (
        <FixtureBroadcasters>
          <Outlet />
        </FixtureBroadcasters>
      )}
      <AutoJoinDialog />
      {elementDialog !== 0 && (
        <Dialog closeDialog={handleDialogClose}>
          <ElementInfo />
        </Dialog>
      )}
    </>
  ) : null;
};

export default App;
