export interface DataLayerObject {
  [key: string]: any;
}

declare const window: Window & { dataLayer: DataLayerObject };

export const pushToGtagDataLayer = (obj: DataLayerObject) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
};
