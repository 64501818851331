import styled from "styled-components";

export const ExplainContent = styled.div`
  margin-top: ${(props) => props.theme.space[4]};

  &[aria-hidden] {
    display: none;
  }

  &[aria-hidden="false"] {
    display: block;
  }
`;
