import { useEffect, useState } from "react";
import { useAppSelector } from "../../../rtk-core/src/app/hooks";
import { useLazyFetchEventLiveQuery } from "../../../rtk-core/src/features/api/elements";
import {
  IElementStat,
  getElementStatsByIdentifier,
} from "../../../rtk-core/src/features/elementStats";
import {
  IElementExplain,
  getElementsEventDataById,
} from "../../../rtk-core/src/features/elements";
import { getActiveEvent } from "../../../rtk-core/src/features/events";
import { ChevronDown } from "../../Chevrons";
import Table from "../../Table";
import ElementFixture from "../ElementFixture";
import { ExplainButton } from "../ElementFixtureItem/styles";
import { ExplainContent } from "./styles";
import { ElementInfoExplain } from "./types";

const ElementExplain = ({ elementId, fixture }: ElementInfoExplain) => {
  const activeEvent = useAppSelector(getActiveEvent);
  const elementsDataById = useAppSelector((state) =>
    getElementsEventDataById(state, activeEvent.id)
  );
  const statsByIdentifier: Record<string, IElementStat> = useAppSelector(
    getElementStatsByIdentifier
  );
  const [fetchEventLiveTrigger] = useLazyFetchEventLiveQuery();

  const [state, setState] = useState({ open: false });
  const [id, setId] = useState("");

  // Used to generate unique ids for collapsible content / aria controls
  let lastId = 0;

  useEffect(() => {
    const newId = (prefix = "ism-id") => `${prefix}${++lastId}`;
    setId(() => newId("ism-explain-"));
  }, [lastId]);

  const handleShowExplain = () => {
    fetchEventLiveTrigger(activeEvent.id);
    setState({ open: !state.open });
  };

  const explain = elementsDataById ? elementsDataById[elementId].explain : [];

  const getExplainForFixture = (explain: IElementExplain[]) => {
    if (explain.length === 1) {
      return explain[0];
    } else if (explain.length > 1) {
      return explain.find((e) => e.fixture === fixture.id);
    }
    return null;
  };

  const fixtureExplain = getExplainForFixture(explain);

  if (!fixtureExplain) {
    return null;
  }

  return (
    <>
      <ExplainButton
        type="button"
        onClick={handleShowExplain}
        aria-expanded={state.open}
        aria-controls={id}
      >
        <ElementFixture elementId={elementId} fixture={fixture} />
        <ChevronDown />
      </ExplainButton>
      <ExplainContent id={id} aria-hidden={!state.open}>
        <Table $hasBorder={false} $isBold={false}>
          <thead>
            <tr>
              <th scope="col">Statistic</th>
              <th scope="col">Value</th>
              <th scope="col">Points</th>
            </tr>
          </thead>
          <tbody>
            {fixtureExplain.stats.map((stat) => (
              <tr key={stat.identifier}>
                <td>{statsByIdentifier[stat.identifier].label}</td>
                <td>{stat.value}</td>
                <td>
                  {stat.points}
                  {stat.points_modification !== 0 &&
                    ` (${stat.points_modification > 0 ? "+" : ""}${
                      stat.points_modification
                    })`}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ExplainContent>
    </>
  );
};

export default ElementExplain;
