import React from "react";
import { Main, Wrapper } from "../Layout";

const Loading: React.FC = () => (
  <Wrapper>
    <Main>
      <div className="mx-2">Loading ...</div>
    </Main>
  </Wrapper>
);

export default Loading;
