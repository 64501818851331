import { size } from "polished";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import ChevronRightIcon from "../../../img/icons/chevron-right.svg?react";
import { IStyledEventStat } from "./types";

export const EventStatValue = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[2]};
`;

export const StyledEventStat = styled.li<IStyledEventStat>`
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  gap: ${(props) => (props.$isPrimary ? "0.1rem" : "0.7rem")};
  border-radius: ${(props) => props.theme.radii[2]};
  line-height: 1;
  text-align: center;

  ${({ $isPrimary, theme }) =>
    $isPrimary &&
    css`
      padding: ${theme.space[1]};
      background-color: ${theme.colors.white};

      ${EventStatValue} {
        font-family: ${theme.fonts.bold};
        font-size: 3rem;
        line-height: 1.33333;
      }
    `}
`;

export const EventStatHeading = styled.h5`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 1rem;
  line-height: 1.2;
`;

export const EventStatLink = styled(Link)`
  color: currentColor;
  text-decoration: none;
`;

export const EventStatChevron = styled(ChevronRightIcon)`
  ${size(8)}
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.2rem;
`;
