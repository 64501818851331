import { size } from "polished";
import styled, { css } from "styled-components";
import BaseExternalIcon from "../../img/icons/external.svg?react";
import { IFixtureScoreProps, IStyledFixture } from "./types";

export const StyledFixture = styled.div<IStyledFixture>`
  overflow: auto;
  border-radius: ${({ theme }) => theme.radii[2]};
  font-size: inherit;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    position: relative;
  }

  ${(props) =>
    props.$isStarted &&
    css`
      flex: 1;
    `}

  /* Pad left side by width of IconWrap on started fixture button */
  ${(props) =>
    !props.$isStarted &&
    css`
      padding-left: 3rem;

      @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
        padding-left: 4.4rem;
      }
    `}
`;

export const Teams = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.space[2]} 0;
`;

export const TimePlayed = styled.span`
  position: absolute;
  left: 10px;
  padding: 0.3rem ${({ theme }) => theme.space[1]};
  font-family: ${({ theme }) => theme.fonts.heavy};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  vertical-align: middle;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 1.7rem;
  }
`;

export const Team = styled.div`
  flex: 1 1 calc(50% - (4.8rem / 2));
`;

export const TeamH = styled(Team)`
  text-align: right;
`;

export const TeamName = styled.abbr`
  font-family: ${({ theme }) => theme.fonts.bold};
  vertical-align: middle;
  text-decoration: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    &:after {
      content: attr(title);
    }
    span {
      display: none;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    &:after {
      font-size: 1.7rem;
    }
  }
`;

export const FixtureBadge = styled.div`
  display: inline-block;
  margin-right: 0.6rem;
  margin-left: 0.6rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-right: 0.8rem;
    margin-left: 0.8rem;
  }
`;

export const FixtureKOTime = styled.time`
  flex: 1 0 4.8rem;
  display: inline-block;
  padding: 0.2rem ${({ theme }) => theme.space[2]};
  border: 1px solid ${({ theme }) => theme.colors.purple10};
  border-radius: ${({ theme }) => theme.radii[1]};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: 0.1rem 0.6rem;
    font-size: ${({ theme }) => theme.fontSizes[3]};
  }
`;

export const FixtureScore = styled.div<IFixtureScoreProps>`
  flex: 1 0 4.8rem;
  display: flex;
  padding: 0.8rem 0;
  border-radius: ${({ theme }) => theme.radii[0]};
  background: ${({ theme, finished }) =>
    finished ? `${theme.colors.primary}` : theme.colors.pinkToOrange};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: 0.7rem 0;
  }
`;

export const Broadcaster = styled.div`
  margin: 0.7rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24%;
    margin: 0;
    text-align: right;
  }
`;

export const BroadcasterBox = styled.div`
  padding: 0 ${({ theme }) => theme.space[1]};
  text-align: center;
`;

export const BroadcasterList = styled.ul`
  display: flex;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    display: block;
  }
`;

export const BroadcasterItem = styled.li`
  padding-right: ${({ theme }) => theme.space[1]};
  padding-left: ${({ theme }) => theme.space[1]};
  flex: 1;

  :not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.grey};
    text-align: right;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    display: inline-block;
    :not(:last-child) {
      text-align: left;
    }
  }
`;

export const StyledLogo = styled.img`
  vertical-align: middle;
`;

export const ButtonWrap = styled.div`
  text-align: center;
`;

export const TVButton = styled.button`
  padding: ${({ theme }) => theme.space[1]};
  border: 0;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  color: black;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: inherit;
  cursor: pointer;
`;

export const TVLogo = styled.img`
  margin-right: ${({ theme }) => theme.space[1]};
  vertical-align: middle;
`;

export const ButtonText = styled.span`
  vertical-align: middle;
`;

export const TVLink = styled.a`
  display: inline-block;
  margin: ${({ theme }) => theme.space[1]} 0;
  padding: ${({ theme }) => theme.space[1]} 0.4rem;
  border: 0;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1rem;
  color: #333;
  text-decoration: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
    font-size: inherit;
  }
`;

export const ExternalIcon = styled(BaseExternalIcon)`
  ${size(10)}
  margin-left: ${({ theme }) => theme.space[1]};
  fill: black;
  vertical-align: middle;
`;
