import React from "react";
import SubNav from "../SubNav";

const links = [
  { href: "/help", text: "Fantasy Challenge" },
  { href: "/help/faq", text: "FAQs" },
  { href: "/help/rules", text: "Rules" },
  { href: "/help/terms", text: "T&Cs" },
];

const HelpNav: React.FC = () => <SubNav links={links} />;

export default HelpNav;
