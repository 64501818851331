import { IDreamTeamData, IDreamTeamDataForEvent } from "../dreamTeam";
import { apiSlice } from "./apiSlice";

export const dreamTeamApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchEventDreamTeam: builder.query<IDreamTeamDataForEvent, number>({
      query: (eventId: number) => `/dream-team/${eventId}/`,
      transformResponse: (response: IDreamTeamData, meta, eventId) => {
        return {
          event: eventId,
          data: response,
        };
      },
    }),
    fetchOverallDreamTeam: builder.query<IDreamTeamData, null>({
      query: () => ({
        url: "/dream-team/",
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchEventDreamTeamQuery,
  useFetchOverallDreamTeamQuery,
  useLazyFetchEventDreamTeamQuery,
  useLazyFetchOverallDreamTeamQuery,
} = dreamTeamApi;
