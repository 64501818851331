import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { bootstrapApi } from "../api/bootstrap";
import { elementsApi } from "../api/elements";
import { IGetBootstrapStaticResponse } from "../bootstrap";
import { formatNameForSearching } from "./selectors";
import {
  IElement,
  IElementControls,
  IElementEventRawData,
  IElementState,
  IElementSummaryData,
  IElementSummaryDataTransformed,
  IFetchElementEventDataResponse,
} from "./types";

const initialState: IElementState = {
  byId: {},
  controls: {
    search: "",
    sort: "total_points",
    filter: "",
    maxCost: 0,
    getUnavailable: true,
    excludeRemoved: false,
    getSpecial: false,
    getYetToPlay: false,
  },
  dialog: 0,
  eventDataById: {},
  fixturesById: {},
  historyById: {},
  seasonHistoryById: {},
};

const elements = createSlice({
  name: "elements",
  initialState,
  reducers: {
    addElements: (state, action: PayloadAction<IElement[]>) => ({
      ...state,
      byId: Object.fromEntries(
        action.payload.map((element: IElement) => [
          element.id,
          {
            ...element,
            search_name: formatNameForSearching(element.web_name),
          },
        ])
      ),
    }),
    fetchElementSummary: (
      state,
      action: PayloadAction<{
        summary_data: IElementSummaryData;
        elementId: number;
      }>
    ) => ({
      ...state,
      fixturesById: {
        ...state.fixturesById,
        [action.payload.elementId]: action.payload.summary_data.fixtures,
      },
      historyById: {
        ...state.historyById,
        [action.payload.elementId]: action.payload.summary_data.history,
      },
      seasonHistoryById: {
        ...state.seasonHistoryById,
        [action.payload.elementId]: action.payload.summary_data.history_past,
      },
    }),
    fetchElementEventData: (
      state,
      action: PayloadAction<IFetchElementEventDataResponse>
    ) => ({
      ...state,
      eventDataById: {
        ...state.eventDataById,
        [action.payload.id]: Object.fromEntries(
          action.payload.data.elements.map(
            (element_data: IElementEventRawData) => [
              element_data.id,
              {
                stats: element_data.stats,
                explain: element_data.explain,
                modified: element_data.modified,
              },
            ]
          )
        ),
      },
    }),
    updateElementControls: (
      state,
      action: PayloadAction<IElementControls>
    ) => ({
      ...state,
      controls: action.payload,
    }),
    elementDialogShow: (state, action: PayloadAction<number>) => ({
      ...state,
      dialog: action.payload,
    }),
    elementDialogHide: (state) => ({
      ...state,
      dialog: 0,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      elementsApi.endpoints.fetchEventLive.matchFulfilled,
      (state, action: PayloadAction<IFetchElementEventDataResponse>) =>
        elements.caseReducers.fetchElementEventData(state, {
          ...action,
          payload: action.payload,
        })
    );
    builder.addMatcher(
      bootstrapApi.endpoints.getBootstrapStatic.matchFulfilled,
      (state, action: PayloadAction<IGetBootstrapStaticResponse>) =>
        elements.caseReducers.addElements(state, {
          ...action,
          payload: action.payload.elements,
        })
    );
    builder.addMatcher(
      elementsApi.endpoints.getElementSummary.matchFulfilled,
      (state, action: PayloadAction<IElementSummaryDataTransformed>) =>
        elements.caseReducers.fetchElementSummary(state, {
          ...action,
          payload: action.payload,
        })
    );
    builder.addMatcher(
      elementsApi.endpoints.getElements.matchFulfilled,
      (state, action: PayloadAction<IElement[]>) =>
        elements.caseReducers.addElements(state, {
          ...action,
          payload: action.payload,
        })
    );
  },
});

export const {
  addElements,
  elementDialogHide,
  elementDialogShow,
  fetchElementEventData,
  fetchElementSummary,
  updateElementControls,
} = elements.actions;

export default elements.reducer;
