import {
  Body,
  Content,
  Footer,
  Heading,
  Img,
  StyledChallengePromo,
} from "../styles";
import { IChallengePromo } from "./types";

const ChallengePromo = ({
  children,
  flipped = false,
  footerLink,
  img,
  title,
}: IChallengePromo) => (
  <StyledChallengePromo $flipped={flipped}>
    {/* img */}
    <Img $flipped={flipped}>{img}</Img>
    {/* content */}
    <Content $flipped={flipped}>
      {/* body */}
      <Body>
        <Heading>{title}</Heading>
        <>{children}</>
      </Body>
      {/* footer */}
      {footerLink && <Footer>{footerLink}</Footer>}
    </Content>
  </StyledChallengePromo>
);

export default ChallengePromo;
