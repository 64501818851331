import { size } from "polished";
import styled, { css } from "styled-components";
import BasePadlock from "../../../img/icons/padlock.svg?react";
import { IRankBulletCurrentRank } from "./types";

export const PadlockIcon = styled(BasePadlock)`
  ${size(35)}
  margin: ${(props) => props.theme.space[1]};
`;

export const Bullet = styled.div`
  ${size(10)}
  background-color: black;
  border-radius: 50%;
`;

export const BulletBorder = styled.div<IRankBulletCurrentRank>`
  border: none;
  ${({ $currentRank }) =>
    $currentRank &&
    css`
      padding: 2px;
      border: 2px solid black;
      border-radius: 50%;
    `}
`;
