import styled from "styled-components";

export const ElementType = styled.abbr`
  display: block;
  color: #fff;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: ${(props) => props.theme.fontSizes[0]};
  line-height: 1;
  text-decoration: none;

  /* Reset ism.css */
  &[title] {
    cursor: inherit;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    font-family: ${(props) => props.theme.fonts.bold};

    &::after {
      content: attr(title);
    }
  }
`;

export const ElementTypeShort = styled.span`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: none;
  }
`;
