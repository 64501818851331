import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
`;

export const InfoStyles = css`
  flex: 1 1 100%;
  align-self: flex-start;
  width: 46%;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  color: ${({ theme }) => theme.colors.purple60};
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: 100%;
  }
`;

export const DidNotEnter = styled.p`
  ${InfoStyles}
  text-align: left;
`;

export const LastUpdated = styled.p`
  ${InfoStyles}
  text-align: right;
`;
