import { useAppSelector } from "../../../rtk-core/src/app/hooks";
import { getActiveEvent } from "../../../rtk-core/src/features/events";
import {
  IFixture,
  getFixturesForEventById,
} from "../../../rtk-core/src/features/fixtures";
import { formatRawAsLocal } from "../../../utils/datetime";
import ElementExplain from "../ElementExplain";
import ElementFixture from "../ElementFixture/ElementFixture";
import { ElementFixtureWrap, FixtureDay, FixtureWrap } from "./styles";
import { IElementFixtureItem } from "./types";

const ElementFixtureItem = ({ elementId, fixtureId }: IElementFixtureItem) => {
  const activeEvent = useAppSelector(getActiveEvent);
  const fixturesForEventById: Record<string, IFixture> | null = useAppSelector(
    (state) => getFixturesForEventById(state, activeEvent.id)
  );

  const fixture = fixturesForEventById?.[fixtureId];

  if (!fixture) {
    return null;
  }

  return (
    <div>
      {fixture.kickoff_time && (
        <FixtureDay>
          {formatRawAsLocal(fixture.kickoff_time, "EEEE d MMMM ")}
        </FixtureDay>
      )}
      <FixtureWrap>
        {fixture.started && fixture.team_h_score !== null ? (
          <ElementExplain elementId={elementId} fixture={fixture} />
        ) : (
          <ElementFixtureWrap>
            <ElementFixture elementId={elementId} fixture={fixture} />
          </ElementFixtureWrap>
        )}
      </FixtureWrap>
    </div>
  );
};

export default ElementFixtureItem;
