import React from "react";
import CannotEnterEvent from "../components/CannotEnterEvent";
import EventNavigator from "../components/EventNavigator";
import Fixtures from "../components/Fixtures";
import HelmetHead from "../components/HelmetHead";
import { GridItem, GridWrapper, TwoCol50Grid } from "../components/Layout";
import LeaderboardAd from "../components/LeaderboardAd";
import LeaguePanel from "../components/Leagues/LeaguePanel/";
import { DividerLight } from "../components/styles";
import { useAppDispatch, useAppSelector } from "../rtk-core/src/app/hooks";
import {
  getEntry,
  getPrivateClassicLeaguesForEntry,
  getSystemClassicLeaguesForEntry,
} from "../rtk-core/src/features/entries";
import { IEvent, getActiveEvent } from "../rtk-core/src/features/events";
import { leaveLeague } from "../rtk-core/src/features/leagues";
import {
  ILoggedInPlayer,
  getPlayerData,
} from "../rtk-core/src/features/player";
import { getChallengeAssets } from "../utils/challenge";
import {
  getBroadcasterLeagues,
  getFilteredSystemClassicLeagues,
} from "../utils/leagues";

const MyLeagues: React.FC = () => {
  const dispatch = useAppDispatch();

  const activeEvent = useAppSelector(getActiveEvent);
  const player = useAppSelector(
    (state) => getPlayerData(state) as ILoggedInPlayer
  );
  const entry = useAppSelector((state) => getEntry(state, player.entry));
  const privateClassicLeagues = useAppSelector((state) =>
    getPrivateClassicLeaguesForEntry(state, player.entry)
  );
  const systemClassicLeagues = useAppSelector((state) =>
    getSystemClassicLeaguesForEntry(state, player.entry)
  );

  const challenge = getChallengeAssets(activeEvent.id);

  const leaveLeagueHandler = (leagueId: number) => {
    if (window.confirm("Are you sure you want to leave this league?")) {
      dispatch(leaveLeague(leagueId));
    }
  };

  const broadcasterLeagues = getBroadcasterLeagues(systemClassicLeagues);
  const filteredSystemClassicLeagues =
    getFilteredSystemClassicLeagues(systemClassicLeagues);

  const getEventUrl = (event: IEvent) => {
    return `/leagues/${event.id}`;
  };

  if (!activeEvent.released) {
    return (
      <GridWrapper>
        <TwoCol50Grid>
          <GridItem>
            <div className="mt-3">
              <EventNavigator getEventUrl={getEventUrl} />
            </div>
          </GridItem>
          <GridItem>
            <CannotEnterEvent />
          </GridItem>
          <GridItem>
            <Fixtures eventId={activeEvent.id} />
          </GridItem>
        </TwoCol50Grid>
      </GridWrapper>
    );
  }

  if (!entry || !challenge) {
    return null;
  }

  return (
    <GridWrapper>
      <div className="mt-3 mx-0">
        <LeaderboardAd
          id="div-gpt-ad-1501757861635-0"
          slot="DesktopFPLLeague"
        />
      </div>
      <TwoCol50Grid>
        <HelmetHead
          title={"Create & Join Fantasy Football Leagues | PL Challenges"}
          description={
            "To view all types of leagues, as well as creating & joining new leagues, visit the official website of the Premier League."
          }
        />
        <GridItem>
          <div className="mt-3 -mb-3">
            <EventNavigator getEventUrl={getEventUrl} />
          </div>
        </GridItem>
        <GridItem>
          <div className="mt-2">
            <DividerLight />
          </div>
        </GridItem>
        <GridItem>
          <LeaguePanel
            invitational={true}
            title="Invitational Leagues"
            text="Join invitational leagues to compete against friends and others playing the game."
            leagues={privateClassicLeagues}
            leave={leaveLeagueHandler}
          />
          <div className="my-5">
            <DividerLight />
          </div>
          <LeaguePanel
            title="Global Leagues"
            text="You are automatically registered into the Global Leagues to take on the best managers in the world."
            leagues={filteredSystemClassicLeagues}
            leave={leaveLeagueHandler}
          />
          {broadcasterLeagues.length > 0 && (
            <LeaguePanel
              title="Broadcaster Leagues"
              text="You are automatically registered in the Broadcaster Leagues to take on the best managers in their regions."
              leagues={broadcasterLeagues}
              leave={leaveLeagueHandler}
            />
          )}
        </GridItem>
        <GridItem>
          <Fixtures eventId={activeEvent.id} />
        </GridItem>
      </TwoCol50Grid>
    </GridWrapper>
  );
};

export { MyLeagues as MyLeaguesTest };

export default MyLeagues;
