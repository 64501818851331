import {
  combineReducers,
  configureStore,
  isRejected,
  Middleware,
  MiddlewareAPI,
  PreloadedState,
} from "@reduxjs/toolkit";

import { apiSlice } from "../features/api/apiSlice";
import autoJoinReducer from "../features/autoJoin";
import bootstrapReducer from "../features/bootstrap";
import chipReducer from "../features/chips";
import cupReducer from "../features/cup";
import dreamTeamReducer from "../features/dreamTeam";
import elementsReducer from "../features/elements";
import elementStatsReducer from "../features/elementStats";
import elementTypesReducer from "../features/elementTypes";
import entriesReducer from "../features/entries";
import eventsReducer from "../features/events";
import fixturesReducer from "../features/fixtures";
import gameReducer from "../features/game";
import globalReducer, { addServerError } from "../features/global";
import leaguesReducer from "../features/leagues";
import manageReducer from "../features/manage";
import myTeamReducer from "../features/myTeam";
import phasesReducer from "../features/phases";
import playerReducer from "../features/player";
import regionReducer from "../features/regions";
import squadReducer from "../features/squad";
import statsReducer from "../features/stats";
import teamsReducer from "../features/teams";

export const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  autoJoin: autoJoinReducer,
  bootstrap: bootstrapReducer,
  chips: chipReducer,
  cup: cupReducer,
  dreamTeams: dreamTeamReducer,
  entries: entriesReducer,
  elements: elementsReducer,
  elementStats: elementStatsReducer,
  elementTypes: elementTypesReducer,
  events: eventsReducer,
  fixtures: fixturesReducer,
  game: gameReducer,
  global: globalReducer,
  leagues: leaguesReducer,
  manage: manageReducer,
  myTeam: myTeamReducer,
  phases: phasesReducer,
  player: playerReducer,
  regions: regionReducer,
  stats: statsReducer,
  squad: squadReducer,
  teams: teamsReducer,
});

// A simple middleware that catches errors from network requests
// and adds them to the serverError section of the store
export const ErrorMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejected(action)) {
      if (action.payload) {
        const payload = action.payload;
        if (payload.status && payload.status !== 400) {
          let error;
          // The below is inferred from the FetchBaseQueryError type
          if (Number.isInteger(payload.status)) {
            error = {
              code: action.payload.status,
              details: action.payload.data.detail,
            };
          } else if (
            action.payload.status === "PARSING_ERROR" &&
            action.payload.originalStatus !== 400
          ) {
            error = {
              code: action.payload.originalStatus,
              details: action.payload.error,
            };
          }
          if (error?.code && error.details) {
            api.dispatch(
              addServerError({
                code: action.payload.originalStatus,
                details: action.payload.data.detail,
              })
            );
          }
        }
      }
    }
    return next(action);
  };

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([ErrorMiddleware, apiSlice.middleware]),
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(apiSlice.middleware),
  });
};

export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = AppStore["dispatch"];
export type SavingState = "" | "saving" | "saved";
