import React, { useState } from "react";

interface IDialogProps {
  render: (
    showDialog: boolean,
    handleHide: () => void,
    handleShow: () => void
  ) => React.ReactNode;
}

const DialogManager: React.FC<IDialogProps> = ({ render }) => {
  const [showDialog, setShowDialog] = useState(false);

  const handleShow = () => {
    setShowDialog(true);
  };

  const handleHide = () => {
    setShowDialog(false);
  };

  return <>{render(showDialog, handleShow, handleHide)}</>;
};

export default DialogManager;
