import { rgba, size } from "polished";
import styled from "styled-components";

const borderWidth = 2;
const padding = 8;

export const StyledEventCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Use px value not theme so we can calc negative margin in EventCardHeader */
  padding: ${padding}px;
  border: ${borderWidth}px solid ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.radii[2]};
  background-image: linear-gradient(
    to right,
    ${rgba("white", 0.6)},
    ${rgba("white", 0.9)}
  );
  text-align: center;
`;

export const EventCardHeader = styled.div`
  margin-top: -${borderWidth + padding}px;
`;

export const EventHeading = styled.h3`
  max-width: fit-content;
  margin: auto;
  padding: 0 ${(props) => props.theme.space[4]};
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: ${({ theme }) => `0 0 ${theme.radii[1]} ${theme.radii[1]}`};
`;

export const EventHeadingCopy = styled.span`
  background-color: ${(props) => props.theme.colors.fantasy};
  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.colors.fantasy},
    ${(props) => props.theme.colors.blueLight}
  );
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[0]};
`;

export const EventHeadingStatus = styled(EventHeadingCopy)`
  margin-left: 0.4rem;
  font-family: ${(props) => props.theme.fonts.regular};
`;

export const LockupImg = styled.img`
  ${size(120, 220)}
  margin-top: ${(props) => props.theme.space[3]};
  margin-top: ${(props) => props.theme.space[1]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    ${size(143, 253)}
    margin-top: ${(props) => props.theme.space[2]};
  }
`;

export const EventCardHeading = styled.h4`
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: ${(props) => props.theme.fontSizes[5]};
`;

export const EventDescription = styled.p`
  margin-bottom: ${(props) => props.theme.space[4]};
  font-size: ${(props) => props.theme.fontSizes[0]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    margin-bottom: 2.2rem;
  }
`;

export const EventStatList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.space[4]};
`;

export const ManageTeamMsg = styled.p`
  padding: ${({ theme }) => `${theme.space[2]} ${theme.space[3]}`};
`;
