import { hiDPI, rgba, size } from "polished";
import styled, { css } from "styled-components";
import BaseLogo from "../../img/headline-logo.svg?react";
import BaseCaptain from "../../img/icons/captain.svg?react";
import BaseGamechanger from "../../img/icons/gamechanger.svg?react";
import BaseViceCaptain from "../../img/icons/vice-captain.svg?react";
import pattern1_1236 from "../../img/pattern/pattern-1-1236.png";
import pattern1_618 from "../../img/pattern/pattern-1-618.png";
import { ElementPoints, FixtureScore } from "./types";

export const ButtonWrap = styled.div`
  margin-top: ${(props) => props.theme.space[3]};
`;

export const timeScoreWidth = "6rem";
export const gap = "0.6rem";

export const Logo = styled(BaseLogo)`
  ${size(32, 89)}
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.6rem;
`;

export const Summary = styled.div`
  padding: ${(props) => props.theme.space[3]};
  background-position: right -190px bottom -225px, 0, 0;
  background-repeat: no-repeat;
  background-size: 493px 678px, auto;
  background-image: url(${pattern1_618}),
    linear-gradient(
      to right,
      ${(props) => props.theme.colors.fantasy},
      ${(props) => props.theme.colors.blueLight}
    );

  ${hiDPI(2)} {
    background-image: url(${pattern1_1236}),
      linear-gradient(
        to right,
        ${(props) => props.theme.colors.fantasy},
        ${(props) => props.theme.colors.blueLight}
      );
  }
`;

export const ElementName = styled.h3`
  margin-bottom: 0.6rem;
`;

export const FirstName = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[3]};
  line-height: 1;
`;

export const LastName = styled.div`
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: 2.5rem;
  line-height: 1;
`;

export const ElementType = styled.div`
  margin-bottom: ${(props) => props.theme.space[2]};
  font-size: ${(props) => props.theme.fontSizes[3]};
  line-height: 1;
`;

export const TeamName = styled.span`
  margin-left: 0.4rem;
  font-size: ${(props) => props.theme.fontSizes[2]};
`;

export const ElementSummary = styled.div`
  display: flex;
`;

export const ElementSummaryUnit = styled.div`
  flex: 1;
`;

export const Img = styled.img`
  display: block;
  max-width: 150px;
  margin: auto;
`;

export const StatList = styled.ul`
  display: flex;
  padding: 0.5rem ${(props) => props.theme.space[2]};
  border-radius: ${(props) => props.theme.radii[2]};
  background-color: ${rgba("white", 0.6)};
  box-shadow: 0 4px 12px 0 ${rgba("black", 0.08)};
`;

export const ElementInfoBody = styled.div`
  padding: ${(props) => props.theme.space[3]};
  background-color: ${(props) => props.theme.colors.purple5};
`;

export const SpecialElementBox = styled.div`
  margin-bottom: ${(props) => props.theme.space[3]};
  padding: ${(props) => props.theme.space[2]} 1.8rem;
  border-radius: ${(props) => props.theme.radii[2]};
  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.colors.fantasy},
    ${(props) => props.theme.colors.blueLight}
  );

  p {
    text-align: center;
  }
`;

export const SpecialElementHeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const SpecialElementHeading = styled.h3`
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: ${(props) => props.theme.fontSizes[3]};
  text-align: center;
`;

export const ElementControls = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[2]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex-direction: row;
    margin-bottom: ${(props) => props.theme.space[3]};
  }
`;

export const ElementControl = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: 1;
    flex-basis: fit-content;
  }
`;

const CaptainStyles = css`
  ${size(12)};
  left: 0.2rem;
  bottom: 3rem;
  display: inline-block;
  vertical-align: middle;
  fill: ${(props) => props.theme.colors.primary};
  color: #fff;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    ${size(40)};
    left: 0.5rem;
    bottom: 4.5rem;
    margin-right: 0.5rem;
  }
`;

export const Captain = styled(BaseCaptain)`
  ${CaptainStyles}
`;

export const ViceCaptain = styled(BaseViceCaptain)`
  ${CaptainStyles}
`;

export const SubHeading = styled.h3`
  margin-bottom: ${(props) => props.theme.space[1]};
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: 2rem;
  line-height: 1;
`;

export const Score = styled.div<FixtureScore>`
  display: flex;
  gap: 0.6rem;
  padding: ${({ theme }) => `${theme.space[1]} ${theme.space[3]}`};
  border-radius: ${(props) => props.theme.radii[1]};
  background: ${({ $isLive = false, theme }) =>
    $isLive ? theme.colors.pinkToOrange : theme.colors.primary};
  color: #fff;
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: ${(props) => props.theme.fontSizes[3]};
  line-height: 1.3125;
`;

export const ExtraStat = css`
  border-radius: ${(props) => props.theme.radii[1]};
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[0]};
  text-align: center;
`;

export const Points = styled.div<ElementPoints>`
  ${ExtraStat}
  padding: 0 ${(props) => props.theme.space[2]};
  background: ${({ $isSpecial, theme }) =>
    $isSpecial
      ? `linear-gradient(to right, ${theme.colors.fantasy}, ${theme.colors.blueLight})`
      : theme.colors.purple10};
`;

export const EventSubHeading = styled(SubHeading)`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    text-align: center;
  }
`;

export const InfoMessage = styled.p`
  margin: ${(props) => props.theme.space[4]} 0
    ${(props) => props.theme.space[3]};
  text-align: center;
`;

export const GameChangerIcon = styled(BaseGamechanger)`
  ${size(20)};
`;
