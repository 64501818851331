import * as Sentry from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  matchRoutes,
  useLocation,
  useNavigationType,
  useRouteError,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./GlobalStyle";
import App from "./components/App";
import AutoJoin from "./components/AutoJoin";
import CreateClassic from "./components/CreateClassic";
import CreateJoin from "./components/CreateJoin";
import FDRContainer from "./components/FDR/FDRContainer";
import JoinPrivate from "./components/JoinPrivate";
import { Main, Wrapper } from "./components/Layout";
import SetPieceTakers from "./components/SetPieceTakers";
import { ManageTeamProvider } from "./contexts/ManageTeamContext";
import AdminClassic from "./pages/AdminClassic";
import EntryEvent from "./pages/EntryEvent";
import FixturesWrapper from "./pages/FixturesWrapper";
import Help from "./pages/Help";
import Home from "./pages/Home";
import Invite from "./pages/Invite";
import MyLeagues from "./pages/Leagues";
import ManageTeam from "./pages/ManageTeam";
import ManageTeamCreate from "./pages/ManageTeamCreate";
import ManagerProfile from "./pages/ManagerProfile";
import News from "./pages/News";
import Prizes from "./pages/Prizes";
import Rules from "./pages/Rules";
import Scout from "./pages/Scout";
import StandingsClassic from "./pages/StandingsClassic";
import Statistics from "./pages/Statistics";
import Terms from "./pages/Terms";
// https://github.com/ismfg/pl-issues/issues/280
// import Winners from "./pages/Winners";
import ReportName from "./components/ReportName";
import { WebViewProvider } from "./contexts/WebViewContext";
import About from "./pages/About";
import { useAppSelector } from "./rtk-core/src/app/hooks";
import { store } from "./rtk-core/src/app/store";
import { getPlayerData } from "./rtk-core/src/features/player";
import theme from "./theme";

type SentryEnvironment =
  | "development"
  | "testing"
  | "staging"
  | "production"
  | undefined;
interface SentryConfig {
  traces: number;
  profiles: number;
}
const sentryEnvironment = import.meta.env
  .VITE_SENTRY_ENVIRONMENT as SentryEnvironment;

if (sentryEnvironment) {
  const sampleRates: Record<string, SentryConfig> = {
    development: {
      traces: 1,
      profiles: 1,
    },
    testing: {
      traces: 0.5, // Capture 50% of the transactions
      profiles: 0.5, // Capture 50% of the 50% being sampled so 25% of traffic.
    },
    staging: {
      traces: 0.5, // Capture 50% of the transactions
      profiles: 0.5, // Capture 50% of the 50% being sampled so 25% of traffic.
    },
  };
  let tracesSampleRate = 0.000001;
  let profilesSampleRate = 0.000001;
  if (sampleRates[sentryEnvironment]) {
    tracesSampleRate = sampleRates[sentryEnvironment].traces;
    profilesSampleRate = sampleRates[sentryEnvironment].profiles;
  }
  Sentry.init({
    dsn: "https://a5a07cbd932f255147c9869f7e843e8d@o118622.ingest.sentry.io/4506043167277056",
    environment: sentryEnvironment,
    ignoreErrors: [
      /Error: No error message/,
      /Failed to fetch/,
      /TypeError: Load failed/,
    ],
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate: tracesSampleRate, // Performance Monitoring
    profilesSampleRate: profilesSampleRate, // Profiling
  });
}

const EntryRoute = () => {
  const player = useAppSelector(getPlayerData);
  if (player && player.entry) {
    return <Outlet />;
  } else {
    return <Navigate to={"/manage/create"} replace />;
  }
};

export const NotFound: React.FC = () => (
  <Wrapper>
    <Main>
      <h4>Page not found</h4>
      <p>Sorry, but the page you were looking for can't be found.</p>
    </Main>
  </Wrapper>
);

const PlayerRoute = () => {
  const player = useAppSelector(getPlayerData);
  if (player) {
    return <Outlet />;
  } else {
    return <Navigate to={"/"} replace />;
  }
};

const container = document.getElementById("root")!;
const root = createRoot(container);

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const ErrorBoundary: React.FC = () => {
  const error = useRouteError();
  console.log(error);
  return (
    // If we use a component instead of embedding the fallback will be
    // returned if the component errors
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <p>Sorry, the page you were loading has generated an error.</p>
    </Sentry.ErrorBoundary>
  );
};

const routes = sentryCreateBrowserRouter(
  createRoutesFromElements([
    <Route path="/" element={<App />} errorElement={<ErrorBoundary />}>
      <Route path="home" element={<Home />} />
      <Route path="fixtures/:eventId" element={<FixturesWrapper />} />
      <Route path="fixtures/fdr" element={<FDRContainer />} />
      <Route path="help" element={<About />} />
      <Route path="help/faq" element={<Help />} />
      <Route path="help/rules" element={<Rules />} />
      <Route path="help/terms" element={<Terms />} />
      <Route path="/help/report-name" element={<ReportName />} />
      <Route path="/prizes" element={<Prizes />} />
      {/* https://github.com/ismfg/pl-issues/issues/280 */}
      {/* <Route path="/prizes/winners" element={<Winners />} /> */}
      <Route element={<PlayerRoute />}>
        <Route path="manage/create" element={<ManageTeamCreate />} />
        <Route path="manage/:eventId" element={<ManageTeam />} />
      </Route>
      <Route path="/entry/:entryId/event/:eventId" element={<EntryEvent />} />
      <Route element={<EntryRoute />}>
        <Route path="leagues/:eventId" element={<MyLeagues />} />
        <Route path="profile" element={<ManagerProfile />} />
        <Route
          path="/leagues/:leagueId/standings/c/:eventId"
          element={<StandingsClassic />}
        />
        <Route
          path="/leagues/:leagueId/standings/c/:eventId/:phaseId"
          element={<StandingsClassic />}
        />
        <Route path="/leagues/create-join" element={<CreateJoin />} />
        <Route path="/leagues/create/classic" element={<CreateClassic />} />
        <Route path="/leagues/join/private" element={<JoinPrivate />} />
        <Route path="/leagues/:leagueId/admin/c" element={<AdminClassic />} />
        <Route path="/leagues/:leagueId/invite" element={<Invite />} />
      </Route>
      <Route path="/leagues/auto-join/:code" element={<AutoJoin />} />
      <Route path="statistics" element={<Statistics />} />
      <Route path="/statistics/:statName" element={<Statistics />} />
      <Route path="the-scout" element={<Scout />} />
      <Route path="the-scout/player-news" element={<News />} />
      <Route path="the-scout/set-piece-takers" element={<SetPieceTakers />} />
      <Route path="*" element={<NotFound />} />
    </Route>,
  ])
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Provider store={store}>
        <WebViewProvider>
          <ManageTeamProvider>
            <RouterProvider router={routes} />
          </ManageTeamProvider>
        </WebViewProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);
