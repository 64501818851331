import BoldHyperlink from "../../components/BoldHyperlink";
import {
  PanelHeading,
  StyledPanel,
  StyledPanelBody,
  StyledPanelFooter,
} from "./styles";
import { IPanelProps } from "./types";

const Panel = ({ children, title, footer }: IPanelProps) => {
  return (
    <StyledPanel>
      <PanelHeading>{title}</PanelHeading>
      <StyledPanelBody>{children}</StyledPanelBody>
      {footer && (
        <StyledPanelFooter>
          <BoldHyperlink href={footer.url}>{footer.text}</BoldHyperlink>
        </StyledPanelFooter>
      )}
    </StyledPanel>
  );
};

export default Panel;
