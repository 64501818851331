import { size } from "polished";
import styled, { css } from "styled-components";
import BaseHeadlineLogo from "../../img/headline-logo.svg?react";

interface HeadlineLogoProps {
  $isWebView: boolean;
}

const HeadlineLogo = styled(BaseHeadlineLogo)<HeadlineLogoProps>`
  ${size(68, 160)}
  margin: ${(props) => `${props.theme.space[6]} 0`};
  display: inline-block;
  vertical-align: middle;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    ${size(84, 196)}
    margin: 3.8rem 0 2.1rem;
  }

  ${({ $isWebView }) =>
    $isWebView &&
    css`
      ${size(67, 160)}
      margin: 5.7rem 0 2.4rem;

      @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
        margin: 3.8rem 0 2.4rem;
      }
    `}
`;

export default HeadlineLogo;
