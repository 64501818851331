import React from "react";
import { ILeague } from "../../rtk-core/src/features/leagues";
import ButtonLink from "../ButtonLink";
import { ArrowRight } from "./styles";

interface IProps {
  entryId?: number;
  league?: ILeague;
}

const ReportNameButton: React.FC<IProps> = ({ entryId, league }) => {
  // We only want one or the other so return nothing if both or neither.
  if ((entryId && league) || (!entryId && !league)) {
    return null;
  }
  return (
    <ButtonLink
      to={`/help/report-name?${entryId ? `entryId=${entryId}` : ""}${
        league ? `leagueId=${league.id}&leagueName=${league.name}` : ""
      }`}
      $variant="dark"
    >
      Report Offensive Name
      <ArrowRight />
    </ButtonLink>
  );
};
export default ReportNameButton;
