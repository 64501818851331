import { hideVisually } from "polished";
import styled from "styled-components";
import { StyledButtonLink } from "../ButtonLink";
import { IStyledChallengePromo } from "./ChallengePromo/types";

export const StyledChallengePromo = styled.div<IStyledChallengePromo>`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: flex;
    flex-direction: ${(props) => (props.$flipped ? "row-reverse" : "row")};
  }
`;

export const Img = styled.div<IStyledChallengePromo>`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: 0 0 55%;
  }

  > img {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    border-radius: ${({ theme }) => `${theme.radii[3]} ${theme.radii[3]} 0 0`};
    aspect-ratio: 16 / 9;

    @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
      border-radius: ${({ $flipped, theme }) =>
        $flipped
          ? `0 ${theme.radii[3]} ${theme.radii[3]} 0`
          : `${theme.radii[3]} 0 0 ${theme.radii[3]}`};
    }
  }
`;

export const Content = styled.div<IStyledChallengePromo>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${(props) => props.theme.space[6]};
  padding: ${({ theme }) => `${theme.space[3]} ${theme.space[2]}`};
  border: ${(props) => props.theme.borders[0]};
  border-radius: ${({ theme }) => `0 0 ${theme.radii[3]} ${theme.radii[3]}`};
  background-color: ${(props) => props.theme.colors.purple5};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: 0 0 45%;
    padding: ${(props) => props.theme.space[8]};
    border-radius: ${({ $flipped, theme }) =>
      $flipped
        ? `${theme.radii[3]} 0 0 ${theme.radii[3]}`
        : `0 ${theme.radii[3]} ${theme.radii[3]} 0`};
  }

  @media (min-width: ${(props) => props.theme.breakpoints[5]}) {
    padding: 4.8rem;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[2]};
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    gap: ${(props) => props.theme.space[3]};
    text-align: left;
  }
`;

export const Heading = styled.h2`
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: ${(props) => props.theme.fontSizes[5]};
  font-size: 1.8rem;
  line-height: 1.2;

  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    font-size: ${(props) => props.theme.fontSizes[5]};
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    font-size: 2.5rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[5]}) {
    font-size: ${(props) => props.theme.fontSizes[6]};
  }
`;

export const PromoCopy = styled.p`
  font-size: ${(props) => props.theme.fontSizes[3]};
`;

export const LeadPromoCopy = styled(PromoCopy)`
  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    ${hideVisually}
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[3]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex-direction: row;
  }
`;

export const FooterButton = styled(StyledButtonLink)`
  @media (max-width: ${(props) => props.theme.breakpoints[4]}) {
    display: flex;
  }
`;
