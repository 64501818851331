import { useState } from "react";
import { useMatch } from "react-router-dom";
import CloseIcon from "../../img/icons/close.svg?react";
import SubIcon from "../../img/icons/sub.svg?react";
import TransferIcon from "../../img/icons/transfer.svg?react";
import { useAppDispatch, useAppSelector } from "../../rtk-core/src/app/hooks";
import { getElementTypesById } from "../../rtk-core/src/features/elementTypes";
import {
  IElementFixture,
  elementDialogHide,
  getElement,
  getElementDialog,
  getElementsEventDataById,
  getFixtures,
  getHistory,
} from "../../rtk-core/src/features/elements";
import { getActiveEvent } from "../../rtk-core/src/features/events";
import { getRules } from "../../rtk-core/src/features/game";
import {
  IManagePickProposed,
  actionSubstitution,
  changeCaptain,
  changeViceCaptain,
  getHasBench,
  getPicksProposed,
  hasCaptainPlayed,
  hasViceCaptainPlayed,
  proposeElement,
  removeElement,
} from "../../rtk-core/src/features/manage";
import { getTeamsById } from "../../rtk-core/src/features/teams";
import { getChallengeAssets } from "../../utils/challenge";
import { integerToMoney } from "../../utils/money";
import { getStatDescription } from "../../utils/stats";
import Alert from "../Alert";
import Badge from "../Badge";
import Button from "../Button";
import { DialogBody } from "../Dialog";
import { getElementShirtPath } from "../ElementShirt";
import Tooltip, { TooltipLabel } from "../Tooltip";
import ElementFixtureItem from "./ElementFixtureItem";
import EventHistoryTable from "./EventHistoryTable";
import StatItem from "./StatItem";
import {
  ButtonWrap,
  Captain,
  ElementControl,
  ElementControls,
  ElementInfoBody,
  ElementName,
  ElementSummary,
  ElementSummaryUnit,
  ElementType,
  EventSubHeading,
  FirstName,
  GameChangerIcon,
  Header,
  Img,
  InfoMessage,
  LastName,
  Logo,
  SpecialElementBox,
  SpecialElementHeading,
  SpecialElementHeadingContainer,
  StatList,
  Summary,
  TeamName,
  ViceCaptain,
} from "./styles";

const ElementInfo = () => {
  const [expandedResults, setExpandedResults] = useState<boolean>(false);

  const activeEvent = useAppSelector(getActiveEvent);
  const dispatch = useAppDispatch();
  const managePathMatch = useMatch(`/manage/${activeEvent.id}`);

  const elementId = useAppSelector(getElementDialog);
  const element = useAppSelector((state) =>
    getElement(state, elementId, activeEvent.id)
  );
  const elementTypesById = useAppSelector((state) =>
    getElementTypesById(state, activeEvent.id)
  );
  const summaryFixtures = useAppSelector((state) =>
    getFixtures(state, elementId)
  );
  const summaryHistory = useAppSelector((state) =>
    getHistory(state, elementId)
  );
  const elementsDataById = useAppSelector((state) =>
    getElementsEventDataById(state, activeEvent.id)
  );

  const isGameChanger =
    elementsDataById && elementId
      ? elementsDataById[elementId].modified
      : false;

  const proposedPicks = useAppSelector(getPicksProposed);
  const proposedPick = proposedPicks.find(
    (pick: IManagePickProposed) => pick.element && pick.element === elementId
  );
  const rules = useAppSelector((state) => getRules(state, activeEvent.id));
  const captainPlayed = useAppSelector(hasCaptainPlayed);
  const viceCaptainPlayed = useAppSelector(hasViceCaptainPlayed);
  const teamsById = useAppSelector(getTeamsById);
  const canManage = element?.can_transact && activeEvent.can_manage;
  const hasBench = useAppSelector(getHasBench);

  const { copy } = { ...getChallengeAssets(activeEvent.id) };

  if (!element || !rules || !copy) {
    return null;
  }

  const isSubsitutionDisabled = () => {
    if (!canManage) {
      return true;
    } else {
      if (proposedPick) {
        if (proposedPick.subStatus && proposedPick.subStatus !== "target") {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  };

  const isViceCaptainDisabled = () => {
    if (proposedPick && proposedPick.position > rules.squad_squadplay) {
      return true;
    } else {
      if (viceCaptainPlayed || !canManage) {
        return true;
      } else {
        if (proposedPick) {
          return proposedPick?.is_vice_captain;
        } else {
          return true;
        }
      }
    }
  };

  const isCaptainDisabled = () => {
    if (proposedPick && proposedPick.position > rules.squad_squadplay) {
      return true;
    } else {
      if (captainPlayed || !canManage) {
        return true;
      } else {
        if (proposedPick) {
          return proposedPick.is_captain;
        } else {
          return true;
        }
      }
    }
  };

  const closeInfo = () => dispatch(elementDialogHide());

  const elementType = elementTypesById[element.element_type];
  const team = teamsById[element.team];
  const activeEventSummaryFixtures = summaryFixtures.filter(
    (f) => f.event === activeEvent.id
  );

  const activeEventSummaryHistory = summaryHistory.filter(
    (h) => h.round === activeEvent.id
  );

  const getHistoryWithoutFixtures = () => {
    const fixtureIds = new Set(
      activeEventSummaryFixtures
        .filter((f): f is IElementFixture => "id" in f)
        .map((f) => f.id)
    );
    return activeEventSummaryHistory.filter((h) => !fixtureIds.has(h.fixture));
  };

  const handleMakeCaptain = () => {
    dispatch(changeCaptain(elementId));
  };

  const handleMakeViceCaptain = () => {
    dispatch(changeViceCaptain(elementId));
  };

  const handleSubstitution = async () => {
    if (proposedPick) {
      await dispatch(actionSubstitution(proposedPick.position)).then(() =>
        closeInfo()
      );
    }
  };

  const handleTransferIn = async () => {
    await dispatch(proposeElement(elementId));
  };

  const handleTransferOut = async () => {
    if (proposedPick) {
      await dispatch(removeElement(proposedPick.position)).then(() =>
        closeInfo()
      );
    }
  };

  return (
    <DialogBody>
      <Summary>
        <Header>
          <h2>
            <Logo title="Fantasy Challenge" />
          </h2>
          <Button $variant="dark" onClick={closeInfo}>
            <CloseIcon title="Close" />
            Close
          </Button>
        </Header>
        <ElementSummary>
          <ElementSummaryUnit>
            <div style={{ display: "flex" }}>
              {proposedPick && proposedPick.is_captain && <Captain />}
              {proposedPick && proposedPick.is_vice_captain && <ViceCaptain />}
              <ElementName>
                <FirstName>{element.first_name}</FirstName>
                <LastName>{element.second_name}</LastName>
              </ElementName>
            </div>
            <ElementType>{elementType.singular_name}</ElementType>
            <div>
              <Badge team={team} sizes="32px" isPresentation={true} />
              <TeamName>{team.name}</TeamName>
            </div>
          </ElementSummaryUnit>
          <ElementSummaryUnit>
            <Img
              srcSet={`
                  //resources.premierleague.com/premierleague/photos/players/110x140/p${element.code}.png 110w,
                  //resources.premierleague.com/premierleague/photos/players/250x250/p${element.code}.png 250w,
                  `}
              alt=""
              sizes="150px"
              role="presentation"
              onError={(e) => {
                e.currentTarget.srcset = `${getElementShirtPath(
                  element.element_type,
                  teamsById[element.team].code,
                  "standard"
                )}-110.png`;
              }}
            />
          </ElementSummaryUnit>
        </ElementSummary>
        <StatList>
          <StatItem
            title="Price"
            value={`£${integerToMoney(element.now_cost, 10)}m`}
            rankType={element.now_cost_rank_type}
            element={element}
          />
          <StatItem
            title={
              <Tooltip content={getStatDescription("form")}>
                <span>
                  <TooltipLabel>Form</TooltipLabel>
                </span>
              </Tooltip>
            }
            value={element.form}
            rankType={element.form_rank_type}
            element={element}
          />
          <StatItem
            title={
              <Tooltip content="Teams Selected By">
                <span>
                  <TooltipLabel>TSB</TooltipLabel>%
                </span>
              </Tooltip>
            }
            value={`${element.selected_by_percent}%`}
            rankType={element.selected_rank_type}
            element={element}
          />
        </StatList>
      </Summary>
      {element.news && (
        <Alert $copnr={element.chance_of_playing_next_round}>
          {element.news}
        </Alert>
      )}
      <ElementInfoBody>
        {isGameChanger && (
          <SpecialElementBox>
            <SpecialElementHeadingContainer>
              <GameChangerIcon />
              <SpecialElementHeading>Gamechanger</SpecialElementHeading>
            </SpecialElementHeadingContainer>
            <p>{copy.description}</p>
          </SpecialElementBox>
        )}
        {
          /* Controls */
          managePathMatch && (
            <ElementControls>
              <ElementControl>
                <Button
                  $isFullWidth={true}
                  onClick={() => handleMakeCaptain()}
                  disabled={isCaptainDisabled()}
                  $variant="tertiary"
                >
                  Make captain
                </Button>
              </ElementControl>
              {rules.sys_vice_captain_enabled && (
                <ElementControl>
                  <Button
                    $isFullWidth={true}
                    onClick={() => handleMakeViceCaptain()}
                    disabled={isViceCaptainDisabled()}
                    $variant="secondary"
                  >
                    Make vice captain
                  </Button>
                </ElementControl>
              )}
              {hasBench && (
                <ElementControl>
                  <Button
                    $isFullWidth={true}
                    disabled={isSubsitutionDisabled()}
                    onClick={() => handleSubstitution()}
                    $variant="secondary"
                  >
                    <SubIcon />
                    Substitute
                  </Button>
                </ElementControl>
              )}
              <ElementControl>
                {proposedPick ? (
                  <div className="mb-3">
                    <Button
                      $isFullWidth={true}
                      onClick={() => handleTransferOut()}
                      $variant="secondary"
                      disabled={!canManage}
                    >
                      <TransferIcon />
                      Transfer Out
                    </Button>
                  </div>
                ) : (
                  <div className="mb-3">
                    <Button
                      $isFullWidth={true}
                      onClick={() => handleTransferIn()}
                      $variant="secondary"
                      disabled={!canManage}
                    >
                      <TransferIcon />
                      Transfer In
                    </Button>
                  </div>
                )}
              </ElementControl>
            </ElementControls>
          )
        }
        {/* Fixtures */}
        <div>
          <EventSubHeading>{activeEvent.name} Fixtures</EventSubHeading>
          {activeEventSummaryFixtures.map((f) => (
            <ElementFixtureItem
              elementId={element.id}
              fixtureId={f.id}
              key={f.id}
            />
          ))}
          {getHistoryWithoutFixtures().map((h) => (
            <ElementFixtureItem
              elementId={element.id}
              fixtureId={h.fixture}
              key={h.fixture}
            />
          ))}
        </div>
        {/* Results */}
        <EventSubHeading>Latest Results</EventSubHeading>
        {summaryHistory.length ? (
          <>
            <EventHistoryTable
              elementId={element.id}
              expandedResults={expandedResults}
            />
            {summaryHistory.length > 5 && (
              <ButtonWrap>
                <Button
                  $variant="tertiary"
                  $isFullWidth
                  onClick={() =>
                    setExpandedResults((expandedResults) => !expandedResults)
                  }
                >
                  {expandedResults ? "Show less" : "Show more results"}
                </Button>
              </ButtonWrap>
            )}
          </>
        ) : (
          <InfoMessage>
            Data will appear here once the season is underway.
          </InfoMessage>
        )}
      </ElementInfoBody>
    </DialogBody>
  );
};

export default ElementInfo;
