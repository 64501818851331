import styled from "styled-components";

const TextDivider = styled.div`
  margin: ${(props) => props.theme.space[6]} 0;
  color: ${(props) => props.theme.colors.purple60};
  font-family: ${(props) => props.theme.fonts.bold};
  text-align: center;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: relative;
    display: inline-block;
    width: 50%;
    height: 1px;
    background-color: ${(props) => props.theme.colors.purple30};
    vertical-align: middle;
  }

  &::before {
    right: ${(props) => props.theme.space[4]};
    margin-left: -50%;
  }

  &::after {
    left: ${(props) => props.theme.space[4]};
    margin-right: -50%;
  }
`;

export default TextDivider;
