import { useAppSelector } from "../../../rtk-core/src/app/hooks";
import { getActiveEvent } from "../../../rtk-core/src/features/events";
import { getPhasesById } from "../../../rtk-core/src/features/phases";
import { getLeagueUrl, getTopPercentileString } from "../../../utils/leagues";
import { getSuffix } from "../../../utils/plural";
import Button from "../../Button";
import ButtonLink from "../../ButtonLink";
import Dialog, { DialogButtonItem } from "../../Dialog";
import DialogBody from "../../DialogBody";
import DialogHeader from "../../DialogHeader";
import DialogManager from "../../DialogManager";
import { ArrowRight } from "../../Icons";
import Link from "../../Link";
import Movement from "../../Movement";
import {
  ActionList,
  ChevronIcon,
  Cog,
  Label,
  LeagueInfo,
  LeagueInfoWrap,
  LeagueName,
  LeagueRowWrapper,
  LeagueStat,
  LeagueStatsWrap,
  PlayerCountWrap,
  PlayersIcon,
  RankSuffix,
  StatLabel,
  StatValue,
  StatValueWrap,
  StyledChevronRightLink,
  StyledLinkButton,
} from "./styles";
import { ILeagueMenuDialogProps, ILeagueProps } from "./types";

const LeagueRow = ({
  league,
  leave,
  hideOptions,
  canUnWrap = true,
}: ILeagueProps) => {
  const playerCount = league.rank_count;
  const activeEvent = useAppSelector(getActiveEvent);
  const phasesById = useAppSelector(getPhasesById);
  const activePhases = league.active_phases;

  const sortedPhases = [...activePhases].sort((a, b) => {
    const phaseA = phasesById[a.phase];
    const phaseB = phasesById[b.phase];

    if (!phaseA || !phaseB) return 0;

    const gapA = phaseA.stop_event - phaseA.start_event;
    const gapB = phaseB.stop_event - phaseB.start_event;

    return gapA - gapB;
  });

  return (
    <LeagueRowWrapper key={league.id} isResponsive={canUnWrap}>
      <LeagueInfoWrap>
        <LeagueInfo isResponsive={canUnWrap}>
          <Link
            to={getLeagueUrl(
              league.id,
              league.scoring,
              undefined,
              activeEvent.id
            )}
            aria-label="Go to league standings"
          >
            <LeagueName>{league.name}</LeagueName>
          </Link>
        </LeagueInfo>
        <LeagueInfo isResponsive={canUnWrap}>
          {playerCount && (
            <PlayerCountWrap>
              <PlayersIcon />
              <Label>{`${playerCount.toLocaleString()} players`}</Label>
            </PlayerCountWrap>
          )}
          {!hideOptions && (
            <DialogManager
              render={(showDialog, handleShow, handleHide) => (
                <>
                  <StyledLinkButton onClick={handleShow}>
                    <Cog />
                    <Label>Options</Label>
                  </StyledLinkButton>
                  {showDialog && (
                    <LeagueMenuDialog
                      leagueEntry={league}
                      leave={leave}
                      handleHide={handleHide}
                    />
                  )}
                </>
              )}
            />
          )}
        </LeagueInfo>
      </LeagueInfoWrap>
      <LeagueStatsWrap>
        {sortedPhases.map((rank) => {
          const hasRankChanged = rank.rank > 0 && rank.last_rank > 0;
          return (
            <LeagueStat isResponsive={canUnWrap} key={rank.phase}>
              <StatValueWrap>
                {hasRankChanged && (
                  <Movement lastRank={rank.last_rank} rank={rank.rank} />
                )}
                <StatValue>
                  {league.league_type === "s" ? (
                    getTopPercentileString(rank.entry_percentile_rank)
                  ) : (
                    <>
                      {rank.rank.toLocaleString()}
                      <RankSuffix>{getSuffix(rank.rank)}</RankSuffix>
                    </>
                  )}
                </StatValue>
              </StatValueWrap>
              <StatLabel> {phasesById[rank.phase]?.name}</StatLabel>
            </LeagueStat>
          );
        })}
        <StyledChevronRightLink
          to={getLeagueUrl(
            league.id,
            league.scoring,
            undefined,
            activeEvent.id
          )}
          aria-label="Go to league standings"
        >
          <ChevronIcon />
        </StyledChevronRightLink>
      </LeagueStatsWrap>
    </LeagueRowWrapper>
  );
};

export default LeagueRow;

const LeagueMenuDialog: React.FC<ILeagueMenuDialogProps> = ({
  handleHide,
  leagueEntry,
  leave,
}) => {
  const activeEvent = useAppSelector(getActiveEvent);

  return (
    <Dialog closeDialog={handleHide}>
      <DialogHeader closeDialog={handleHide}>{leagueEntry.name}</DialogHeader>
      <DialogBody isPadded={true}>
        <ActionList>
          <DialogButtonItem>
            <ButtonLink
              to={`/leagues/${leagueEntry.id}/standings/${leagueEntry.scoring}/${activeEvent.id}`}
              $isFullWidth={true}
              $variant="secondary"
              $stretch={true}
            >
              Standings
              <ArrowRight width="16px" />
            </ButtonLink>
          </DialogButtonItem>
          {leagueEntry.entry_can_leave && (
            <DialogButtonItem>
              <Button
                $stretch={true}
                onClick={() => {
                  leave(leagueEntry.id);
                  handleHide();
                }}
                $isFullWidth={true}
                $variant="tertiary"
              >
                Leave league
                <ArrowRight width="16px" />
              </Button>
            </DialogButtonItem>
          )}
          {leagueEntry.entry_can_admin && (
            <DialogButtonItem>
              <ButtonLink
                $stretch={true}
                to={`/leagues/${leagueEntry.id}/admin/${leagueEntry.scoring}`}
                $isFullWidth={true}
              >
                Administer
                <ArrowRight width="16px" />
              </ButtonLink>
            </DialogButtonItem>
          )}
          {leagueEntry.entry_can_invite && (
            <DialogButtonItem>
              <ButtonLink
                $stretch={true}
                to={`/leagues/${leagueEntry.id}/invite`}
                $isFullWidth={true}
              >
                Invite Friends
                <ArrowRight width="16px" />
              </ButtonLink>
            </DialogButtonItem>
          )}
        </ActionList>
      </DialogBody>
    </Dialog>
  );
};
