import { apiSlice } from "./apiSlice";
import {
  ICreateClassicLeagueData,
  ICreateH2HLeagueData,
  ILeagueAdmin,
  IRenewableLeague,
  IClassicStandingsAPIData,
  IH2HStandingsAPIData,
  IH2HMatchesData,
  ILeague,
  IEntryInLeague,
  ILeagueCupStatus,
  IUpdateClassicLeaguePutData,
  IUpdateH2HLeaguePutData,
  IBanEntryPostData,
  JoinLeagueResponse,
  IJoinPrivateLeaguePostData,
  IJoinPublicLeaguePostData,
  ILeagueCodeResponse,
  IFetchLeagueEntriesResponse,
  ICheckLeagueCodeResponse,
  IFetchH2HLeagueMatchesResponse,
  IFetchH2HLeagueMatchesInput,
  IH2HStandingsAPIInput,
  IRenewRenewableLeagueResponse,
  IDeleteRenewableLeagueResponse,
  IDeleteLeagueResponse,
  IDeleteLeagueData,
  ILeaveLeagueResponse,
  ILeaveLeagueData,
  IClassicLeagueStandingsResponse,
  IClassicLeagueStandingsData,
  ILeagueCode,
} from "../leagues";
import { IError } from "../../app/types";
import { ILeagueEntry } from "../entries";

export const leaguesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createClassicLeague: builder.query<ILeagueAdmin, ICreateClassicLeagueData>({
      query: (data: ICreateClassicLeagueData) => ({
        url: "leagues-classic/",
        method: "POST",
        body: data,
      }),
    }),
    createH2HLeague: builder.query<ILeagueAdmin, ICreateH2HLeagueData>({
      query: (data: ICreateH2HLeagueData) => ({
        url: "leagues-h2h/",
        method: "POST",
        body: data,
      }),
    }),
    fetchClassicLeagueForAdmin: builder.query<ILeagueAdmin, number>({
      query: (leagueId: number) => `leagues-classic/${leagueId}/`,
    }),
    fetchH2HLeagueForAdmin: builder.query<ILeagueAdmin, number>({
      query: (leagueId: number) => `leagues-h2h/${leagueId}/`,
    }),
    updateClassicLeague: builder.query<
      ILeagueAdmin,
      IUpdateClassicLeaguePutData
    >({
      query: ({ leagueId, data }) => ({
        url: `leagues-classic/${leagueId}/`,
        method: "PUT",
        body: data,
      }),
    }),
    updateH2HLeague: builder.query<ILeagueAdmin, IUpdateH2HLeaguePutData>({
      query: ({ leagueId, data }) => ({
        url: `leagues-h2h/${leagueId}/`,
        method: "PUT",
        body: data,
      }),
    }),
    banLeagueEntry: builder.query<ILeagueAdmin, IBanEntryPostData>({
      query: ({ leagueId, data }) => ({
        url: `league/${leagueId}/bans/ban/`,
        method: "POST",
        body: data,
      }),
    }),
    unbanLeagueEntry: builder.query<ILeagueAdmin, IBanEntryPostData>({
      query: ({ leagueId, data }) => ({
        url: `league/${leagueId}/bans/unban/`,
        method: "POST",
        body: data,
      }),
    }),
    joinPrivateLeague: builder.query<
      JoinLeagueResponse,
      IJoinPrivateLeaguePostData
    >({
      query: ({ data, entry }) => ({
        url: "leagues-private/join/",
        method: "POST",
        body: data,
        params: { entry: entry },
      }),
      transformResponse: (response: ILeagueEntry, meta, { entry }) => {
        return {
          entry: entry,
          data: response,
        };
      },
    }),
    joinPublicLeague: builder.query<
      JoinLeagueResponse,
      IJoinPublicLeaguePostData
    >({
      query: ({ data, entry }) => ({
        url: "leagues-public/join/",
        method: "POST",
        body: data,
        params: { entry: entry },
      }),
      transformResponse: (response: ILeagueEntry, meta, { entry }) => {
        return {
          entry: entry,
          data: response,
        };
      },
    }),
    fetchLeagueCode: builder.query<ILeagueCodeResponse, number>({
      query: (leagueId: number) => ({
        url: `league/${leagueId}/code/`,
        params: { league: leagueId },
      }),
      transformResponse: (response: ILeagueCode, meta, leagueId) => {
        return {
          league: leagueId,
          data: response,
        };
      },
    }),
    regenerateLeagueCode: builder.query<ILeagueCodeResponse, number>({
      query: (leagueId) => ({
        url: `league/${leagueId}/code/regenerate/`,
        method: "POST",
        body: {},
        params: { league: leagueId },
      }),
      transformResponse: (response: ILeagueCode, meta, leagueId) => {
        return {
          league: leagueId,
          data: response,
        };
      },
    }),
    leaveLeague: builder.query<ILeaveLeagueResponse, ILeaveLeagueData>({
      query: ({ entry, leagueId }) => ({
        url: `league/${leagueId}/leave/`,
        method: "POST",
        body: {},
        params: { entry: entry, league: leagueId },
      }),
      transformResponse: (response: {}, meta, { leagueId, entry }) => {
        return {
          league: leagueId,
          entry: entry,
          data: response,
        };
      },
    }),
    deleteLeague: builder.query<IDeleteLeagueResponse, IDeleteLeagueData>({
      query: ({ entry, leagueId }) => ({
        url: `leagues-private/delete/`,
        method: "POST",
        body: { entry: entry, league: leagueId },
      }),
      transformResponse: (response: {}, meta, { leagueId, entry }) => {
        return {
          league: leagueId,
          entry: entry,
          data: response,
        };
      },
    }),
    fetchRenewableLeagues: builder.query<IRenewableLeague[], void>({
      query: () => `leagues-renewable/`,
    }),
    renewRenewableLeague: builder.query<IRenewRenewableLeagueResponse, number>({
      query: (id) => ({
        url: `leagues-renewable/${id}/renew/`,
        method: "POST",
        body: {},
        params: { archive_league_id: id },
      }),
    }),
    deleteRenewableLeague: builder.query<
      IDeleteRenewableLeagueResponse,
      number
    >({
      query: (id) => ({
        url: `leagues-renewable/${id}/`,
        method: "DELETE",
        body: {},
        params: { archive_league_id: id },
      }),
    }),
    fetchClassicLeagueStandings: builder.query<
      IClassicLeagueStandingsResponse,
      IClassicLeagueStandingsData
    >({
      query: ({ leagueId, pageNewEntries, pageStandings, phaseId }) =>
        `leagues-classic/${leagueId}/standings/` +
        `?page_new_entries=${pageNewEntries}&page_standings=${pageStandings}` +
        `&phase=${phaseId}`,
      transformResponse: (
        response: IClassicStandingsAPIData,
        meta,
        { phaseId }
      ) => {
        return {
          phaseId: phaseId,
          data: response,
        };
      },
    }),
    fetchH2HLeagueStandings: builder.query<
      IH2HStandingsAPIData,
      IH2HStandingsAPIInput
    >({
      query: ({ leagueId, pageNewEntries, pageStandings }) =>
        `leagues-h2h/${leagueId}/standings/` +
        `?page_new_entries=${pageNewEntries}&page_standings=${pageStandings}`,
    }),
    fetchH2HLeagueMatches: builder.query<
      IFetchH2HLeagueMatchesResponse,
      IFetchH2HLeagueMatchesInput
    >({
      query: ({ leagueId, entryId, eventId, page }) =>
        `leagues-h2h-matches/league/${leagueId}/` +
        `?page=${page}${entryId ? `&entry=${entryId}` : ``}${
          eventId ? `&event=${eventId}` : ``
        }`,
      transformResponse: (
        response: IH2HMatchesData,
        meta,
        { leagueId, entryId, eventId }
      ) => {
        return {
          leagueId: leagueId,
          entryId: entryId,
          eventId: eventId,
          data: response,
        };
      },
    }),
    checkLeagueCode: builder.query<ICheckLeagueCodeResponse, string>({
      query: (code) => ({
        url: `league/code-check/`,
        method: "POST",
        body: { code: code },
      }),
      transformResponse: (response: ILeague, meta, code) => {
        return {
          code: code,
          data: response,
        };
      },
    }),
    fetchLeagueEntries: builder.query<IFetchLeagueEntriesResponse, number>({
      query: (leagueId) => `league/${leagueId}/entries/`,
      transformResponse: (response: IEntryInLeague[], meta, leagueId) => {
        return {
          leagueId: leagueId,
          data: response,
        };
      },
      transformErrorResponse: (response: IError, meta, leagueId) => {
        return {
          error: response,
          url: `league/${leagueId}/entries/`,
        };
      },
    }),
    fetchLeagueCupStatus: builder.query<ILeagueCupStatus, number>({
      query: (leagueId: number) => `league/${leagueId}/cup-status/`,
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchClassicLeagueForAdminQuery,
  useLazyFetchClassicLeagueStandingsQuery,
  useFetchLeagueCodeQuery,
  useLazyFetchLeagueCodeQuery,
  useLazyFetchClassicLeagueForAdminQuery,
} = leaguesApi;
