import { createAsyncThunk } from "@reduxjs/toolkit";
import { IThunkApi } from "../../app/types";
import { clearValue, setValue } from "./autoJoinPersistentStorage";
import { clearAutoJoinCode, setAutoJoinCode } from "./autoJoinSlice";

export const localStorageKey = "autoJoinCode";

export const clearCode = createAsyncThunk<void, void, IThunkApi>(
  "autoJoin/clearCode",
  async (_: void, { dispatch }) => {
    clearValue(localStorageKey);
    await dispatch(clearAutoJoinCode());
  }
);

export const setCode = createAsyncThunk<void, string, IThunkApi>(
  "autoJoin/setCode",
  async (code, { dispatch }) => {
    setValue(localStorageKey, code);
    await dispatch(setAutoJoinCode(code));
  }
);
