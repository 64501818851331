import React, { useState } from "react";
import { useAppDispatch } from "../../rtk-core/src/app/hooks";
import { banLeagueEntry } from "../../rtk-core/src/features/leagues";
import { IPrivateLeagueAdmin } from "../../rtk-core/src/features/leagues";
import Button from "../Button";
import { FieldWrap } from "../FieldRenderers";
import SubHeading from "../SubHeading";
import EntrySelector from "../EntrySelector";
import Copy from "../Copy";

interface IOwnProps {
  league: IPrivateLeagueAdmin;
}

const AddBan: React.FC<IOwnProps> = ({ league }) => {
  const [entry, setEntry] = useState<number>(0);
  const dispatch = useAppDispatch();

  const handleEntryChange = (entryId: number) => {
    setEntry(entryId);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(banLeagueEntry({ leagueId: league.id, data: { entry } }));
  };

  const disabled = !entry;

  return (
    <>
      <Copy>
        <SubHeading>Add League suspensions</SubHeading>
        <p>
          Search for the player you wish to suspend from this league. Once the
          player has been suspended they will be unable to rejoin the league
          again.
        </p>
      </Copy>
      <form onSubmit={handleSubmit}>
        <FieldWrap>
          <EntrySelector
            handleChange={handleEntryChange}
            leagueId={league.id}
            excluded={[league.admin_entry]}
          />
        </FieldWrap>
        <Button type="submit" disabled={disabled}>
          Suspend player
        </Button>
      </form>
    </>
  );
};

export { AddBan as AddBanTest };
export default AddBan;
