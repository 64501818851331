import { size } from "polished";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import BaseExternalIcon from "../../img/icons/external.svg?react";
import { INavLink } from "../Navigation";
import "./ListItem.css";

const LIStyled = styled.li`
  margin-right: ${({ theme }) => theme.space[1]};
  list-style-type: none;
  display: inline-block;
  font-family: ${({ theme }) => theme.fonts.bold};

  a {
    font-size: ${({ theme }) => theme.fontSizes[0]};

    @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
      line-height: 3.6rem;
    }
  }
`;

const ExternalIcon = styled(BaseExternalIcon)`
  ${size(8)}
  margin-left: 0.4rem;
  fill: white;
`;

interface IOwnProps {
  isWebView: boolean;
}

type Props = IOwnProps & INavLink;

const ListItem = React.forwardRef<HTMLLIElement, Props>(
  (
    {
      useRouter,
      text,
      href,
      baseUrl,
      rel,
      indicateExternal,
      target,
      isWebView,
    },
    ref
  ) => {
    const { pathname } = useLocation();
    const baseUrlMatch = baseUrl ? pathname.startsWith(baseUrl) : false;
    const webviewClass =
      isWebView && text === "Sign Out" ? "webview-hidden" : "";

    return (
      <LIStyled ref={ref}>
        {useRouter ? (
          <NavLink
            className={({ isActive }) =>
              isActive || baseUrlMatch ? `ism-nav__tab active` : `ism-nav__tab`
            }
            to={href}
          >
            {text}
          </NavLink>
        ) : (
          <a
            href={href}
            className={`ism-nav__tab ${webviewClass}`}
            rel={rel}
            target={target}
          >
            <span>{text}</span>
            {indicateExternal && <ExternalIcon />}
          </a>
        )}
      </LIStyled>
    );
  }
);

export default ListItem;
