import React from "react";
import { connect } from "react-redux";
import Collapsible from "../components/Collapsible";
import Copy from "../components/Copy";
import HelpNav from "../components/HelpNav/HelpNav";
import { GridItem, GridWrapper, SidebarGrid } from "../components/Layout";
import Table from "../components/Table";
import Title from "../components/Title";
import { RootState } from "../rtk-core/src/app/store";
import {
  IElementType,
  getElementTypes,
} from "../rtk-core/src/features/elementTypes";
import {
  IEvent,
  IEventsById,
  getEvents,
  getEventsById,
} from "../rtk-core/src/features/events";
import { IRules, getRules } from "../rtk-core/src/features/game";

interface IPropsFromState {
  elementTypes: IElementType[];
  events: IEvent[];
  eventsById: IEventsById;
  rules: IRules | null;
}
type Props = IPropsFromState;

const Rules: React.FC<Props> = ({ elementTypes, events, rules }) => {
  return (
    rules && (
      <GridWrapper>
        <SidebarGrid $hasMargin={true}>
          <GridItem>
            <Title>Help</Title>
          </GridItem>
          <GridItem>
            <div className="-mt-3">
              <HelpNav />
            </div>
          </GridItem>
          <GridItem $colSpan={1}>
            <Copy>
              <Overview />
            </Copy>
          </GridItem>
          <GridItem $colSpan={1} $gridColStart={1}>
            <Copy>
              <TeamSelection elementTypes={elementTypes} rules={rules} />
            </Copy>
          </GridItem>
          <GridItem $colSpan={1} $gridColStart={1}>
            <Copy>
              <TeamManagement rules={rules} />
            </Copy>
          </GridItem>
          <GridItem $colSpan={1} $gridColStart={1}>
            <Copy>
              <PriceChanges rules={rules} />
            </Copy>
          </GridItem>
          <GridItem $colSpan={1} $gridColStart={1}>
            <Copy>
              <Deadlines events={events} />
            </Copy>
          </GridItem>
          <GridItem $colSpan={1} $gridColStart={1}>
            <Copy>
              <Scoring />
            </Copy>
          </GridItem>
          <GridItem $colSpan={1} $gridColStart={1}>
            <Copy>
              <Leagues rules={rules} />
            </Copy>
          </GridItem>
          <GridItem $colSpan={1} $gridColStart={1}>
            <Copy>
              <GlobalLeagues />
            </Copy>
          </GridItem>
        </SidebarGrid>
      </GridWrapper>
    )
  );
};

export { Rules as RulesTest };

const mapStateToProps = (state: RootState) => ({
  elementTypes: getElementTypes(state),
  events: getEvents(state),
  eventsById: getEventsById(state),
  rules: getRules(state),
});

export default connect(mapStateToProps)(Rules);

const Overview: React.FC = () => (
  <Collapsible headingText="Overview" initialOpen>
    <p>
      FPL Challenge is a new way of playing Fantasy Premier League which allows
      managers to test their skills by picking a NEW team each Gameweek.
    </p>
    <p>
      Each Gameweek there will be a different Challenge that affects every
      player’s team that week. For example, the budget for your squad could be
      different, or certain players will receive double points.
    </p>
    <p>
      In the 'Gameweek' view of a league, your scores will reset to zero each
      Gameweek and not create a total across the season.
    </p>
    <p>
      In the 'Event' view of a league, your scoring will be the sum of all
      Gameweeks for the duration of the Event. Once the Event ends, your
      cumulative score will be reset to zero ahead of the next event.
    </p>
    <p>
      In the 'Season' view of a league, your scoring will be the sum of every
      Gameweek for the duration of the season.
    </p>
  </Collapsible>
);

interface ITeamSelectionProps {
  elementTypes: IElementType[];
  rules: IRules;
}

const TeamSelection: React.FC<ITeamSelectionProps> = ({
  elementTypes,
  rules,
}) => (
  <Collapsible headingText="Team Selection" initialOpen>
    <p>
      You must select a new squad each Gameweek / Challenge. You may select a
      squad for a Gameweek at any point, provided that the Gameweek is open for
      team selection. You can see if a Gameweek is open for team selection by
      navigating to that Gameweek on the Home or Manage Team page.
    </p>
    <p>
      To successfully enter a squad for a Challenge, you must select and save a
      valid squad for the given Gameweek. For example, if the challenge requires
      a team of 11 players then you need to select 11 players or if the
      challenge requires a team of 5 players, then you are only required to
      select 5.
    </p>
    <p>
      In FPL Challenge, you may create a squad for a Gameweek provided that it
      is still possible to select a team of players that haven't kicked off yet
      and the team meets the challenge criteria. That is because you cannot
      select a player from a club that has already played a fixture (or is
      currently playing a fixture) that Gameweek.
    </p>
    <p>
      A Challenge may have additional rules around squad composition (e.g.
      minimum and/or maximum requirements based on a particular player
      characteristic). The Challenge rules and requirements will be detailed at
      the top of the Manage Team page for the specific Gameweek.
    </p>
  </Collapsible>
);

interface ITeamManagementProps {
  rules: IRules;
}

const TeamManagement: React.FC<ITeamManagementProps> = ({ rules }) => (
  <Collapsible headingText="Team Management" initialOpen>
    <p>
      Users must select a captain each gameweek who will score double points.
    </p>
    <p>
      You can make unlimited changes to your team and captain choice before and
      during the Gameweek but these must be made before a player's club starts
      their first match of the Gameweek; after this they will be locked. When a
      player is locked, you can no longer transfer them out of your team. You
      also cannot change your captain once they are locked, nor can you captain
      a locked player.
    </p>
    <p>
      Should you want to make a transfer, you would need to ensure your squad is
      within budget when saving your squad.
    </p>
  </Collapsible>
);

interface IPriceChanges {
  rules: IRules;
}

const PriceChanges: React.FC<IPriceChanges> = ({ rules }) => (
  <Collapsible headingText="Price Changes" initialOpen>
    <p>
      Player prices will reflect those in Fantasy Premier League, including any
      rises and falls.
    </p>
    <p>
      As a result of this the value of your squad value may differ from the
      initial budget set during the challenge. However, when making any changes
      to your squad, players will be sold at the price you paid, irrespective of
      any rises or falls.
    </p>
  </Collapsible>
);

interface IDeadlinesProps {
  events: IEvent[];
}
const Deadlines: React.FC<IDeadlinesProps> = ({ events }) => (
  <Collapsible headingText="Deadlines" initialOpen>
    <p>
      In FPL Challenge, you may create a new team for a Gameweek until the Final
      Gameweek Deadline. The Final Gameweek Deadline occurs when it is no longer
      possible to select a valid team based on the Gameweeks selection criteria.
      That is because you cannot select a player from a club that has already
      played a fixture (or is currently playing a fixture) that Gameweek.
    </p>
    <p>
      Changes to your team (e.g. starting team, transfers, captain) can be made
      at any time during the Gameweek, including after the Final Gameweek
      Deadline. However, once a player's club has started their first match of
      the Gameweek, they will be locked from any changes.
    </p>
    <p>
      Due to the limit of the number of players that can be selected from an
      individual team, the deadlines for initial squad selection are subject to
      change.
    </p>
    {/* <Table>
      <thead>
        <tr>
          <th scope="col">Gameweek</th>
          <th scope="col">Deadline</th>
        </tr>
      </thead>
      <tbody>
        {events.map((event) => (
          <tr key={event.id}>
            <td>{event.name}</td>
            <td>{formatRawAsLocal(event.deadline_time)}</td>
          </tr>
        ))}
      </tbody>
    </Table> */}
  </Collapsible>
);

// TODO: Populate from backend rules
interface IScoringProps {}
const Scoring: React.FC<IScoringProps> = () => {
  const config = {
    scoring: {
      short_play: 1,
      long_play: 2,
      long_play_limit: 60,
      goals_scored: { 1: 10, 2: 6, 3: 5, 4: 4 },
      assists: 3,
      clean_sheets: { 1: 4, 2: 4, 3: 1, 4: 0 },
      saves_limit: 3,
      saves: 1,
      penalties_saved: 5,
      penalties_missed: -2,
      concede_limit: 2,
      goals_conceded: -1,
      yellow_cards: -1,
      red_cards: -3,
      own_goals: -2,
    },
    bps: {
      long_play_limit: 60,
      short_play: 3,
      long_play: 6,
      goals_scored: { 1: 12, 2: 12, 3: 18, 4: 24 },
      assists: 9,
      clean_sheets: { 1: 12, 2: 12, 3: 0, 4: 0 },
      penalties_saved: 9,
      saves: 2,
      open_play_crosses: 1,
      big_chances_created: 3,
      cbi_limit: 2,
      clearances_blocks_interceptions: 1,
      recoveries_limit: 3,
      recoveries: 1,
      key_passes: 1,
      tackles: 2,
      dribbles: 1,
      winning_goals: 3,
      attempted_passes_limit: 30,
      pass_percentage_70: 2,
      pass_percentage_80: 4,
      pass_percentage_90: 6,
      penalties_conceded: -3,
      penalties_missed: -6,
      yellow_cards: -3,
      red_cards: -9,
      own_goals: -6,
      big_chances_missed: -3,
      errors_leading_to_goal: -3,
      errors_leading_to_goal_attempt: -1,
      tackled: -1,
      fouls: -1,
      offside: -1,
      target_missed: -1,
      goal_line_clearances: 3,
      fouls_won: 1,
      goals_conceded: { 1: -4, 2: -4, 3: 0, 4: 0 },
      shots_on_target: 2,
    },
  };
  return (
    <Collapsible headingText="Scoring" initialOpen>
      <p>
        During the season, your fantasy football players will be allocated
        points based on their performance in the Premier League. The points
        awarded for FPL Challenge mirror the scoring in Fantasy Premier League,
        unless affected by a specific challenge rule in a Gameweek.
      </p>
      <Table>
        <thead>
          <tr>
            <th scope="col">Action</th>
            <th scope="col">Points</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>For playing up to {config.scoring.long_play_limit} minutes</td>
            <td>{config.scoring.short_play}</td>
          </tr>
          <tr>
            <td>
              For playing {config.scoring.long_play_limit} minutes or more
              (excluding stoppage time)
            </td>
            <td>{config.scoring.long_play}</td>
          </tr>
          <tr>
            <td>For each goal scored by a goalkeeper</td>
            <td>{config.scoring.goals_scored[1]}</td>
          </tr>
          <tr>
            <td>For each goal scored by a defender</td>
            <td>{config.scoring.goals_scored[2]}</td>
          </tr>
          <tr>
            <td>For each goal scored by a midfielder</td>
            <td>{config.scoring.goals_scored[3]}</td>
          </tr>
          <tr>
            <td>For each goal scored by a forward</td>
            <td>{config.scoring.goals_scored[4]}</td>
          </tr>
          <tr>
            <td>For each goal assist</td>
            <td>{config.scoring.assists}</td>
          </tr>
          <tr>
            <td>For a clean sheet by a goalkeeper or defender</td>
            <td>{config.scoring.clean_sheets[1]}</td>
          </tr>
          <tr>
            <td>For a clean sheet by a midfielder</td>
            <td>{config.scoring.clean_sheets[3]}</td>
          </tr>
          <tr>
            <td>
              For every {config.scoring.saves_limit} shot saves by a goalkeeper
            </td>
            <td>{config.scoring.saves}</td>
          </tr>
          <tr>
            <td>For each penalty save</td>
            <td>{config.scoring.penalties_saved}</td>
          </tr>
          <tr>
            <td>For each penalty miss</td>
            <td>{config.scoring.penalties_missed}</td>
          </tr>
          <tr>
            <td>Bonus points for the best players in a match</td>
            <td>1-3</td>
          </tr>
          <tr>
            <td>
              For every {config.scoring.concede_limit} goals conceded by a
              goalkeeper or defender
            </td>
            <td>{config.scoring.goals_conceded}</td>
          </tr>
          <tr>
            <td>For each yellow card</td>
            <td>{config.scoring.yellow_cards}</td>
          </tr>
          <tr>
            <td>For each red card</td>
            <td>{config.scoring.red_cards}</td>
          </tr>
          <tr>
            <td>For each own goal</td>
            <td>{config.scoring.own_goals}</td>
          </tr>
        </tbody>
      </Table>
      <h4>Clean sheets</h4>
      <p>
        A clean sheet is awarded for not conceding a goal whilst on the pitch
        and playing at least 60 minutes (excluding stoppage time).
      </p>
      <p>
        If a player has been substituted when a goal is conceded this will not
        affect any clean sheet bonus.
      </p>
      <h4>Red Cards</h4>
      <p>
        If a player receives a red card, they will continue to be penalised for
        goals conceded by their team.
      </p>
      <p>Red card deductions include any points deducted for yellow cards.</p>
      <h4>Assists</h4>
      <p>
        Fantasy assists are awarded to the player from the goal scoring team who
        last touches the ball before their teammate who scores the goal. The
        final touch can be an intentional pass, an inadvertent touch, or the
        result of an effort on goal.
      </p>
      <p>
        If an opposing player deflects the ball after the final pass before a
        goal is scored, significantly altering the intended destination of the
        ball, then no assist in Fantasy is awarded. Should an unforced defensive
        error lead to the goalscoring opportunity, then no Fantasy assist will
        be awarded. If the goal scorer loses and then regains possession, then
        no assist is awarded.
      </p>
      <h4>Rebounds</h4>
      <p>
        If a shot at goal is blocked by an opposition player, saved by a
        goalkeeper, or hits the woodwork, and from the rebound a goal is scored,
        then a Fantasy assist is awarded. The shot does not have to be on target
        to result in a Fantasy assist. The action leading to the rebound can be
        a shot or a cross-shot, but not an intended cross or pass attempt, as
        determined by Stats Perform.
      </p>
      <p>
        If a significant touch is made by an opposition player after the blocked
        effort, then no Fantasy assist is awarded.
      </p>
      <h4>Own Goals</h4>
      <p>
        If a player shoots or passes the ball and directly forces an opposing
        player to put the ball in their own net, then an assist is awarded. If
        the pass or cross takes a significant deflection off an opposition
        player prior to their teammate scoring an own goal, then no Fantasy
        assist is awarded.
      </p>
      <h4>Penalties and Free-Kicks</h4>
      <p>
        In the event of a penalty or free-kick being scored, the player earning
        the penalty or free-kick is awarded a Fantasy assist. However, if the
        goal is scored by the player who earned the penalty or free-kick, then
        no Fantasy assist is awarded.
      </p>
      <h4>Finalising Assists</h4>
      <p>
        For the avoidance of doubt, points awarded in-game are subject to change
        up until one hour after the final whistle of the last match of the
        Gameweek. Once the points have all been updated on that day, no further
        adjustments to points will be made unless under extraordinary
        circumstances.
      </p>
      <p>
        The final decision on Fantasy assists is made by Fantasy Premier League
        in consultation with Stats Perform.
      </p>
      <h4>Bonus Points</h4>
      <p>
        The Bonus Points System (BPS) utilises a range of statistics to create a
        BPS score for every player. The three best performing players in each
        match will be awarded bonus points. 3 points will be awarded to the
        highest scoring player, 2 to the second best and 1 to the third.
      </p>
      <p>Examples of how bonus point ties will be resolved are as follows:</p>
      <ul>
        <li>
          If there is a tie for first place, Players 1 & 2 will receive 3 points
          each and Player 3 will receive 1 point.
        </li>
        <li>
          If there is a tie for second place, Player 1 will receive 3 points and
          Players 2 and 3 will receive 2 points each.
        </li>
        <li>
          If there is a tie for third place, Player 1 will receive 3 points,
          Player 2 will receive 2 points and Players 3 & 4 will receive 1 point
          each.
        </li>
      </ul>
      <h4>How is the BPS score calculated?</h4>
      <p>
        Players score BPS points based on the following statistics (one point
        for each unless otherwise stated):
      </p>
      <Table>
        <thead>
          <tr>
            <th scope="col">Action</th>
            <th scope="col">BPS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Playing 1 to {config.bps.long_play_limit} minutes</td>
            <td>{config.bps.short_play}</td>
          </tr>
          <tr>
            <td>Playing over {config.bps.long_play_limit} minutes</td>
            <td>{config.bps.long_play}</td>
          </tr>
          <tr>
            <td>Goalkeepers and defenders scoring a goal</td>
            <td>{config.bps.goals_scored[1]}</td>
          </tr>
          <tr>
            <td>Midfielders scoring a goal</td>
            <td>{config.bps.goals_scored[3]}</td>
          </tr>
          <tr>
            <td>Forwards scoring a goal</td>
            <td>{config.bps.goals_scored[4]}</td>
          </tr>
          <tr>
            <td>Assists</td>
            <td>{config.bps.assists}</td>
          </tr>
          <tr>
            <td>Goalkeepers and defenders keeping a clean sheet</td>
            <td>{config.bps.clean_sheets[1]}</td>
          </tr>
          <tr>
            <td>Saving a penalty</td>
            <td>{config.bps.penalties_saved}</td>
          </tr>
          <tr>
            <td>Save</td>
            <td>{config.bps.saves}</td>
          </tr>
          <tr>
            <td>Successful open play cross</td>
            <td>{config.bps.open_play_crosses}</td>
          </tr>
          <tr>
            <td>
              Creating a big chance (a chance where the receiving player should
              score)
            </td>
            <td>{config.bps.big_chances_created}</td>
          </tr>
          <tr>
            <td>
              For every {config.bps.cbi_limit} clearances, blocks and
              interceptions (total)
            </td>
            <td>{config.bps.clearances_blocks_interceptions}</td>
          </tr>
          <tr>
            <td>For every {config.bps.recoveries_limit} recoveries</td>
            <td>{config.bps.recoveries}</td>
          </tr>
          <tr>
            <td>Key pass</td>
            <td>{config.bps.key_passes}</td>
          </tr>
          <tr>
            <td>Successful tackle (net*)</td>
            <td>{config.bps.tackles}</td>
          </tr>
          <tr>
            <td>Successful dribble</td>
            <td>{config.bps.dribbles}</td>
          </tr>
          <tr>
            <td>Scoring the goal that wins a match</td>
            <td>{config.bps.winning_goals}</td>
          </tr>
          <tr>
            <td>Goal line clearance</td>
            <td>{config.bps.goal_line_clearances}</td>
          </tr>
          <tr>
            <td>Foul won</td>
            <td>{config.bps.fouls_won}</td>
          </tr>
          <tr>
            <td>Shot on target</td>
            <td>{config.bps.shots_on_target}</td>
          </tr>
          <tr>
            <td>
              70 to 79% pass completion (at least{" "}
              {config.bps.attempted_passes_limit} passes attempted)
            </td>
            <td>{config.bps.pass_percentage_70}</td>
          </tr>
          <tr>
            <td>
              80 to 89% pass completion (at least{" "}
              {config.bps.attempted_passes_limit} passes attempted)
            </td>
            <td>{config.bps.pass_percentage_80}</td>
          </tr>
          <tr>
            <td>
              90%+ pass completion (at least {config.bps.attempted_passes_limit}{" "}
              passes attempted)
            </td>
            <td>{config.bps.pass_percentage_90}</td>
          </tr>
          <tr>
            <td>Goalkeepers and defenders conceding a goal</td>
            <td>{config.bps.goals_conceded[1]}</td>
          </tr>
          <tr>
            <td>Conceding a penalty</td>
            <td>{config.bps.penalties_conceded}</td>
          </tr>
          <tr>
            <td>Missing a penalty</td>
            <td>{config.bps.penalties_missed}</td>
          </tr>
          <tr>
            <td>Yellow card</td>
            <td>{config.bps.yellow_cards}</td>
          </tr>
          <tr>
            <td>Red card</td>
            <td>{config.bps.red_cards}</td>
          </tr>
          <tr>
            <td>Own goal</td>
            <td>{config.bps.own_goals}</td>
          </tr>
          <tr>
            <td>Missing a big chance</td>
            <td>{config.bps.big_chances_missed}</td>
          </tr>
          <tr>
            <td>Making an error which leads to a goal</td>
            <td>{config.bps.errors_leading_to_goal}</td>
          </tr>
          <tr>
            <td>Making an error which leads to an attempt at goal</td>
            <td>{config.bps.errors_leading_to_goal_attempt}</td>
          </tr>
          <tr>
            <td>Being tackled</td>
            <td>{config.bps.tackled}</td>
          </tr>
          <tr>
            <td>Conceding a foul</td>
            <td>{config.bps.fouls}</td>
          </tr>
          <tr>
            <td>Being caught offside</td>
            <td>{config.bps.offside}</td>
          </tr>
          <tr>
            <td>Shot off target</td>
            <td>{config.bps.target_missed}</td>
          </tr>
        </tbody>
      </Table>
      <p>
        *Net successful tackles is the total of all successful tackles minus any
        unsuccessful tackles. Players will not be awarded negative BPS points
        for this statistic.
      </p>
      <p>
        Data is supplied by Opta and will not be changed after the last day of
        the Gameweek has been marked as final. We will not enter into discussion
        around any of the statistics used to calculate this score for any
        individual match.
      </p>
    </Collapsible>
  );
};

// TODO: Populate from backend rules
interface ILeaguesProps {
  rules: IRules;
}
const Leagues: React.FC<ILeaguesProps> = ({ rules }) => {
  return (
    <Collapsible headingText="Leagues" initialOpen>
      <p>
        After entering your squad, you can join and create leagues to compete
        with friends and other game players.
      </p>
      <p>
        Leagues are the heart and soul of the game, where you compete against
        your friends. Just create a league and then send out the unique code to
        allow your friends to join, easy!
      </p>
      <p>
        You can compete in up to {rules.league_join_private_max} private
        leagues. There's no limit on the number of teams in a single league.
        Everyone starts from 0 for each new Gameweek and you'll automatically
        join your existing leagues when creating a team for a new challenge.
      </p>
    </Collapsible>
  );
};

const GlobalLeagues: React.FC = () => {
  return (
    <Collapsible headingText="Global Leagues" initialOpen>
      <p>You are automatically entered into the following global leagues:</p>

      <ul>
        <li>The overall league featuring all registered teams</li>
        <li>A league for fellow managers from your country</li>
        <li>A league for supporters of your favourite Premier League team</li>
      </ul>
    </Collapsible>
  );
};
