import { size } from "polished";
import styled, { css } from "styled-components";
import ArrowRightIcon from "../../img/icons/arrow-right.svg?react";

export const ArrowRight = styled(ArrowRightIcon)`
  fill: currentColor;
  vertical-align: middle;
  ${size(16)}
`;

export const ReportNameButtonWrapStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
