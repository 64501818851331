import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICupState, ICupDetailTransformed } from "./types";
import { cupApi } from "../api/cup";
import { IFetchEntrySummaryResponse } from "../entries";
import { entriesApi } from "../api/entries";

const initialState: ICupState = {
  byEntryId: {},
};

const cup = createSlice({
  name: "cup",
  initialState,
  reducers: {
    fetchEntryCupDetail: (
      state,
      action: PayloadAction<ICupDetailTransformed>
    ) => ({
      ...state,
      byEntryId: {
        ...state.byEntryId,
        [action.payload.id]: {
          matches: action.payload.data.cup_matches,
          status: action.payload.data.cup_status,
        },
      },
    }),
    fetchEntrySummary: (
      state,
      action: PayloadAction<IFetchEntrySummaryResponse>
    ) => {
      const entryId = action.payload.id;
      const cupDetail = action.payload.data.leagues.cup;
      const newState = { ...state.byEntryId };
      newState[entryId] = cupDetail;
      if (state.byEntryId[entryId]) {
        const idsFromSummary = newState[entryId].matches.map((m) => m.id);
        state.byEntryId[entryId].matches.forEach((m) => {
          if (idsFromSummary.indexOf(m.id) === -1) {
            newState[entryId] = {
              ...newState[entryId],
              matches: [...newState[entryId].matches, m],
            };
          }
        });
      }
      state.byEntryId = newState;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      cupApi.endpoints.fetchEntryCupDetail.matchFulfilled,
      (state, action: PayloadAction<ICupDetailTransformed>) =>
        cup.caseReducers.fetchEntryCupDetail(state, action)
    );
    builder.addMatcher(
      entriesApi.endpoints.fetchEntrySummary.matchFulfilled,
      (state, action: PayloadAction<IFetchEntrySummaryResponse>) =>
        cup.caseReducers.fetchEntrySummary(state, action)
    );
  },
});

export const { fetchEntryCupDetail } = cup.actions;

export default cup.reducer;
