import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Copy from "../components/Copy";
import {
  CheckboxField,
  FieldWrap,
  InputField,
  SelectField,
} from "../components/FieldRenderers";
import { FormBackground } from "../components/FormBackground";
import { GridItem, GridWrapper, SidebarGrid } from "../components/Layout";
import { NewTabCopy } from "../components/Utils";
import ManageTeamContext from "../contexts/ManageTeamContext";
import { useAppSelector } from "../rtk-core/src/app/hooks";
import { getActiveEvent } from "../rtk-core/src/features/events";
import { getTeams } from "../rtk-core/src/features/teams";
import { EventTitle } from "./ManageTeam";

const ManageTeamCreate = () => {
  const navigate = useNavigate();
  const activeEvent = useAppSelector(getActiveEvent);
  const { createEntryData, isValid, setCreateEntryData } =
    useContext(ManageTeamContext);
  const teams = useAppSelector(getTeams);

  const handleCreateTeam = () => {
    if (isValid()) {
      navigate(`/manage/${activeEvent.id}`, { state: "initial" });
    } else {
      console.log("Invalid create data");
    }
  };

  const handleFaveChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value;
    setCreateEntryData({
      ...createEntryData,
      favourite_team: value === "" ? null : parseInt(value, 10),
    });
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCreateEntryData({
      ...createEntryData,
      name: e.target.value,
    });

  const handleTermsChange = (e: React.FormEvent<HTMLInputElement>) =>
    setCreateEntryData({
      ...createEntryData,
      terms_agreed: e.currentTarget.checked ? true : false,
    });

  return (
    <GridWrapper>
      <SidebarGrid $hasMargin={true}>
        <GridItem $colSpan={1}>
          <FormBackground>
            <Copy>
              <EventTitle>Create your team name</EventTitle>
              <p>You need to give your team a name.</p>
              <FieldWrap>
                <InputField
                  id="entryName"
                  name="entryName"
                  label="Team name"
                  value={createEntryData.name}
                  onChange={handleNameChange}
                  hint="Maximum 20 characters"
                  maxLength={20}
                />
              </FieldWrap>
              <FieldWrap>
                <SelectField
                  id="entryFave"
                  name="entryFave"
                  label="Favourite club"
                  onChange={handleFaveChange}
                  value={createEntryData.favourite_team ?? ""}
                >
                  <option
                    value={0}
                    aria-selected={createEntryData.favourite_team === 0}
                  >
                    Choose a club...
                  </option>
                  {teams.map((tm) => (
                    <option
                      value={tm.id}
                      key={tm.id}
                      aria-selected={createEntryData.favourite_team === tm.id}
                    >
                      {tm.name}
                    </option>
                  ))}
                  <option
                    value=""
                    aria-selected={createEntryData.favourite_team === null}
                  >
                    None
                  </option>
                </SelectField>
              </FieldWrap>
              <FieldWrap>
                <CheckboxField
                  id="entryTerms"
                  name="entryTerms"
                  $boxAlign="top"
                  label={
                    <>
                      I agree to the Fantasy Premier League Challenge{" "}
                      <a
                        href="/help/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        terms and conditions
                        <NewTabCopy />
                      </a>
                      , which include free entry into a prize draw for eligible
                      participants &#40;as set out in the terms and
                      conditions&#41;.
                    </>
                  }
                  onChange={handleTermsChange}
                  checked={createEntryData.terms_agreed}
                />
              </FieldWrap>
              <Button onClick={handleCreateTeam}>Create team</Button>
            </Copy>
          </FormBackground>
        </GridItem>
      </SidebarGrid>
    </GridWrapper>
  );
};

export default ManageTeamCreate;
