import { IPhase, IPhaseState } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { bootstrapApi } from "../api/bootstrap";
import { IGetBootstrapStaticResponse } from "../bootstrap";

const initialState: IPhaseState = {
  byId: {},
};

const phases = createSlice({
  name: "phases",
  initialState,
  reducers: {
    addPhases: (state, action: PayloadAction<IPhase[]>) => ({
      ...state,
      byId: Object.fromEntries(
        action.payload.map((phase: IPhase) => [
          phase.id,
          {
            ...phase,
          },
        ])
      ),
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      bootstrapApi.endpoints.getBootstrapStatic.matchFulfilled,
      (state, action: PayloadAction<IGetBootstrapStaticResponse>) =>
        phases.caseReducers.addPhases(state, {
          ...action,
          payload: action.payload.phases,
        })
    );
  },
});

export const { addPhases } = phases.actions;

export default phases.reducer;
