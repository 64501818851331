import moment from "moment";
import React from "react";
import styled, { css } from "styled-components";

import promoBg from "../../img/pattern/scout-bg.png";
import NewsThumb from "../NewsThumb";

const ScoutPattern = css`
  overflow: auto;
  background-image: url(${promoBg}),
    linear-gradient(
      to right,
      ${({ theme }) => theme.colors.blueLight},
      ${({ theme }) => theme.colors.fantasy}
    );
  background-position: right -75px bottom -230px, 0;
  background-repeat: no-repeat;
`;

const ScoutLink = styled.a`
  display: block;
  color: inherit;
  text-decoration: none;

  :hover img {
    transform: scale(1.1);
  }
`;

const ScoutContent = styled.figure`
  ${ScoutPattern}

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
  }
`;

const ScoutImgWrap = styled.div`
  margin: 1rem;
  overflow: hidden;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 1;
  }
`;

const ScoutBody = styled.figcaption`
  padding: ${({ theme }) => `${theme.space[5]} ${theme.space[2]}`};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 1;
    padding: 3rem;
  }
`;

const ScoutTitle = styled.h4`
  margin-bottom: ${({ theme }) => theme.space[2]};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.6rem;

  ${ScoutLink}:hover & {
    text-decoration: underline;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-top: 0.7rem;
    font-size: ${(props) => props.theme.space[6]};
  }
`;

const ScoutSummary = styled.p`
  font-size: 1.3rem;
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

const ScoutDate = styled.time`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.1rem;
`;

type NewsLeaderProps = {
  news: any;
};

export const NewsLeader: React.FC<NewsLeaderProps> = ({ news }) => {
  return (
    <div>
      <ScoutLink href={`https://www.premierleague.com/news/${news.id}`}>
        <ScoutContent>
          <ScoutImgWrap>
            <NewsThumb
              news={news}
              imgSizes={{
                x1: { width: 400, height: 266 },
                x2: { width: 800, height: 532 },
              }}
            />
          </ScoutImgWrap>
          <ScoutBody>
            <ScoutTitle>{news.title}</ScoutTitle>
            <ScoutSummary>{news.summary}</ScoutSummary>
            <ScoutDate dateTime={news.date}>
              {moment(news.date).format("DD/MM/YYYY")}
            </ScoutDate>
          </ScoutBody>
        </ScoutContent>
      </ScoutLink>
    </div>
  );
};

export default NewsLeader;
