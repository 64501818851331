import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPlayerData } from "../../rtk-core/src/features/player";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setCode, clearCode } from "../../rtk-core/src/features/autoJoin";
import { AppDispatch } from "../../rtk-core/src/app/store";
import { useAppSelector } from "../../rtk-core/src/app/hooks";
import { getActiveEvent } from "../../rtk-core/src/features/events";

const AutoJoin: React.FC = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const reduxDispatch = useDispatch<AppDispatch>();
  const player = useAppSelector(getPlayerData);
  const activeEvent = useAppSelector(getActiveEvent);
  useEffect(() => {
    code ? reduxDispatch(setCode(code)) : reduxDispatch(clearCode());
    navigate(next, { replace: true });
  });
  const next = player ? `/home/${activeEvent.id}` : "/";
  return <div />;
};

export default AutoJoin;
