import { StyledRankBadge } from "./styles";
import { IRankBadge } from "./types";

const RankBadge: React.FC<IRankBadge> = ({ name, rank, size }) => {
  const path = `/src/img/ranks/FPL_Rank_${rank.replace(" ", "_")}`;
  const rankName = name ? `Rank: ${name}` : "";
  const altText = rankName === "Rank: Unranked" ? "" : rankName;
  return (
    <StyledRankBadge
      src={`${path}.svg`}
      alt={altText}
      $size={size}
      title={rankName}
    />
  );
};
export default RankBadge;
