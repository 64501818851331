import React from "react";
import ButtonLink from "../ButtonLink";
import Copy from "../Copy";
import HelmetHead from "../HelmetHead";
import { Main, Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import TextDivider from "../TextDivider";
import Title from "../Title";

const CreateJoin: React.FC = () => (
  <Wrapper>
    <HelmetHead
      title={"Create & Join Fantasy Football Leagues | PL Challenges"}
      description={
        "To view all types of leagues, as well as creating & joining new leagues, visit the official website of the Premier League."
      }
    />
    <Main>
      <Copy>
        <Title>Create or Join Leagues</Title>
        <SubHeading>Join an Existing League</SubHeading>
        <p>
          Join invitational leagues to compete against friends and other game
          players
        </p>
        <ButtonLink to="../leagues/join/private">Join a League</ButtonLink>
        <TextDivider>or</TextDivider>
        <SubHeading>Create a New League</SubHeading>
        <p>Create an invitational league to compete against friends.</p>
        <ButtonLink to="../leagues/create/classic">Create a League</ButtonLink>
      </Copy>
    </Main>
  </Wrapper>
);

export default CreateJoin;
