import { createAsyncThunk } from "@reduxjs/toolkit";
import { IThunkApi } from "../../app/types";
import { elementsApi } from "../api/elements";
import { getWatched } from "../player/playerSlice";
import { elementDialogShow, updateElementControls } from "./elementsSlice";
import {
  filterElements,
  getElementControls,
  getElementTypeFilterOptions,
  getElements,
  maxCostFromElements,
} from "./selectors";
import { IElementControls } from "./types";

export const updateElementControlsAndMaxCost = createAsyncThunk<
  void,
  IElementControls,
  IThunkApi
>(
  "elements/updateElementControlsAndMaxCost",
  async (controls: IElementControls, { dispatch, getState }) => {
    const elements = getElements(getState());
    dispatch(
      updateElementControls({
        ...controls,
        maxCost: maxCostFromElements(
          filterElements(elements, controls.filter, getWatched(getState()))
        ),
      })
    );
  }
);

export const updateElementTypeControl = createAsyncThunk<
  void,
  number,
  IThunkApi
>(
  "elements/updateElementTypeControl",
  async (elementTypeId: number, { dispatch, getState }) => {
    dispatch(
      updateElementControlsAndMaxCost({
        ...getElementControls(getState()),
        filter: getElementTypeFilterOptions(getState())[elementTypeId - 1]
          .value,
      })
    );
  }
);

export const showElementSummary = createAsyncThunk<void, number, IThunkApi>(
  "elements/showElementSummary",
  async (elementId: number, { dispatch }) => {
    dispatch(elementDialogShow(elementId));
    // We use 0 as a value to determine if the dialog should show or not so we want
    // to make sure we don't fire an API call when the value is 0
    if (elementId !== 0) {
      await dispatch(
        elementsApi.endpoints.getElementSummary.initiate(elementId)
      );
    }
  }
);
