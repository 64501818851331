import { useEffect } from "react";
import { useAppSelector } from "../../rtk-core/src/app/hooks";
import { useLazyFetchEntryHistoryQuery } from "../../rtk-core/src/features/api/entries";
import { getEntryEventHistory } from "../../rtk-core/src/features/entries";
import { getEntry } from "../../rtk-core/src/features/entries/";
import { getEventsById } from "../../rtk-core/src/features/events";
import { getPlayerData } from "../../rtk-core/src/features/player";
import { getEventShortName } from "../../utils/events";
import { getTopPercentileString } from "../../utils/leagues";
import Link from "../Link";
import { ScrollTable } from "../Table";
import {
  EntryHistoryTable,
  GameWeek,
  GlobalPosition,
  GlobalRank,
  Points,
  PointsLabel,
  PointsValue,
  PointsWrap,
  StyledChevronRight,
  StyledMessage,
  SubHeading,
  TableHeaderContent,
  Title,
  TotalPlayers,
} from "./styles";

const EntryHistory = () => {
  const player = useAppSelector(getPlayerData);
  const entry = useAppSelector((state) =>
    player?.entry ? getEntry(state, player.entry) : null
  );

  const eventHistory = useAppSelector((state) =>
    entry ? getEntryEventHistory(state, entry.id) : null
  );
  const eventsById = useAppSelector(getEventsById);

  const [fetchEntryHistoryTrigger] = useLazyFetchEntryHistoryQuery();

  useEffect(() => {
    if (entry) {
      fetchEntryHistoryTrigger(entry.id);
    }
  }, [entry, fetchEntryHistoryTrigger]);

  if (!eventHistory || !entry) {
    return null;
  }

  return (
    <>
      <Title>Entry history</Title>
      <SubHeading>This season</SubHeading>
      <ScrollTable>
        <EntryHistoryTable>
          <thead>
            <tr>
              <GameWeek scope="col">
                <TableHeaderContent title="Gameweek">
                  <span title="Gameweek">GW</span>
                </TableHeaderContent>
              </GameWeek>
              <GlobalRank scope="col">
                <TableHeaderContent title="Global Rank">
                  <span>GR</span>
                </TableHeaderContent>
              </GlobalRank>
              <GlobalPosition scope="col">
                <TableHeaderContent title="Global Position">
                  <span>GP</span>
                </TableHeaderContent>
              </GlobalPosition>
              <TotalPlayers scope="col">
                <TableHeaderContent title="Total Players">
                  <span>TP</span>
                </TableHeaderContent>
              </TotalPlayers>
              <Points scope="col">
                <TableHeaderContent title="Points">
                  <span>PTS</span>
                </TableHeaderContent>
              </Points>
            </tr>
          </thead>
          <tbody>
            {eventHistory.length ? (
              [...eventHistory].reverse().map((eh) => (
                <tr key={eh.event}>
                  <td>
                    <Link to={`/entry/${entry.id}/event/${eh.event}`}>
                      {getEventShortName(eventsById[eh.event].name)}
                    </Link>
                  </td>
                  <td>
                    {eh.percentile_rank
                      ? getTopPercentileString(eh.percentile_rank)
                      : "-"}
                  </td>
                  <td>{eh.rank ? eh.rank.toLocaleString() : "-"}</td>
                  <td>
                    {eventsById[eh.event].ranked_count
                      ? eventsById[eh.event].ranked_count.toLocaleString()
                      : "-"}
                  </td>
                  <td>
                    <PointsWrap to={`/entry/${entry.id}/event/${eh.event}`}>
                      <span>
                        <PointsValue>{eh.points || 0}</PointsValue>
                        <PointsLabel> pts</PointsLabel>
                      </span>
                      <StyledChevronRight />
                    </PointsWrap>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <StyledMessage colSpan={5}>
                  Event history is not available yet.
                </StyledMessage>
              </tr>
            )}
          </tbody>
        </EntryHistoryTable>
      </ScrollTable>
    </>
  );
};

export default EntryHistory;
