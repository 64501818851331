import { hideVisually, hiDPI, rgba } from "polished";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";
import Close from "../../img/icons/close.svg?react";
import pattern1_1236 from "../../img/pattern/pattern-1-1236.png";
import pattern1_874 from "../../img/pattern/pattern-1-874.png";
import pattern2Crop184 from "../../img/pattern/pattern-2-crop-184.png";
import pattern2Crop90 from "../../img/pattern/pattern-2-crop-90.png";

export const CloseIcon = styled(Close)`
  fill: ${({ theme }) => theme.colors.primary};
`;

const CloseLabel = styled.span`
  ${hideVisually}
`;

export const CloseButton = styled.button`
  padding: ${({ theme }) => theme.space[2]};
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

interface IStyledHeaderProps {
  variant?: "light" | undefined;
}

const StyledHeader = styled.div<IStyledHeaderProps>`
  position: relative;
  ${(props) =>
    props.variant === "light" &&
    css`
      background-color: transparent;
      color: ${({ theme }) => theme.colors.primary};
    `};
`;

const DialogHeading = styled.h2`
  margin-bottom: ${({ theme }) => theme.space[5]};
  padding-top: 2.4rem;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[4]};
  text-align: center;
`;

export const CloseButtonWrap = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const DialogButtonItem = styled.li`
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

const Overlay = styled.div`
  position: fixed;
  z-index: ${(props) => props.theme.zIndex.overlay};
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${rgba("black", 0.5)};
  border-radius: 6px;
`;

const StyledDialog = styled.dialog<{ isElementDialog: boolean }>`
  z-index: ${(props) => props.theme.zIndex.dialog};
  max-width: 90%;
  /* height: 400px; [1] */
  max-height: 90%;
  padding: 0;
  border: 0;
  border-radius: ${({ theme }) => theme.space[2]};
  /* Ugly hack to deal with sub-pixel rounding in chrome bug causing blurry content with transform */
  width: 640px;
  color: currentColor;
  box-shadow: 0 12px 24px 0 ${rgba("black", 0.25)};
  letter-spacing: ${({ theme }) => theme.letterSpacing[0]};

  ${(props) =>
    props.isElementDialog &&
    css`
      background-image: linear-gradient(
          to bottom,
          ${rgba("white", 0)} 240px,
          white 360px
        ),
        url(${pattern1_874}), url(${pattern2Crop90}),
        linear-gradient(
          to right,
          ${({ theme }) => theme.colors.blueLight},
          ${({ theme }) => theme.colors.darkBlue}
        );
      background-position: 0 0, left -290px top -310px, right top, 0 0;
      background-size: auto, 618px 848px, 90px 60px, auto;

      ${hiDPI(2)} {
        background-image: linear-gradient(
            to bottom,
            ${rgba("white", 0)} 240px,
            white 360px
          ),
          url(${pattern1_1236}), url(${pattern2Crop184}),
          linear-gradient(
            to right,
            ${({ theme }) => theme.colors.blueLight},
            ${({ theme }) => theme.colors.darkBlue}
          );
        background-size: auto, 618px 848px, 90px 60px, auto;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
        background-image: linear-gradient(
            to bottom,
            ${rgba("white", 0)} 240px,
            white 360px
          ),
          url(${pattern1_874}),
          linear-gradient(
            to right,
            ${({ theme }) => theme.colors.blueLight},
            ${({ theme }) => theme.colors.darkBlue}
          );
        background-position: 0 0, right -60px top -481px, 0 0;
        background-size: auto, 618px 848px, auto;

        ${hiDPI(2)} {
          background-image: linear-gradient(
              to bottom,
              ${rgba("white", 0)} 240px,
              white 360px
            ),
            url(${pattern1_1236}),
            linear-gradient(
              to right,
              ${({ theme }) => theme.colors.blueLight},
              ${({ theme }) => theme.colors.darkBlue}
            );
          background-size: auto, 618px 848px, auto;
        }
      }
    `}
`;

interface IDialogBodyProps {
  children: any;
  isPadded?: boolean;
}

const StyledDialogBody = styled.div<IDialogBodyProps>`
  max-height: calc(90vh - 50px);
  overflow-y: auto;
  ${(props) =>
    props.isPadded &&
    css`
      margin: ${props.theme.space[5]} ${props.theme.space[3]};
    `};
`;

export const DialogBody: React.FC<IDialogBodyProps> = ({
  children,
  isPadded,
}) => (
  <StyledDialogBody isPadded={isPadded} className="ism-overflow-scroll">
    {children}
  </StyledDialogBody>
);

interface IDialogProps {
  children: any;
  closeDialog: (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLDivElement>
      | KeyboardEvent
  ) => void;
  focusable?: string;
  isElementDialog?: boolean;
}

interface IDialogHeaderProps {
  children: React.ReactNode;
  closeDialog: () => void;
}

type DialogHeaderProps = IDialogHeaderProps & IStyledHeaderProps;

export const DialogHeader: React.FC<DialogHeaderProps> = ({
  children,
  variant,
  closeDialog,
}) => (
  <StyledHeader variant={variant}>
    <DialogHeading id="ism-dialog-title">{children}</DialogHeading>
    <CloseButtonWrap>
      <CloseButton onClick={closeDialog}>
        <CloseLabel>close</CloseLabel>
        <CloseIcon />
      </CloseButton>
    </CloseButtonWrap>
  </StyledHeader>
);

export type Props = IDialogProps & Partial<typeof defaultProps>;

const Dialog: React.FC<Props> = ({
  children,
  closeDialog,
  focusable = "a[href], area[href], input:not([disabled]), " +
    "select:not([disabled]), textarea:not([disabled]), " +
    "button:not([disabled]), iframe, object, embed, " +
    "*[tabindex], *[contenteditable]",
  isElementDialog = false,
}) => {
  const docref = useRef<HTMLDivElement>(null);
  const focusReturn = document.activeElement as HTMLElement;

  const isVisible = (elem: HTMLElement) =>
    !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

  const handleTab = (e: KeyboardEvent) => {
    const docRef = docref.current;
    const focusedItem = document.activeElement;
    if (docRef) {
      const focusableItems = Array.prototype.slice
        .call(docRef.querySelectorAll(focusable))
        .filter(isVisible);
      const numFocusableItems = focusableItems.length;
      const focusedIndex = focusableItems.indexOf(focusedItem);
      if (!e.shiftKey && focusedIndex === numFocusableItems - 1) {
        // Moving past last focusable item so focus first
        focusableItems[0].focus();
        e.preventDefault();
      } else if (e.shiftKey && focusedIndex === 0) {
        // Moving before first focusable item so focus last
        focusableItems[numFocusableItems - 1].focus();
        e.preventDefault();
      }
    }
  };

  const handleKeydown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      closeDialog(e);
    } else if (e.key === "Tab") {
      handleTab(e);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    const docRef = docref.current;
    if (docRef) {
      const focusElement = docRef.querySelector(focusable) as HTMLElement;
      if (focusElement) {
        focusElement.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      document.removeEventListener("keydown", handleKeydown);
      if (focusReturn) {
        if (focusReturn.focus) {
          focusReturn.focus();
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOutsideMouseClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeDialog(e);
      e.stopPropagation();
    }
  };

  return ReactDOM.createPortal(
    <Overlay
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        handleOutsideMouseClick(e);
      }}
      role="presentation"
    >
      <StyledDialog
        role="dialog"
        aria-labelledby="ism-dialog-title"
        open={true}
        isElementDialog={isElementDialog}
      >
        <div role="document" ref={docref}>
          {children}
        </div>
      </StyledDialog>
    </Overlay>,
    document.getElementById("root-dialog") as HTMLElement
  );
};

const defaultProps = {
  focusable:
    "a[href], area[href], input:not([disabled]), " +
    "select:not([disabled]), textarea:not([disabled]), " +
    "button:not([disabled]), iframe, object, embed, " +
    "*[tabindex], *[contenteditable]",
  isElementDialog: false,
};

export default Dialog;
