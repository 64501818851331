import { hiDPI, rgba } from "polished";
import styled, { css } from "styled-components";
import pattern2Crop184 from "../../img/pattern/pattern-2-crop-184.png";
import pattern2Crop90 from "../../img/pattern/pattern-2-crop-90.png";

interface IStyledProps {
  $isSquad?: boolean;
}

export const PatternWrapMain = styled.div<IStyledProps>`
  margin: 0.8rem;
  padding-top: ${({ theme }) => theme.space[3]};
  border-radius: ${({ theme }) => theme.radii[1]};
  background-repeat: no-repeat;
  background-position: 0, right top, 0;
  box-shadow: 0 8px 12px 0 ${rgba("black", 0.08)};
  background-image: linear-gradient(
      to bottom,
      ${rgba("white", 0)} 30px,
      rgba(255, 255, 255, 0.5) 75px,
      white 120px
    ),
    url(${pattern2Crop90}),
    linear-gradient(
      to right,
      ${({ theme }) => theme.colors.blueLight},
      ${({ theme }) => theme.colors.darkBlue}
    );
  overflow: auto;

  ${hiDPI(2)} {
    background-image: linear-gradient(
        to bottom,
        ${rgba("white", 0)} 30px,
        rgba(255, 255, 255, 0.5) 75px,
        white 120px
      ),
      url(${pattern2Crop184}),
      linear-gradient(
        to right,
        ${({ theme }) => theme.colors.blueLight},
        ${({ theme }) => theme.colors.darkBlue}
      );
    background-size: auto, 90px 60px, auto;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin: 0 0 ${({ theme }) => theme.space[3]};
    background-image: linear-gradient(
        to bottom,
        ${rgba("white", 0)} 30px,
        rgba(255, 255, 255, 0.5) 75px,
        white 120px
      ),
      url(${pattern2Crop184}),
      linear-gradient(
        to right,
        ${({ theme }) => theme.colors.blueLight},
        ${({ theme }) => theme.colors.darkBlue}
      );
    background-size: auto, auto, auto;
  }

  ${(props) =>
    props.$isSquad &&
    css`
      background-image: linear-gradient(
          to bottom,
          ${rgba("white", 0)} 200px,
          white 320px
        ),
        url(${pattern2Crop90}),
        linear-gradient(
          to right,
          ${({ theme }) => theme.colors.blueLight},
          ${({ theme }) => theme.colors.darkBlue}
        );

      ${hiDPI(2)} {
        background-image: linear-gradient(
            to bottom,
            ${rgba("white", 0)} 200px,
            white 320px
          ),
          url(${pattern2Crop184}),
          linear-gradient(
            to right,
            ${({ theme }) => theme.colors.blueLight},
            ${({ theme }) => theme.colors.darkBlue}
          );
      }

      @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
        background-image: linear-gradient(
            to bottom,
            ${rgba("white", 0)} 200px,
            white 320px
          ),
          url(${pattern2Crop184}),
          linear-gradient(
            to right,
            ${({ theme }) => theme.colors.blueLight},
            ${({ theme }) => theme.colors.darkBlue}
          );
      }
    `}
`;

export const PatternWrap = styled.div`
  border-radius: ${({ theme }) => theme.radii[1]};
  background-repeat: no-repeat;
  background-position: 0, right top, 0;
  background-image: linear-gradient(
      to bottom,
      ${rgba("white", 0)} 10px,
      rgba(255, 255, 255, 0.2) 30px,
      white 60px
    ),
    url(${pattern2Crop90}),
    linear-gradient(
      to right,
      ${({ theme }) => theme.colors.blueLight},
      ${({ theme }) => theme.colors.darkBlue}
    );
  box-shadow: 0 8px 12px 0 rgba(107, 107, 107, 0.08);
  overflow: auto;
`;

export const Glass = styled.div`
  margin: 0 ${({ theme }) => theme.space[2]} 1.2rem;
  padding: 0 0.8rem;
  border-radius: ${({ theme }) => theme.radii[1]};
  background: ${rgba("white", 0.6)};
  box-shadow: 0 8px 12px 0 ${rgba("#6b6b6b", 0.32)};
  text-align: center;
`;
