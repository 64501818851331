import { IScoreboardStat } from "../types";
import {
  ScoreboardStatHeading,
  ScoreboardStatValue,
  StyledScoreboardStat,
} from "./styles";

const ScoreboardStat = ({ heading, value }: IScoreboardStat) => (
  <StyledScoreboardStat>
    <ScoreboardStatHeading>{heading}</ScoreboardStatHeading>
    <ScoreboardStatValue>{value}</ScoreboardStatValue>
  </StyledScoreboardStat>
);

export default ScoreboardStat;
