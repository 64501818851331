import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { bootstrapApi } from "../api/bootstrap";
import { IGetBootstrapStaticResponse } from "../bootstrap";
import { IElementStat } from "./types";

const initialState: IElementStat[] = [];

const elementStats = createSlice({
  name: "elementStats",
  initialState,
  reducers: {
    addElementStats: (state, action: PayloadAction<IElementStat[]>) => {
      state.push(...action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      bootstrapApi.endpoints.getBootstrapStatic.matchFulfilled,
      (state, action: PayloadAction<IGetBootstrapStaticResponse>) =>
        elementStats.caseReducers.addElementStats(state, {
          ...action,
          payload: action.payload.element_stats,
        })
    );
  },
});

export const { addElementStats } = elementStats.actions;

export default elementStats.reducer;
