import React from "react";
import { NewsImg } from "./styles";

interface IImgSize {
  width: number;
  height: number;
}

interface IImgSizes {
  x1: IImgSize;
  x2: IImgSize;
}

interface IProps {
  news: any;
  imgSizes: IImgSizes;
}

const NewsThumb: React.FC<IProps> = ({ news, imgSizes }) => {
  const newsType = news.type;
  const allowedNewsTypes = ["text", "video"];
  if (allowedNewsTypes.indexOf(newsType) === -1) {
    return null;
  }
  let thumbSrc = "";
  if (newsType === "text") {
    if (!news.leadMedia) {
      return null;
    }
    const lm = news.leadMedia;
    thumbSrc =
      lm.type === "video" && lm.thumbnail
        ? lm.thumbnail.onDemandUrl
        : lm.onDemandUrl;
  }
  if (newsType === "video") {
    if (!news.thumbnail) {
      return null;
    }
    thumbSrc = news.thumbnail.onDemandUrl;
  }
  if (!thumbSrc) {
    return null;
  }
  const defaultSize = `${thumbSrc}?width=${imgSizes.x1.width}&height=${imgSizes.x1.height}`;
  return (
    <NewsImg
      srcSet={`${defaultSize}, ${thumbSrc}?width=${imgSizes.x2.width}&height=${imgSizes.x2.height} 2x`}
      src={defaultSize}
      alt=""
    />
  );
};

export default NewsThumb;
