import { createSlice, original, PayloadAction } from "@reduxjs/toolkit";
import { sortBy } from "lodash";
import { myTeamApi } from "../api/myTeam";

import {
  IMyTeamResponseData,
  IMyTeamState,
  IPickProposed,
  SubStatus,
} from "./types";

const initialState: IMyTeamState = {
  picksProposed: [],
  picksSaved: [],
  savingState: "",
};

const changeCaptainOrVicePrepare = (
  currentPicks: IPickProposed[],
  elementId: number,
  isVice: boolean
) => {
  const newCaptain = currentPicks.reduce<IPickProposed | null>(
    (memo, p) => (p.element === elementId ? p : memo),
    null
  );
  const attrMake = isVice ? "is_vice_captain" : "is_captain";
  const attrOther = isVice ? "is_captain" : "is_vice_captain";

  if (!newCaptain || newCaptain[attrMake]) {
    return currentPicks;
  }
  const oldCaptain = currentPicks.reduce<IPickProposed | null>(
    (memo, p) => (p[attrMake] ? p : memo),
    null
  );

  return currentPicks.map((p) => {
    const newPick = { ...p };
    if (p.element === elementId) {
      newPick[attrMake] = true;
      newPick[attrOther] = false;
    } else {
      newPick[attrMake] = false;
      // Handle case of roles being swapped
      if (
        oldCaptain &&
        oldCaptain.element === p.element &&
        newCaptain[attrOther]
      ) {
        newPick[attrOther] = true;
      }
    }
    return newPick;
  });
};

const myTeam = createSlice({
  name: "myTeam",
  initialState,
  reducers: {
    changeCaptain: (state, action: PayloadAction<number>) => ({
      ...state,
      picksProposed: changeCaptainOrVicePrepare(
        state.picksProposed,
        action.payload,
        false
      ),
    }),
    changeViceCaptain: (state, action: PayloadAction<number>) => ({
      ...state,
      picksProposed: changeCaptainOrVicePrepare(
        state.picksProposed,
        action.payload,
        true
      ),
    }),
    fetchMyTeam: (state, action: PayloadAction<IMyTeamResponseData>) => ({
      ...state,
      picksProposed: sortBy(action.payload.data.picks, "position").map((e) => ({
        ...e,
        elementType: action.payload.elementsById[e.element].element_type,
        subStatus: "" as SubStatus,
      })),
      picksSaved: sortBy(action.payload.data.picks, "position"),
    }),
    saveMyTeam: (_, action: PayloadAction<IMyTeamResponseData>) => ({
      picksProposed: sortBy(action.payload.data.picks, "position").map((e) => ({
        ...e,
        elementType: action.payload.elementsById[e.element].element_type,
        subStatus: "" as SubStatus,
      })),
      picksSaved: sortBy(action.payload.data.picks, "position"),
      savingState: "saved",
    }),
    saveMyTeamPending: (state) => ({
      ...state,
      savingState: "saving",
    }),
    substitutionStart: {
      reducer: (
        state,
        action: PayloadAction<{
          pick: IPickProposed;
          possibleReplacements: IPickProposed[];
        }>
      ) => {
        const newPicksProposed: IPickProposed[] = state.picksProposed.map(
          (p: IPickProposed) => {
            const newPick = { ...p };
            // The use immer original here is needed for strict equality checks of an object.
            // Read here for more info: https://immerjs.github.io/immer/original
            const pickFromState = original(p);
            if (pickFromState === action.payload.pick) {
              newPick.subStatus = "instigator";
            } else if (
              pickFromState &&
              action.payload.possibleReplacements.indexOf(pickFromState) > -1
            ) {
              newPick.subStatus = "target";
            } else {
              newPick.subStatus = "invalid";
            }
            return newPick;
          }
        );
        state.picksProposed = newPicksProposed;
      },
      prepare: (pick: IPickProposed, possibleReplacements: IPickProposed[]) => {
        return { payload: { pick, possibleReplacements } };
      },
    },
    substitutionProcess: {
      reducer: (
        state,
        action: PayloadAction<{ pick: IPickProposed; subsStart: number }>
      ) => {
        // Find the pick who instigated the substitution
        const picksProposed: IPickProposed[] = state.picksProposed;
        const instigator = picksProposed.reduce<IPickProposed | null>(
          (memo, p) => (p.subStatus === "instigator" ? p : memo),
          null
        );
        if (!instigator) {
          state.picksProposed = picksProposed;
        } else {
          // Swap positions / captain data and reset subStatus
          const attrs: Array<keyof IPickProposed> = [
            "position",
            "is_captain",
            "is_vice_captain",
          ];
          const target = action.payload.pick;
          const newPicks = picksProposed.map((p) => {
            const newPick = { ...p };
            // See https://github.com/microsoft/TypeScript/issues/31663 for
            // explanation for use of 'as any'
            if (p.element === instigator.element) {
              attrs.forEach((a) => ((newPick[a] as any) = target[a]));
            } else if (p.element === target.element) {
              attrs.forEach((a) => ((newPick[a] as any) = instigator[a]));
            }
            newPick.subStatus = "";
            return newPick;
          });

          // Order by position, factoring in elementType for starters
          const subStart = action.payload.subsStart;
          newPicks.sort((a, b) => {
            if (a.position < subStart && b.position < subStart) {
              return (
                a.elementType * 99 +
                a.position -
                (b.elementType * 99 + b.position)
              );
            }
            return a.position - b.position;
          });

          // Reset position based on index and return
          state.picksProposed = newPicks.map((p, index) => {
            const newPick = { ...p };
            newPick.position = index + 1;
            return newPick;
          });
        }
      },
      prepare: (pick: IPickProposed, subsStart: number) => {
        return { payload: { pick, subsStart } };
      },
    },
    substitutionStop: (state) => ({
      ...state,
      picksProposed: state.picksProposed.map((p) => ({
        ...p,
        subStatus: "" as SubStatus,
      })),
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      myTeamApi.endpoints.fetchMyTeam.matchFulfilled,
      (state, action: PayloadAction<IMyTeamResponseData>) =>
        myTeam.caseReducers.fetchMyTeam(state, action)
    );
    builder.addMatcher(myTeamApi.endpoints.saveMyTeam.matchPending, (state) =>
      myTeam.caseReducers.saveMyTeamPending(state)
    );
    builder.addMatcher(
      myTeamApi.endpoints.saveMyTeam.matchFulfilled,
      (state, action: PayloadAction<IMyTeamResponseData>) =>
        myTeam.caseReducers.saveMyTeam(state, action)
    );
    builder.addMatcher(
      myTeamApi.endpoints.createTeamForEvent.matchFulfilled,
      (state, action: PayloadAction<IMyTeamResponseData>) =>
        myTeam.caseReducers.saveMyTeam(state, action)
    );
  },
});

export const {
  changeCaptain,
  changeViceCaptain,
  fetchMyTeam,
  substitutionProcess,
  substitutionStart,
  substitutionStop,
} = myTeam.actions;

export default myTeam.reducer;
