import { useAppSelector } from "../../rtk-core/src/app/hooks";
import {
  getEventPhase,
  getPhasesById,
} from "../../rtk-core/src/features/phases";
import { getPhaseAssets } from "../../utils/phase";
import Navigator from "../Navigator";
import { IPhaseNavigator } from "./types";

const PhaseNavigator: React.FC<IPhaseNavigator> = ({
  activePhaseId,
  nextOnClick,
  prevOnClick,
}) => {
  const phasesById = useAppSelector(getPhasesById);
  const activePhase = phasesById[activePhaseId];

  const nextEventPhase = useAppSelector((state) =>
    getEventPhase(state, activePhaseId + 1)
  );

  const prevEventPhase = useAppSelector((state) =>
    getEventPhase(state, activePhaseId - 1)
  );

  const phaseAssets = getPhaseAssets(activePhaseId);
  const phaseTitle = phaseAssets?.title || activePhase.name;

  const phaseSubheading = `Gameweek ${activePhase.start_event} - ${activePhase.stop_event}`;

  return (
    <Navigator
      title={phaseTitle}
      subheading={phaseSubheading}
      nextOnClick={nextOnClick}
      prevOnClick={prevOnClick}
      disableNext={!nextEventPhase}
      disablePrev={!prevEventPhase}
    />
  );
};

export default PhaseNavigator;
