import React, { useState } from "react";
import { IPrivateLeagueAdmin } from "../../rtk-core/src/features/leagues";
import Button from "../Button";
import EntrySelector from "../EntrySelector";
import { FieldWrap } from "../FieldRenderers";
import SubHeading from "../SubHeading";

interface IProps {
  updateAdmin: (entryId: number) => void;
  league: IPrivateLeagueAdmin;
}

const ChangeAdmin: React.FC<IProps> = ({ updateAdmin, league }) => {
  const [entry, setEntry] = useState<number>(0);

  const handleEntryChange = (entryId: number) => {
    setEntry(entryId);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateAdmin(entry);
  };

  const disabled = !entry;

  return (
    <>
      <div className="mb={2}">
        <SubHeading>Change League Administrator</SubHeading>
      </div>
      <form onSubmit={handleSubmit}>
        <FieldWrap>
          <EntrySelector
            handleChange={handleEntryChange}
            leagueId={league.id}
            excluded={[league.admin_entry]}
          />
        </FieldWrap>
        <Button type="submit" disabled={disabled}>
          Change administrator
        </Button>
      </form>
    </>
  );
};

export default ChangeAdmin;
