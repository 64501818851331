import { rgba } from "polished";
import styled, { css } from "styled-components";
import { ElementRow } from "../ElementTable";

interface IStyledProps {
  $isProposed: boolean;
}

export const StyledElementListRow = styled(ElementRow)<IStyledProps>`
  ${(props) =>
    props.$isProposed &&
    css`
      td,
      button {
        color: ${rgba("black", 0.6)};
      }

      img {
        opacity: 0.6;
      }
    `}
`;

export const OppCell = styled.td`
  text-align: center;
`;

export const StatCell = styled.td`
  text-align: right;
`;

export const CostCell = styled.td`
  text-align: right;
`;

export const ElementSelectWrapper = styled.button`
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  text-align: left;
`;
