import ButtonLink from "../../../components/ButtonLink";
import LeagueRow from "../../../components/Leagues/LeagueRow";
import Link from "../../../components/Link";
import { ChevronRight } from "../../Chevrons";
import { useAppSelector } from "../../../rtk-core/src/app/hooks";
import { getActiveEvent } from "../../../rtk-core/src/features/events";
import {
  ActionBar,
  ActionBarItem,
  MyLeaguesWrapper,
  StyledTitle,
  TagLine,
  TitleBlock,
  LeaguePanelWrap,
} from "./styles";
import { ILeaguePanelProps } from "./types";

const LeaguePanel = ({
  leagues,
  leave,
  title,
  text = "",
  invitational = false,
}: ILeaguePanelProps) => {
  const activeEvent = useAppSelector(getActiveEvent);
  const activeLeagues = leagues.filter(
    (league) => league.start_event <= activeEvent.id
  );
  return (
    <LeaguePanelWrap>
      <TitleBlock>
        <StyledTitle>{title}</StyledTitle>
        {invitational && (
          <ActionBar>
            <ActionBarItem>
              <ButtonLink
                $isFullWidth={true}
                to="../leagues/join/private"
                $variant="dark"
              >
                Join League
                <ChevronRight />
              </ButtonLink>
            </ActionBarItem>
            <ActionBarItem>
              <ButtonLink
                $isFullWidth={true}
                to="../leagues/create/classic"
                $variant="dark"
              >
                Create League
                <ChevronRight />
              </ButtonLink>
            </ActionBarItem>
          </ActionBar>
        )}
        <TagLine>{text}</TagLine>
      </TitleBlock>
      {activeLeagues.length === 0 ? (
        <div className="my-2">
          <p>
            {`No ${title.toLowerCase()} joined yet. `}
            <Link to="/leagues/create-join">Create and join new leagues</Link>.
          </p>
        </div>
      ) : (
        <MyLeaguesWrapper>
          {activeLeagues.map((l) => (
            <LeagueRow
              invitational={invitational}
              league={l}
              leave={leave}
            ></LeagueRow>
          ))}
        </MyLeaguesWrapper>
      )}
    </LeaguePanelWrap>
  );
};

export default LeaguePanel;
