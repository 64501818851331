import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Alert from "../components/Alert";
import ElementDialogButton from "../components/ElementDialogButton";
import ElementFilter from "../components/ElementFilter";
import ElementInTable from "../components/ElementInTable";
import ElementSort from "../components/ElementSort";
import { ElementRow, ElementTable } from "../components/ElementTable";
import { FieldCol, FieldCols } from "../components/FieldRenderers";
import HelmetHead from "../components/HelmetHead";
import { GridItem, GridWrapper, SidebarGrid } from "../components/Layout";
import NewsContentList from "../components/NewsContentList";
import Paginator, { paginate } from "../components/Paginator";
import Title from "../components/Title";
import Tooltip, { TooltipLabel } from "../components/Tooltip";
import { AppDispatch, RootState } from "../rtk-core/src/app/store";
import {
  IElement,
  IElementControls,
  IElementDataFromControls,
  getElementControls,
  getElementsFromControls,
  showElementSummary,
  updateElementControls,
  updateElementControlsAndMaxCost,
} from "../rtk-core/src/features/elements";
import { ITeamsById, getTeamsById } from "../rtk-core/src/features/teams";
import { integerToMoneyWithCurrency } from "../utils/money";
import { getStatDetails } from "../utils/stats";

const StatsStatus = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 7%;
  }
`;

const StatsElement = styled.th`
  width: 40%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 38%;
  }
`;

const StatsPrice = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 11%;
  }
`;

const StatsSel = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 11%;
  }
`;

const StatsForm = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 11%;
  }
`;

const StatsPts = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 11%;
  }
`;

const StatsExtra = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 11%;
  }
`;

interface IPropsFromState {
  currencyDivisor: number;
  elements: IElementDataFromControls;
  controls: IElementControls;
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  showElementDialog: (elementId: number) => void;
  updateControls: (controls: IElementControls) => void;
  updateControlsAndMaxCost: (controls: IElementControls) => void;
}

type Props = IPropsFromState & IPropsFromDispatch;

export const Statistics: React.FC<Props> = ({
  currencyDivisor,
  elements,
  controls,
  teamsById,
  showElementDialog,
  updateControls,
  updateControlsAndMaxCost,
}) => {
  const [page, setPage] = useState(1);
  const { statName } = useParams();

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPage(1);
    updateControls({
      ...controls,
      filter: e.target.value,
    });
  };

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPage(1);
    updateControls({
      ...controls,
      sort: e.target.value,
    });
  };

  const defaultStats: Array<keyof IElement> = [
    "now_cost",
    "selected_by_percent",
    "form",
    "total_points",
  ];

  useEffect(() => {
    updateControlsAndMaxCost({
      ...controls,
      filter: "all",
      sort: statName || "total_points",
      search: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, totalPages } = paginate(elements.data, page, 30);
  const extraStat =
    defaultStats.indexOf(controls.sort) === -1 ? controls.sort : "";
  const statDetail = getStatDetails(String(controls.sort));

  return (
    <GridWrapper>
      <SidebarGrid $hasMargin>
        <HelmetHead
          title="Fantasy Football Statistics | Fantasy Premier League"
          description="To view statistics for all Fantasy Premier League players, or select by position or team, visit the official website of the Premier League."
        />
        <GridItem $colSpan={1}>
          <Title>Statistics</Title>
        </GridItem>
        <GridItem $colSpan={1} $rowSpan={5} $order={2}>
          <NewsContentList
            number={3}
            tag="FPL%20Challenge%2C%20Stats%20Sidebar"
            title="FPL Stats Explained"
          />
          <NewsContentList
            number={4}
            tag="FPL%20Challenge%2C%20Scout"
            title="Latest FPL News"
          />
        </GridItem>
        <GridItem $colSpan={1}>
          <form>
            <FieldCols>
              <FieldCol>
                <ElementFilter handleFilterChange={handleFilterChange} />
              </FieldCol>
              <FieldCol>
                <ElementSort handleSortChange={handleSortChange} />
              </FieldCol>
            </FieldCols>
          </form>
        </GridItem>
        <GridItem $colSpan={1}>
          <Alert $align="center" aria-live="polite">
            {statDetail ? statDetail.description : ""}
          </Alert>
        </GridItem>
        <GridItem $colSpan={1}>
          <ElementTable>
            <thead>
              <tr>
                <StatsStatus scope="col">&nbsp;</StatsStatus>
                <StatsElement scope="col">Player</StatsElement>
                <StatsPrice scope="col">Price</StatsPrice>
                <StatsSel scope="col">
                  <Tooltip content={<p>Selected by %</p>}>
                    <TooltipLabel>Sel.</TooltipLabel>
                  </Tooltip>
                </StatsSel>
                <StatsForm scope="col">Form</StatsForm>
                <StatsPts scope="col">
                  <Tooltip content={<p>Total Points</p>}>
                    <TooltipLabel>Pts.</TooltipLabel>
                  </Tooltip>
                </StatsPts>
                {extraStat ? <StatsExtra scope="col">**</StatsExtra> : null}
              </tr>
            </thead>
            <tbody>
              {data.map((e) => (
                <ElementRow key={e.id}>
                  <td>
                    <ElementDialogButton elementId={e.id} variant="list" />
                  </td>
                  <td>
                    <ElementInTable element={e} team={teamsById[e.team]} />
                  </td>
                  <td>
                    {integerToMoneyWithCurrency(e.now_cost, currencyDivisor)}
                  </td>
                  <td>{e.selected_by_percent}%</td>
                  <td>{e.form}</td>
                  <td>{e.total_points}</td>
                  {extraStat ? <td>{e[extraStat]}</td> : null}
                </ElementRow>
              ))}
            </tbody>
          </ElementTable>
        </GridItem>
        <GridItem $colSpan={1}>
          <Paginator totalPages={totalPages} page={page} setPage={setPage} />
        </GridItem>
      </SidebarGrid>
    </GridWrapper>
  );
};

export { Statistics as StatisticsTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  controls: getElementControls(state),
  currencyDivisor: 10,
  elements: getElementsFromControls(state),
  teamsById: getTeamsById(state),
});

const mapDispatchToProps = (dispatch: AppDispatch): IPropsFromDispatch => ({
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
  updateControls: (controls) => dispatch(updateElementControls(controls)),
  updateControlsAndMaxCost: (controls) =>
    dispatch(updateElementControlsAndMaxCost(controls)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
