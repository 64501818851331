import { apiSlice } from "./apiSlice";
import { IBestLeague, IMostValuableTeam } from "../stats";

export const statsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchBestClassicPrivateLeagues: builder.query<IBestLeague[], void>({
      query: () => `stats/best-classic-private-leagues/`,
    }),
    fetchMostValuableTeams: builder.query<IMostValuableTeam[], void>({
      query: () => `stats/most-valuable-teams/`,
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchBestClassicPrivateLeaguesQuery,
  useFetchMostValuableTeamsQuery,
} = statsApi;
