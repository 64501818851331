import RankBadgeDesktop from "../../../img/rb-desktop.svg?react";
import { useAppSelector } from "../../../rtk-core/src/app/hooks";
import { getEntry } from "../../../rtk-core/src/features/entries";
import {
  getPlayerData,
  ILoggedInPlayer,
} from "../../../rtk-core/src/features/player";
import { getEloBadge } from "../../../utils/ranks";
import RankBadge from "../../RankBadge";
import RankBullet from "../RankBullet";
import RankLabel from "../RankLabel";
import {
  Column,
  ColumnBackground,
  ColumnBody,
  ColumnsWrapper,
  EntryName,
  ProgressInfo,
  ProgressWrap,
  RankBadgeWrapper,
  RankDescription,
  RankPercentage,
  StyledRankGraph,
  StyledRankGraphImage,
} from "./styles";

const RankGraph = () => {
  const player = useAppSelector(getPlayerData) as ILoggedInPlayer;
  const entry = useAppSelector((state) =>
    player?.entry ? getEntry(state, player.entry) : null
  );

  const rankBadges = [1, 6, 11, 16, 21, 26, 31, 36];
  const rankPercentages = [100, 84, 70, 56, 43, 29, 15, 1];

  if (!entry) {
    return (
      <StyledRankGraph>
        <StyledRankGraphImage>
          <RankBadgeDesktop />
        </StyledRankGraphImage>
      </StyledRankGraph>
    );
  }

  return (
    <StyledRankGraph>
      <ColumnsWrapper>
        {rankBadges.map((level: number, index: number) => {
          const rankBadge = getEloBadge(Number(level));
          const userRankBadge = getEloBadge(entry.elo_badge);
          const userRank =
            userRankBadge.split("_")[0] === rankBadge.split("_")[0];
          return (
            <Column>
              <ColumnBody>
                <RankBadgeWrapper>
                  <RankBadge rank={rankBadge} size={75} />
                </RankBadgeWrapper>
                <ColumnBackground $rankIndex={index + 1}>
                  <RankPercentage>{`Top ${rankPercentages[index]}%`}</RankPercentage>
                  <RankLabel rankName={rankBadge.split("_")[0]} />
                </ColumnBackground>
              </ColumnBody>
              <div>
                {entry.elo_badge && entry.elo_badge >= level ? (
                  userRank ? (
                    <>
                      <ProgressWrap>
                        <RankBullet $currentRank={true} locked={false} />
                      </ProgressWrap>
                      <ProgressInfo>
                        <EntryName>{entry.name}</EntryName>
                        <RankDescription>
                          {userRankBadge.split("_").join(" ")}
                        </RankDescription>
                      </ProgressInfo>
                    </>
                  ) : (
                    <ProgressWrap>
                      <RankBullet $currentRank={false} locked={false} />
                    </ProgressWrap>
                  )
                ) : (
                  <ProgressWrap>
                    <RankBullet />
                  </ProgressWrap>
                )}
              </div>
            </Column>
          );
        })}
      </ColumnsWrapper>
    </StyledRankGraph>
  );
};
export default RankGraph;
