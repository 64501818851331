import React, { useState } from "react";
import Downshift from "downshift";
import { SearchField } from "../FieldRenderers";
import debounce from "lodash/debounce";

interface IEntry {
  entry: number;
  player_name: string;
  entry_name: string;
}

interface IEntrySelectorProps {
  entries: IEntry[];
  handleChange: (selectedItem: any) => void;
  searchChange: (value: string) => any;
}

const EntrySelector: React.FC<IEntrySelectorProps> = ({
  entries,
  handleChange,
  searchChange,
}) => (
  <Downshift
    onChange={(selection) => handleChange(Number(selection.entry))}
    onInputValueChange={debounce(searchChange, 400)}
    itemToString={(item) => (item ? item.player_name : "")}
  >
    {({
      getInputProps,
      getItemProps,
      getLabelProps,
      getMenuProps,
      isOpen,
      highlightedIndex,
      selectedItem,
    }) => (
      <div>
        <SearchField {...getInputProps()} {...getLabelProps()} label="Player" />
        <ul {...getMenuProps()}>
          {isOpen
            ? entries.map((item, index) => (
                <li
                  {...getItemProps({
                    key: item.entry,
                    index,
                    item,
                    style: {
                      backgroundColor:
                        highlightedIndex === index ? "lightgray" : "white",
                      fontWeight: selectedItem === item ? "bold" : "normal",
                    },
                  })}
                >
                  {item.player_name} ({item.entry_name})
                </li>
              ))
            : null}
        </ul>
      </div>
    )}
  </Downshift>
);

interface IProps {
  excluded?: number[];
  handleChange: (selectedItem: any) => void;
  leagueId: number;
}

const EntrySelectorWrapper: React.FC<IProps> = ({
  excluded = [],
  handleChange,
  leagueId,
}) => {
  const [entries, setEntries] = useState<IEntry[]>([]);
  const [lastSearch, setLastSearch] = useState("");

  const searchChange = async (value: string) => {
    // If the search term has been cleared, clear any state
    if (!value.length) {
      handleChange(0);
      setEntries([]);
      setLastSearch("");
    }

    // We are only interested in first name searches
    const searchMatches = value.match(/^(.*?) /);
    if (searchMatches && searchMatches[1] !== lastSearch) {
      const response = await fetch(
        `/api/league/${leagueId}/search-entries/?search=${encodeURIComponent(
          searchMatches[1]
        )}`
      );
      setLastSearch(searchMatches[1]);
      if (response.ok) {
        const newEntries: IEntry[] = await response.json();
        setEntries(newEntries.filter((e) => excluded.indexOf(e.entry) === -1));
      }
    }
  };

  return (
    <EntrySelector
      entries={entries}
      handleChange={handleChange}
      searchChange={searchChange}
    />
  );
};

export default EntrySelectorWrapper;
