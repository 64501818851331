import _ from "lodash";
import React from "react";
import { Option, SelectField } from "../FieldRenderers";
import { StyledFieldWrap } from "./styles";
import DropdownProps from "./types";

const Dropdown: React.FC<DropdownProps> = ({
  formations,
  selectedFormation,
  handleFilterChange,
}) => (
  <StyledFieldWrap>
    <SelectField
      id="formation"
      name="filter"
      label="Choose formation"
      value={selectedFormation}
      onChange={handleFilterChange}
    >
      {formations.map((formation, index) => (
        <Option
          key={index}
          value={formation}
          aria-selected={selectedFormation === formation}
        >
          {_.tail(formation.split("-")).join("-")}
        </Option>
      ))}
    </SelectField>
  </StyledFieldWrap>
);

export default Dropdown;
