import { hideVisually, rgba, size } from "polished";
import * as React from "react";
import styled, { css } from "styled-components";
import BaseSearchIcon from "../../img/icons/search.svg?react";
import SelectIcon from "../../img/icons/select.svg";
import theme from "../../theme";
import { VisuallyHidden } from "../Utils/Utils";

export const FieldWrap = styled.div<FieldWrapProps>`
  margin-bottom: ${(props) => props.theme.space[6]};
`;

const fieldStyles = css`
  display: block;
  width: 100%;
  padding: ${(props) => props.theme.space[2]};
  border: ${(props) => props.theme.borders[1]};
  border-radius: 2px;
  background-color: white;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 16px;
  color: ${(props) => props.theme.colors.primary};
  line-height: inherit;
`;

const Label = styled.label`
  display: block;
  padding-bottom: ${(props) => props.theme.space[2]};
`;

const SelectLabel = styled.label`
  display: block;
  padding: 0 0.6rem;
`;

const FieldLabel = styled.span`
  display: inline-block;
  margin-right: ${(props) => props.theme.space[1]};
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
`;

const InlineFieldLabel = styled(FieldLabel)`
  display: inline-block;
  margin-right: ${({ theme }) => theme.space[2]};
`;

const SelectFieldLabel = styled.span`
  display: inline-block;
  margin-right: ${(props) => props.theme.space[1]};
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 10px;
  color: ${(props) => props.theme.colors.purple70};
  cursor: pointer;
`;

const Hint = styled.span`
  display: inline-block;
  font-size: 10px;
`;

export const Input = styled.input`
  ${fieldStyles}
`;

export const SelectWrap = styled.div`
  position: relative;
  z-index: ${theme.zIndex.select.wrap};
  padding: 0 ${theme.space[1]} 0.2rem ${theme.space[1]};
  border: ${theme.borders[1]};
  border-radius: ${theme.radii[1]};
  background-color: ${theme.colors.white};

  &:focus-within {
    outline: -webkit-focus-ring-color auto 5px;
  }

  &::after {
    content: "";
    background: url(${SelectIcon}) no-repeat;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    width: 20px;
    ${size(12, 8)};
    background-size: contain;
  }
`;

// Unable to extend SelectWrap for some reason
const InlineSelectWrap = styled.div`
  position: relative;
  display: inline-block;
  z-index: ${theme.zIndex.select.wrap};

  &:focus-within {
    outline: -webkit-focus-ring-color auto 5px;
  }

  &::after {
    content: "";
    background: url(${SelectIcon}) no-repeat;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    width: 20px;
    ${size(12, 8)};
    background-size: contain;
  }
`;

export const Select = styled.select`
  ${fieldStyles}
  position: relative;
  z-index: ${theme.zIndex.select.select};
  margin: 0;
  padding: 0 0.6rem;
  border: 0;
  border-radius: 0;
  background: none;
  color: inherit;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  appearance: none;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &::-ms-expand {
    display: none; /* [1] */
  }
`;

export const Option = styled.option`
  font-family: ${({ theme }) => theme.fonts.regular};
`;

const InlineSelect = styled.select`
  padding: ${({ theme }) =>
    `${theme.space[2]} 4rem ${theme.space[2]} ${theme.space[2]}`};
  border: 1px solid ${theme.colors.darkGrey};
  background: none;
  color: inherit;
  font-family: ${({ theme }) => theme.fonts.bold};
  appearance: none;
  cursor: pointer;
`;

const SearchWrap = styled.div`
  position: relative;
  border: ${theme.borders[0]};
  border-radius: ${theme.radii[1]};

  /* Overrride ism.css */
  input[type="search"] {
    box-sizing: inherit;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  border: 0;
  border-radius: 4px;
  padding: ${({ theme }) => `${theme.space[4]} ${theme.space[2]}`};
  background-color: white;
  font-size: 16px;
  appearance: textfield;

  &::placeholder {
    color: inherit;
  }
`;

const SearchIconWrap = styled.span`
  ${size(24, 40)}
  position: absolute;
  top: 50%;
  right: 0;
  display: inline-block;
  padding: ${(props) => `${props.theme.space[1]} 1rem`};
  border-left: ${(props) => props.theme.borders[0]};
  transform: translateY(-50%);
`;

const SearchIcon = styled(BaseSearchIcon)`
  fill: ${(props) => props.theme.colors.primary};
`;

export const FieldCols = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[2]};
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex-direction: row;
  }
`;

export const FieldCol = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex: 1;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  border: ${(props) => props.theme.borders[1]};
`;

export interface FieldWrapProps {
  marginBottom?: number;
}

export interface ILabelHintProps {
  hint?: React.ReactNode;
  id: string;
  label: React.ReactNode;
}

interface IFieldProps extends ILabelHintProps {
  name?: string;
  placeholder?: string;
  value?: string | number;
}

export interface IInputProps extends IFieldProps {
  maxLength?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  disabled?: boolean;
  readonly?: boolean;
}

export const LabelHint: React.FC<ILabelHintProps> = ({ id, label, hint }) => (
  <Label htmlFor={id}>
    <div>
      <FieldLabel>{label}</FieldLabel>
      {hint && <Hint aria-describedby={id}>{hint}</Hint>}
    </div>
  </Label>
);

export const SelectLabelHint: React.FC<ILabelHintProps> = ({
  id,
  label,
  hint,
}) => (
  <SelectLabel htmlFor={id}>
    <div>
      <SelectFieldLabel>{label}</SelectFieldLabel>
      {hint && <Hint aria-describedby={id}>{hint}</Hint>}
    </div>
  </SelectLabel>
);

const InlineLabel: React.FC<ILabelHintProps> = ({ id, label }) => (
  <label htmlFor={id}>
    <InlineFieldLabel>{label}</InlineFieldLabel>
  </label>
);

export const InputField: React.FC<IInputProps> = ({
  hint,
  id,
  label,
  maxLength,
  name,
  onChange,
  type,
  value,
  disabled = false,
  readonly = false,
}) => (
  <>
    <LabelHint id={id} label={label} hint={hint} />
    <Input
      type={type}
      id={id}
      name={name}
      onChange={onChange}
      value={value}
      maxLength={maxLength}
      disabled={disabled}
      readOnly={readonly}
    />
  </>
);

export interface ISearchProps extends IFieldProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchField: React.FC<ISearchProps> = ({
  hint,
  id,
  label,
  name,
  onChange,
  placeholder,
  value,
  ...rest
}) => (
  <>
    <VisuallyHidden>
      <label>{label}</label>
    </VisuallyHidden>
    <SearchWrap>
      <SearchInput
        type="search"
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        {...rest}
      />
      <SearchIconWrap>
        <SearchIcon />
      </SearchIconWrap>
    </SearchWrap>
  </>
);

export interface INumberProps extends IFieldProps {
  ariaValueMax?: number;
  ariaValueMin?: number;
  ariaValueNow?: number;
  max?: string | number;
  min?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  step?: string | number;
}

export const NumberField: React.FC<INumberProps> = ({
  ariaValueMax,
  ariaValueMin,
  ariaValueNow,
  hint,
  id,
  label,
  max = "",
  min = "",
  name,
  onChange,
  step = "",
  value,
}) => (
  <>
    <LabelHint id={id} label={label} hint={hint} />
    <Input
      aria-valuemax={ariaValueMax}
      aria-valuemin={ariaValueMin}
      aria-valuenow={ariaValueNow}
      type="number"
      id={id}
      max={max}
      min={min}
      name={name}
      onChange={onChange}
      step={step}
      value={value}
    />
  </>
);

const Checkbox = styled.input`
  ${hideVisually()};

  &:hover + span::before {
    border-color: ${(props) => props.theme.colors.purple70};
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"><path d="M5.19939 11.1666C4.98432 11.1666 4.77532 11.0966 4.60363 10.9669L0.390754 7.77955C0.181762 7.62214 0.0447457 7.38775 0.00918149 7.12914C-0.0263739 6.87052 0.042135 6.60845 0.200829 6.40088C0.530364 5.96756 1.14865 5.8828 1.58313 6.21059L4.95995 8.76553L10.1891 0.942417C10.334 0.724459 10.5603 0.57223 10.817 0.519466C11.0745 0.46757 11.3425 0.52033 11.561 0.665637C11.7796 0.810942 11.9305 1.03669 11.9816 1.29355C12.0319 1.55131 11.9781 1.81769 11.8316 2.03479L6.02054 10.7281C5.83756 11.0022 5.52979 11.1666 5.19939 11.1666Z" fill="%23E8E8E8"/></svg>');
  }

  &:focus + span {
    outline: -webkit-focus-ring-color auto 5px;
  }

  &:checked + span::before {
    border-color: ${(props) => props.theme.colors.purple70};
    background-color: ${(props) => props.theme.colors.fantasy};
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"><path d="M5.19939 11.1666C4.98432 11.1666 4.77532 11.0966 4.60363 10.9669L0.390754 7.77955C0.181762 7.62214 0.0447457 7.38775 0.00918149 7.12914C-0.0263739 6.87052 0.042135 6.60845 0.200829 6.40088C0.530364 5.96756 1.14865 5.8828 1.58313 6.21059L4.95995 8.76553L10.1891 0.942417C10.334 0.724459 10.5603 0.57223 10.817 0.519466C11.0745 0.46757 11.3425 0.52033 11.561 0.665637C11.7796 0.810942 11.9305 1.03669 11.9816 1.29355C12.0319 1.55131 11.9781 1.81769 11.8316 2.03479L6.02054 10.7281C5.83756 11.0022 5.52979 11.1666 5.19939 11.1666Z" fill="%37003c"/></svg>');
  }
`;

interface ICheckboxTextProps {
  $boxAlign?: "center" | "top";
}
const CheckboxText = styled.span<ICheckboxTextProps>`
  display: inline-block;
  position: relative;
  padding: 4px 0 4px 32px;

  &::before {
    content: "";
    position: absolute;
    top: ${({ $boxAlign = "center" }) =>
      $boxAlign === "top" ? "20px" : "50%"};
    left: 0;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    border: ${(props) => props.theme.borders[3]};
    border-radius: ${(props) => props.theme.radii[1]};
    background-color: ${rgba("#fff", 0.3)};
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
    vertical-align: middle;
  }
`;

export const CheckboxWrap = styled.div`
  padding: ${(props) => props.theme.space[2]} ${(props) => props.theme.space[3]};
  border: ${theme.borders[0]};
  border-radius: ${theme.radii[1]};

  &:focus-within {
    outline: -webkit-focus-ring-color auto 5px;
  }

  input[type="checkbox"]:focus:focus + span {
    outline: none;
  }
`;

export const BlockLabel = styled.label`
  display: block;
`;

export interface ICheckboxProps {
  checked?: boolean;
  id: string;
  label: React.ReactNode;
  name?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  $boxAlign?: "center" | "top";
}

export const CheckboxField: React.FC<ICheckboxProps> = ({
  id,
  onChange,
  label,
  checked,
  $boxAlign = "center",
}) => (
  <div>
    <label htmlFor={id}>
      <Checkbox
        type="checkbox"
        id={id}
        role="checkbox"
        checked={checked}
        aria-checked={checked}
        onChange={onChange}
      />
      <CheckboxText $boxAlign={$boxAlign}>{label}</CheckboxText>
    </label>
  </div>
);

CheckboxField.defaultProps = {
  checked: false,
};

export const CheckboxButton: React.FC<ICheckboxProps> = ({
  id,
  onChange,
  label,
  checked,
}) => (
  <CheckboxWrap>
    <BlockLabel htmlFor={id}>
      <Checkbox
        type="checkbox"
        id={id}
        role="checkbox"
        checked={checked}
        aria-checked={checked}
        onChange={onChange}
      />
      <CheckboxText>{label}</CheckboxText>
    </BlockLabel>
  </CheckboxWrap>
);

CheckboxField.defaultProps = {
  checked: false,
};

const Fieldset = styled.fieldset`
  padding: 0;
  border: 0;
`;

export interface ILabelLegendProps {
  children: React.ReactNode;
  legend: string;
}

export const LabelLegend: React.FC<ILabelLegendProps> = ({
  children,
  legend,
}) => (
  <Fieldset>
    <legend>
      <FieldLabel>{legend}</FieldLabel>
    </legend>
    {children}
  </Fieldset>
);

const Radio = styled.input`
  ${hideVisually()};

  &:hover + span::before {
    background-color: #e5e5e5;
    box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colors.darkGrey};
  }

  &:focus + span {
    outline: -webkit-focus-ring-color auto 5px;
  }

  &:checked + span::before {
    background-color: ${(props) => props.theme.colors.darkPink};
  }
`;

const RadioText = styled.span`
  display: inline-block;
  position: relative;
  padding: 6px 0 6px 42px;
  line-height: 1.6;

  &::before {
    /* [1] */
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 23px;
    height: 23px;
    transform: translateY(-50%);
    border: 5px solid white;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #e5e5e5;
    content: "";
    vertical-align: middle;
  }
`;

interface IOptions {
  checked?: boolean;
  id: string;
  label: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  value: string;
}
export interface IRadioGroupProps {
  legend: React.ReactNode;
  name: string;
  options: IOptions[];
}

export const RadioGroup: React.FC<IRadioGroupProps> = ({
  legend,
  name,
  options,
}) => (
  <Fieldset>
    <legend>
      <FieldLabel>{legend}</FieldLabel>
    </legend>
    {options.map((o) => (
      <div key={o.id}>
        <label htmlFor={o.id}>
          <Radio
            type="radio"
            id={o.id}
            name={name}
            value={o.value}
            role="radio"
            aria-checked={o.checked}
            onChange={o.onChange}
            checked={o.checked}
          />
          <RadioText>{o.label}</RadioText>
        </label>
      </div>
    ))}
  </Fieldset>
);

export interface ISelectProps extends IFieldProps {
  children: React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const SelectField: React.FC<ISelectProps> = ({
  children,
  hint,
  id,
  label,
  onChange,
  value,
}) => (
  <>
    <SelectWrap>
      <SelectLabelHint id={id} label={label} hint={hint} />
      <Select id={id} onChange={onChange} value={value}>
        {children}
      </Select>
    </SelectWrap>
  </>
);

export const InlineSelectField: React.FC<ISelectProps> = ({
  children,
  id,
  label,
  onChange,
  value,
}) => (
  <>
    <InlineLabel id={id} label={label} />
    <InlineSelectWrap>
      <InlineSelect id={id} onChange={onChange} value={value}>
        {children}
      </InlineSelect>
    </InlineSelectWrap>
  </>
);

export interface ITextAreaProps extends IFieldProps {
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows: number;
}

export const TextAreaField: React.FC<ITextAreaProps> = ({
  hint,
  id,
  label,
  name,
  onChange,
  rows,
  value,
}) => (
  <>
    <LabelHint id={id} label={label} hint={hint} />
    <Textarea
      id={id}
      name={name}
      onChange={onChange}
      value={value}
      rows={rows}
    />
  </>
);
