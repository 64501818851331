import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../rtk-core/src/app/hooks";
import { RootState } from "../../../rtk-core/src/app/store";
import { useLazyFetchLeagueCodeQuery } from "../../../rtk-core/src/features/api/leagues";
import { setCode } from "../../../rtk-core/src/features/autoJoin";
import { getActiveEvent } from "../../../rtk-core/src/features/events";
import {
  ILeague,
  getActiveEntryInLeague,
  getCode,
} from "../../../rtk-core/src/features/leagues";
import { getPlayerData } from "../../../rtk-core/src/features/player";
import { getChallengeAssets } from "../../../utils/challenge";
import Button from "../../Button";
import ButtonLink from "../../ButtonLink";
import ChallengeBlock, { ChallengeBlockBody } from "../../ChallengeBlock";
import { ChevronRight } from "../../Chevrons";
import {
  ButtonWrapper,
  ChallengeCopyWrap,
  ChallengeDescription,
  ChallengeTitle,
  ChallengeTitleWrap,
  GameChangerIcon,
  Title,
} from "./styles";

interface StandingsHeadingProps {
  type: string;
  league: ILeague | null;
  leagueNumber: number;
}

const StandingsHeading: React.FC<StandingsHeadingProps> = ({
  type,
  league,
  leagueNumber,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const leagueNumberInt = leagueNumber ? leagueNumber : 0;
  const activeEvent = useAppSelector(getActiveEvent);
  const challenge = getChallengeAssets(activeEvent.id);

  const entryInLeague = useSelector((state: RootState) =>
    getActiveEntryInLeague(
      state,
      leagueNumberInt,
      type === "classic" ? "classic" : "h2h"
    )
  );
  const player = useSelector(getPlayerData);
  const code = useSelector((state: RootState) =>
    leagueNumberInt ? getCode(state, leagueNumberInt) : null
  );

  const isAdmin =
    entryInLeague &&
    entryInLeague.league &&
    entryInLeague.league.entry_can_admin;
  const isEntryInLeague = entryInLeague && entryInLeague.league;
  const [fetchLeagueCodeTrigger] = useLazyFetchLeagueCodeQuery();

  useEffect(() => {
    if (
      !isEntryInLeague &&
      league &&
      league.code_privacy === "a" &&
      leagueNumber
    ) {
      fetchLeagueCodeTrigger(leagueNumber);
    }
  }, [league, isAdmin, isEntryInLeague, leagueNumber, fetchLeagueCodeTrigger]);

  if (!challenge) {
    return null;
  }

  let showInviteBtn = false;
  let showJoinBtn = false;

  if (league && !league.closed) {
    if (isAdmin) {
      showInviteBtn = true;
    } else {
      if (league) {
        if (isEntryInLeague) {
          if (league.code_privacy === "a") {
            showInviteBtn = true;
          }
          if (league.code_privacy === "l") {
            showInviteBtn = true;
          }
        }

        if (!isEntryInLeague) {
          if (league.code_privacy === "a") {
            showJoinBtn = true;
          }
        }
      }
    }
  }

  return (
    <ChallengeBlock>
      <ChallengeBlockBody>
        <div className="flex flex-col justify-center gap-3">
          <Title>{league && league.name}</Title>
          <ChallengeCopyWrap>
            <ChallengeTitleWrap>
              <GameChangerIcon></GameChangerIcon>
              <ChallengeTitle>{challenge.copy.title}</ChallengeTitle>
            </ChallengeTitleWrap>
            <ChallengeDescription>
              {challenge.copy.description}
            </ChallengeDescription>
          </ChallengeCopyWrap>
          <ButtonWrapper>
            {showInviteBtn && entryInLeague && (
              <ButtonLink
                $variant="dark"
                to={`/leagues/${entryInLeague.league.id}/invite`}
              >
                Invite friends
                <ChevronRight />
              </ButtonLink>
            )}
            {code && showJoinBtn && (
              <Button
                onClick={() =>
                  player && player.entry
                    ? dispatch(setCode(code))
                    : navigate(`/leagues/auto-join/${code}`)
                }
              >
                Join this league
                <ChevronRight />
              </Button>
            )}
          </ButtonWrapper>
        </div>
      </ChallengeBlockBody>
    </ChallengeBlock>
  );
};

export default StandingsHeading;
