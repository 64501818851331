import Button from "../Button";
import { ChevronLeft, ChevronRight } from "../Chevrons";
import {
  NavigatorHeader,
  NavigatorNext,
  NavigatorPrev,
  NavigatorSubheading,
  NavigatorTitle,
  StyledNavigator,
  UnstyledAbbr,
} from "./styles";
import { INavigator } from "./types";

const Navigator = ({
  disableNext,
  disablePrev,
  nextOnClick,
  prevOnClick,
  subheading,
  title,
}: INavigator) => (
  <StyledNavigator>
    <NavigatorHeader>
      <NavigatorTitle>{title}</NavigatorTitle>
      {subheading && <NavigatorSubheading>{subheading}</NavigatorSubheading>}
    </NavigatorHeader>
    <NavigatorPrev>
      <Button
        $isFullWidth={true}
        $variant="tertiary"
        disabled={disablePrev}
        onClick={prevOnClick}
      >
        <ChevronLeft />
        <UnstyledAbbr title="Previous">
          <span>Prev</span>
        </UnstyledAbbr>
      </Button>
    </NavigatorPrev>
    <NavigatorNext>
      <Button
        $isFullWidth={true}
        $variant="tertiary"
        disabled={disableNext}
        onClick={nextOnClick}
      >
        Next
        <ChevronRight />
      </Button>
    </NavigatorNext>
  </StyledNavigator>
);

export default Navigator;
