import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ITeam, ITeamsState } from "./types";
import { bootstrapApi } from "../api/bootstrap";
import { IGetBootstrapStaticResponse } from "../bootstrap";

const initialState: ITeamsState = {
  byId: {},
};

const teams = createSlice({
  name: "teams",
  initialState,
  reducers: {
    addTeams: (state, action: PayloadAction<ITeam[]>) => ({
      ...state,
      byId: Object.fromEntries(
        action.payload.map((team: ITeam) => [
          team.id,
          {
            ...team,
          },
        ])
      ),
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      bootstrapApi.endpoints.getBootstrapStatic.matchFulfilled,
      (state, action: PayloadAction<IGetBootstrapStaticResponse>) =>
        teams.caseReducers.addTeams(state, {
          ...action,
          payload: action.payload.teams,
        })
    );
  },
});

export const { addTeams } = teams.actions;

export default teams.reducer;
