import React from "react";
import { useSelector } from "react-redux";
import {
  IElement,
  getElementControls,
} from "../../rtk-core/src/features/elements";
import { getStatDetails, getStatNames } from "../../utils/stats";
import { Option, SelectField } from "../FieldRenderers";

interface ISortOption {
  label: string;
  value: keyof IElement;
}

interface IProps {
  handleSortChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  sortOptions?: ISortOption[];
}

const ElementSort: React.FC<IProps> = ({ handleSortChange, sortOptions }) => {
  const controls = useSelector(getElementControls);
  const elementSortOptions: ISortOption[] = sortOptions
    ? sortOptions
    : getStatNames().map((s) => ({
        label: getStatDetails(s)!.label,
        value: s,
      }));

  return (
    <SelectField
      id="sort"
      name="sort"
      label="Sorted by"
      value={controls.sort}
      onChange={handleSortChange}
    >
      {elementSortOptions.map((o) => (
        <Option
          key={o.value}
          value={o.value}
          aria-selected={controls.filter === o.value}
        >
          {o.label}
        </Option>
      ))}
    </SelectField>
  );
};

export { ElementSort as ElementSortTest };

export default ElementSort;
