import { useContext } from "react";
import WebViewContext from "../../../contexts/WebViewContext";
import { useAppSelector } from "../../../rtk-core/src/app/hooks";
import {
  IEntryEventStatus,
  getEntry,
} from "../../../rtk-core/src/features/entries";
import { getActiveEvent } from "../../../rtk-core/src/features/events";
import { getPlayerData } from "../../../rtk-core/src/features/player";
import { getEntryStatusForEvent } from "../../../utils/entries";
import ButtonLink, { ButtonHyperlink } from "../../ButtonLink";
import { ChevronRight } from "../../Chevrons";
import ManageTeamCta from "../../ManageTeamCta";
import { HeroActionBar, HeroActionItem } from "./styles";

const HeroActions = () => {
  const activeEvent = useAppSelector(getActiveEvent);
  const player = useAppSelector(getPlayerData);
  const entry = useAppSelector((state) =>
    player?.entry ? getEntry(state, player.entry) : null
  );
  const eventStatus = entry ? getEntryStatusForEvent(entry, activeEvent) : "";

  const app = "plw-web";
  const plusers = import.meta.env.VITE_PLUSERS_BASE || "";
  const redirect = `${window.location.protocol}//${window.location.host}/`;
  const register = `${plusers}/users/register/personal?app=${app}&redirect_uri=${redirect}`;
  const { isWebView } = useContext(WebViewContext);

  if (!player) {
    return (
      <HeroActionBar>
        {!isWebView && (
          <HeroActionItem>
            <ButtonHyperlink
              href={register}
              $variant="secondary"
              $isFullWidth={true}
            >
              Register to play
              <ChevronRight />
            </ButtonHyperlink>
          </HeroActionItem>
        )}

        <HeroActionItem>
          <ButtonHyperlink
            href="#login"
            $variant={isWebView ? "secondary" : "tertiary"}
            $isFullWidth={true}
          >
            Sign in to play
            <ChevronRight />
          </ButtonHyperlink>
        </HeroActionItem>
      </HeroActionBar>
    );
  }

  return (
    <HeroActionBar>
      <HeroActionItem>
        <ManageTeamCta event={activeEvent} isFullWidth={true} />
      </HeroActionItem>
      {(eventStatus === IEntryEventStatus.CanEnter ||
        eventStatus === IEntryEventStatus.EnteredCanManage) && (
        <ButtonLink to="/help" $variant="tertiary">
          What is FPL Challenge?
          <ChevronRight />
        </ButtonLink>
      )}
    </HeroActionBar>
  );
};

export default HeroActions;
