import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../rtk-core/src/app/store";
import {
  IPrivateLeagueAdmin,
  getCode,
} from "../../rtk-core/src/features/leagues";
import Button from "../Button";
import {
  leaguesApi,
  useLazyFetchLeagueCodeQuery,
} from "../../rtk-core/src/features/api/leagues";
import { useAppDispatch } from "../../rtk-core/src/app/hooks";

interface IOwnProps {
  league: IPrivateLeagueAdmin;
}

const Code: React.FC<IOwnProps> = ({ league }) => {
  const dispatch = useAppDispatch();
  const code = useSelector((state: RootState) => getCode(state, league.id));
  const [fetchLeagueCodeTrigger] = useLazyFetchLeagueCodeQuery();

  useEffect(() => {
    if (!league.closed) {
      fetchLeagueCodeTrigger(league.id);
    }
  }, [dispatch, fetchLeagueCodeTrigger, league.closed, league.id]);

  if (league.closed) {
    return null;
  }

  const handleRegenerateCode = () => {
    dispatch(leaguesApi.endpoints.regenerateLeagueCode.initiate(league.id));
  };

  return (
    <>
      <div className="mb-2">
        <p>League code: {code}</p>
      </div>
      <Button onClick={handleRegenerateCode}>Regenerate</Button>
    </>
  );
};

export default Code;
