import { RootState } from "../../app/store";
import { getOverrides } from "../overrides";

export const getRules = (state: RootState, eventId?: number) => {
  if (state.game.rules) {
    const overrides = getOverrides(state, eventId);
    if (!overrides) {
      return state.game.rules;
    } else {
      return {
        ...state.game.rules,
        ...overrides.rules,
      };
    }
  } else {
    return null;
  }
};

export const getScoring = (state: RootState, eventId?: number) => {
  if (state.game.scoring) {
    const overrides = getOverrides(state, eventId);
    if (!overrides) {
      return state.game.scoring;
    } else {
      return {
        ...state.game.scoring,
        ...overrides.scoring,
      };
    }
  } else {
    return null;
  }
};

export const getTotalPlayers = (state: RootState) => state.game.total_players;
