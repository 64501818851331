import { IEntry, IEntryEventStatus } from "../rtk-core/src/features/entries";
import { IEvent } from "../rtk-core/src/features/events";
import { IPlayer } from "../rtk-core/src/features/player";

// All of the functions below require access to the store but due to how
// they're used we're unable to use them as conventional selectors. To
// alleviate the need to access the store they both take the required
// objects as parameters
export const getEntryEnteredEvent = (entry: IEntry, event: IEvent) =>
  new Set(entry.entered_events).has(event.id);

export const getEntryStatusForEvent = (entry: IEntry, event: IEvent) => {
  const entryEnteredEvent = getEntryEnteredEvent(entry, event);
  if (!event.released) {
    return IEntryEventStatus.Unreleased;
  } else if (entryEnteredEvent && event.can_manage) {
    return IEntryEventStatus.EnteredCanManage;
  } else if (entryEnteredEvent && !event.can_manage) {
    return IEntryEventStatus.EnteredCannotManage;
  } else if (!entryEnteredEvent && event.can_enter) {
    return IEntryEventStatus.CanEnter;
  } else {
    return IEntryEventStatus.CannotEnter;
  }
};

export const getEventUrlForEntry = (
  entry: IEntry,
  event: IEvent,
  player: IPlayer | null
) => {
  if (player && player.entry === entry.id) {
    const entryEventStatus = getEntryStatusForEvent(entry, event);
    switch (entryEventStatus) {
      case IEntryEventStatus.CanEnter:
        return `/manage/${event.id}`;
      case IEntryEventStatus.CannotEnter:
        return `/entry/${entry.id}/event/${event.id}`;
      case IEntryEventStatus.EnteredCanManage:
        return `/manage/${event.id}`;
      case IEntryEventStatus.EnteredCannotManage:
        return `/entry/${entry.id}/event/${event.id}`;
      default:
        // We use default here to infer the type as a string. For context the
        // default statement infers the following case:
        // IEntryEventStatus.Unreleased
        return `/manage/${event.id}`;
    }
  } else {
    return `/entry/${entry.id}/event/${event.id}`;
  }
};
