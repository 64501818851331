import { IEloData } from "../rtk-core/src/features/entries";

export const rankToBadgeMap: { [key: number]: string } = {
  1: "Bronze_1",
  2: "Bronze_2",
  3: "Bronze_3",
  4: "Bronze_4",
  5: "Bronze_5",
  6: "Silver_1",
  7: "Silver_2",
  8: "Silver_3",
  9: "Silver_4",
  10: "Silver_5",
  11: "Gold_1",
  12: "Gold_2",
  13: "Gold_3",
  14: "Gold_4",
  15: "Gold_5",
  16: "Platinum_1",
  17: "Platinum_2",
  18: "Platinum_3",
  19: "Platinum_4",
  20: "Platinum_5",
  21: "Pro_1",
  22: "Pro_2",
  23: "Pro_3",
  24: "Pro_4",
  25: "Pro_5",
  26: "Elite_1",
  27: "Elite_2",
  28: "Elite_3",
  29: "Elite_4",
  30: "Elite_5",
  31: "World_Class_1",
  32: "World_Class_2",
  33: "World_Class_3",
  34: "World_Class_4",
  35: "World_Class_5",
  36: "Legend",
};

export const getEloBadge = (rank: number | null) => {
  return rank ? rankToBadgeMap[rank] : "Unranked_5";
};

export const getEloRank = (eloData: IEloData) => {
  return eloData.elo_badge
    ? getEloBadge(eloData.elo_badge)
    : eloData.elo_events_until_ranked
    ? `Unranked_${eloData.elo_events_until_ranked}`
    : "Unranked_5";
};

export const getRankFriendlyName = (eloData: IEloData) => {
  const eloRank = getEloRank(eloData);
  return eloRank.startsWith("Unranked")
    ? "Unranked"
    : eloRank.replace(/_/g, " ");
};
