import { difference, isEqual, sortBy, zipObject } from "lodash";
import { createSelector } from "reselect";
import { RootState } from "../../app/store";
import { getActiveOrProposedTeamChipName } from "../chips";
import {
  IElementType,
  getElementTypes,
  getElementTypesById,
  getPossibleFormations,
} from "../elementTypes";
import {
  IElement,
  getElement,
  getElements,
  getElementsById,
} from "../elements";
import { calculatePointsForPicks } from "../entries";
import { getActiveEvent } from "../events";
import { IRules, getRules } from "../game";
import { IPick } from "../myTeam";
import { ITransfersData } from "../squad";
import { getTeams } from "../teams";
import {
  IManageErrors,
  IManagePickProposed,
  IProposedPicksByElementType,
} from "./types";

export const getActiveFormation = (state: RootState) =>
  state.manage.activeFormation;

export const getPendingFormation = (state: RootState) =>
  state.manage.pendingFormation;

export const getPicksProposedByPosition = (
  state: RootState
): { [key: number]: IManagePickProposed } => state.manage.picksProposed;

export const getPicksSavedByPosition = (
  state: RootState
): { [key: number]: IPick | null } => state.manage.picksSaved;

export const getPicksProposed = createSelector(
  [getPicksProposedByPosition],
  (picks) => Object.values(picks)
);

export const getPicksProposedWithElements = createSelector(
  [getPicksProposed],
  (picks) => picks.filter((p) => p.element)
);

export const getHasBench = createSelector(
  [(state) => getRules(state, getActiveEvent(state).id)],
  (rules) => {
    return rules ? rules.squad_squadsize > rules.squad_squadplay : false;
  }
);

export const getPendingRemovals = (state: RootState): IElementType[] =>
  state.manage.pendingRemovals;

export const getLastChange = (state: RootState) => state.manage.lastChange;

// This uses Typescript Narrowing to type guard the selector https://www.typescriptlang.org/docs/handbook/2/narrowing.html
export const getPicksSaved = createSelector(
  [getPicksSavedByPosition],
  (picks) =>
    Object.values(picks).filter((pick: IPick | null): pick is IPick => {
      return (pick as IPick) !== null;
    })
);

export const getProposedElements = createSelector(
  [
    getPicksProposed,
    (state: RootState) => getElementsById(state, getActiveEvent(state).id),
  ],
  (picks, elementsById) => {
    let elements: IElement[] = [];
    picks.forEach((p) => {
      if (p.element) {
        elements.push(elementsById[p.element]);
      }
    });
    return elements;
  }
);

export const getSavedElements = createSelector(
  [
    getPicksSaved,
    (state: RootState) => getElementsById(state, getActiveEvent(state).id),
  ],
  (picks, elementsById) => picks.map((p) => elementsById[p.element])
);

export const getNonTransactableElements = createSelector(
  [(state, eventId) => getElements(state, eventId)],
  (elements) => elements.filter((element) => !element.can_transact)
);

export const getMySubsSaved = createSelector(
  getPicksSaved,
  (state: RootState) => getRules(state, getActiveEvent(state).id),
  (picks, rules) => {
    if (rules) {
      return picks.slice(rules.squad_squadplay, rules.squad_squadsize);
    } else {
      return [];
    }
  }
);

export const getNonTransactablePicks = createSelector(
  [
    getPicksProposed,
    (state, eventId) => getNonTransactableElements(state, eventId),
  ],
  (proposedPicks, nonTransactableElements) =>
    proposedPicks.filter((pick) =>
      nonTransactableElements.some((el) => el.id === pick.element)
    )
);

export const getElementTypesByFormation = createSelector(
  [
    (state: RootState) => getElementTypesById(state, getActiveEvent(state).id),
    (_, formation: string) => formation,
    (state: RootState) => getRules(state, getActiveEvent(state).id),
  ],
  (elementTypesById, formation, rules) => {
    if (!rules) {
      return [];
    }

    let starters: number[] = [];
    let subs: number[] = [];
    formation.split("-").forEach((value: string, i: number) => {
      const et = elementTypesById[i + 1];
      const etSquadSelect =
        et.squad_max_select && et.squad_max_select > 0
          ? et.squad_max_select
          : et.squad_select;
      const differential = etSquadSelect - Number(value);
      starters.push(...Array(Number(value)).fill(i + 1));
      subs.push(...Array(differential).fill(i + 1));
    });

    const squad =
      rules.squad_squadsize > rules.squad_squadplay
        ? [...starters, ...subs]
        : starters;
    return squad.map((elementType: number) => elementTypesById[elementType]);
  }
);

export const getElementTypesByActiveFormation = (state: RootState) => {
  const activeFormation = getActiveFormation(state);
  return activeFormation
    ? getElementTypesByFormation(state, activeFormation)
    : [];
};

export const getCountByTeam = createSelector(
  getPicksProposed,
  (state: RootState) => getElementsById(state, getActiveEvent(state).id),
  getTeams,
  (picksProposed, elementsById, teams) => {
    const countByTeam: { [id: string]: number } = teams.reduce(
      (memo, t) => ({ ...memo, [t.id]: 0 }),
      {}
    );
    let pickElements: IElement[] = [];
    picksProposed.forEach((pick: IManagePickProposed) => {
      if (pick.element) {
        pickElements.push(elementsById[pick.element]);
      }
    });
    pickElements.forEach((element: IElement) => {
      countByTeam[element.team]++;
    });
    return countByTeam;
  }
);

export const getTypesNeeded = createSelector(
  [
    getHasBench,
    getActiveFormation,
    getPicksProposed,
    (state: RootState) => getElementTypes(state, getActiveEvent(state).id),
  ],
  (hasBench, formation, picksProposed, types) => {
    let needed: { [id: string]: number } = {};
    if (hasBench) {
      needed = types.reduce(
        (memo, et) => ({ ...memo, [et.id]: et.squad_select }),
        {}
      );
    } else {
      // If no bench we can derive the amounts from the formation
      if (formation) {
        formation.split("-").forEach((value: string, index: number) => {
          needed[index + 1] = Number(value);
        });
      }
    }

    picksProposed.forEach((pick: IManagePickProposed) => {
      if (pick.element) {
        needed[pick.elementType]--;
      }
    });

    return needed;
  }
);

export const getMyStartersProposed = createSelector(
  getPicksProposed,
  (state: RootState) => getRules(state, getActiveEvent(state).id),
  (picks, rules) =>
    rules ? picks.filter((pick) => pick.position <= rules.squad_squadplay) : []
);

export const getMyStartersSaved = createSelector(
  getPicksSaved,
  (state: RootState) => getRules(state, getActiveEvent(state).id),
  (picks, rules) => picks.slice(0, rules ? rules.squad_squadplay : 0)
);

export const getMySubsProposed = createSelector(
  getPicksProposed,
  (state: RootState) => getRules(state, getActiveEvent(state).id),
  (picks, rules) => {
    if (rules) {
      return picks.slice(rules.squad_squadplay, rules.squad_squadsize);
    } else {
      return [];
    }
  }
);

export const getMyStartingTotalsByType = createSelector(
  getMyStartersProposed,
  (state: RootState) => getElementTypesById(state, getActiveEvent(state).id),
  (picks, types) => {
    const totals = zipObject(
      Object.keys(types),
      Array(Object.keys(types).length).fill(0)
    );
    picks.forEach((pick) => totals[pick.elementType]++);
    return totals;
  }
);

export const getMyPossibleReplacementsForPick = (
  state: RootState,
  pick1: IManagePickProposed
) => {
  const possibles: IManagePickProposed[] = [];
  const totals = getMyStartingTotalsByType(state);
  getPicksProposed(state).forEach((pick2) => {
    if (isLegalSwap(state, pick1, pick2, totals)) {
      possibles.push(pick2);
    }
  });
  return possibles;
};

export const isSub = createSelector(
  [
    (_, pick: IManagePickProposed) => pick,
    (state: RootState) => getRules(state, getActiveEvent(state).id) as IRules,
  ],
  (pick, rules) => pick.position > rules?.squad_squadplay
);

export const isLegalSwap = createSelector(
  [
    (state: RootState) => getElementsById(state, getActiveEvent(state).id),
    (state: RootState) => getElementTypesById(state, getActiveEvent(state).id),
    (_, p1: IManagePickProposed) => p1,
    (state: RootState, p1: IManagePickProposed) => isSub(state, p1),
    (_, __, p2: IManagePickProposed) => p2,
    (state: RootState, __, p2: IManagePickProposed) => isSub(state, p2),
    (_, __, ___, totals: Record<string, number>) => totals,
  ],
  (elementsById, elementTypesById, p1, p1Sub, p2, p2Sub, totals) => {
    const p1Type = p1.elementType;
    const p2Type = p2.elementType;
    let validSwap = false;

    // Swapping starters disallowed
    if (!p1Sub && !p2Sub) {
      return false;
    }

    // If p2 has already played
    if (p2.element && !elementsById[p2.element].can_transact) {
      return false;
    }

    // Swapping self disallowed
    if (p1.element && p2.element && p1.element === p2.element) {
      return false;
    }

    // Can always swap like for like
    if (p1Type === p2Type) {
      return true;
    }

    // Can swap subs unless positions locked
    if (p1Sub && p2Sub) {
      validSwap = true;
    } else if (p1Sub) {
      // Bringing p1 in, p2 out
      validSwap =
        totals[p1Type] < elementTypesById[p1Type].squad_max_play &&
        totals[p2Type] > elementTypesById[p2Type].squad_min_play;
    } else {
      // Bringing p2 in, p1 out
      validSwap =
        totals[p2Type] < elementTypesById[p2Type].squad_max_play &&
        totals[p1Type] > elementTypesById[p1Type].squad_min_play;
    }

    // Check any subs are allowed to be in their new positions
    if (
      p2Sub && // p1 becoinng a sub
      elementTypesById[p1Type].sub_positions_locked.length && // locked type
      elementTypesById[p1Type].sub_positions_locked.indexOf(p2.position) === -1
    ) {
      return false;
    }
    if (
      p1Sub && // p2 becoming a sub
      elementTypesById[p2Type].sub_positions_locked.length && // locked type
      elementTypesById[p2Type].sub_positions_locked.indexOf(p1.position) === -1
    ) {
      return false;
    }

    return validSwap;
  }
);

export const getMyFormationProposed = createSelector(
  getMyStartingTotalsByType,
  (totals) => {
    return sortBy(Object.keys(totals), (et) => Number(et))
      .map((et) => totals[et])
      .join("-");
  }
);

// Calculates the positions on the pitch based on the current formation
export const getPositionsInFormation = createSelector(
  getActiveFormation,
  (formation) => {
    if (!formation) {
      return null;
    }
    const positionsByEt: {
      [key: number]: number[];
    } = {
      1: [1, 2],
      2: [3, 4, 5, 6, 7],
      3: [8, 9, 10, 11, 12],
      4: [13, 14, 15],
    };

    const positions: number[] = [];
    formation.split("-").forEach((value: string, index: number) => {
      positions.push(...positionsByEt[index + 1].slice(0, Number(value)));
    });

    positions.push(
      ...Array.from({ length: 15 }, (_, i) => i + 1).filter(
        (pos: number) => !positions.includes(pos)
      )
    );

    return positions;
  }
);

export const buildManageTeamApiData = createSelector(
  getPicksProposed,
  getActiveOrProposedTeamChipName,
  (picks, chip) => {
    return {
      picks: Object.values(picks),
      chip: chip,
    };
  }
);

// Returns removed, original or replaced for each squad position
export const getTransferPositionStatus = createSelector(
  getPicksSaved,
  getPicksProposed,
  (saved, proposed) =>
    Object.keys(saved).reduce<
      Record<string, "removed" | "original" | "replaced">
    >(
      (memo, pos) => ({
        ...memo,
        [pos]: !proposed[Number(pos)]
          ? "removed"
          : saved[Number(pos)].element === proposed[Number(pos)]?.element
          ? "original"
          : "replaced",
      }),
      {}
    )
);

export const getToSpend = createSelector(
  getTransferPositionStatus,
  getProposedElements,
  (state: RootState) => getRules(state, getActiveEvent(state).id),
  getPicksSaved,
  (posStatus, proposed, rules, saved) => {
    if (rules) {
      return proposed.reduce((toSpend, e) => {
        const savedPick = saved.find((p) => p.element === e.id);
        if (savedPick) {
          return toSpend - savedPick.selling_price;
        }
        if (e) {
          return toSpend - e.now_cost;
        } else {
          return toSpend;
        }
      }, rules.squad_total_spend);
    }
    return 0;
  }
);

export const hasTeamChanged = createSelector(
  getPicksProposed,
  getPicksSaved,
  (proposed, saved) => {
    const proposedWithoutProposedAttrs = proposed.map(
      (pick: IManagePickProposed) => {
        const { elementType, subStatus, ...rest } = pick;
        return {
          ...rest,
        } as IPick;
      }
    );
    return !isEqual(proposedWithoutProposedAttrs, saved);
  }
);

export const getProposedTransfers = createSelector(
  getProposedElements,
  getSavedElements,
  getPicksSaved,
  (elements, savedElements, picks) => {
    const data: Array<ITransfersData> = [];
    const transfersIn: IElement[] = difference(elements, savedElements);
    const transfersOut: IElement[] = difference(savedElements, elements);

    // Sort by element_type
    transfersIn.sort((a, b) => a.element_type - b.element_type);
    transfersOut.sort((a, b) => a.element_type - b.element_type);

    if (transfersIn.length === transfersOut.length) {
      Object.keys(transfersIn).forEach((i: string) => {
        const index = Number(i);
        const p = picks.find((p) => p.element === transfersOut[index].id);
        if (p) {
          data.push({
            element_in: transfersIn[index].id,
            element_out: p.element,
            purchase_price: transfersIn[index].now_cost,
            selling_price: p.selling_price,
          });
        }
      });
    }
    return data;
  }
);

export const hasCaptainPlayed = createSelector(
  [getActiveEvent, getPicksSaved, (state) => state],
  (activeEvent, savedPicks, state) => {
    const captainId = savedPicks.find((pick) => pick.is_captain)?.element;
    const captainElement = captainId
      ? getElement(state, captainId, activeEvent.id)
      : null;
    const captainPlayed = captainElement && !captainElement.can_transact;
    return captainPlayed;
  }
);

export const hasViceCaptainPlayed = createSelector(
  [getActiveEvent, getPicksSaved, (state) => state],
  (activeEvent, savedPicks, state) => {
    const viceCaptainId = savedPicks.find(
      (pick) => pick.is_vice_captain
    )?.element;
    const viceCaptainElement = viceCaptainId
      ? getElement(state, viceCaptainId, activeEvent.id)
      : null;
    const viceCaptainPlayed =
      viceCaptainElement && !viceCaptainElement.can_transact;
    return viceCaptainPlayed;
  }
);

export const getPossibleFormationsFromPicks = createSelector(
  [
    (state, eventId) => getElementTypes(state, eventId),
    getPossibleFormations,
    getMyStartersSaved,
    (state, eventId) => getNonTransactablePicks(state, eventId),
  ],
  (
    elementTypes,
    possibleFormations,
    savedStartingPicks,
    nonTransactablePicks
  ) => {
    // obtain picks that are not transactable grouped by element type
    // so we know what must be in the formation
    const nonTransactablePicksGroupedByType = nonTransactablePicks.reduce(
      (acc: IProposedPicksByElementType, pick) => {
        const elementType = pick.elementType;
        if (!acc[elementType]) {
          acc[elementType] = [];
        }
        acc[elementType].push(pick);
        return acc;
      },
      {}
    );

    return possibleFormations.filter((formation) => {
      const positions = formation.split("-").map(Number);

      return positions.every((position, index) => {
        const elementType = elementTypes[index];
        const startingPicksOfType = savedStartingPicks.filter(
          (pick) => pick.element_type === elementType.id
        );
        // Get total unmanageable of the element type in question
        const totalUnmanageable =
          nonTransactablePicksGroupedByType[elementType.id]?.length || 0;

        // starting picks that cant be moved for element type in question
        const startingPicksUnmanageable = savedStartingPicks.filter(
          (pick_saved) =>
            nonTransactablePicksGroupedByType[elementType.id]?.some(
              (pick) => pick.element === pick_saved.element
            )
        ).length;

        // determine max amount of open spaces that can be changed still
        // taking into account those that are starting already and
        // cant be moved/managed
        const maxPlay =
          elementType.squad_max_play -
          (totalUnmanageable - startingPicksUnmanageable);

        // check if element type in formation is credible and can be selected
        // given constraints
        return (
          (position >= startingPicksUnmanageable && position <= maxPlay) ||
          position === startingPicksOfType.length
        );
      });
    });
  }
);

export const getProposedPicksSelectionPriorityOrdered = createSelector(
  [
    getPicksProposed,
    getMyStartersSaved,
    (state, eventId) => getNonTransactableElements(state, eventId),
  ],
  (picksProposed, savedStartingPicks, nonTransactableElements) => {
    const sortedPicks = picksProposed.sort((pick1, pick2) => {
      const isPick1NonTransactable = nonTransactableElements.find(
        (el) => el.id === pick1.element
      );
      const isPick2NonTransactable = nonTransactableElements.find(
        (el) => el.id === pick2.element
      );

      const isPick1Starting = savedStartingPicks.find(
        (pick) => pick.element === pick1.element
      );
      const isPick2Starting = savedStartingPicks.find(
        (pick) => pick.element === pick2.element
      );

      // Prioritize non-transactable and starting picks
      if (
        isPick1NonTransactable &&
        isPick1Starting &&
        !(isPick2NonTransactable && isPick2Starting)
      ) {
        return -1;
      } else if (
        isPick2NonTransactable &&
        isPick2Starting &&
        !(isPick1NonTransactable && isPick1Starting)
      ) {
        return 1;
      }

      // Deprioritize non-transactable if not starting
      if (
        isPick1NonTransactable &&
        !isPick1Starting &&
        !isPick2NonTransactable
      ) {
        return 1;
      } else if (
        isPick2NonTransactable &&
        !isPick2Starting &&
        !isPick1NonTransactable
      ) {
        return -1;
      }

      // // NOTE: Commented out until we have finalised loading of manage team
      // // Sort remaining transactable picks by selling price
      // //  have to account for manage pick on typescript having optional selling price
      // if (!isPick1NonTransactable && !isPick2NonTransactable) {
      //   const sellingPriceA =
      //     typeof pick1.selling_price === "number" ? pick1.selling_price : 0;
      //   const sellingPriceB =
      //     typeof pick2.selling_price === "number" ? pick2.selling_price : 0;
      //   return sellingPriceB - sellingPriceA;
      // }

      // If both picks are non-transactable and one is starting
      // while the other is not, prioritize the starting one
      if (isPick1NonTransactable && isPick2NonTransactable) {
        if (isPick1Starting && !isPick2Starting) {
          return -1;
        } else if (!isPick1Starting && isPick2Starting) {
          return 1;
        }
      }

      return 0;
    }); // In case all other conditions fail, keep original order

    return sortedPicks;
  }
);

export const getErrors = createSelector(
  getProposedElements,
  (state: RootState, eventId: number) => getRules(state, eventId),
  getToSpend,
  getCountByTeam,
  (elements, rules, toSpend, countByTeam) => {
    const errors: IManageErrors = {};
    if (!rules) {
      return errors;
    }
    const needed = rules.squad_squadsize;

    if (toSpend < 0) {
      errors.budgetExceeded = -toSpend;
    }

    const selected = Object.keys(elements).length;
    if (selected !== needed) {
      errors.needElements = needed - selected;
    }

    Object.keys(countByTeam).forEach((teamId) => {
      if (countByTeam[teamId] > rules.squad_team_limit) {
        if (!errors.overTeamLimit) {
          errors.overTeamLimit = [];
        }
        errors.overTeamLimit.push(parseInt(teamId, 10));
      }
    });

    return errors;
  }
);

export const getManageEntryEventPoints = (
  state: RootState,
  eventId: number
) => {
  const picks = getPicksSaved(state);
  return calculatePointsForPicks(state, picks, eventId);
};
