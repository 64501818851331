import { RankNameWrapper, RankName } from "./styles";
import { IRankLabel } from "./types";

const RankLabel = ({ rankName }: IRankLabel) => (
  <RankNameWrapper>
    <RankName>{rankName}</RankName>
  </RankNameWrapper>
);

export default RankLabel;
