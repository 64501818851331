import { useAppSelector } from "../../rtk-core/src/app/hooks";
import {
  IEntryEventStatus,
  getEntry,
} from "../../rtk-core/src/features/entries";
import { getPlayerData } from "../../rtk-core/src/features/player";
import {
  getEntryStatusForEvent,
  getEventUrlForEntry,
} from "../../utils/entries";
import ButtonLink from "../ButtonLink";
import { ChevronRight } from "../Icons";
import { IManageTeamCta } from "./types";

const ManageTeamCta = ({
  event,
  isFullWidth = false,
  variant = "secondary",
}: IManageTeamCta) => {
  const player = useAppSelector(getPlayerData);
  const entry = useAppSelector((state) =>
    player?.entry ? getEntry(state, player.entry) : null
  );

  if (!player) {
    return null;
  }

  const eventUrl = entry
    ? getEventUrlForEntry(entry, event, player)
    : `/manage/${event.id}`;
  const eventStatus = entry
    ? getEntryStatusForEvent(entry, event)
    : "can_enter";

  let buttonText = "";

  if (eventStatus === IEntryEventStatus.CanEnter) {
    buttonText = "Pick team";
  } else if (eventStatus === IEntryEventStatus.EnteredCanManage) {
    buttonText = "Manage team";
  } else if (eventStatus === IEntryEventStatus.EnteredCannotManage) {
    buttonText = " View points";
  }

  if (!buttonText) {
    return null;
  }

  return (
    <ButtonLink to={eventUrl} $isFullWidth={isFullWidth} $variant={variant}>
      {buttonText}
      <ChevronRight />
    </ButtonLink>
  );
};

export default ManageTeamCta;
