import styled from "styled-components";
import { IPlayerCompProps } from "../../utils/player-comps";

const StyledPlayerComp = styled.div<IPlayerCompProps>`
  /* Until we come up with a wrapper without Flex */
  flex: 1;
  background-image: url(${(props) => props.$images.x1});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 198px 131px;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    background-size: 256px 169px;
  }
`;

export default StyledPlayerComp;
