import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { IError } from "../rtk-core/src/app/types";
import { IRules } from "../rtk-core/src/features/game";

export const getErrorType = (error: IError | null) => {
  if (!error) return "";

  if ("status" in error && error.status) {
    const fetchError = error as FetchBaseQueryError;

    // Check if the data property contains the error information
    if (fetchError.data && typeof fetchError.data === "object") {
      const djangoError = fetchError.data as {
        code?: string;
        non_field_errors?: [{ code: string }];
      };

      if (
        djangoError.non_field_errors &&
        djangoError.non_field_errors.length > 0
      ) {
        return djangoError.non_field_errors[0].code;
      }

      if (djangoError.code) {
        return djangoError.code;
      }
    }

    // Fallback to a generic error message if no specific details are found
    return `Error: ${fetchError.status}`;
  }

  // Handling SerializedError
  if ("message" in error) {
    const serializedError = error as SerializedError;
    return serializedError.message || "";
  }

  return JSON.stringify(error);
};

export const formatErrorMsg = (error: string, rules: IRules) => {
  switch (error) {
    case "max_length": {
      return `Invalid code entered. Please check the code.`;
    }
    case "invalid": {
      return `Invalid code entered. Please check the code and confirm with the league administrator if you are still having problems.`;
    }
    case "league_closed": {
      return `This league is closed to new entries.`;
    }
    case "already_joined": {
      return `You are already entered in this league.`;
    }
    case "entry_banned": {
      return `The creator of this league has banned you from entering.`;
    }
    case "max_private_entries": {
      return `You are in the maximum number of ${rules.league_join_private_max} invitational leagues. Before you can join a new league you will need to leave one of these leagues.`;
    }
    case "public_league_max_exceeded": {
      return `You may only enter ${rules.league_join_public_max} public leagues`;
    }
    default:
      return error;
  }
};
