import { useAppSelector } from "../../../../rtk-core/src/app/hooks";
import { getElement } from "../../../../rtk-core/src/features/elements";
import FixturesForElement from "../../../FixturesForElement";
import ElementPoints from "../ElementPoints";
import { PadlockIcon, PointsWrap, StyledElementFixtureBar } from "./styles";
import { IElementFixtureBar } from "./types";

const ElementFixtureBar = ({
  elementFixtures,
  isStarted,
  pick,
}: IElementFixtureBar) => {
  const element = useAppSelector((state) => getElement(state, pick.element));

  if (!elementFixtures.length) {
    return <StyledElementFixtureBar>-</StyledElementFixtureBar>;
  }

  // TODO Move these into a util or selector so we can make use of them elsewhere
  // https://github.com/ismfg/pl-issues/issues/381
  const isLive = elementFixtures.some(
    (fixture) => !fixture.finished_provisional && fixture.started
  );

  const isFinished = elementFixtures.every(
    (fixture) => fixture.finished_provisional
  );

  if (isStarted) {
    return (
      <StyledElementFixtureBar $isLive={isLive} $isFinished={isFinished}>
        <PointsWrap>
          <PadlockIcon />
          <ElementPoints pick={pick} />
        </PointsWrap>
      </StyledElementFixtureBar>
    );
  }

  return (
    <StyledElementFixtureBar>
      <FixturesForElement elementId={element.id} />
    </StyledElementFixtureBar>
  );
};

export default ElementFixtureBar;
