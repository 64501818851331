import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAutoJoinState } from "./types";
import { getInitialState } from "./autoJoinPersistentStorage";

const initialState: IAutoJoinState = {
  code: getInitialState(),
};

const autoJoin = createSlice({
  name: "autoJoin",
  initialState,
  reducers: {
    setAutoJoinCode: (state, action: PayloadAction<string>) => ({
      ...state,
      code: action.payload,
    }),
    clearAutoJoinCode: (state) => ({
      ...state,
      code: null,
    }),
  },
});

export const { setAutoJoinCode, clearAutoJoinCode } = autoJoin.actions;

export default autoJoin.reducer;
