import * as React from "react";
import styled from "styled-components";
import { PatternWrap } from "../GraphicPatterns";

const PanelHeading = styled.h3`
  flex: 1;
  margin: ${({ theme }) => theme.space[3]} 0;
  padding: 0 ${({ theme }) => theme.space[2]};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.7rem;
`;

const PanelBody = styled.div`
  padding-top: ${({ theme }) => theme.space[2]};
`;

const PanelFooter = styled.div`
  margin: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[2]} 0;
  padding-bottom: ${({ theme }) => theme.space[3]};
`;

interface IScoutPanelHeaderProps {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  title: React.ReactNode;
}

const PanelHeader: React.FC<IScoutPanelHeaderProps> = ({
  children,
  icon = null,
  title,
}) => (
  <div>
    <PanelHeading>
      {icon ? (
        <div className="flex items-center">
          <div className="mr-2">{icon}</div>
          <span>{title}</span>
        </div>
      ) : (
        <>{title}</>
      )}
    </PanelHeading>
    {children}
  </div>
);

const ScoutPanel: React.FC<IScoutPanelHeaderProps> = ({
  children,
  icon = null,
  title,
}) => (
  <PatternWrap>
    <PanelHeader icon={icon} title={title} children={children} />
    <PanelBody />
    <PanelFooter />
  </PatternWrap>
);

export default ScoutPanel;
