import { em } from "polished";
import styled, { css } from "styled-components";
import { ITable } from "./types";

export const ScrollTable = styled.div`
  @media (max-width: ${em("480px")}) {
    overflow-x: auto;

    table {
      width: 48rem;
    }
  }
`;

const Table = styled.table<ITable>`
  width: 100%;
  border-collapse: separate;
  border: ${({ $hasBorder = true, theme }) =>
    $hasBorder ? theme.borders[0] : 0};
  border-radius: ${(props) => props.theme.radii[2]};
  background-color: #fff;
  font-size: ${(props) => props.theme.fontSizes[0]};

  th,
  td {
    padding: ${(props) => `${props.theme.space[2]}  ${props.theme.space[3]}`};
    @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
      padding: ${(props) => `${props.theme.space[2]} ${props.theme.space[4]}`};
    }
  }

  thead th {
    background-color: ${({ $isBold = true, theme }) =>
      $isBold ? theme.colors.purple5 : "transparent"};
    color: ${({ theme }) => theme.colors.purple60};
    font-family: ${(props) => props.theme.fonts.heavy};
    text-align: left;

    &:first-child {
      border-top-left-radius: ${(props) => props.theme.radii[2]};
    }

    &:last-child {
      border-top-right-radius: ${(props) => props.theme.radii[2]};
    }
  }

  tbody {
    td {
      position: relative;
      border-top: ${(props) => props.theme.borders[0]};

      ${({ $hasDividers = true, theme }) =>
        $hasDividers &&
        css`
          &:not(:first-child)::before {
            content: "";
            position: absolute;
            top: ${theme.space[2]};
            left: 0;
            bottom: ${theme.space[2]};
            display: block;
            width: 1px;
            background: ${theme.colors.purple10};
          }
        `};
    }

    th {
      text-align: left;
    }
  }

  tfoot td {
    font-family: ${(props) => props.theme.fonts.bold};
  }
`;

export default Table;
