import { ITeam } from "../rtk-core/src/features/teams";

export const baseUrl = import.meta.env.VITE_PULSE_API_BASE || "";

export interface IPulseResponse {
  pageInfo: any;
  content: Array<any>;
}

export interface IPulseRelatedArticle {
  id: number;
  label: string | null;
  type: string;
}

export interface IPulseTag {
  id: number;
  label: string;
}

interface ITeamsByPulseId {
  [key: string]: ITeam;
}

export interface IPulseTeams {
  name: string;
  club: { name: string; shortName: string; abbr: string; id: number };
  teamType: string;
  shortName: string;
  id: number;
  altIds: { opta: string };
}

export interface IPulseGoals {
  personId: number;
  assistId: number;
  clock: { secs: number; label: string };
  phase: string;
  type: string;
  description: string;
}

export interface IPulseFixture {
  gameweek: {
    id: number;
  };
  kickoff: {
    completeness: number;
    millis: number;
    label: string;
    gmtOffset: number;
  };
  provisionalKickoff: {
    completeness: number;
    millis: number;
    label: string;
    gmtOffset: number;
  };
  teams: IPulseTeams[];
  replay: boolean;
  ground: { name: string; city: string; source: string; id: number };
  status: string;
  phase: string;
  outcome: string;
  attendance: number;
  clock: { secs: number; label: string };
  fixtureType: string;
  extraTime: boolean;
  shootout: boolean;
  goals: IPulseGoals[];
  penaltyShootouts: [];
  id: number;
  altIds: { opta: string };
}

export interface IPulseNewsArticleRefs {
  label: string | null;
  id: number;
  type: string;
}

export interface IPulseNewsArticle {
  id: number;
  accountId: number;
  type: string;
  title: string;
  description: string | null;
  date: string;
  location: string | null;
  coordinates: Array<number> | null;
  commentsOn: boolean;
  copyright: null;
  publishFrom: number;
  publishTo: number;
  tags: Array<{ id: number; label: string }>;
  platform: string;
  language: string;
  additionalInfo: any;
  canonicalUrl: string;
  references: IPulseNewsArticleRefs[];
  related: Array<any>;
  metadata: any;
  titleTranslations: null;
  lastModified: number;
  titleUrlSegment: string;
  body: null;
  author: null;
  subtitle: string | null;
  summary: string | null;
  hotlinkUrl: string | null;
  duration: number;
  leadMedia: any;
  imageUrl: null;
  onDemandUrl: null;
}

export interface IPulseNewsResponse {
  pageInfo: {
    page: number;
    numPages: number;
    pageSize: number;
    numEntries: number;
  };
  content: IPulseNewsArticle[];
}

async function _fetch(url: string, withError = false) {
  const response = await fetch(url);
  if (!response.ok) {
    if (withError) {
      throw new Error(response.statusText);
    } else {
      console.log(response.statusText);
    }
  }
  return await response.json();
}

// Scout
export async function fetchNews(
  number: number,
  tag: string
): Promise<IPulseResponse> {
  const textUrl = [
    `${baseUrl}/content/PremierLeague/text/EN/?pageSize=${number}`,
    `&page=0&tagNames=${tag}`,
  ];
  return _fetch(textUrl.join(""));
}

export async function fetchNewsText(id: number): Promise<any> {
  const detailUrl = `${baseUrl}/content/PremierLeague/TEXT/EN/${id}`;
  return _fetch(detailUrl);
}

export async function fetchNewsArticle(id: number): Promise<any> {
  return _fetch(`${baseUrl}/content/PremierLeague/news/en/${id}`);
}

export async function fetchPlaylist(
  id: number,
  pageSize: number
): Promise<any> {
  return _fetch(
    `${baseUrl}/content/PremierLeague/playlist/en/${id}?pageSize=${pageSize}&page=0&fullObjectResponse=true`
  );
}

export async function fetchNewsArticles(
  referenceExpression: string
): Promise<IPulseNewsArticle[]> {
  const params = new URLSearchParams();
  params.set("pageSize", "50");
  params.set("tagExpression", `"ClubTeamNews"`);
  params.set("referenceExpression", referenceExpression);

  const { content } = await _fetch(
    `${baseUrl}/content/PremierLeague/news/EN/?${params.toString()}`
  );
  return Promise.resolve(content as IPulseNewsArticle[]);
}

export async function fetchVideos(
  number: number,
  tag: string
): Promise<IPulseResponse> {
  const videoUrl = [
    `${baseUrl}/content/PremierLeague/video/EN/?pageSize=${number}`,
    `&page=0&tagNames=${tag}`,
  ];
  return _fetch(videoUrl.join(""));
}

export const imageFromNews = (news: any, id: number) => {
  if (!news.leadMedia) {
    return null;
  }
  const lm = news.leadMedia;
  const variants =
    lm.type === "video" && lm.thumbnail ? lm.thumbnail.variants : lm.variants;
  const filteredVariants = variants.filter(
    (v: any) => v.tag && v.tag.id === id
  );
  return filteredVariants.length ? filteredVariants[0] : null;
};

// Broadcasters
export async function fetchBroadcastersForFixtures(
  startDate: string,
  endDate: string
): Promise<IPulseResponse> {
  const url = [
    `${baseUrl}/football/broadcasting-schedule/fixtures`,
    `?comps=1&pageSize=100&page=0&altIds=true`,
    `&startDate=${startDate}&endDate=${endDate}`,
    // `&countryCodes=ES`
  ];
  return _fetch(url.join(""));
}

export async function fetchFixtures(
  eventDeadlineTime: string
): Promise<IPulseFixture[]> {
  const params = new URLSearchParams();
  params.set("sort", "asc");
  params.set("comps", "1");
  params.set("altIds", "true");
  params.set("detail", "1");
  params.set("startDate", eventDeadlineTime);

  const { content } = await _fetch(
    `${baseUrl}/football/fixtures?${params.toString()}`
  );
  return Promise.resolve(content as IPulseFixture[]);
}

export const getPulseTeamIdFromRefs = (refs: IPulseNewsArticleRefs[]) => {
  const clubRefs = refs.filter((r) => r.type === "FOOTBALL_CLUB");
  return clubRefs.length && clubRefs[0].id ? clubRefs[0].id : 0;
};

export const getTeamsByPulseId = (teams: ITeam[]): ITeamsByPulseId =>
  teams.reduce((acc, curr) => {
    if (!curr.pulse_id) {
      return acc;
    }
    return { ...acc, [curr.pulse_id]: curr };
  }, {});
