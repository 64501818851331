import { useAppSelector } from "../../../../rtk-core/src/app/hooks";
import {
  getElement,
  getElementEventData,
} from "../../../../rtk-core/src/features/elements";
import { getActiveEvent } from "../../../../rtk-core/src/features/events";
import { getFixturesForEventByTeam } from "../../../../rtk-core/src/features/fixtures";
import FixtureForElement from "../../../FixturesForElement/FixtureForElement";
import { ElementMultiPoints } from "./styles";
import { IElementPoints } from "./types";

export const ElementPoints = ({ pick }: IElementPoints) => {
  const activeEvent = useAppSelector(getActiveEvent);
  const fixturesForEventByTeam = useAppSelector((state) =>
    getFixturesForEventByTeam(state, activeEvent.id)
  );
  const element = useAppSelector((state) => getElement(state, pick.element));
  const elementEventData = useAppSelector((state) =>
    element ? getElementEventData(state, element.id, activeEvent.id) : null
  );

  if (!elementEventData) {
    return null;
  }

  const explainList = elementEventData.explain;
  const totalPoints =
    elementEventData.stats.total_points * Math.max(pick.multiplier, 1);

  if (explainList.length === 1) {
    return <>{totalPoints}</>;
  }

  const ElementFixtures = fixturesForEventByTeam[element.team];
  const allFixturesStarted = ElementFixtures.every((f) => f.started);
  let fragments: React.ReactNode[] = [];
  let started = 0;

  elementEventData.explain.forEach((e) => {
    const fixture = ElementFixtures.find((f) => f.id === e.fixture);
    if (fixture) {
      if (!fixture.started) {
        fragments.push(
          <FixtureForElement element={element} fixture={fixture} />
        );
      } else {
        started++;
      }
    }
  });
  if (started) {
    fragments.unshift(totalPoints);
  }
  const MultiPoints = fragments.reduce((result, item) => (
    <ElementMultiPoints $allStarted={allFixturesStarted}>
      {result}
      {", "}
      {item}
    </ElementMultiPoints>
  ));

  return <>{MultiPoints}</>;
};

export default ElementPoints;
