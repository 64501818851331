import { ChallengeEvent, ChallengeName } from "./styles";
import { IChallengeHeading } from "./types";

const ChallengeHeading = ({ challengeTitle, eventName }: IChallengeHeading) => (
  <h3>
    <ChallengeEvent>{eventName} Challenge</ChallengeEvent>
    <ChallengeName>{challengeTitle}</ChallengeName>
  </h3>
);

export default ChallengeHeading;
