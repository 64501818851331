import { useAppSelector } from "../../../../rtk-core/src/app/hooks";
import { getEventsById } from "../../../../rtk-core/src/features/events";
import { getEventGameweekId } from "../../../../utils/events";
import { getSuffixFromId } from "../../../../utils/fixtures";
import Badge from "../../../Badge";
import Result from "../../../Result";
import { Points, Score } from "../../styles";
import { BadgeWrap, HistoryResult, Opp } from "./styles";
import { IHistoryRow } from "./types";

const HistoryRow: React.FC<IHistoryRow> = ({
  history,
  teamsById,
  statsByName,
}) => {
  const eventsById = useAppSelector(getEventsById);
  const event = eventsById[history.round];

  const getResult = (teamAScore: number, teamBScore: number) => {
    if (teamAScore > teamBScore) {
      return "W";
    }
    if (teamAScore < teamBScore) {
      return "L";
    }
    return "D";
  };

  const getElementResult = () =>
    history.was_home
      ? getResult(history.team_h_score, history.team_a_score)
      : getResult(history.team_a_score, history.team_h_score);

  const oppTeam = teamsById[history.opponent_team];

  return (
    <tr>
      <td>{getEventGameweekId(event.name)}</td>
      <td>
        <BadgeWrap>
          <Badge team={oppTeam} sizes="24px" />
        </BadgeWrap>
        <Opp>{oppTeam.short_name}</Opp>{" "}
        {getSuffixFromId(history.fixture, history.was_home)}
      </td>
      <td>
        {history.team_h_score !== null && (
          <HistoryResult>
            <Score>
              <span>{history.team_h_score}</span>
              <span>-</span>
              <span>{history.team_a_score}</span>
            </Score>
            <Result resultChar={getElementResult()} />
          </HistoryResult>
        )}
      </td>
      <td>
        {/* TODO Change this to history.special once available */}
        <Points $isSpecial={false}>{history.total_points} pts</Points>
      </td>
      <td>{history[statsByName["minutes"].name]}&apos;</td>
    </tr>
  );
};

export default HistoryRow;
