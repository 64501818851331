import { apiSlice } from "./apiSlice";
import { IFixture, IFixtureAndEvent } from "../fixtures";

export const fixturesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchFixtures: builder.query<IFixtureAndEvent, number>({
      query: (event: number) => `fixtures/?event=${event}`,
      transformResponse: (response: IFixture[], meta, event: number) => {
        return {
          event,
          data: response,
        };
      },
    }),
    fetchFixturesFuture: builder.query<IFixture[], void>({
      query: () => `fixtures/?future=1`,
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchFixturesQuery,
  useFetchFixturesFutureQuery,
  useLazyFetchFixturesQuery,
  useLazyFetchFixturesFutureQuery,
} = fixturesApi;
