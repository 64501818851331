import { em } from "polished";

const blueLight = "#05f0ff";
const darkPink = "#e90052";
const fantasy = "#00ff87";
const green = "#07db77";
const darkGrey = "#7a7a7a";
const lightGrey = "#efefef";
const amber = "#ffab1b";
const offblack = "#2f2f2f";
const purple = "#37003c";
const purple10 = "#ebe5eb";
const purple20 = "#d7ccd8";
const purple30 = "#c3b3c5";
const purple70 = "#734c76";
const yellow = "#ebff00";
const pink = "#ff2882";

const breakpointsArray = [400, 610, 700, 900, 1024, 1220, 1360];

const theme = {
  borders: [
    `1px solid ${purple10}`,
    `1px solid ${purple20}`,
    `1px solid ${purple30}`,
    `1px solid ${purple70}`,
  ],
  breakpointsArray,
  breakpoints: breakpointsArray.map((bp) => em(`${bp}px`)),
  colors: {
    black: offblack,
    bud: "#c7102d",
    darkBlue: "#627bff",
    darkGreen: "#029f4f",
    darkGrey,
    darkPink,
    difficulties: {
      easy: {
        bg: green,
        color: purple,
      },
      medium: {
        bg: amber,
        color: purple,
      },
      hard: {
        bg: "#ff1751",
        color: "white",
      },
    },
    elementStatus: {
      0: {
        bg: "#b2002d",
        color: "white",
      },
      25: {
        bg: "#d44401",
        color: "white",
      },
      50: {
        bg: amber,
        color: purple,
      },
      75: {
        bg: "#fbe772",
        color: purple,
      },
    },
    elementTypes: {
      1: {
        bg: yellow,
        color: purple,
      },
      2: {
        bg: fantasy,
        color: purple,
      },
      3: {
        bg: blueLight,
        color: purple,
      },
      4: {
        bg: darkPink,
        color: "white",
      },
    },
    error: darkPink,
    fantasy,
    grey: "#ebebe6",
    blueLight,
    lightGrey,
    pinkToOrange:
      "linear-gradient(30deg, #963cff, #c710e6 5%, #e900ca, #ff00ad, #ff0090, #ff0075, #ff005a, #ff2e41, #ff5028, #ff6900 87.5%);",
    movement: {
      down: {
        bg: "#fc2c80",
        color: "white",
      },
      same: {
        bg: darkGrey,
        color: darkGrey,
      },
      up: {
        bg: "#05fa87",
        color: purple,
      },
    },
    pink: pink,
    primary: purple,
    purple5: "#f5f2f5",
    purple10,
    purple20,
    purple30,
    purple40: "#af99b1",
    purple60: "#87668a",
    purple70,
    purple90: "#4b1a50",
    purpleDark: "#250029",
    purpleLight: "#963cff",
    result: {
      w: { bg: fantasy, color: purple },
      d: { bg: purple10, color: purple },
      l: { bg: pink, color: "white" },
    },
    social: {
      facebook: {
        bg: "#1877f2",
        color: "white",
      },
      x: {
        bg: "black",
        color: "white",
      },
      google: {
        bg: "white",
        color: purple,
      },
      apple: {
        bg: "black",
        color: "white",
      },
    },
    success: green,
    text: offblack,
    white: "#ffffff",
    offWhite: "#fbfbfb",
    yellow,
  },
  fontSizes: ["1.2rem", "1.3rem", "1.4rem", "1.6rem", "1.8rem", "2rem", "3rem"],
  fonts: {
    bold: '"PremierSans-Bold", Arial, "Helvetica Neue", Helvetica, "sans-seriff"',
    boldItalic:
      '"PremierSans-Bold-Italic", Arial, "Helvetica Neue", Helvetica, "sans-seriff"',
    heavy:
      '"PremierSans-Heavy", Arial, "Helvetica Neue", Helvetica, sans-serif;',
    italic:
      '"PremierSans-Italic", Arial, "Helvetica Neue", Helvetica, "sans-seriff"',
    light:
      '"PremierSans-Light", Arial, "Helvetica Neue", Helvetica, "sans-serif"',
    regular:
      '"PremierSans-Regular", Arial, "Helvetica Neue", Helvetica, "sans-serif"',
  },
  letterSpacing: ["-0.04em"],
  radii: [
    "0",
    "0.4rem",
    "0.8rem",
    "1.2rem",
    "1.6rem",
    "2rem",
    "2.4rem",
    "2.8rem",
    "3.2rem",
  ],
  space: [
    "0",
    "0.4rem",
    "0.8rem",
    "1.2rem",
    "1.6rem",
    "2rem",
    "2.4rem",
    "2.8rem",
    "3.2rem",
  ],
  zIndex: {
    dialog: 120,
    overlay: 110,
    select: {
      select: 90,
      wrap: 80,
    },
  },
};

export default theme;
