import { createAsyncThunk } from "@reduxjs/toolkit";
import { IThunkApi } from "../../app/types";
import { leaguesApi } from "../api/leagues";
import { clearCode } from "../autoJoin";
import { getPlayerData } from "../player/playerSlice";
import {
  IBanEntryData,
  ICreateClassicLeagueData,
  ICreateH2HLeagueData,
  IJoinPrivateLeagueData,
  IJoinPublicLeagueData,
  IUpdateClassicLeagueData,
  IUpdateH2HLeagueData,
} from "./types";

export const createClassicLeague = createAsyncThunk<
  void,
  ICreateClassicLeagueData,
  IThunkApi
>("leagues/createClassicLeague", async (data, { dispatch }) => {
  await dispatch(leaguesApi.endpoints.createClassicLeague.initiate(data));
  // The navigaiton is likely to be removed later. The reason for this is that useNavigate is a hook.
  // This causes any use of it within tests for one to fail. In addition, this is likely to cause
  // issues in many ways. Therefore the only way to use this is to call it is using the lazy method provided
  // by Redux store. This will mean we have a trigger that we can call to initiate it. The decision may result in
  // this being removed and being called on a case by case basis

  // const navigate = useNavigate();
  // navigate("/leagues");
});

export const joinPrivateLeague = createAsyncThunk<
  void,
  IJoinPrivateLeagueData,
  IThunkApi
>(
  "leagues/joinPrivateLeague",
  async (privateLeagueData, { dispatch, getState }) => {
    const player = getPlayerData(getState());
    if (player && player.entry) {
      await dispatch(
        leaguesApi.endpoints.joinPrivateLeague.initiate({
          data: privateLeagueData,
          entry: player.entry,
        })
      );
    }
  }
);

export const joinPublicLeague = createAsyncThunk<
  void,
  IJoinPublicLeagueData,
  IThunkApi
>(
  "leagues/joinPublicLeague",
  async (publicLeagueData, { dispatch, getState }) => {
    const player = getPlayerData(getState());
    if (player && player.entry) {
      await dispatch(
        leaguesApi.endpoints.joinPublicLeague.initiate({
          data: publicLeagueData,
          entry: player.entry,
        })
      );
      // The navigaiton is likely to be removed later. The reason for this is that useNavigate is a hook.
      // This causes any use of it within tests for one to fail. In addition, this is likely to cause
      // issues in many ways. Therefore the only way to use this is to call it is using the lazy method provided
      // by Redux store. This will mean we have a trigger that we can call to initiate it. The decision may result in
      // this being removed and being called on a case by case basis

      // const navigate = useNavigate();
      // navigate("/leagues");
    }
  }
);

export const leaveLeague = createAsyncThunk<void, number, IThunkApi>(
  "leagues/leaveLeague",
  async (leagueId, { dispatch, getState }) => {
    const player = getPlayerData(getState());
    if (player && player.entry) {
      await dispatch(
        leaguesApi.endpoints.leaveLeague.initiate({
          leagueId: leagueId,
          entry: player.entry,
        })
      );
    }
  }
);

export const deleteLeague = createAsyncThunk<void, number, IThunkApi>(
  "leagues/deleteLeague",
  async (leagueId, { dispatch, getState }) => {
    const player = getPlayerData(getState());
    if (player && player.entry) {
      await dispatch(
        leaguesApi.endpoints.deleteLeague.initiate({
          leagueId: leagueId,
          entry: player.entry,
        })
      );
      // The navigaiton is likely to be removed later. The reason for this is that useNavigate is a hook.
      // This causes any use of it within tests for one to fail. In addition, this is likely to cause
      // issues in many ways. Therefore the only way to use this is to call it is using the lazy method provided
      // by Redux store. This will mean we have a trigger that we can call to initiate it. The decision may result in
      // this being removed and being called on a case by case basis

      // const navigate = useNavigate();
      // navigate("/leagues");
    }
  }
);

export const createH2HLeague = createAsyncThunk<
  void,
  ICreateH2HLeagueData,
  IThunkApi
>("leagues/createH2HLeague", async (data, { dispatch }) => {
  await dispatch(leaguesApi.endpoints.createH2HLeague.initiate(data));
  // The navigaiton is likely to be removed later. The reason for this is that useNavigate is a hook.
  // This causes any use of it within tests for one to fail. In addition, this is likely to cause
  // issues in many ways. Therefore the only way to use this is to call it is using the lazy method provided
  // by Redux store. This will mean we have a trigger that we can call to initiate it. The decision may result in
  // this being removed and being called on a case by case basis

  // const navigate = useNavigate();
  // navigate("/leagues");
});

export const updateClassicLeague = createAsyncThunk<
  void,
  { leagueId: number; data: IUpdateClassicLeagueData },
  IThunkApi
>("leagues/updateClassicLeague", async (apidata, { dispatch }) => {
  await dispatch(leaguesApi.endpoints.updateClassicLeague.initiate(apidata));
  await dispatch(
    leaguesApi.endpoints.fetchClassicLeagueForAdmin.initiate(apidata.leagueId, {
      forceRefetch: true,
    })
  );
});

export const updateH2HLeague = createAsyncThunk<
  void,
  { leagueId: number; data: IUpdateH2HLeagueData },
  IThunkApi
>("leagues/updateH2HLeague", async (apidata, { dispatch }) => {
  await dispatch(leaguesApi.endpoints.updateH2HLeague.initiate(apidata));
  // The navigaiton is likely to be removed later. The reason for this is that useNavigate is a hook.
  // This causes any use of it within tests for one to fail. In addition, this is likely to cause
  // issues in many ways. Therefore the only way to use this is to call it is using the lazy method provided
  // by Redux store. This will mean we have a trigger that we can call to initiate it. The decision may result in
  // this being removed and being called on a case by case basis

  // const navigate = useNavigate();
  // navigate("/leagues");
});

export const banLeagueEntry = createAsyncThunk<
  void,
  { leagueId: number; data: IBanEntryData },
  IThunkApi
>("leagues/banLeagueEntry", async (apidata, { dispatch }) => {
  await dispatch(leaguesApi.endpoints.banLeagueEntry.initiate(apidata));
  // The navigaiton is likely to be removed later. The reason for this is that useNavigate is a hook.
  // This causes any use of it within tests for one to fail. In addition, this is likely to cause
  // issues in many ways. Therefore the only way to use this is to call it is using the lazy method provided
  // by Redux store. This will mean we have a trigger that we can call to initiate it. The decision may result in
  // this being removed and being called on a case by case basis

  // const navigate = useNavigate();
  // navigate("/leagues");
});

export const unbanLeagueEntry = createAsyncThunk<
  void,
  { leagueId: number; data: IBanEntryData },
  IThunkApi
>("leagues/unbanLeagueEntry", async (apidata, { dispatch }) => {
  await dispatch(leaguesApi.endpoints.unbanLeagueEntry.initiate(apidata));
  // The navigaiton is likely to be removed later. The reason for this is that useNavigate is a hook.
  // This causes any use of it within tests for one to fail. In addition, this is likely to cause
  // issues in many ways. Therefore the only way to use this is to call it is using the lazy method provided
  // by Redux store. This will mean we have a trigger that we can call to initiate it. The decision may result in
  // this being removed and being called on a case by case basis

  // const navigate = useNavigate();
  // navigate("/leagues");
});

export const checkLeagueCode = createAsyncThunk<void, string, IThunkApi>(
  "leagues/checkLeagueCode",
  async (data, { dispatch }) => {
    const response = await dispatch(
      leaguesApi.endpoints.checkLeagueCode.initiate(data)
    );
    if (response.isError) {
      await dispatch(clearCode());
    }
  }
);
