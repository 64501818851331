import { ILeagueEntry } from "../rtk-core/src/features/entries";

// TODO: Make the eventId a compulsory type
// See https://github.com/ismfg/games/pull/6227#discussion_r1689274866
export const getLeagueUrl = (
  id: number,
  scoring: string,
  defaultEntry?: number,
  eventId?: number,
  phaseId?: number
) => {
  let url =
    scoring === "h"
      ? `/leagues/${id}/matches/${scoring}`
      : `/leagues/${id}/standings/${scoring}`;

  // Append query parameters
  const queryParams = [];
  if (defaultEntry) {
    queryParams.push(`default_entry=${defaultEntry}`);
  }
  if (eventId !== undefined) {
    url += `/${eventId}`;
  }
  if (eventId !== undefined && phaseId) {
    url += `/${phaseId}`;
  }
  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }
  return url;
};

export const getBroadcasterLeagues = (systemClassicLeagues: ILeagueEntry[]) => {
  // Extract any broadcaster leagues
  const broadcasterLeagueMatch = /^brd-/;
  // Please note the "!" non-null assertion operator on the filter
  return systemClassicLeagues.filter((l) =>
    l.short_name!.match(broadcasterLeagueMatch)
  );
};

export const getFilteredSystemClassicLeagues = (
  systemClassicLeagues: ILeagueEntry[]
) => {
  const broadcasterLeagues = getBroadcasterLeagues(systemClassicLeagues);
  return systemClassicLeagues.filter(
    (l) => !broadcasterLeagues.length || broadcasterLeagues.indexOf(l) === -1
  );
};

export const getCupUrl = (
  leagueId: number,
  cup_league: number | null,
  entryId?: number
) => {
  const linkUrl = `/leagues/${leagueId}/cup`;
  if (!cup_league) {
    return `${linkUrl}-not-started`;
  }
  if (entryId) {
    return `${linkUrl}?entry=${entryId}`;
  }
  return linkUrl;
};

export const getTopPercentileString = (percentileRank: number | null) =>
  percentileRank !== null ? `Top ${percentileRank}%` : "-";
