import {
  IElement,
  IElementEventRaw,
  IElementSummaryData,
  IElementSummaryDataTransformed,
  IElementsForEvent,
  IFetchElementEventDataResponse,
} from "../elements/types";
import { apiSlice } from "./apiSlice";

export const elementsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getElementSummary: builder.query<IElementSummaryDataTransformed, number>({
      query: (elementId: number) => `/element-summary/${elementId}/`,
      transformResponse: (response: IElementSummaryData, meta, elementId) => {
        return {
          elementId: elementId,
          summary_data: response,
        };
      },
    }),
    fetchEventLive: builder.query<IFetchElementEventDataResponse, number>({
      query: (eventId: number) => `/event/${eventId}/live/`,
      transformResponse: (response: IElementEventRaw, meta, eventId) => {
        return {
          id: eventId,
          data: response,
        };
      },
    }),
    getElements: builder.query<IElement[], null>({
      query: () => ({
        url: "/elements/",
        method: "GET",
      }),
    }),
    getElementsForEvent: builder.query<IElementsForEvent, number>({
      query: (eventId: number) => `/elements/${eventId}/`,
      transformResponse: (response: IElement[], meta, eventId) => {
        return {
          event_id: eventId,
          data: response,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchEventLiveQuery,
  useGetElementSummaryQuery,
  useGetElementsForEventQuery,
  useLazyFetchEventLiveQuery,
  useLazyGetElementsForEventQuery,
} = elementsApi;
