import { IStatsState } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBestLeague, IMostValuableTeam } from "./types";
import { statsApi } from "../api/stats";

const initialState: IStatsState = {
  bestClassicPrivateLeagues: [],
  mostValuableTeams: [],
};

const stats = createSlice({
  name: "stats",
  initialState,
  reducers: {
    fetchBestClassicPrivateLeagues: (
      state,
      action: PayloadAction<IBestLeague[]>
    ) => ({
      ...state,
      bestClassicPrivateLeagues: action.payload,
    }),
    fetchMostValuableTeams: (
      state,
      action: PayloadAction<IMostValuableTeam[]>
    ) => ({
      ...state,
      mostValuableTeams: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      statsApi.endpoints.fetchBestClassicPrivateLeagues.matchFulfilled,
      (state, action: PayloadAction<IBestLeague[]>) =>
        stats.caseReducers.fetchBestClassicPrivateLeagues(state, action)
    );
    builder.addMatcher(
      statsApi.endpoints.fetchMostValuableTeams.matchFulfilled,
      (state, action: PayloadAction<IMostValuableTeam[]>) =>
        stats.caseReducers.fetchMostValuableTeams(state, action)
    );
  },
});

export default stats.reducer;

export const { fetchBestClassicPrivateLeagues, fetchMostValuableTeams } =
  stats.actions;
