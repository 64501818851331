import styled from "styled-components";
import { IStyledRankInfo } from "./types";

export const StyledRankInfo = styled.div<IStyledRankInfo>`
  padding: ${({ theme }) => `${theme.space[4]} ${theme.space[2]}`};
  background: ${({ $isPrimary, theme }) =>
    $isPrimary
      ? `linear-gradient(to right, ${theme.colors.fantasy}, ${theme.colors.blueLight})`
      : theme.colors.purple5};

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.purple10};
  }
`;

export const RankInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[2]};
  padding: ${(props) => props.theme.space[2]};

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    gap: ${(props) => props.theme.space[3]};
    padding: ${(props) => props.theme.space[4]};
  }
`;

export const RankInfoHeading = styled.h2`
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: 2.5rem;
  line-height: 1.2;

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    font-size: ${(props) => props.theme.fontSizes[6]};
    line-height: inherit;
  }
`;

export const RankInfoDescription = styled.p`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    margin-right: calc(100% / 3);
  }
`;
