import { rgba } from "polished";
import styled from "styled-components";

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.heavy};
  font-size: ${({ theme }) => theme.fontSizes[4]};
  @media (max-width: ${({ theme }) => theme.breakpoints[3]}) {
    position: relative;
    ::before {
      content: "";
      position: absolute;
      height: 1px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background-image: linear-gradient(
        to right,
        ${rgba("white", 0)} 0%,
        #efefef 50%,
        ${rgba("white", 0)} 100%
      );
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: ${({ theme }) => theme.fontSizes[6]};
  }
`;

export default Title;
