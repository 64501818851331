import React from "react";
import { IEvent } from "../../rtk-core/src/features/events";
import SubNav from "../SubNav";

interface IFixturesNavProps {
  event: IEvent;
}

const FixturesNav: React.FC<IFixturesNavProps> = ({ event }) => {
  const links = [
    { href: `/fixtures/${event.id}`, text: "Fixtures" },
    { href: "/fixtures/fdr", text: "FDR" },
  ];
  return <SubNav links={links} />;
};

export default FixturesNav;
