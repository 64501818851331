import {
  EventStatChevron,
  EventStatHeading,
  EventStatLink,
  EventStatValue,
  StyledEventStat,
} from "./styles";
import { EventStatProps } from "./types";

const EventStat = ({ $isPrimary, heading, url, value }: EventStatProps) => (
  <StyledEventStat $isPrimary={$isPrimary}>
    <EventStatHeading>
      {url ? (
        <EventStatLink to={url}>
          {heading}
          <EventStatChevron />
        </EventStatLink>
      ) : (
        heading
      )}
    </EventStatHeading>
    <EventStatValue>{value}</EventStatValue>
  </StyledEventStat>
);

export default EventStat;
