import {
  IGetBootstrapDynamicResponse,
  IGetBootstrapStaticResponse,
} from "../bootstrap";
import { apiSlice } from "./apiSlice";

export const bootstrapApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBootstrapDynamic: builder.query<IGetBootstrapDynamicResponse, void>({
      query: () => ({
        url: "/me/",
        method: "GET",
      }),
    }),
    getBootstrapStatic: builder.query<IGetBootstrapStaticResponse, void>({
      query: () => ({
        url: "/bootstrap-static/",
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetBootstrapDynamicQuery, useGetBootstrapStaticQuery } =
  bootstrapApi;
