import Badge from "../components/Badge";
import EntryHistory from "../components/EntryHistory";
import EntryTeamUpdate from "../components/EntryProfile/EntryTeamUpdate";
import { GridItem, GridWrapper, SidebarGrid } from "../components/Layout";
import Panel from "../components/Panel";
import PlayerOverview from "../components/PlayerOverview";
import RanksExplained from "../components/RanksExplained";
import { useAppSelector } from "../rtk-core/src/app/hooks";
import { getEntry } from "../rtk-core/src/features/entries";
import { getPlayerData } from "../rtk-core/src/features/player";
import { getTeamsById } from "../rtk-core/src/features/teams";

const ManagerProfile = () => {
  const player = useAppSelector(getPlayerData);
  const teamsById = useAppSelector(getTeamsById);
  const entry = useAppSelector((state) =>
    player?.entry ? getEntry(state, player.entry) : null
  );

  if (!entry) {
    return null;
  }
  const faveTeam = entry.favourite_team
    ? teamsById[entry.favourite_team]
    : null;

  return (
    <GridWrapper>
      <SidebarGrid $hasMargin={true}>
        <GridItem $colSpan={1}>
          <PlayerOverview />
        </GridItem>
        {faveTeam && (
          <GridItem $colSpan={1}>
            <Panel
              title="Fan League"
              children={
                <Badge
                  team={faveTeam}
                  isPresentation={true}
                  sizes="(min-width: 135px) 135px"
                />
              }
              footer={{
                url: `/leagues/${faveTeam.id}/standings/c/1`,
                text: "View my fan league",
              }}
            />
          </GridItem>
        )}
        <GridItem $colSpan={1}>
          <RanksExplained />
        </GridItem>
        <GridItem />
        <GridItem $colSpan={1}>
          <EntryHistory />
        </GridItem>
        <GridItem />
        <GridItem $colSpan={1}>
          <EntryTeamUpdate entry={entry} />
        </GridItem>
      </SidebarGrid>
    </GridWrapper>
  );
};

export default ManagerProfile;
