import styled from "styled-components";

export const StyledNavigator = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavigatorHeader = styled.div`
  flex: 0 0 54%;
  /* Set order because we want screen readers to read 1) header, 2) previous, 3) next */
  order: 2;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex-basis: 72%;
  }
`;

export const NavigatorTitle = styled.h2`
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: ${(props) => props.theme.fontSizes[5]};
  line-height: 1;
`;

export const NavigatorSubheading = styled.div`
  font-size: 1rem;
  line-height: 1.6;
`;

const NavigatorButton = styled.div`
  flex: 0 0 23%;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex-basis: 14%;
  }
`;

export const NavigatorPrev = styled(NavigatorButton)`
  order: 1;
`;

export const NavigatorNext = styled(NavigatorButton)`
  order: 3;
`;

export const UnstyledAbbr = styled.abbr`
  text-decoration: none;

  &[title] {
    cursor: inherit;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    &:after {
      content: attr(title);
    }
    span {
      display: none;
    }
  }
`;
