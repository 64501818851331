import { size } from "polished";
import styled from "styled-components";
import Button from "../components/Button";
import Dialog from "../components/Dialog";
import DialogBody from "../components/DialogBody";
import DialogHeader from "../components/DialogHeader";
import { ArrowRight } from "../components/Icons";

export const EntryTeamUpdateDialogControls = styled.div`
  margin-top: ${({ theme }) => theme.space[5]};
`;

export const EntryTeamUpdateDialogArrow = styled(ArrowRight)`
  ${size("15px")};
  margin-left: 0.5rem;
`;

export const EntryTeamUpdateDialogCopy = styled.div`
  font-size: ${(props) => props.theme.fontSizes[3]};
`;

export interface IEntryTeamUpdateDialogProps {
  handleHide: () => void;
}

const EntryTeamUpdateDialog: React.FC<IEntryTeamUpdateDialogProps> = ({
  handleHide,
}) => {
  return (
    <Dialog closeDialog={handleHide}>
      <DialogHeader closeDialog={handleHide}>Change successful</DialogHeader>
      <DialogBody isPadded={true}>
        <EntryTeamUpdateDialogCopy>
          Your details have been changed successfully
        </EntryTeamUpdateDialogCopy>
        <EntryTeamUpdateDialogControls>
          <div className="mb-2">
            <Button
              $isFullWidth
              $stretch
              $variant="secondary"
              onClick={handleHide}
            >
              Back to manager profile
              <EntryTeamUpdateDialogArrow />
            </Button>
          </div>
        </EntryTeamUpdateDialogControls>
      </DialogBody>
    </Dialog>
  );
};

export default EntryTeamUpdateDialog;
