import styled from "styled-components";
import { IStyledResult } from "./types";

export const StyledResult = styled.span<IStyledResult>`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: ${({ $resultChar, theme }) =>
    theme.colors.result[$resultChar].bg};
  color: ${({ $resultChar, theme }) => theme.colors.result[$resultChar].color};
  font-family: ${({ theme }) => theme.fonts.heavy};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: 1;
`;
