import Copy from "../components/Copy";
import Dialog, { DialogBody } from "../components/Dialog";
import DialogHeader from "../components/DialogHeader";

export interface IManageTeamErrorModalProps {
  closeDialog: () => void;
  errorMessage: string;
}

export const ManageTeamErrorModal: React.FC<IManageTeamErrorModalProps> = ({
  closeDialog,
  errorMessage,
}) => {
  return (
    <Dialog closeDialog={closeDialog}>
      <DialogHeader closeDialog={closeDialog}>
        {/* for now static, if flexibility needed, can be added to context */}
        Cannot select player
      </DialogHeader>
      <DialogBody isPadded={true}>
        <Copy>{errorMessage}</Copy>
      </DialogBody>
    </Dialog>
  );
};

export default ManageTeamErrorModal;
