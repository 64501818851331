export const getSuffixFromId = (id: number, isHome: boolean) => {
  const neutralIds: number[] = [];
  return neutralIds.indexOf(id) > -1 ? "(N)" : isHome ? "(H)" : "(A)";
};

export const difficultyLabels: Record<number, string> = {
  1: "easy",
  2: "easy",
  3: "medium",
  4: "hard",
  5: "hard",
};
