import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { merge } from "lodash";
import { bootstrapApi } from "../api/bootstrap";
import { elementsApi } from "../api/elements";
import { eventsApi } from "../api/events";
import { IGetBootstrapStaticResponse } from "../bootstrap";
import {
  formatNameForSearching,
  IElement,
  IElementsForEvent,
} from "../elements";
import { IElementType } from "../elementTypes";
import { IBaseEvent, IEvent, IEventState, IEventStatusData } from "./types";

const initialState: IEventState = {
  activeEvent: null,
  byId: {},
  currentEventStatus: null,
};

const formatEvents = (events: IBaseEvent[]): IEvent[] => {
  return events.map((event: IBaseEvent) => {
    return {
      ...event,
      overrides: {
        ...event.overrides,
        element_types: {
          byId: event.overrides.element_types.length
            ? Object.fromEntries(
                event.overrides.element_types.map(
                  (elementType: IElementType) => [
                    elementType.id,
                    {
                      ...elementType,
                    },
                  ]
                )
              )
            : {},
        },
      },
    };
  });
};

const events = createSlice({
  name: "events",
  initialState,
  reducers: {
    addElementOverrides: (state, action: PayloadAction<IElementsForEvent>) => {
      if (action.payload.event_id in state.byId) {
        const eventState: IEvent = state.byId[action.payload.event_id];
        eventState.overrides.elements = {
          byId: Object.fromEntries(
            action.payload.data.map((element: IElement) => [
              element.id,
              {
                ...element,
                search_name: formatNameForSearching(element.web_name),
              },
            ])
          ),
        };
        state.byId[action.payload.event_id] = eventState;
      }
    },
    addEvents: (state, action: PayloadAction<IEvent[]>) => {
      action.payload.forEach((event: IEvent) => {
        if (event.id in state.byId) {
          state.byId[event.id] = {
            ...event,
            overrides: merge(state.byId[event.id].overrides, event.overrides),
          };
        } else {
          state.byId[event.id] = event;
        }
      });
    },
    fetchEventStatus: (state, action: PayloadAction<IEventStatusData>) => ({
      ...state,
      currentEventStatus: { ...action.payload },
    }),
    setActiveEvent: (state, action: PayloadAction<IEvent>) => ({
      ...state,
      activeEvent: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      bootstrapApi.endpoints.getBootstrapStatic.matchFulfilled,
      (state, action: PayloadAction<IGetBootstrapStaticResponse>) =>
        events.caseReducers.addEvents(state, {
          ...action,
          payload: formatEvents(action.payload.events),
        })
    );
    builder.addMatcher(
      elementsApi.endpoints.getElementsForEvent.matchFulfilled,
      (state, action: PayloadAction<IElementsForEvent>) =>
        events.caseReducers.addElementOverrides(state, action)
    );
    builder.addMatcher(
      eventsApi.endpoints.getEvents.matchFulfilled,
      (state, action: PayloadAction<IBaseEvent[]>) =>
        events.caseReducers.addEvents(state, {
          ...action,
          payload: formatEvents(action.payload),
        })
    );
    builder.addMatcher(
      eventsApi.endpoints.getEventStatus.matchFulfilled,
      (state, action: PayloadAction<IEventStatusData>) =>
        events.caseReducers.fetchEventStatus(state, {
          ...action,
          payload: action.payload,
        })
    );
  },
});

export const { addElementOverrides, addEvents, setActiveEvent } =
  events.actions;

export default events.reducer;
