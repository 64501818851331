import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { leaguesApi } from "../api/leagues";
import {
  ILeagueState,
  ILeagueAdmin,
  ILeagueCupStatus,
  IH2HStandingsAPIData,
  IRenewableLeague,
  JoinLeagueResponse,
  ILeagueCodeResponse,
  IFetchLeagueEntriesResponse,
  IFetchLeagueEntriesErrorResponse,
  ICheckLeagueCodeResponse,
  IFetchH2HLeagueMatchesResponse,
  IRenewRenewableLeagueResponse,
  IDeleteRenewableLeagueResponse,
  IDeleteLeagueResponse,
  IClassicLeagueStandingsResponse,
} from "./types";
import { getH2HMatchesKey } from "./selectors";
import { IError } from "../../app/types";

const initialState: ILeagueState = {
  adminById: {},
  byCode: {},
  classicStandingsById: {},
  codesByLeagueId: {},
  createClassicLeagueError: null,
  createH2HLeagueError: null,
  cupStatusById: {},
  entriesById: {},
  entriesErrorById: {},
  h2hMatchesById: {},
  h2hStandingsById: {},
  joinPrivateLeagueSuccess: null,
  joinPrivateLeagueError: null,
  joinPublicLeagueError: null,
  renewableById: {},
  updateClassicLeagueError: null,
  updateH2HLeagueError: null,
};

const leagues = createSlice({
  name: "leagues",
  initialState,
  reducers: {
    fetchClassicLeagueStandings: (
      state,
      action: PayloadAction<IClassicLeagueStandingsResponse>
    ) => {
      const { data, phaseId } = action.payload;
      const newData = {
        league: data.league,
        newEntriesByPage:
          state.classicStandingsById[data.league.id]?.newEntriesByPage || {},
        standingsByPhaseAndPage:
          state.classicStandingsById[data.league.id]?.standingsByPhaseAndPage ||
          {},
      };
      newData["newEntriesByPage"][data.new_entries.page] = data.new_entries;
      if (!newData["standingsByPhaseAndPage"][phaseId]) {
        newData["standingsByPhaseAndPage"][phaseId] = {};
      }
      newData["standingsByPhaseAndPage"][phaseId][data.standings.page] = {
        ...data.standings,
        lastUpdatedData: data.last_updated_data,
      };
      state.classicStandingsById[data.league.id] = newData;
    },
    deleteLeague: (state, action: PayloadAction<IDeleteLeagueResponse>) => {
      if (state.classicStandingsById[action.payload.league]) {
        delete state.classicStandingsById[action.payload.league];
      }
      if (state.adminById[action.payload.league]) {
        delete state.adminById[action.payload.league];
      }
      if (state.codesByLeagueId[action.payload.league]) {
        delete state.codesByLeagueId[action.payload.league];
      }
      if (state.h2hStandingsById[action.payload.league]) {
        delete state.h2hStandingsById[action.payload.league];
      }
    },
    fetchH2HLeagueMatches: (
      state,
      action: PayloadAction<IFetchH2HLeagueMatchesResponse>
    ) => {
      const { data, entryId, eventId, leagueId } = action.payload;
      const key = getH2HMatchesKey(entryId, eventId, data.page);
      const newData = {
        byEntryEventPage:
          state.h2hMatchesById[leagueId]?.byEntryEventPage || {},
      };
      newData["byEntryEventPage"][key] = data;
      state.h2hMatchesById[leagueId] = newData;
    },
    createClassicLeague: (state, action: PayloadAction<ILeagueAdmin>) => ({
      ...state,
      adminById: { ...state.adminById, [action.payload.id]: action.payload },
    }),
    fetchClassicLeagueForAdmin: (
      state,
      action: PayloadAction<ILeagueAdmin>
    ) => ({
      ...state,
      adminById: { ...state.adminById, [action.payload.id]: action.payload },
    }),
    updateClassicLeague: (state, action: PayloadAction<ILeagueAdmin>) => ({
      ...state,
      adminById: { ...state.adminById, [action.payload.id]: action.payload },
    }),
    createH2HLeague: (state, action: PayloadAction<ILeagueAdmin>) => ({
      ...state,
      adminById: { ...state.adminById, [action.payload.id]: action.payload },
    }),
    fetchH2HLeagueForAdmin: (state, action: PayloadAction<ILeagueAdmin>) => ({
      ...state,
      adminById: { ...state.adminById, [action.payload.id]: action.payload },
    }),
    updateH2HLeague: (state, action: PayloadAction<ILeagueAdmin>) => ({
      ...state,
      adminById: { ...state.adminById, [action.payload.id]: action.payload },
    }),
    checkLeagueCode: (
      state,
      action: PayloadAction<ICheckLeagueCodeResponse>
    ) => ({
      ...state,
      byCode: { ...state.byCode, [action.payload.code]: action.payload.data },
    }),
    fetchLeagueCode: (state, action: PayloadAction<ILeagueCodeResponse>) => ({
      ...state,
      codesByLeagueId: {
        ...state.codesByLeagueId,
        [action.payload.league]: action.payload.data.code,
      },
    }),
    regenerateLeagueCode: (
      state,
      action: PayloadAction<ILeagueCodeResponse>
    ) => ({
      ...state,
      codesByLeagueId: {
        ...state.codesByLeagueId,
        [action.payload.league]: action.payload.data.code,
      },
    }),
    createClassicLeagueError: (state, action: PayloadAction<IError>) => ({
      ...state,
      createClassicLeagueError: action.payload,
    }),
    createH2HLeagueError: (state, action: PayloadAction<IError>) => ({
      ...state,
      createH2HLeagueError: action.payload,
    }),
    fetchLeagueCupStatus: (state, action: PayloadAction<ILeagueCupStatus>) => ({
      ...state,
      cupStatusById: {
        ...state.cupStatusById,
        [action.payload.qualifying_league]: action.payload,
      },
    }),
    fetchH2HLeagueStandings: (
      state,
      action: PayloadAction<IH2HStandingsAPIData>
    ) => {
      const data = action.payload;
      const s = state.h2hStandingsById[data.league.id];
      return {
        ...state,
        h2hStandingsById: {
          ...state.h2hStandingsById,
          [data.league.id]: {
            league: data.league,
            newEntriesByPage: {
              ...(s ? s.newEntriesByPage : {}),
              [data.new_entries.page]: data.new_entries,
            },
            standingsByPage: {
              ...(s ? s.standingsByPage : {}),
              [data.standings.page]: data.standings,
            },
          },
        },
      };
    },
    fetchLeagueEntries: (
      state,
      action: PayloadAction<IFetchLeagueEntriesResponse>
    ) => ({
      ...state,
      entriesById: {
        ...state.entriesById,
        [action.payload.leagueId]: action.payload.data,
      },
    }),
    fetchLeagueEntriesError: (
      state,
      action: PayloadAction<IFetchLeagueEntriesErrorResponse>
    ) => {
      if (action.payload) {
        const match = action.payload.url.match(/^league\/(\d+)\/entries\/$/);
        if (match) {
          state.entriesErrorById[match[1]] = { ...action.payload.error };
        }
      }
    },
    joinPrivateLeagueSuccess: (
      state,
      action: PayloadAction<JoinLeagueResponse>
    ) => {
      state.joinPrivateLeagueSuccess = action.payload.data.id;
    },
    joinPrivateLeagueError: (state, action: PayloadAction<IError>) => {
      state.joinPrivateLeagueError = action.payload;
    },
    joinPublicLeagueError: (state, action: PayloadAction<IError>) => {
      state.joinPublicLeagueError = action.payload;
    },
    fetchRenewableLeagues: (
      state,
      action: PayloadAction<IRenewableLeague[]>
    ) => ({
      ...state,
      renewableById: Object.fromEntries(
        action.payload.map((rleague: IRenewableLeague) => [
          rleague.id,
          {
            ...rleague,
          },
        ])
      ),
    }),
    deleteRenewableLeague: (
      state,
      action: PayloadAction<IDeleteRenewableLeagueResponse>
    ) => {
      if (state.renewableById[action.payload.id]) {
        delete state.renewableById[action.payload.id];
      }
    },
    renewRenewableLeague: (
      state,
      action: PayloadAction<IRenewRenewableLeagueResponse>
    ) => {
      if (state.renewableById[action.payload.id]) {
        delete state.renewableById[action.payload.id];
      }
    },
    updateClassicLeagueError: (state, action: PayloadAction<IError>) => {
      state.updateClassicLeagueError = action.payload;
    },
    updateH2HLeagueError: (state, action: PayloadAction<IError>) => {
      state.updateH2HLeagueError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      leaguesApi.endpoints.createClassicLeague.matchFulfilled,
      (state, action: PayloadAction<ILeagueAdmin>) =>
        leagues.caseReducers.createClassicLeague(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.createClassicLeague.matchRejected,
      (state, action: PayloadAction<IError>) =>
        leagues.caseReducers.createClassicLeagueError(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.createH2HLeague.matchFulfilled,
      (state, action: PayloadAction<ILeagueAdmin>) =>
        leagues.caseReducers.createH2HLeague(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.createH2HLeague.matchRejected,
      (state, action: PayloadAction<IError>) =>
        leagues.caseReducers.createH2HLeagueError(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.fetchClassicLeagueForAdmin.matchFulfilled,
      (state, action: PayloadAction<ILeagueAdmin>) =>
        leagues.caseReducers.fetchClassicLeagueForAdmin(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.fetchH2HLeagueForAdmin.matchFulfilled,
      (state, action: PayloadAction<ILeagueAdmin>) =>
        leagues.caseReducers.fetchH2HLeagueForAdmin(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.updateClassicLeague.matchFulfilled,
      (state, action: PayloadAction<ILeagueAdmin>) =>
        leagues.caseReducers.updateClassicLeague(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.updateClassicLeague.matchRejected,
      (state, action: PayloadAction<IError>) =>
        leagues.caseReducers.updateClassicLeagueError(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.updateH2HLeague.matchFulfilled,
      (state, action: PayloadAction<ILeagueAdmin>) =>
        leagues.caseReducers.updateH2HLeague(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.updateH2HLeague.matchRejected,
      (state, action: PayloadAction<IError>) =>
        leagues.caseReducers.updateH2HLeagueError(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.joinPrivateLeague.matchFulfilled,
      (state, action: PayloadAction<JoinLeagueResponse>) =>
        leagues.caseReducers.joinPrivateLeagueSuccess(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.joinPrivateLeague.matchRejected,
      (state, action: PayloadAction<IError>) =>
        leagues.caseReducers.joinPrivateLeagueError(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.joinPublicLeague.matchRejected,
      (state, action: PayloadAction<IError>) =>
        leagues.caseReducers.joinPublicLeagueError(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.fetchLeagueCode.matchFulfilled,
      (state, action: PayloadAction<ILeagueCodeResponse>) =>
        leagues.caseReducers.fetchLeagueCode(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.regenerateLeagueCode.matchFulfilled,
      (state, action: PayloadAction<ILeagueCodeResponse>) =>
        leagues.caseReducers.regenerateLeagueCode(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.deleteLeague.matchFulfilled,
      (state, action: PayloadAction<IDeleteLeagueResponse>) =>
        leagues.caseReducers.deleteLeague(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.fetchRenewableLeagues.matchFulfilled,
      (state, action: PayloadAction<IRenewableLeague[]>) =>
        leagues.caseReducers.fetchRenewableLeagues(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.renewRenewableLeague.matchFulfilled,
      (state, action: PayloadAction<IRenewRenewableLeagueResponse>) =>
        leagues.caseReducers.renewRenewableLeague(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.deleteRenewableLeague.matchFulfilled,
      (state, action: PayloadAction<IDeleteRenewableLeagueResponse>) =>
        leagues.caseReducers.deleteRenewableLeague(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.fetchClassicLeagueStandings.matchFulfilled,
      (state, action: PayloadAction<IClassicLeagueStandingsResponse>) =>
        leagues.caseReducers.fetchClassicLeagueStandings(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.fetchH2HLeagueStandings.matchFulfilled,
      (state, action: PayloadAction<IH2HStandingsAPIData>) =>
        leagues.caseReducers.fetchH2HLeagueStandings(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.fetchH2HLeagueMatches.matchFulfilled,
      (state, action: PayloadAction<IFetchH2HLeagueMatchesResponse>) =>
        leagues.caseReducers.fetchH2HLeagueMatches(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.checkLeagueCode.matchFulfilled,
      (state, action: PayloadAction<ICheckLeagueCodeResponse>) =>
        leagues.caseReducers.checkLeagueCode(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.fetchLeagueEntries.matchFulfilled,
      (state, action: PayloadAction<IFetchLeagueEntriesResponse>) =>
        leagues.caseReducers.fetchLeagueEntries(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.fetchLeagueEntries.matchRejected,
      // Slight fudge here by defining any as cant define the type of
      // error currently in redux store
      // https://github.com/reduxjs/redux-toolkit/issues/1591
      // However, you can transform it, see api slice for error transformation
      (state, action: PayloadAction<any>) =>
        leagues.caseReducers.fetchLeagueEntriesError(state, action)
    );
    builder.addMatcher(
      leaguesApi.endpoints.fetchLeagueCupStatus.matchFulfilled,
      (state, action: PayloadAction<ILeagueCupStatus>) =>
        leagues.caseReducers.fetchLeagueCupStatus(state, action)
    );
  },
});

export const {
  fetchClassicLeagueStandings,
  deleteLeague,
  createClassicLeague,
  createH2HLeague,
  fetchClassicLeagueForAdmin,
  fetchH2HLeagueForAdmin,
  updateClassicLeague,
  updateH2HLeague,
  fetchLeagueCode,
  regenerateLeagueCode,
  createClassicLeagueError,
  createH2HLeagueError,
  updateClassicLeagueError,
  updateH2HLeagueError,
  joinPrivateLeagueSuccess,
  joinPrivateLeagueError,
  joinPublicLeagueError,
  fetchRenewableLeagues,
  renewRenewableLeague,
  deleteRenewableLeague,
  fetchH2HLeagueStandings,
  fetchH2HLeagueMatches,
  checkLeagueCode,
  fetchLeagueEntries,
  fetchLeagueEntriesError,
  fetchLeagueCupStatus,
} = leagues.actions;

export default leagues.reducer;
