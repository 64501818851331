import { ElementType, ElementTypeShort } from "./styles";
import { IElementTypeLabel } from "./types";

const ElementTypeLabel = ({ elementType }: IElementTypeLabel) => (
  <ElementType title={elementType.singular_name}>
    <ElementTypeShort>{elementType.singular_name_short}</ElementTypeShort>
  </ElementType>
);

export default ElementTypeLabel;
