import { EmblaCarouselType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";
import PrevIcon from "../../img/icons/arrow-left-white.svg?react";
import NextIcon from "../../img/icons/arrow-right-white.svg?react";
import EventCard from "../EventCard";
import { VisuallyHidden } from "../Utils";
import {
  CarouselContainer,
  CarouselSlide,
  NavButton,
  NavButtons,
} from "./styles";
import { IEventCarousel } from "./types";

const EventCarousel = ({ events }: IEventCarousel) => {
  const getInitialSlide = () => {
    const currentEventIndex = events.findIndex((e) => e.is_current);
    if (currentEventIndex === -1 || 0) {
      return 0;
    }

    if (
      events[currentEventIndex].finished &&
      currentEventIndex !== events.length - 1
    ) {
      return currentEventIndex + 1;
    }

    return currentEventIndex;
  };

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    startIndex: getInitialSlide(),
  });
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onSelect]);
  return (
    <div>
      <NavButtons>
        <NavButton onClick={scrollPrev} disabled={prevBtnDisabled}>
          <PrevIcon />
          <VisuallyHidden>Previous</VisuallyHidden>
        </NavButton>
        <NavButton onClick={scrollNext} disabled={nextBtnDisabled}>
          <VisuallyHidden>Next</VisuallyHidden>
          <NextIcon />
        </NavButton>
      </NavButtons>
      <div ref={emblaRef}>
        <CarouselContainer>
          {events.map((e) => (
            <CarouselSlide key={e.id}>
              <EventCard event={e} />
            </CarouselSlide>
          ))}
        </CarouselContainer>
      </div>
    </div>
  );
};

export default EventCarousel;
