import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { dreamTeamApi } from "../api/dreamTeam";
import {
  IDreamTeamData,
  IDreamTeamDataForEvent,
  IDreamTeamState,
} from "./types";

const initialState: IDreamTeamState = {
  overall: null,
  byEventId: {},
};

const dreamTeam = createSlice({
  name: "dreamTeam",
  initialState,
  reducers: {
    addByEventId: (state, action: PayloadAction<IDreamTeamDataForEvent>) => ({
      ...state,
      byEventId: {
        ...state.byEventId,
        [action.payload.event]: action.payload.data,
      },
    }),
    addDreamTeamOverall: (state, action: PayloadAction<IDreamTeamData>) => ({
      ...state,
      overall: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      dreamTeamApi.endpoints.fetchEventDreamTeam.matchFulfilled,
      (state, action: PayloadAction<IDreamTeamDataForEvent>) =>
        dreamTeam.caseReducers.addByEventId(state, action)
    );
    builder.addMatcher(
      dreamTeamApi.endpoints.fetchOverallDreamTeam.matchFulfilled,
      (state, action: PayloadAction<IDreamTeamData>) =>
        dreamTeam.caseReducers.addDreamTeamOverall(state, action)
    );
  },
});

export const { addByEventId, addDreamTeamOverall } = dreamTeam.actions;

export default dreamTeam.reducer;
