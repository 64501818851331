import { rgba } from "polished";
import styled from "styled-components";
import { IRankColumnBackground } from "./types";

export const ProgressWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;
  flex: 1;

  ${ProgressWrap} {
    &::before,
    &::after {
      content: "";
      width: 100%;
      border-bottom: 2px solid black;
    }
  }

  &:first-child {
    ${ProgressWrap} {
      &::before {
        content: "";
        border: none;
      }
    }
  }

  &:last-child {
    ${ProgressWrap} {
      &::after {
        content: "";
        border: none;
      }
    }
  }
`;

export const ColumnBackground = styled.div<IRankColumnBackground>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: ${(props) => `${10 + props.$rankIndex}rem`};
  padding: ${(props) => props.theme.space[1]};
  border-radius: ${(props) => props.theme.radii[2]};
  background-color: ${rgba("white", 0.7)};
`;

export const ColumnBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 250px;
  margin: ${(props) => `0 ${props.theme.space[2]}`};
  text-align: center;
`;

export const ColumnsWrapper = styled.div`
  display: flex;
  width: 800px;
  margin: 0 auto;
`;

export const EntryName = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[2]};
`;

export const ProgressInfo = styled.div`
  margin: ${(props) => `0 ${props.theme.space[1]}`};
  text-align: center;
`;

export const RankBadgeWrapper = styled.div`
  position: relative;
  top: 28px;
  align-self: center;
`;

export const RankDescription = styled.div`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: ${(props) => props.theme.fontSizes[0]};
`;

export const RankPercentage = styled.p`
  font-family: ${(props) => props.theme.fonts.heavy};
`;

export const StyledRankGraph = styled.div`
  overflow-x: scroll;
  // Padding added to prevent system-settings scroll bar from obscuring content.
  padding-bottom: ${(props) => props.theme.space[5]};
  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    padding-bottom: ${(props) => props.theme.space[3]};
  }
`;

export const StyledRankGraphImage = styled.div`
  text-align: center;
`;
