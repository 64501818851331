import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { bootstrapApi } from "../api/bootstrap";
import { myTeamApi } from "../api/myTeam";
import { IGetBootstrapStaticResponse } from "../bootstrap";
import { IElementType } from "../elementTypes";
import { IMyTeamResponseData } from "../myTeam";
import { IBaseChip, IChip, IChipState, IPotentialChip } from "./types";

const someActiveOrProposed = (chips: IPotentialChip[]) =>
  chips.some(
    (c) => c.status_for_entry === "active" || c.status_for_entry === "proposed"
  );

const initialState: IChipState = {
  byId: {},
  potential: [],
};

const formatChips = (chips: IBaseChip[]): IChip[] => {
  return chips.map((c: IBaseChip) => {
    return {
      ...c,
      overrides: {
        ...c.overrides,
        element_types: {
          byId: c.overrides.element_types.length
            ? Object.fromEntries(
                c.overrides.element_types.map((elementType: IElementType) => [
                  elementType.id,
                  {
                    ...elementType,
                  },
                ])
              )
            : {},
        },
      },
    };
  });
};

const chips = createSlice({
  name: "chips",
  initialState,
  reducers: {
    addChips: (state, action: PayloadAction<IChip[]>) => ({
      ...state,
      byId: Object.fromEntries(
        action.payload.map((chip: IChip) => [chip.id, chip])
      ),
    }),
    addPotentialChips: (state, action: PayloadAction<IPotentialChip[]>) => ({
      ...state,
      potential: action.payload,
    }),
    proposeAvailableChip: (state, action: PayloadAction<string>) => {
      if (!someActiveOrProposed(state.potential)) {
        state.potential = state.potential.map((c) => ({
          ...c,
          status_for_entry:
            c.name === action.payload &&
            c.status_for_entry.match(/^(available|unavailable)$/)
              ? "proposed"
              : c.status_for_entry,
        }));
      }
    },
    cancelProposedChip: (state, action: PayloadAction<string>) => {
      state.potential = state.potential.map((c) => ({
        ...c,
        status_for_entry:
          c.name === action.payload && c.status_for_entry === "proposed"
            ? "available"
            : c.status_for_entry,
      }));
    },
    cancelActiveChip: (state, action: PayloadAction<string>) => {
      state.potential = state.potential.map((c) => ({
        ...c,
        status_for_entry:
          c.name === action.payload &&
          c.status_for_entry === "active" &&
          c.chip_type === "team"
            ? "cancelled"
            : c.status_for_entry,
      }));
    },
    restoreCancelledChip: (state, action: PayloadAction<string>) => {
      if (!someActiveOrProposed(state.potential)) {
        state.potential = state.potential.map((c) => ({
          ...c,
          status_for_entry:
            c.name === action.payload && c.status_for_entry === "cancelled"
              ? "active"
              : c.status_for_entry,
        }));
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      bootstrapApi.endpoints.getBootstrapStatic.matchFulfilled,
      (state, action: PayloadAction<IGetBootstrapStaticResponse>) =>
        chips.caseReducers.addChips(state, {
          ...action,
          payload: formatChips(action.payload.chips),
        })
    );
    builder.addMatcher(
      myTeamApi.endpoints.fetchMyTeam.matchFulfilled,
      (state, action: PayloadAction<IMyTeamResponseData>) =>
        chips.caseReducers.addPotentialChips(state, {
          ...action,
          payload: action.payload.data.chips,
        })
    );
    builder.addMatcher(
      myTeamApi.endpoints.saveMyTeam.matchFulfilled,
      (state, action: PayloadAction<IMyTeamResponseData>) =>
        chips.caseReducers.addPotentialChips(state, {
          ...action,
          payload: action.payload.data.chips,
        })
    );
  },
});

export const {
  addChips,
  addPotentialChips,
  cancelActiveChip,
  cancelProposedChip,
  proposeAvailableChip,
  restoreCancelledChip,
} = chips.actions;

export default chips.reducer;
