import { createContext, useState } from "react";

export interface IWebViewContext {
  isWebView: boolean;
  setIsWebView: (value: boolean) => void;
}

const WebViewContext = createContext<IWebViewContext>({
  isWebView: false,
  setIsWebView: (value: boolean) => {},
});

export default WebViewContext;

interface IWebViewProviderProps {
  children: React.ReactNode;
}

export const WebViewProvider: React.FC<IWebViewProviderProps> = ({
  children,
}) => {
  const [isWebView, setIsWebView] = useState<boolean>(false);

  return (
    <WebViewContext.Provider
      value={{
        isWebView,
        setIsWebView,
      }}
    >
      {children}
    </WebViewContext.Provider>
  );
};
