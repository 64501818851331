import { useContext } from "react";
import { useSelector } from "react-redux";
import ManageTeamContext from "../../contexts/ManageTeamContext";
import { useAppSelector } from "../../rtk-core/src/app/hooks";
import { getEloData } from "../../rtk-core/src/features/entries";
import { getEntry } from "../../rtk-core/src/features/entries";
import { getActiveEvent } from "../../rtk-core/src/features/events";
import { getRules } from "../../rtk-core/src/features/game";
import {
  getPicksProposedByPosition,
  getPicksProposedWithElements,
  getToSpend,
} from "../../rtk-core/src/features/manage";
import { IPlayer, getPlayerData } from "../../rtk-core/src/features/player";
import { getChallengeAssets } from "../../utils/challenge";
import { integerToMoney } from "../../utils/money";
import { getEloRank, getRankFriendlyName } from "../../utils/ranks";
import ChallengeBlock from "../ChallengeBlock";
import RankBadge from "../RankBadge";
import ScoreboardStat from "./ScoreboardStat";
import {
  ChallengeDescription,
  ChallengeDetailWrap,
  ChallengeEventId,
  ChallengeHeading,
  ChallengeStats,
  ChallengeStatsBoard,
  ChallengedDescriptionWrap,
  EntryInfoHeader,
  EntryInfoPlayer,
  EntryInfoStats,
  EntryInfoWrapper,
  EntryScoreboardItem,
  EntryScoreboardWrap,
  EntryTeamName,
  GameChangerIcon,
  InfoBoard,
  PrimaryStat,
  PrimaryStatHeading,
  PrimaryStatValue,
  SecondaryEntryStats,
} from "./styles";
import { IEntryScoreboardProps } from "./types";

export const EntryScoreboard: React.FC<IEntryScoreboardProps> = ({
  entry,
  points,
  showChallengeStats = true,
}) => {
  const activeEvent = useAppSelector(getActiveEvent);
  const player = useSelector(getPlayerData) as IPlayer;
  const challenge = getChallengeAssets(activeEvent.id);
  const focusedEntry = useAppSelector((state) =>
    entry ? entry : player.entry ? getEntry(state, player.entry) : null
  );

  const eloData = useAppSelector((state) =>
    focusedEntry ? getEloData(state, focusedEntry.id, activeEvent.id) : null
  );

  const { createEntryData } = useContext(ManageTeamContext);

  const proposedPicksWithElements = useAppSelector(
    getPicksProposedWithElements
  );
  const proposedPicksByPosition = useAppSelector(getPicksProposedByPosition);
  const rules = useAppSelector((state) => getRules(state, activeEvent.id));
  const toSpend = useAppSelector(getToSpend);

  const currencyDivisor = 10;
  const playerName = entry
    ? `${entry.player_first_name} ${entry.player_last_name}`
    : `${player.first_name} ${player.last_name}`;

  const entryPoints =
    focusedEntry?.summary_event_points && activeEvent.finished
      ? focusedEntry.summary_event_points
      : points;

  const rankFriendlyName = eloData ? getRankFriendlyName(eloData) : "Unranked";
  const eloRank = eloData ? getEloRank(eloData) : "Unranked_5";

  if (!challenge) {
    return null;
  }

  return (
    <ChallengeBlock>
      <EntryScoreboardWrap>
        <EntryScoreboardItem>
          <EntryInfoWrapper>
            <RankBadge rank={eloRank} name={rankFriendlyName} size={55} />
            <div>
              <EntryTeamName>
                {/* if no focusedEntry exists we look at the createEntryData to get the name */}
                {focusedEntry ? focusedEntry.name : createEntryData.name}
              </EntryTeamName>
              {/* Entry Info Header */}
              <EntryInfoHeader>
                <EntryInfoPlayer>{playerName}</EntryInfoPlayer>
              </EntryInfoHeader>
            </div>
          </EntryInfoWrapper>
          {/* Entry Info Board */}
          <InfoBoard>
            <EntryInfoStats>
              <PrimaryStat>
                <PrimaryStatHeading>Total points</PrimaryStatHeading>
                <PrimaryStatValue>
                  {entryPoints !== null ? entryPoints : "-"}
                </PrimaryStatValue>
              </PrimaryStat>
              <SecondaryEntryStats>
                <ScoreboardStat
                  heading="Global rank"
                  value={
                    focusedEntry && focusedEntry.summary_event_rank
                      ? focusedEntry.summary_event_rank.toLocaleString()
                      : "-"
                  }
                />
                <ScoreboardStat
                  heading="Highest points"
                  value={
                    activeEvent.highest_score && activeEvent.highest_score
                      ? `${activeEvent.highest_score.toLocaleString()} pts`
                      : "-"
                  }
                />
              </SecondaryEntryStats>
            </EntryInfoStats>
          </InfoBoard>
        </EntryScoreboardItem>
        <EntryScoreboardItem>
          <ChallengeDetailWrap>
            <ChallengeEventId>{`${activeEvent.name} Challenge`}</ChallengeEventId>
            <ChallengeHeading>{challenge.copy.title}</ChallengeHeading>
            <ChallengedDescriptionWrap>
              <GameChangerIcon />
              <ChallengeDescription>
                {challenge.copy.description}
              </ChallengeDescription>
            </ChallengedDescriptionWrap>
          </ChallengeDetailWrap>
          {showChallengeStats && (
            <ChallengeStatsBoard>
              <ChallengeStats>
                <ScoreboardStat
                  heading="Players selected"
                  value={`${proposedPicksWithElements.length} / ${
                    Object.keys(proposedPicksByPosition).length
                  }`}
                />
                <ScoreboardStat
                  heading="Remaining budget"
                  value={
                    rules?.squad_total_spend === 9999
                      ? "Unlimited"
                      : `£${integerToMoney(toSpend, currencyDivisor)}m`
                  }
                />
              </ChallengeStats>
            </ChallengeStatsBoard>
          )}
        </EntryScoreboardItem>
      </EntryScoreboardWrap>
    </ChallengeBlock>
  );
};

export default EntryScoreboard;
