import { createSelector } from "reselect";
import { RootState } from "../../app/store";
import {
  getElementsEventDataById,
  getElementsWithUnstartedFixtures,
  getFormationFromPicks,
} from "../elements";
import { getActiveEvent, getEventsById } from "../events";
import { getRules } from "../game";
import { getTeamsById } from "../teams";
import { IPickLight } from "./types";

// if no eventId is passed to this selector by default it will get the
// entryId keyed by id of the activeEvent
export const getEntry = (
  state: RootState,
  entryId: number,
  eventId?: number
) => {
  if (eventId) {
    if (state.entries.entryIdByEventId[eventId]) {
      return state.entries.entryIdByEventId[eventId][entryId] || null;
    }
  } else {
    const activeEvent = getActiveEvent(state);
    if (activeEvent && state.entries.entryIdByEventId[activeEvent.id]) {
      return state.entries.entryIdByEventId[activeEvent.id][entryId] || null;
    }
  }

  return null;
};

export const getEloData = (
  state: RootState,
  entryId: number,
  eventId?: number
) => {
  const entry = getEntry(state, entryId, eventId);
  if (entry) {
    return {
      elo_badge: entry.elo_badge,
      elo_events_until_ranked: entry.elo_events_until_ranked,
    };
  }

  return null;
};

export const getActiveEntry = (state: RootState) => state.entries.activeEntry;

export const getEntryEventHistory = (state: RootState, entryId: number) =>
  state.entries.eventHistoryById[entryId] || [];

export const getEntryEventHistoryForEvent = (
  state: RootState,
  entryId: number,
  eventId: number
) => {
  const eventHistory = getEntryEventHistory(state, entryId);

  if (!eventHistory.length) {
    return null;
  }

  return eventHistory.find((eh) => eh.event === eventId) || null;
};

export const getEntrySeasonHistory = (state: RootState, entryId: number) =>
  state.entries.seasonHistoryById[entryId] || [];

export const getEntryChipHistory = (state: RootState, entryId: number) =>
  state.entries.chipHistoryById[entryId] || [];

export const getEntryEventPicks = (
  state: RootState,
  entryId: number,
  eventId: number
) => {
  if (
    !state.entries.eventPicksById[entryId] ||
    !state.entries.eventPicksById[entryId][eventId]
  ) {
    return null;
  }
  return state.entries.eventPicksById[entryId][eventId];
};

export const getPicksToPlay = (
  state: RootState,
  entryId: number,
  eventId: number
) => {
  const rules = getRules(state, eventId);
  const activeEvent = getActiveEvent(state);
  const entryEventData = getEntryEventPicks(state, entryId, eventId);
  const elementsAvailable = getElementsWithUnstartedFixtures(state, eventId);

  if (activeEvent.finished || !rules) {
    return 0;
  } else if (!activeEvent.is_current) {
    return rules.squad_squadplay;
  } else {
    return entryEventData
      ? entryEventData.picks.filter(
          (p) =>
            (p.element === 0 ||
              elementsAvailable.some((e) => e.id === p.element)) &&
            p.position <= rules.squad_squadplay
        ).length
      : 0;
  }
};

export const calculatePointsForPicks = (
  state: RootState,
  picks: IPickLight[],
  eventId: number
): number => {
  const elementsEventDataById = getElementsEventDataById(state, eventId);

  if (!picks || !elementsEventDataById) {
    return 0;
  }

  return picks.reduce((total, pick) => {
    const elementData = elementsEventDataById[pick.element];
    if (!elementData) {
      return total;
    }
    return total + elementData.stats.total_points * pick.multiplier;
  }, 0);
};

export const getEntryEventPoints = (
  state: RootState,
  entryId: number,
  eventId: number
) => {
  const events = getEventsById(state);
  const event = events[eventId];
  if (event.is_current && !event.finished) {
    const data = getEntryEventPicks(state, entryId, eventId);
    const picks = data ? data.picks : null;
    if (!picks) {
      return 0;
    }
    return calculatePointsForPicks(state, picks, eventId);
  }
  const entry = getEntry(state, entryId, eventId);
  if (entry) {
    return entry.summary_event_points;
  }
  const eventHistory = getEntryEventHistory(state, entryId);
  const entryHistoryForEvent = eventHistory.find((eh) => eh.event === eventId);
  return entryHistoryForEvent?.points || 0;
};

export const getEntryEventFormation = (
  state: RootState,
  entryId: number,
  eventId: number
) => {
  const data = getEntryEventPicks(state, entryId, eventId);
  const rules = getRules(state, eventId);
  if (!data || !rules) {
    return "";
  }
  return getFormationFromPicks(data.picks.slice(0, rules.squad_squadplay));
};

export const getPrivateClassicLeaguesForEntry = createSelector(
  [(state, entryId) => getEntry(state, entryId)],
  (entry) => {
    return entry
      ? entry.leagues.classic.filter((l) => l.league_type === "x")
      : [];
  }
);

export const getPrivateClassicCupLeaguesForEntry = createSelector(
  [(state, entryId) => getEntry(state, entryId)],
  (entry) => {
    return entry
      ? entry.leagues.classic.filter(
          (l) => l.league_type === "x" && l.has_cup === true
        )
      : [];
  }
);

export const getPrivateH2HLeaguesForEntry = createSelector(
  [(state, entryId) => getEntry(state, entryId)],
  (entry) => {
    return entry ? entry.leagues.h2h.filter((l) => l.league_type === "x") : [];
  }
);

export const getPublicClassicLeaguesForEntry = createSelector(
  [(state, entryId) => getEntry(state, entryId)],
  (entry) => {
    return entry
      ? entry.leagues.classic.filter((l) => l.league_type === "c")
      : [];
  }
);

export const getPublicClassicCupLeaguesForEntry = createSelector(
  [(state, entryId) => getEntry(state, entryId)],
  (entry) => {
    return entry
      ? entry.leagues.classic.filter(
          (l) => l.league_type === "c" && l.has_cup === true
        )
      : [];
  }
);

export const getPublicH2HLeaguesForEntry = createSelector(
  [(state, entryId) => getEntry(state, entryId)],
  (entry) => {
    return entry ? entry.leagues.h2h.filter((l) => l.league_type === "c") : [];
  }
);

export const getSystemClassicLeaguesForEntry = createSelector(
  [(state, entryId) => getEntry(state, entryId)],
  (entry) => {
    return entry
      ? entry.leagues.classic.filter((l) => l.league_type === "s")
      : [];
  }
);

export const getOverallLeagueEntryForEntry = createSelector(
  [(state, entryId) => getSystemClassicLeaguesForEntry(state, entryId)],
  (systemClassicLeagues) => {
    return systemClassicLeagues.find((l) => l.name === "Overall") || null;
  }
);

export const getSystemClassicCupLeaguesForEntry = createSelector(
  [(state, entryId) => getEntry(state, entryId)],
  (entry) => {
    return entry
      ? entry.leagues.classic.filter(
          (l) => l.league_type === "s" && l.has_cup === true
        )
      : [];
  }
);

export const getLeagueForEntry = (
  state: RootState,
  entryId: number,
  leagueId: number
) => {
  const entry = getEntry(state, entryId);
  if (entry) {
    const classic = entry.leagues.classic.filter((l) => l.id === leagueId);
    if (classic.length) {
      return classic[0];
    }
    const h2h = entry.leagues.h2h.filter((l) => l.id === leagueId);
    if (h2h.length) {
      return h2h[0];
    }
  }
  return null;
};

export const getCupMatchesByLeagueId = (state: RootState, entryId: number) => {
  const entry = getEntry(state, entryId);
  if (entry) {
    const cupMatches = entry.leagues.cup_matches;
    if (cupMatches.length > 0) {
      return cupMatches.reduce(
        (memo, match) => ({ ...memo, [match.league]: match }),
        {}
      );
    }
    return null;
  }
  return null;
};

export const getTransfersForEntry = (state: RootState, entryId: number) =>
  state.entries.transfersById[entryId] || [];

export const getFanLeagueDetails = (state: RootState, entryId: number) => {
  const fanLeagueMatch = /^team-(\d+)$/;
  const teamsById = getTeamsById(state);
  const systemClassicLeagues = getSystemClassicLeaguesForEntry(state, entryId);

  // Please note the "!" non-null assertion operator on the filter
  const fanLeagues = systemClassicLeagues.filter((l) =>
    l.short_name!.match(fanLeagueMatch)
  );

  if (fanLeagues.length) {
    return {
      league: fanLeagues[0],
      team: teamsById[fanLeagues[0].short_name!.match(fanLeagueMatch)![1]],
    };
  }

  return null;
};

export const getPhaseStandingsForEntry = (state: RootState, entryId: number) =>
  state.entries.phaseStandingsById[entryId];

export const getPhaseStandingsForEntryPhase = (
  state: RootState,
  entryId: number,
  phaseId: number
) => state.entries.phaseStandingsById[entryId]?.[phaseId];

export const getPhaseStandingsForEntryPhaseLeague = (
  state: RootState,
  entryId: number,
  phaseId: number,
  leagueId: number
) =>
  state.entries.phaseStandingsById[entryId]?.[phaseId]?.find(
    (ps) => ps.league_id === leagueId
  );
