import {
  ClosedEventCopy,
  ClosedEventHeading,
  StyledClosedEvent,
} from "./styles";
import IClosedEvent from "./types";

const ClosedEventHero: React.FC<IClosedEvent> = ({
  heading,
  subHeading,
  children,
}) => (
  <StyledClosedEvent>
    <div>
      <ClosedEventHeading>{heading}</ClosedEventHeading>
      <ClosedEventCopy>{subHeading}</ClosedEventCopy>
      {children}
    </div>
  </StyledClosedEvent>
);

export default ClosedEventHero;
