import { DividerLight } from "../../styles";
import EntryLeaguesList from "./EntryLeaguesList";
import { Wrapper } from "./styles";
import IEntryLeaguesProps from "./types";

const EntryLeagues: React.FC<IEntryLeaguesProps> = ({
  privateLeagues,
  systemLeagues,
}) => {
  return (
    <Wrapper>
      <EntryLeaguesList
        heading="Invitational Leagues"
        leagues={privateLeagues}
      />
      <div className="my-1">
        <DividerLight />
      </div>
      <EntryLeaguesList heading="Global Leagues" leagues={systemLeagues} />
    </Wrapper>
  );
};

export default EntryLeagues;
