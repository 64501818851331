import { format } from "date-fns";
import React from "react";
import { DeadlineHeading, DeadlineText, DeadlineTime } from "./styles";
import { FixtureDeadlineProps } from "./types";

const FixtureDeadline: React.FC<FixtureDeadlineProps> = ({
  deadlineText,
  deadlineTime,
  isNext = false,
}) => {
  const formattedTime = format(deadlineTime, "HH:mm");
  const currentTime = new Date();
  const isPassed = deadlineTime < currentTime;

  let headingText = deadlineText;
  if (isNext) {
    if (deadlineText === "Final Deadline") {
      headingText = "Final deadline";
    } else {
      headingText = "Next deadline";
    }
  } else if (deadlineText !== "Final Deadline") {
    headingText = "";
  }
  return (
    <DeadlineHeading
      $isPassed={deadlineText === "Final Deadline" ? false : isPassed}
    >
      <DeadlineTime $isNext={deadlineText === "Final Deadline" ? true : isNext}>
        {formattedTime}
      </DeadlineTime>
      <DeadlineText
        $isNext={deadlineText === "Final Deadline" && isPassed ? true : isNext}
      >
        {headingText}
      </DeadlineText>
    </DeadlineHeading>
  );
};

export default FixtureDeadline;
