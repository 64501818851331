import { size } from "polished";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import BaseExternalIcon from "../../img/icons/external.svg?react";
import BaseMoreIcon from "../../img/icons/more.svg?react";
import { INavLink } from "../Navigation";
import "./Dropdown.css";

const MoreButton = styled.button`
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 1.1rem;
  font-family: ${({ theme }) => theme.fonts.bold};
  line-height: 4rem;
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: 1.3rem;
    line-height: 4.8rem;
  }
`;

type Props = {
  text: string;
  links: INavLink[];
  isWebView: boolean;
};

const ExternalIcon = styled(BaseExternalIcon)`
  ${size(8)}
  margin-left: 0.4rem;
  fill: black;
`;

const MoreIcon = styled(BaseMoreIcon)`
  ${size(14)}
  margin-left: 0.4rem;
  fill: #fff;
`;

type HookProps = {
  event: string;
  handler: (e: any) => void;
};

const useDocumentEventHandler = ({ event, handler }: HookProps) => {
  React.useEffect(() => {
    const eventHandler = (e: Event) => {
      handler(e);
    };

    document.addEventListener(event, eventHandler);
    return () => {
      document.removeEventListener(event, eventHandler);
    };
  }, [event, handler]);
};

const Dropdown: React.FunctionComponent<Props> = ({
  text,
  links,
  isWebView,
}) => {
  const [open, setOpen] = React.useState(false);
  useDocumentEventHandler({ event: "click", handler: () => setOpen(false) });
  const toggleOpen = (e: React.SyntheticEvent) => {
    e.nativeEvent.stopImmediatePropagation();
    setOpen((open) => !open);
  };

  return (
    <span className="dropdown" aria-haspopup="true">
      <MoreButton
        aria-controls="ism-more-menu"
        aria-expanded={open}
        className="button"
        type="button"
        onClick={toggleOpen}
      >
        {text}
        <MoreIcon />
      </MoreButton>
      <ul id="ism-more-menu" className={`list ${open && "show"}`}>
        {links.map((link) => (
          <li
            key={link.text}
            className={
              link.text === "Sign Out" && isWebView ? "webview-hidden" : ""
            }
          >
            {link.useRouter ? (
              <NavLink to={link.href} className="list-item">
                {link.text}
              </NavLink>
            ) : (
              <a
                href={link.href}
                className="list-item"
                rel={link.rel}
                target={link.target}
              >
                {link.text}
                {link.indicateExternal && <ExternalIcon />}
              </a>
            )}
          </li>
        ))}
      </ul>
    </span>
  );
};

export default Dropdown;
