import React from "react";
import styled from "styled-components";
import { IEvent } from "../../rtk-core/src/features/events";
import { ITeam, ITeamsById } from "../../rtk-core/src/features/teams";
import { getEventShortName } from "../../utils/events";
import Badge from "../Badge";
import FDRCell from "./FDRCell";
import FDRHeadingCell from "./FDRHeadingCell";
import {
  COL_WIDTH_LG,
  COL_WIDTH_SM,
  FIRST_COL_WIDTH_LG,
  FIRST_COL_WIDTH_SM,
  WRAPPER_PADDING,
} from "./UseTableColumns";
import { IFixturesWithDifficultyByEventAndTeam } from "./types";

const CellWrapper = styled.div`
  height: 51px;
  display: flex;
  flex-direction: column;
`;

const StyledFDRTable = styled.table`
  max-width: 1180px;
  margin: 0 auto;

  table-layout: fixed;
  width: 100%;
  white-space: nowrap;

  th,
  td {
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
  }

  th,
  td {
    &:first-child {
      margin-left: 0;
      width: ${FIRST_COL_WIDTH_SM}px;

      @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
        width: ${FIRST_COL_WIDTH_LG}px;
      }
    }

    margin-left: ${WRAPPER_PADDING}px;
    width: ${COL_WIDTH_SM}px;

    @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
      width: ${COL_WIDTH_LG}px;
    }
  }

  td {
    padding-top: 0.1rem;
    padding-bottom: 0.4rem;

    &:first-child {
      position: relative;

      ::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.lightGrey};
      }
    }
  }
`;

interface IColTh {
  first?: boolean;
}

const ColTh = styled.th<IColTh>``;

const TeamCell = styled.div`
  display: flex;
  align-items: center;
  padding: 1.3rem 1.5rem;
`;

const TeamCellName = styled.div`
  margin-left: 5px;
  font-size: 11px;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: 14px;
  }
`;

type Props = {
  teamFixtures: IFixturesWithDifficultyByEventAndTeam;
  currentEventIndex: number;
  events: IEvent[];
  teamsById: ITeamsById;
  teams: ITeam[];
  activeHeadingIndex: number;
  onHeadingSort: (eventId: number, sortType: string) => void;
};

const FDRTable: React.FC<Props> = ({
  teamFixtures,
  events,
  currentEventIndex,
  onHeadingSort,
  activeHeadingIndex,
  teams,
  teamsById,
}) => {
  const upcomingGameweeksToShow = 6;

  const eventsToShow = events.slice(
    currentEventIndex - 1,
    currentEventIndex + upcomingGameweeksToShow
  );

  if (teamFixtures == null || eventsToShow.length === 0) {
    return <div>No teams or events to show</div>;
  }

  return (
    <StyledFDRTable data-testid="table">
      <thead data-testid="table-head">
        <tr>
          <ColTh first data-testid="th-team">
            <FDRHeadingCell
              id={0}
              activeEventId={activeHeadingIndex}
              eventName="Team"
              onHandleClick={onHeadingSort}
            />
          </ColTh>
          {eventsToShow.map((event) => {
            return (
              <ColTh data-testid="th-event" key={event.id}>
                <div>
                  <FDRHeadingCell
                    id={event.id}
                    activeEventId={activeHeadingIndex}
                    eventName={getEventShortName(event.name)}
                    eventDeadline={event.deadline_time}
                    onHandleClick={onHeadingSort}
                  />
                </div>
              </ColTh>
            );
          })}
        </tr>
      </thead>
      <tbody data-testid="table-body">
        {teams.map((team) => (
          <tr key={team.id} data-testid="team-row">
            <td data-testid="td-team">
              <TeamCell>
                <Badge
                  team={team}
                  isPresentation={true}
                  sizes="(min-width: 1024px) 24px, 16px"
                />
                <TeamCellName>{team.name}</TeamCellName>
              </TeamCell>
            </td>
            {eventsToShow.map((event) => (
              <td key={`fixture-${event.id}`} data-testid="fdr-cell">
                <CellWrapper>
                  {teamFixtures[event.id] &&
                  teamFixtures[event.id][team.id] &&
                  teamFixtures[event.id][team.id].fixtures.length ? (
                    <>
                      {teamFixtures[event.id][team.id].fixtures.map(
                        (fixture) => (
                          <FDRCell
                            key={fixture.id}
                            isHome={fixture.isHome}
                            teamName={teamsById[fixture.opponent].short_name}
                            difficulty={fixture.difficulty}
                          />
                        )
                      )}
                    </>
                  ) : (
                    <FDRCell teamName="" difficulty={3} />
                  )}
                </CellWrapper>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledFDRTable>
  );
};

export default FDRTable;
