import * as React from "react";
import styled, { css } from "styled-components";

export type buttonVariants =
  | "primary"
  | "secondary"
  | "tertiary"
  | "dark"
  | "clear";

export interface IButtonStyledProps {
  $isFullWidth?: boolean;
  $stretch?: boolean;
  $variant?: buttonVariants;
}

export const SharedButtonStyles = css<IButtonStyledProps>`
  display: inline-flex;
  justify-content: ${({ $stretch = false }) =>
    $stretch ? "space-between" : "center"};
  align-items: center;
  gap: 0.6rem;
  padding: ${({ theme }) => `${theme.space[2]} ${theme.space[3]}`};
  border: 0;
  border-radius: ${(props) => props.theme.radii[1]};
  background: ${({ $variant = "", theme }) =>
    $variant === "clear"
      ? "transparent"
      : `linear-gradient(
    to right,
    ${theme.colors.fantasy},
    ${theme.colors.blueLight}
  )`};
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[2]};

  > svg {
    display: inline-block;
    vertical-align: middle;
    fill: currentColor;
  }

  ${({ $variant = "", theme }) =>
    $variant === "secondary" &&
    css`
      background: ${theme.colors.purpleLight};
      color: white;
    `}

  ${({ $variant = "", theme }) =>
    $variant === "tertiary" &&
    css`
      background: #fff;
      /* Use box-shadow instead of border to maintain consistent box height */
      box-shadow: inset 0 0 0 1px ${theme.colors.purple10};
    `}

    ${({ $variant = "", theme }) =>
    $variant === "dark" &&
    css`
      background: ${theme.colors.purpleDark};
      color: #fff;
    `}

    ${({ $variant = "" }) =>
    $variant === "clear" &&
    css`
      background: transparent;
    `}

    ${({ $isFullWidth = false }) =>
    $isFullWidth &&
    css`
      width: 100%;
    `}
`;

export const StyledButton = styled.button<IButtonStyledProps>`
  ${SharedButtonStyles}
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.3;
      cursor: auto;
    `}
`;

interface IProps {
  children: string | React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  width?: number;
}

type ButtonProps = IButtonStyledProps & IProps;

const Button: React.FC<ButtonProps> = ({
  children,
  $variant = "primary",
  type = "button",
  $isFullWidth,
  ...rest
}) => (
  <StyledButton
    type={type}
    $variant={$variant}
    $isFullWidth={$isFullWidth}
    {...rest}
  >
    {children}
  </StyledButton>
);

export default Button;
