import React from "react";
import styled from "styled-components";
import { NewsLink, NewsSubtitle, NewsTitle } from "./styles";

const StyledNewsItem = styled.div`
  display: flex;
  align-items: center;
`;

const NewsItemImg = styled.div`
  flex: 0 0 104px;
  position: relative;
  overflow: hidden;
`;

const NewsItemBody = styled.div`
  padding: 0 ${({ theme }) => theme.space[2]};
`;

interface IProps {
  href: string;
  img: React.ReactNode;
  subtitle: string | null;
  title: string;
}

const NewsItem: React.FC<IProps> = ({ href, img, subtitle, title }) => (
  <NewsLink href={href}>
    <StyledNewsItem>
      <NewsItemImg>{img}</NewsItemImg>
      <NewsItemBody>
        <div className="mb-1">
          <NewsSubtitle>{subtitle}</NewsSubtitle>
        </div>
        <NewsTitle>{title}</NewsTitle>
      </NewsItemBody>
    </StyledNewsItem>
  </NewsLink>
);

export default NewsItem;
