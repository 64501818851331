import { size } from "polished";
import styled from "styled-components";
import ArrowLeftIcon from "../../img/icons/arrow-left.svg?react";
import ArrowRightIcon from "../../img/icons/arrow-right.svg?react";

interface IArrow {
  color?: "primary" | "darkPink" | "white";
}

export const ArrowLeft = styled(ArrowLeftIcon)<IArrow>`
  fill: ${(props) =>
    props.color ? props.theme.colors[props.color] : "currentColor"};
`;

export const ArrowRight = styled(ArrowRightIcon)<IArrow>`
  fill: ${(props) =>
    props.color ? props.theme.colors[props.color] : "currentColor"};
`;

export const ControlArrowRight = styled(ArrowRight)`
  ${size("10px")};
  margin-left: 0.5rem;
`;

export const ControlArrowLeft = styled(ArrowLeft)`
  ${size("10px")};
  margin-right: 0.5rem;
`;
