import { createSlice } from "@reduxjs/toolkit";
import { doBootstrap, doEntryPerEventBootstrap } from "./thunks";
import { IBootstrapState } from "./types";

export const timeNow = () => Math.round(new Date().getTime() / 1000);

const initialState: IBootstrapState = {
  bootstrap: {
    loading: false,
    times: {
      browser: null,
      server: null,
    },
  },
  entryEventBootstrap: {
    loading: false,
    times: {
      browser: null,
      server: null,
    },
  },
};

const bootstrap = createSlice({
  name: "bootstrap",
  initialState,
  reducers: {
    bootstrapFail: (state) => ({
      ...state,
      bootstrap: {
        ...state.bootstrap,
        loading: false,
      },
    }),
    bootstrapPending: (state) => ({
      ...state,
      bootstrap: {
        ...state.bootstrap,
        loading: true,
      },
    }),
    bootstrapSuccess: (state) => ({
      ...state,
      bootstrap: {
        ...state.bootstrap,
        loading: false,
        times: {
          browser: timeNow(),
          server: null,
        },
      },
    }),
    entryEventBootstrapFail: (state) => ({
      ...state,
      entryEventBootstrap: {
        ...state.entryEventBootstrap,
        loading: false,
      },
    }),
    entryEventBootstrapPending: (state) => ({
      ...state,
      entryEventBootstrap: {
        ...state.entryEventBootstrap,
        loading: true,
      },
    }),
    entryEventBootstrapSuccess: (state) => ({
      ...state,
      entryEventBootstrap: {
        ...state.entryEventBootstrap,
        loading: false,
        times: {
          browser: timeNow(),
          server: null,
        },
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(doBootstrap.rejected, (state) =>
      bootstrap.caseReducers.bootstrapFail(state)
    );
    builder.addCase(doBootstrap.pending, (state) =>
      bootstrap.caseReducers.bootstrapPending(state)
    );
    builder.addCase(doBootstrap.fulfilled, (state) =>
      bootstrap.caseReducers.bootstrapSuccess(state)
    );
    builder.addCase(doEntryPerEventBootstrap.rejected, (state) =>
      bootstrap.caseReducers.entryEventBootstrapFail(state)
    );
    builder.addCase(doEntryPerEventBootstrap.pending, (state) =>
      bootstrap.caseReducers.entryEventBootstrapPending(state)
    );
    builder.addCase(doEntryPerEventBootstrap.fulfilled, (state) =>
      bootstrap.caseReducers.entryEventBootstrapSuccess(state)
    );
  },
});

export const {
  bootstrapFail,
  bootstrapPending,
  bootstrapSuccess,
  entryEventBootstrapFail,
  entryEventBootstrapPending,
  entryEventBootstrapSuccess,
} = bootstrap.actions;

export default bootstrap.reducer;
