import styled from "styled-components";

export const ChallengeEvent = styled.div`
  margin-bottom: ${(props) => props.theme.space[1]};
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[3]};
`;

export const ChallengeName = styled.div`
  margin-bottom: ${(props) => props.theme.space[3]};
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: ${(props) => props.theme.fontSizes[6]};
  line-height: 1;
`;
