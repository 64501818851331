import { range, values, groupBy, orderBy } from "lodash";
import { createSelector } from "reselect";
import { RootState } from "../../app/store";
import { getProposedChipName, getActiveChipName } from "../chips";
import { getElementsById } from "../elements";
import { IElementsById } from "../elements";
import { getElementTypes } from "../elementTypes";
import { getRules } from "../game";
import { IPick } from "../myTeam";
import { getTeams } from "../teams";
import {
  SquadMode,
  IProposedElements,
  IProposedElementsById,
  ITransfersData,
  ISquadErrors,
} from "./types";

export const getProposedPicks = (state: RootState) => state.squad.proposed;

export const getSavedPicks = (state: RootState) => state.squad.saved;

export const getLastChange = (state: RootState) => state.squad.lastChange;

export const getTransferState = (state: RootState) => state.squad.transferState;

export const getLatestTransfers = (state: RootState) =>
  state.squad.latestTransfers;

export const getSquadError = (state: RootState) => state.squad.error;

export const getSquadMode = createSelector(
  getSavedPicks,
  (picks): SquadMode => (Object.keys(picks).length ? "transfers" : "selection")
);

export const getProposedElements = createSelector(
  getProposedPicks,
  getRules,
  getElementsById,
  (picks, rules, elementsById) => {
    const data: IProposedElements = {};
    if (rules) {
      range(1, rules.squad_squadsize + 1).forEach((id) => {
        if (picks[id as keyof typeof picks]) {
          data[id] = elementsById[picks[id as keyof typeof picks]];
        }
      });
    }
    return data;
  }
);

export const getProposedElementsById = createSelector(
  getProposedElements,
  (elements) =>
    Object.keys(elements).reduce<IProposedElementsById>((obj, pos) => {
      obj[elements[pos].id] = {
        ...elements[pos],
        position: parseInt(pos, 10),
      };
      return obj;
    }, {})
);

// Returns removed, original or replaced for each squad position
export const getTransferPositionStatus = createSelector(
  getSavedPicks,
  getProposedPicks,
  (saved, proposed) =>
    Object.keys(saved).reduce<
      Record<string, "removed" | "original" | "replaced">
    >(
      (memo, pos) => ({
        ...memo,
        [pos]: !proposed[pos as keyof typeof proposed]
          ? "removed"
          : saved[pos].element === proposed[pos as keyof typeof proposed]
          ? "original"
          : "replaced",
      }),
      {}
    )
);

export const getToSpend = createSelector(
  getSquadMode,
  getProposedElements,
  getSavedPicks,
  getTransferPositionStatus,
  getRules,
  getTransferState,
  getProposedChipName,
  getActiveChipName,
  (
    mode,
    proposed,
    saved,
    posStatus,
    rules,
    transferState,
    proposedChip,
    activeChip
  ) => {
    if (rules) {
      if (mode === "selection") {
        return values(proposed).reduce(
          (toSpend, e) => toSpend - e.now_cost,
          rules.squad_total_spend
        );
      } else if (mode === "transfers" && transferState !== null) {
        if (proposedChip === "rich" || activeChip === "rich") {
          return 1000000;
        }

        return Object.keys(saved).reduce((toSpend, pos) => {
          if (posStatus[pos] === "removed") {
            toSpend += saved[pos].selling_price;
          } else if (posStatus[pos] === "replaced") {
            toSpend += saved[pos].selling_price - proposed[pos].now_cost;
          }
          return toSpend;
        }, transferState.bank);
      }
    }
    return 0;
  }
);

export const getTeamLimit = createSelector(
  getRules,
  getProposedChipName,
  getActiveChipName,
  (rules, proposedChip, activeChip) => {
    if (rules) {
      return proposedChip === "uteam" || activeChip === "uteam"
        ? rules.squad_squadsize
        : rules.squad_team_limit;
    }
    return 0; // Only temporary (if at all) whilst rules are loaded
  }
);

export const getTypesNeeded = createSelector(
  getProposedElements,
  getElementTypes,
  (elements, types) => {
    let needed: { [id: string]: number } = {};
    needed = types.reduce(
      (memo, et) => ({ ...memo, [et.id]: et.squad_select }),
      {}
    );
    values(elements).forEach((e) => {
      needed[e.element_type]--;
    });
    return needed;
  }
);

export const getCountByTeam = createSelector(
  getProposedElements,
  getTeams,
  (elements, teams) => {
    const countByTeam: { [id: string]: number } = teams.reduce(
      (memo, t) => ({ ...memo, [t.id]: 0 }),
      {}
    );
    const elementsByTeam = groupBy(elements, "team");
    Object.keys(elementsByTeam).forEach((teamId) => {
      countByTeam[teamId] = elementsByTeam[teamId].length;
    });
    return countByTeam;
  }
);

export const getProposedTransfers = createSelector(
  getProposedElements,
  getSavedPicks,
  (elements, picks) => {
    const data: Array<ITransfersData> = [];
    Object.keys(picks).forEach((i) => {
      if (elements[i] && elements[i].id !== picks[i].element) {
        data.push({
          element_in: elements[i].id,
          element_out: picks[i].element,
          purchase_price: elements[i].now_cost,
          selling_price: picks[i].selling_price,
        });
      }
    });
    return data;
  }
);

export const getErrors = createSelector(
  getProposedElements,
  getRules,
  getToSpend,
  getTeamLimit,
  getCountByTeam,
  getProposedTransfers,
  getSquadMode,
  (elements, rules, toSpend, teamLimit, countByTeam, transfers, mode) => {
    const errors: ISquadErrors = {};
    if (!rules) {
      return errors;
    }
    const needed = rules.squad_squadsize;

    if (toSpend < 0) {
      errors.budgetExceeded = -toSpend;
    }

    const selected = Object.keys(elements).length;
    if (selected !== needed) {
      errors.needElements = needed - selected;
    }

    Object.keys(countByTeam).forEach((teamId) => {
      if (countByTeam[teamId] > teamLimit) {
        if (!errors.overTeamLimit) {
          errors.overTeamLimit = [];
        }
        errors.overTeamLimit.push(parseInt(teamId, 10));
      }
    });

    if (mode === "transfers") {
      if (!transfers.length) {
        errors.noTransfersMade = true;
      } else {
        delete errors.noTransfersMade;
      }
    }

    return errors;
  }
);

export const toCreateEntryAPI = createSelector(
  getProposedElements,
  (elements) =>
    values(elements).map((e) => ({
      element: e.id,
      purchase_price: e.now_cost,
    }))
);

export const getNumberOfTransferChanges = createSelector(
  getTransferPositionStatus,
  (positionStatus) =>
    values(positionStatus).reduce(
      (count, s) => (s === "original" ? count : count + 1),
      0
    )
);

export const getFreeTransfers = createSelector(
  getTransferState,
  (transferState) => {
    if (!transferState || transferState.limit === null) {
      return 0;
    }
    return Math.max(transferState.limit - transferState.made, 0);
  }
);

export const getFreeTransfersRemaining = createSelector(
  getFreeTransfers,
  getNumberOfTransferChanges,
  (free, changes) => Math.max(free - changes, 0)
);

export const getTransferCostsWithoutChip = createSelector(
  getTransferState,
  getNumberOfTransferChanges,
  (transferState, changes) => {
    if (!transferState || transferState.limit === null) {
      return 0;
    }
    const overLimit = -(
      Math.max(transferState.limit - transferState.made, 0) - changes
    );
    return overLimit > 0 ? overLimit * transferState.cost : 0;
  }
);

export const getTransferCosts = createSelector(
  getTransferCostsWithoutChip,
  getActiveChipName,
  getProposedChipName,
  (cost, activeChip, proposedChip) => {
    const freeChips = ["wildcard", "freehit", "rich", "uteam"];
    if (activeChip && freeChips.indexOf(activeChip) > -1) {
      return 0;
    }
    if (proposedChip && freeChips.indexOf(proposedChip) > -1) {
      return 0;
    }
    return cost;
  }
);

export const canAutocomplete = createSelector(getErrors, (errors) =>
  Boolean(
    errors.needElements && !errors.budgetExceeded && !errors.overTeamLimit
  )
);

export const canReset = createSelector(
  getSquadMode,
  getProposedElements,
  getNumberOfTransferChanges,
  (mode, proposed, changes) =>
    Boolean(mode === "selection" ? Object.keys(proposed).length : changes)
);

export const picksInSquadOrder = (
  picks: IPick[],
  elementsById: IElementsById
) =>
  orderBy(picks, [
    (p) => elementsById[p.element].element_type,
    (p) => p.element,
  ]);
