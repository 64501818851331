import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface IStyledPitch {
  $pitch: string;
}

const StyledPitch = styled.div<IStyledPitch>`
  border-radius: ${(props) => props.theme.radii[2]};
  background: url(${(props) => props.$pitch}) no-repeat center top;
  background-size: 750px;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    background-size: 840px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding-top: ${(props) => props.theme.space[3]};
    background-size: 1360px;
  }
`;

// have to prettier ignore because it messes with the min-width and max-width media query
// prettier-ignore
export const PitchTeam = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[3]};
  padding-bottom: ${(props) => props.theme.space[3]};

  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    margin-right: 5%;
    margin-left: 5%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    margin-right: 10%;
    margin-left: 10%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    margin-right: 15%;
    margin-left: 15%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    margin-right: 20%;
    margin-left: 20%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    gap: ${(props) => props.theme.space[8]};
    margin-right: 0;
    margin-left: 0;
    padding-bottom: ${(props) => props.theme.space[8]};
  }
`;

export const PitchRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const PitchUnit = styled.div`
  min-height: 8.6rem;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    min-height: 14.1rem;
  }
`;

interface IProps {
  children: React.ReactNode;
  sponsor: string;
}

const Pitch: React.FC<IProps> = ({ children, sponsor }) => {
  let [pitchSrc, setPitchSrc] = useState("");

  useEffect(() => {
    import(`../../img/pitches/pitch-${sponsor}.svg`).then((src) => {
      setPitchSrc(src.default);
    });
  }, [sponsor]);

  return (
    <StyledPitch $pitch={pitchSrc} data-testid="pitch">
      {children}
    </StyledPitch>
  );
};

export default Pitch;
