import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import EntryTeamUpdateDialog from "../../../dialogs/EntryTeamUpdateDialog";
import useFormHook from "../../../rtk-core/hooks/useFormHook";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../rtk-core/src/app/hooks";
import { IEntry, updateEntry } from "../../../rtk-core/src/features/entries";
import { getEvents } from "../../../rtk-core/src/features/events";
import { getTeams } from "../../../rtk-core/src/features/teams";
import { formatRawAsLocal } from "../../../utils/datetime";
import Button from "../../Button";
import Copy from "../../Copy";
import { FieldWrap, InputField, SelectField } from "../../FieldRenderers";
import { FormBackground } from "../../FormBackground";
import SubHeading from "../../SubHeading";
import Title from "../../Title";
import { ButtonWrap } from "./styles";
import { FormState, Props } from "./types";

export const initState = (entry: IEntry): FormState => ({
  "ism-team-name": entry.name,
  "ism-fav-club": entry.favourite_team || -1,
  "ism-email": false,
});

const EntryTeamUpdate = ({ entry }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formState, updateState] = useFormHook<FormState>(initState(entry));
  const [favouriteTeam, setFaveTeam] = useState(
    entry.favourite_team === null ? -1 : entry.favourite_team
  );
  const [showUpdateDialog, setShowUpdateDialog] = useState<boolean>(false);
  const events = useAppSelector(getEvents);
  const teams = useAppSelector(getTeams);

  const handleFaveTeamChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFaveTeam(Number(e.target.value));
  };

  const handleDisabled = !(
    formState["ism-team-name"] !== entry.name ||
    favouriteTeam !== entry.favourite_team
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      updateEntry({
        name: formState["ism-team-name"],
        favourite_team: favouriteTeam === -1 ? null : favouriteTeam,
        email: formState["ism-email"],
      })
    )
      .then(() => navigate("/profile", { replace: true }))
      .then(() => setShowUpdateDialog(true));
  };

  return (
    <div>
      {showUpdateDialog && (
        <EntryTeamUpdateDialog handleHide={() => setShowUpdateDialog(false)} />
      )}
      <FormBackground>
        <form onSubmit={handleSubmit}>
          <Copy>
            <Title>My Details</Title>
            <SubHeading>Team Details</SubHeading>
            <FieldWrap>
              <InputField
                id="ism-team-name"
                label="Team Name"
                onChange={updateState}
                hint="Maximum 20 characters"
                value={formState["ism-team-name"]}
                maxLength={20}
                disabled={entry.name_change_blocked}
              />
            </FieldWrap>

            <p>
              {entry.name_change_blocked
                ? "Your requested team name is currently pending moderation and can't be edited at this time."
                : "Please think carefully before entering your team name. Teams with names that are deemed inappropriate or offensive may result in your account being deleted. Please refer to the Terms & Conditions of entry for more information."}
            </p>
            <SubHeading>Favourite Club</SubHeading>
            <p>
              Choose your favourite Premier League club if you would like to be
              entered into a supporters league. If you do not select an option
              before {formatRawAsLocal(events[0].deadline_time)}, you will not
              be entered into a supporters league.
            </p>
            <FieldWrap>
              <SelectField
                id="ism-fav-club"
                label="Favourite club"
                onChange={handleFaveTeamChange}
                value={Number(favouriteTeam)}
              >
                {teams.map((tm) => (
                  <option
                    value={tm.id}
                    key={tm.id}
                    aria-selected={Number(favouriteTeam) === tm.id}
                  >
                    {tm.name}
                  </option>
                ))}
                <option value={-1} aria-selected={favouriteTeam === -1}>
                  None
                </option>
              </SelectField>
            </FieldWrap>
            <ButtonWrap>
              <Button $isFullWidth disabled={handleDisabled} type="submit">
                Update Details
              </Button>
            </ButtonWrap>
          </Copy>
        </form>
      </FormBackground>
    </div>
  );
};
export default EntryTeamUpdate;

export { EntryTeamUpdate as EntryUpdateTest };
