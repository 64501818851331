import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import moment from "moment";
import "moment-timezone";

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatDateObjAsDate = (dt: Date, format: string = "d MMM") =>
  formatInTimeZone(dt, localTimeZone, format);

export const formatDateAsISO = (dt: Date) =>
  utcToZonedTime(dt, localTimeZone).toISOString();

export const formatDateAsLocal = (dt: Date, format: string = "E d MMM HH:mm") =>
  formatInTimeZone(dt, localTimeZone, format);

export const formatDateAsTime = (dt: Date, format: string = "HH:mm") =>
  formatInTimeZone(dt, localTimeZone, format);

export const formatRawAsDate = (raw: string, format?: string) =>
  formatDateObjAsDate(new Date(raw), format);

export const formatRawAsISO = (raw: string) => formatDateAsISO(new Date(raw));

export const formatRawAsLocal = (raw: string, format?: string) =>
  formatDateAsLocal(new Date(raw), format);

export const formatRawAsTime = (raw: string, format?: string) =>
  formatDateAsTime(new Date(raw), format);

const tzBlockList = [
  "Africa/Asmera",
  "Africa/Timbuktu",
  "America/Argentina/ComodRivadavia",
  "America/Atka",
  "America/Buenos_Aires",
  "America/Catamarca",
  "America/Coral_Harbour",
  "America/Cordoba",
  "America/Ensenada",
  "America/Fort_Wayne",
  "America/Indianapolis",
  "America/Jujuy",
  "America/Knox_IN",
  "America/Louisville",
  "America/Mendoza",
  "America/Montreal",
  "America/Porto_Acre",
  "America/Rosario",
  "America/Santa_Isabel",
  "America/Shiprock",
  "America/Virgin",
  "Antarctica/South_Pole",
  "Asia/Ashkhabad",
  "Asia/Calcutta",
  "Asia/Chongqing",
  "Asia/Chungking",
  "Asia/Dacca",
  "Asia/Harbin",
  "Asia/Istanbul",
  "Asia/Kashgar",
  "Asia/Katmandu",
  "Asia/Macao",
  "Asia/Rangoon",
  "Asia/Saigon",
  "Asia/Tel_Aviv",
  "Asia/Thimbu",
  "Asia/Ujung_Pandang",
  "Asia/Ulan_Bator",
  "Atlantic/Faeroe",
  "Atlantic/Jan_Mayen",
  "Australia/ACT",
  "Australia/Canberra",
  "Australia/Canberra",
  "Australia/LHI",
  "Australia/NSW",
  "Australia/North",
  "Australia/Queensland",
  "Australia/South",
  "Australia/Tasmania",
  "Australia/Victoria",
  "Australia/West",
  "Australia/Yancowinna",
  "Brazil/Acre",
  "Brazil/DeNoronha",
  "Brazil/East",
  "Brazil/West",
  "Canada/Saskatchewan",
  "Canada/Yukon",
  "Chile/Continental",
  "Chile/EasterIsland",
  "Europe/Belfast",
  "Europe/Nicosia",
  "Europe/Tiraspol",
  "Mexico/BajaNorte",
  "Mexico/BajaSur",
  "Mexico/General",
  "Pacific/Johnston",
  "Pacific/Ponape",
  "Pacific/Samoa",
  "Pacific/Truk",
  "Pacific/Yap",
  "US/Aleutian",
  "US/East-Indiana",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Pacific",
];
export const getValidTimeZones = () =>
  moment.tz.names().filter((tz) => {
    if (tz === "UTC") {
      return true;
    }
    if (tz.indexOf("/") === -1) {
      return false;
    }
    if (tz.indexOf("Etc/") === 0) {
      return false;
    }
    if (tzBlockList.indexOf(tz) > -1) {
      return false;
    }
    return true;
  });

export const getDefaultTZ = () => {
  const valid = getValidTimeZones();
  const guess = moment.tz.guess();
  return valid.indexOf(guess) > -1 ? guess : "UTC";
};

export default formatDateAsLocal;
