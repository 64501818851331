import { apiSlice } from "./apiSlice";
import {
  ILoginData,
  IPasswordChangeData,
  IPasswordResetData,
  IPasswordSetData,
} from "../player/types";

export const playerApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    playerLogin: builder.query<void, ILoginData>({
      query: (loginCredentials: ILoginData) => ({
        url: "player/logout/",
        method: "POST",
        body: loginCredentials,
      }),
    }),
    playerLogout: builder.query<void, void>({
      query: () => "player/logout/",
    }),
    // Due to inability to test these endpoints as they don't exist on PLW, when we
    // come to implement this store within other games we want to define the types rather
    // than the arbitrary object currently used below
    playerRegister: builder.query<void, { [key: string]: string }>({
      query: (data: { [key: string]: string }) => ({
        url: "player/create/",
        method: "POST",
        body: data,
      }),
    }),
    playerUpdate: builder.query<void, { [key: string]: string }>({
      query: (data: { [key: string]: string }) => ({
        url: "player/update/",
        method: "POST",
        body: data,
      }),
    }),
    playerPasswordChange: builder.query<void, IPasswordChangeData>({
      query: (data: IPasswordChangeData) => ({
        url: "player/password-change/",
        method: "PUT",
        body: data,
      }),
    }),
    playerPasswordReset: builder.query<void, IPasswordResetData>({
      query: (data: IPasswordResetData) => ({
        url: "player/password-reset/",
        method: "POST",
        body: data,
      }),
    }),
    playerPasswordSet: builder.query<void, IPasswordSetData>({
      query: (data: IPasswordSetData) => ({
        url: "player/password-set/",
        method: "PUT",
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useLazyPlayerLoginQuery } = playerApi;
