import React from "react";
import { IHeading } from "./types";

const Heading = ({ headingLevel, children }: IHeading) => {
  const elementStr = "h" + headingLevel;
  const Tag = ({ ...props }: React.HTMLAttributes<HTMLHeadingElement>) =>
    React.createElement(elementStr, props, children);

  return <Tag>{children}</Tag>;
};

export default Heading;
