import { size } from "polished";
import styled, { css } from "styled-components";
import BaseGamechanger from "../../../img/icons/gamechanger.svg?react";

const ChallengeCopyStyles = css`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  text-align: center;
`;

export const ChallengeTitle = styled.div`
  ${ChallengeCopyStyles}
  font-family: ${({ theme }) => theme.fonts.bold};
`;

export const ChallengeDescription = styled.div`
  ${ChallengeCopyStyles}
  font-family: ${({ theme }) => theme.fonts.regular};
`;

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.heavy};
  font-size: 25px;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
`;

export const GameChangerIcon = styled(BaseGamechanger)`
  ${size(20)};
  align-self: center;
`;

export const ChallengeCopyWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[1]};
`;

export const ChallengeTitleWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.space[1]};
`;
