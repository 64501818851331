import React from "react";
import { useAppSelector } from "../../rtk-core/src/app/hooks";
import SubNav from "../SubNav/SubNav";
import { getActiveEvent } from "../../rtk-core/src/features/events";
import {
  getPhases,
  getPhasesByStartAndStopEventId,
} from "../../rtk-core/src/features/phases";
import { useParams } from "react-router-dom";

const PhaseNav: React.FC = () => {
  const { leagueId } = useParams<{ leagueId: string }>();
  const activeEvent = useAppSelector(getActiveEvent);
  const phases = useAppSelector(getPhases);

  const activePhase = useAppSelector(
    (state) =>
      getPhasesByStartAndStopEventId(state, activeEvent.id, activeEvent.id)[0]
  );
  const overallPhase = useAppSelector(
    (state) => getPhasesByStartAndStopEventId(state, 1, 38)[0]
  );

  const eventPhase = phases.find(
    (phase) =>
      ![activePhase.id, overallPhase.id].includes(phase.id) &&
      phase.start_event <= activeEvent.id &&
      phase.stop_event >= activeEvent.id
  );

  const links = [
    {
      href: `/leagues/${leagueId}/standings/c/${activeEvent.id}`,
      text: "Gameweek",
    },
  ];

  if (eventPhase) {
    links.push({
      href: `/leagues/${leagueId}/standings/c/${activeEvent.id}/${eventPhase.id}`,
      text: "Event",
    });
  }

  links.push({
    href: `/leagues/${leagueId}/standings/c/${activeEvent.id}/${overallPhase.id}`,
    text: "Season",
  });

  return <SubNav $isEqual={true} $alignCenter={true} links={links} />;
};

export default PhaseNav;
