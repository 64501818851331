import { createAsyncThunk } from "@reduxjs/toolkit";
import { range, shuffle, sortBy, tail, values } from "lodash";
import { IThunkApi } from "../../app/types";
import { bootstrapApi } from "../api/bootstrap";
import { entriesApi } from "../api/entries";
import { manageApi } from "../api/manage";
import { IElementType, getElementTypesById } from "../elementTypes";
import {
  IElement,
  getAverageCostByType,
  getElement,
  getElements,
  getElementsById,
} from "../elements";
import { getActiveEvent } from "../events";
import { getRules } from "../game";
import { IPlayer, getPlayerData } from "../player";
import {
  changeCaptain,
  changeViceCaptain,
  initialiseState,
  proposePick,
  removePick,
  resetLastChange,
  resetMyTeam,
  setActiveFormation,
  setPendingFormation,
  setPendingRemovals,
  substitutionProcess,
  substitutionStart,
  substitutionStop,
} from "./manageSlice";
import {
  getCountByTeam,
  getElementTypesByActiveFormation,
  getMyPossibleReplacementsForPick,
  getMyStartersProposed,
  getMySubsSaved,
  getNonTransactablePicks,
  getPendingFormation,
  getPendingRemovals,
  getPicksProposed,
  getPicksProposedByPosition,
  getPicksSaved,
  getProposedElements,
  getProposedPicksSelectionPriorityOrdered,
  getToSpend,
  getTypesNeeded,
} from "./selectors";
import { IManagePickProposed, IManageTeamCreateData } from "./types";

const buildPick = (
  elementType: number,
  position: number,
  element?: IElement
) => {
  let newPick: IManagePickProposed = {
    elementType,
    is_captain: false,
    is_vice_captain: false,
    multiplier: 1,
    position: position,
    purchase_price: element?.now_cost,
    selling_price: element?.now_cost,
    subStatus: "",
  };

  if (element) {
    newPick["element"] = element.id;
  }

  return newPick;
};

export const doInitialiseState = createAsyncThunk<void, void, IThunkApi>(
  "manage/initialiseState",
  async (_: void, { dispatch, getState }) => {
    const rules = getRules(getState(), getActiveEvent(getState()).id);
    const typesByFormation = getElementTypesByActiveFormation(getState());
    if (rules && typesByFormation.length) {
      dispatch(initialiseState({ rules, typesByFormation }));
    }
    return;
  }
);

export const proposeElement = createAsyncThunk<boolean, number, IThunkApi>(
  "manage/proposeElement",
  async (elementId: number, { dispatch, getState }) => {
    const activeEvent = getActiveEvent(getState());
    const element = getElement(getState(), elementId);
    const proposed = getPicksProposed(getState());
    const saved = getPicksSaved(getState());
    const rules = getRules(getState(), activeEvent.id);

    if (!rules) {
      return false;
    }

    const elementTypesByPosition = getElementTypesByActiveFormation(getState());
    if (!element || !elementTypesByPosition) {
      return false;
    }

    const proposedElements = proposed.map(
      (pickProposed: IManagePickProposed) => pickProposed.element
    );

    // Element must not already be selected
    if (values(proposedElements).indexOf(element.id) > -1) {
      return false;
    }

    // Can we find a free position based on type?
    const availablePick = proposed.find(
      (pick) => pick.elementType === element.element_type && !pick.element
    );
    let freePosition = availablePick ? availablePick.position : null;

    if (!freePosition) {
      return false;
    }

    const savedPick = saved.find((p) => p.element === elementId);
    // We already have this element
    if (savedPick) {
      const savedPosition = savedPick.position;
      // In a different position
      if (savedPosition !== freePosition) {
        // The index here is savedPosition - 1 due to 0 indexing of the proposed array
        const proposedPick = proposed[savedPosition - 1];
        // Someone is in saved position so remove them and put in this position
        if (proposedPick) {
          dispatch(removePick(savedPosition));
          dispatch(
            proposePick({
              ...proposedPick,
              position: freePosition,
            })
          );
        }
        freePosition = savedPosition;
      }
    }
    dispatch(
      proposePick(buildPick(element.element_type, freePosition, element))
    );

    // if we now have the required number of starters we call
    // assignRoles to ensure a Captain and ViceCaptain are selected
    const starters = getMyStartersProposed(getState());
    if (starters.filter((p) => p.element).length === rules.squad_squadplay) {
      dispatch(assignRoles());
    }

    return true;
  }
);

export const removeElement = createAsyncThunk<boolean, number, IThunkApi>(
  "manage/removeElement",
  async (position: number, { dispatch, getState }) => {
    const proposed = getPicksProposedByPosition(getState());
    if (!proposed[position]) {
      return false;
    }

    dispatch(removePick(position));

    const pendingRemovals = getPendingRemovals(getState());
    if (pendingRemovals.length) {
      const pendingFormation = getPendingFormation(getState());
      const remainingRemovals: IElementType[] = tail(pendingRemovals);

      dispatch(setPendingRemovals(remainingRemovals));
      if (pendingFormation && !remainingRemovals.length) {
        dispatch(changeFormationNoSub(pendingFormation));
      }
    }

    return true;
  }
);

export const actionSubstitution = createAsyncThunk<void, number, IThunkApi>(
  "manage/actionSubstitution",
  async (position: number, { dispatch, getState }) => {
    const activeEvent = getActiveEvent(getState());
    const pick = getPicksProposedByPosition(getState())[position];
    const rules = getRules(getState(), activeEvent.id);

    if (pick && rules) {
      if (pick.subStatus === "") {
        dispatch(
          substitutionStart(
            pick,
            getMyPossibleReplacementsForPick(getState(), pick)
          )
        );
      } else if (pick.subStatus === "instigator") {
        dispatch(substitutionStop());
      } else if (pick.subStatus === "target") {
        dispatch(substitutionProcess(pick, rules.squad_squadplay + 1));
      }
    }
  }
);

export const autoComplete = createAsyncThunk<boolean, void, IThunkApi>(
  "manage/autoComplete",
  async (_: void, { dispatch, getState }) => {
    const activeEvent = getActiveEvent(getState());
    const elementTypesByFormation = getElementTypesByActiveFormation(
      getState()
    );

    const rules = getRules(getState(), activeEvent.id);
    if (!rules || !elementTypesByFormation) {
      return false;
    }

    const averageCostByType = getAverageCostByType(getState());
    let proposedPicks = getPicksProposed(getState());
    const elementsOwned = proposedPicks.map(
      (pick: IManagePickProposed) => pick.element
    );
    const countByTeam = getCountByTeam(getState());
    let failures = 0;
    range(rules.squad_squadsize).forEach((i: number) => {
      const position = i + 1;
      if (
        !proposedPicks.find(
          (pick) => pick.position === position && pick.element
        )
      ) {
        const typesNeeded = getTypesNeeded(getState());
        const avgToSpend = Object.keys(typesNeeded).reduce(
          (memo, et) => memo + typesNeeded[et] * averageCostByType[et],
          0
        );
        const weightingFactor = getToSpend(getState()) / avgToSpend;
        const invalidTeams = Object.keys(countByTeam)
          .filter((id) => countByTeam[id] >= rules.squad_team_limit)
          .map((id) => parseInt(id, 10));

        // We get the activeEvent so we can get the element data for that event
        const elements = getElements(getState(), activeEvent.id);
        const possibles = elements.filter(
          (e) =>
            // Hasn't played
            e.can_transact &&
            // Correct type
            e.element_type === elementTypesByFormation[i].id &&
            // Cheap enough
            e.now_cost <=
              averageCostByType[elementTypesByFormation[i].id] *
                weightingFactor &&
            // Don't have
            elementsOwned.indexOf(e.id) === -1 &&
            // Available
            e.can_select &&
            // Not removed
            !e.removed &&
            // Within team limit
            invalidTeams.indexOf(e.team) === -1
        );
        if (possibles.length) {
          const choice = shuffle(possibles).sort(
            (a, b) =>
              parseFloat(b.form) - parseFloat(a.form) || b.now_cost - a.now_cost
          )[0];
          dispatch(
            proposePick(buildPick(choice.element_type, position, choice))
          );
          elementsOwned.push(choice.id);
          countByTeam[choice.team]++;
        } else {
          failures++;
        }
      }
    });

    // Set captain and vice captain roles
    dispatch(assignRoles());

    return !failures;
  }
);

export const reset = createAsyncThunk<void, void, IThunkApi>(
  "manage/reset",
  async (_: void, { dispatch, getState }) => {
    const activeEvent = getActiveEvent(getState());
    const elementsById = getElementsById(getState(), activeEvent.id);
    const elementTypes = getElementTypesByActiveFormation(getState());
    const rules = getRules(getState(), activeEvent.id);
    if (rules) {
      dispatch(resetLastChange());
      dispatch(resetMyTeam(elementsById, elementTypes, rules));
    }
  }
);

// Sets captain and vice captain roles if they don't exist in the
// starting line-up
export const assignRoles = createAsyncThunk<void, void, IThunkApi>(
  "manage/assignRoles",
  async (_: void, { dispatch, getState }) => {
    const activeEvent = getActiveEvent(getState());
    const rules = getRules(getState(), activeEvent.id);
    if (!rules) {
      return;
    }

    const proposedStarters = getMyStartersProposed(getState());
    const captain = proposedStarters.find((p) => p.is_captain);
    const viceCaptain = proposedStarters.find((p) => p.is_vice_captain);

    const startingElementsByPrice = sortBy(
      getProposedElements(getState()).slice(0, rules.squad_squadplay),
      "now_cost"
    );
    // Filter starting elements to only include players that are yet to play
    const startingElementsByPriceFiltered = startingElementsByPrice.filter(
      (e) => e.can_transact
    );
    // Get the two most expensive players in the starting squad as these will be the new vice captain and captain
    const [second, first] = startingElementsByPriceFiltered
      .filter(
        (e) => [captain?.element, viceCaptain?.element].indexOf(e.id) === -1
      )
      .slice(-2);

    // If there currently isn't a captain we set one
    if (!captain) {
      dispatch(changeCaptain(first.id));
    }

    // If there currently isn't a vice captain we set one
    if (!viceCaptain && rules.sys_vice_captain_enabled) {
      // If no vice captain but captain is already set we set the vice captain
      // to be the most expensive player in the starting line-up
      if (captain) {
        dispatch(changeViceCaptain(first.id));
      } else {
        dispatch(changeViceCaptain(second.id));
      }
    }
  }
);

export const doSaveMyTeam = createAsyncThunk<
  void,
  { eventId: number; createEntryData?: IManageTeamCreateData },
  IThunkApi
>(
  "manage/saveMyTeam",
  async (
    { eventId, createEntryData },
    { dispatch, getState, rejectWithValue }
  ) => {
    const activeEvent = getActiveEvent(getState());
    const player = getPlayerData(getState()) as IPlayer;
    if (player.entry) {
      const response = await dispatch(
        manageApi.endpoints.postManageTeam.initiate(
          { eventId },
          { forceRefetch: true }
        )
      );

      if (response.isError) {
        return rejectWithValue(response.error);
      }

      await dispatch(
        entriesApi.endpoints.fetchEntrySummary.initiate(
          {
            entryId: player.entry!,
            eventId: activeEvent.id,
          },
          { forceRefetch: true }
        )
      );
    } else {
      const response = await dispatch(
        manageApi.endpoints.postManageTeam.initiate(
          { eventId, createEntryData },
          { forceRefetch: true }
        )
      );

      if (response.isError) {
        return rejectWithValue(response.error);
      }

      const bootstrapDynamicResponse = await dispatch(
        bootstrapApi.endpoints.getBootstrapDynamic.initiate(undefined, {
          forceRefetch: true,
        })
      );

      if (bootstrapDynamicResponse.data) {
        const meData = bootstrapDynamicResponse.data;
        if (meData && meData.player && meData.player.entry) {
          await dispatch(
            entriesApi.endpoints.fetchEntrySummary.initiate(
              {
                entryId: meData.player.entry,
                eventId: activeEvent.id,
              },
              { forceRefetch: true }
            )
          );
        }
      }
    }
  }
);

export const changeFormation = createAsyncThunk<void, void, IThunkApi>(
  "manage/changeFormation",
  async (_: void, { dispatch, getState }) => {
    const elementTypesByFormation = getElementTypesByActiveFormation(
      getState()
    );
    const activeEvent = getActiveEvent(getState());

    const sortedPicks = getProposedPicksSelectionPriorityOrdered(
      getState(),
      activeEvent.id
    );

    const pickByPosition: { [key: string]: IManagePickProposed } = {};

    const benchSaved = getMySubsSaved(getState());
    const nonTransactablePicks = getNonTransactablePicks(
      getState(),
      activeEvent.id
    );

    // Loop through elementTypesByFormation and on each iteration we find the next available pick
    // that matches the required elementType and add that to pickByPosition. Once a pick has been added
    // we add the position to positionsAdded to prevent adding the same pick more than once
    let positionsAdded: number[] = [];

    elementTypesByFormation.forEach((et, index) => {
      const pick = sortedPicks.find(
        (pick) =>
          pick.elementType === et.id &&
          positionsAdded.indexOf(pick.position) === -1
      )!;
      pickByPosition[index + 1] = pick;
      positionsAdded.push(pick.position);
    });

    // Now loop through the saved bench and reshuffle them so those that have played are in
    // their same position as they are saved, anything else, will fill the gaps
    benchSaved.forEach((savedPick) => {
      const benchedPick = sortedPicks.find(
        (pick) => pick.element === savedPick.element
      );
      const isNonTransactable = nonTransactablePicks.some(
        (nonTransactablePick) =>
          nonTransactablePick.element === savedPick.element
      );
      if (isNonTransactable && savedPick.position > 11 && benchedPick) {
        const currentPosition = positionsAdded.indexOf(savedPick.position);
        if (currentPosition !== -1) {
          // Find the pick that is currently placed in the saved pick's position
          const conflictingPick = pickByPosition[savedPick.position];

          // Update pickByPosition accordingly
          pickByPosition[currentPosition + 1] = conflictingPick;
          pickByPosition[savedPick.position] = benchedPick;
        }
      }
    });

    let picksToRemove: IManagePickProposed[] = [];
    let picksToPropose: IManagePickProposed[] = [];
    Object.keys(pickByPosition).forEach((position: string) => {
      const positionAsNumber = Number(position);
      if (positionAsNumber !== pickByPosition[positionAsNumber]!.position) {
        picksToRemove.push(pickByPosition[positionAsNumber]!);
        picksToPropose.push({
          ...pickByPosition[positionAsNumber]!,
          position: positionAsNumber,
        });
      }
    });

    picksToRemove.forEach((pick) => {
      dispatch(removePick(pick.position));
    });

    picksToPropose.forEach((pick) => {
      dispatch(proposePick(pick));
    });

    dispatch(assignRoles());
  }
);

export const changeFormationNoSub = createAsyncThunk<void, string, IThunkApi>(
  "manage/changeFormationNoSub",
  async (newFormation, { dispatch, getState }) => {
    const activeEvent = getActiveEvent(getState());
    const elementTypesById = getElementTypesById(getState(), activeEvent.id);
    const proposedElements = getProposedElements(getState());

    let toRemove: IElementType[] = [];

    newFormation.split("-").forEach((required: string, index) => {
      const proposedElementsForEt = proposedElements.filter(
        (e) => e.element_type === index + 1
      );
      if (proposedElementsForEt.length > Number(required)) {
        const difference = proposedElementsForEt.length - Number(required);
        toRemove.push(
          ...Array.from(
            { length: difference },
            (v) => elementTypesById[index + 1]
          )
        );
      }
    });

    if (toRemove.length) {
      dispatch(setPendingFormation(newFormation));
      dispatch(setPendingRemovals(toRemove));
      return;
    }

    let elementTypesByFormation: IElementType[] = [];
    newFormation.split("-").forEach((required: string, index) => {
      elementTypesByFormation.push(
        ...Array(Number(required)).fill(elementTypesById[index + 1])
      );
    });

    const picksProposed = getPicksProposed(getState());
    let positionsAdded: number[] = [];
    let picksToPropose: IManagePickProposed[] = [];
    elementTypesByFormation.forEach((et, index) => {
      const pick = picksProposed.find(
        (pick) =>
          pick.element &&
          pick.elementType === et.id &&
          positionsAdded.indexOf(pick.position) === -1
      );

      if (pick) {
        positionsAdded.push(pick.position);
        picksToPropose.push({
          ...pick,
          position: index + 1,
        });
      } else {
        picksToPropose.push(buildPick(et.id, index + 1));
      }
    });

    picksToPropose.forEach((p) => {
      dispatch(removePick(p.position));
      dispatch(proposePick(p));
    });

    dispatch(assignRoles());
    dispatch(setActiveFormation(newFormation));
  }
);

export const cancelFormationChange = createAsyncThunk<void, void, IThunkApi>(
  "manage/cancelFormationChange",
  async (_: void, { dispatch }) => {
    dispatch(setPendingFormation(null));
    dispatch(setPendingRemovals([]));
  }
);
