import styled, { css } from "styled-components";
import { IGrid, IGridItem, IMainProps, IWrapper } from "./types";

export const SharedWrapperStyles = css`
  max-width: 136rem;
  min-height: 0;
  min-width: 0;
  padding: 0 ${(props) => props.theme.space[3]};
  letter-spacing: ${(props) => props.theme.letterSpacing[0]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    margin-right: auto;
    margin-left: auto;
  }
`;

export const Wrapper = styled.div<IWrapper>`
  ${SharedWrapperStyles}

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: ${({ $hasColumns = false }) => ($hasColumns ? "flex" : "block")};
  }
`;

export const Main = styled.div<IMainProps>`
  padding-top: ${(props) => props.theme.space[6]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding-top: ${(props) => props.theme.space[8]};
    width: ${({ $isFullWidth = false }) =>
      $isFullWidth ? "100%" : "calc(100% / 3 * 2)"};
  }
`;

export const Secondary = styled.div`
  padding-top: ${(props) => props.theme.space[6]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding-top: ${(props) => props.theme.space[8]};
    margin-left: ${(props) => props.theme.space[3]};
    width: calc(100% / 3);
  }
`;

export const GridWrapper = styled.div<IWrapper>`
  ${SharedWrapperStyles}
`;

export const TwoCol50Grid = styled.div<IGrid>`
  display: grid;
  gap: ${(props) => props.theme.space[6]};
  margin-top: ${({ theme, $hasMargin = false }) =>
    $hasMargin ? theme.space[6] : "0"};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const GridItem = styled.div<IGridItem>`
  order: ${({ $order }) => ($order ? $order : 0)};
  min-width: 0;

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-column: span ${({ $colSpan }) => ($colSpan ? $colSpan : "2")};
    grid-column-start: ${({ $gridColStart }) =>
      $gridColStart ? $gridColStart : "0"};
    grid-row: span ${({ $rowSpan }) => ($rowSpan ? $rowSpan : "1")};
    order: 0;
  }
`;

export const SidebarGrid = styled.div<IGrid>`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${(props) => props.theme.space[6]};
  margin-top: ${({ theme, $hasMargin = false }) =>
    $hasMargin ? theme.space[6] : "0"};

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 2fr 1fr;
  }
`;
