import styled from "styled-components";

const SectionHeading = styled.h3`
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.heavy};
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.primary};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-right: 0;
    padding-left: 0;
  }
`;

export default SectionHeading;
