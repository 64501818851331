import styled, { css } from "styled-components";
import theme from "../../theme";
import { IDeadlineHeading, IDeadlineTextProps } from "./types";

export const DeadlineHeading = styled.h4<IDeadlineHeading>`
  position: relative;
  margin-bottom: ${(props) => props.theme.space[1]};
  font-size: ${(props) => props.theme.fontSizes[0]};

  ${(props) =>
    props.$isPassed &&
    css`
      color: ${theme.colors.purple70};
    `}
`;

export const DeadlineTime = styled.time<IDeadlineTextProps>`
  font-family: ${(props) =>
    props.$isNext ? theme.fonts.heavy : theme.fonts.bold};
  margin-right: ${(props) => props.theme.space[1]};
`;

export const DeadlineText = styled.span<IDeadlineTextProps>`
  font-family: ${(props) =>
    props.$isNext ? theme.fonts.heavy : theme.fonts.regular};
`;
