import { apiSlice } from "./apiSlice";
import { IRegion } from "../regions/types";

export const regionsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchRegions: builder.query<IRegion[], void>({
      query: () => `regions/`,
    }),
  }),
  overrideExisting: false,
});

export const { useFetchRegionsQuery } = regionsApi;
