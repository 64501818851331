import { createSelector } from "@reduxjs/toolkit";
import { startOfDay } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { isEqual } from "lodash";
import { RootState } from "../../app/store";
import { IElement } from "../elements";
import { getEventsById, getNextEvent } from "../events";
import { getPlayerData } from "../player";
import { getTeamIdsPlayingInEvent } from "../teams";
import {
  FixturesByEvent,
  IFixture,
  IFixtureDeadline,
  IFixtureDeadlinesPerDay,
  IFixtureGroup,
  IFixturesForEventByTeam,
  IGroupedFixturesByEvent,
} from "./types";

export const getFixturesByEvent = (state: RootState) => state.fixtures.byEvent;

export const getGroupedFixturesByEvent = (state: RootState) => {
  const fixturesByEvent = getFixturesByEvent(state);
  const groupedFixturesByEvent: IGroupedFixturesByEvent = {};

  Object.keys(fixturesByEvent).forEach((eventId) => {
    const fixtures = fixturesByEvent[eventId];
    const groups: IFixtureGroup[] = [];
    let currentGroup: IFixtureGroup | null = null;
    fixtures.forEach((fixture: IFixture) => {
      if (fixture.kickoff_time) {
        const kickoffDate = new Date(fixture.kickoff_time);
        const fixtureDate = startOfDay(
          utcToZonedTime(
            kickoffDate,
            Intl.DateTimeFormat().resolvedOptions().timeZone
          )
        );
        if (currentGroup == null || !isEqual(fixtureDate, currentGroup.date)) {
          currentGroup = {
            date: fixtureDate,
            fixtures: [],
          };
          groups.push(currentGroup);
        }
        currentGroup.fixtures.push({ kickoffDate, ...fixture });
      }
    });
    groupedFixturesByEvent[eventId] = groups;
  });
  return groupedFixturesByEvent;
};

export const getFixturesByFixtureDeadlineForEvent = (
  state: RootState,
  eventId: number,
  deadlineDate: string
) => {
  let eventDeadlineIdCounter = 1;
  let teamsPlaying = getTeamIdsPlayingInEvent(state, eventId);
  const fixtures = getFixturesForEvent(state, eventId);
  const groups: IFixtureDeadlinesPerDay[] = [];
  let currentGroup: IFixtureDeadlinesPerDay | null = null;
  let currentFixtureDeadline: IFixtureDeadline | null = null;
  let fixtureDeadlineUsed = false;

  const deadlineMoment = new Date(deadlineDate);
  const teamsAlreadyPlayed = new Set<number>();

  const uniqueKickoffTimes = new Set(
    fixtures
      .map((fixture) => fixture.kickoff_time)
      .filter((kickoff_time) => kickoff_time !== null)
  );

  fixtures.forEach((fixture: IFixture) => {
    teamsPlaying = teamsPlaying.filter(
      (teamId) => teamId !== fixture.team_a && teamId !== fixture.team_h
    );

    const isNewTeamPlaying =
      !teamsAlreadyPlayed.has(fixture.team_a) ||
      !teamsAlreadyPlayed.has(fixture.team_h);

    if (fixture.kickoff_time) {
      const kickoffDate = new Date(fixture.kickoff_time);
      const fixtureDate = startOfDay(
        utcToZonedTime(
          new Date(kickoffDate),
          Intl.DateTimeFormat().resolvedOptions().timeZone
        )
      );

      if (
        currentGroup == null ||
        groups.filter((group) => fixtureDate.getTime() === group.date.getTime())
          .length === 0
      ) {
        currentGroup = {
          date: fixtureDate,
          fixtureDeadlines: [],
        };
        groups.push(currentGroup);
      } else {
        currentGroup = groups.filter(
          (group) => fixtureDate.getTime() === group.date.getTime()
        )[0];
      }

      let eventDeadlineText:
        | string
        | null = `Deadline ${eventDeadlineIdCounter}`;
      if (fixtureDeadlineUsed) {
        eventDeadlineText = null;
      } else if (!fixtureDeadlineUsed && !isNewTeamPlaying) {
        eventDeadlineText = "";
      } else if (
        kickoffDate.getTime() >= deadlineMoment.getTime() ||
        uniqueKickoffTimes.size === 1
      ) {
        eventDeadlineText = "Final Deadline";
      }

      if (
        currentFixtureDeadline == null ||
        currentGroup?.fixtureDeadlines.filter(
          (fd) => kickoffDate.getTime() === fd.deadline.getTime()
        ).length === 0
      ) {
        currentFixtureDeadline = {
          deadline: kickoffDate,
          fixtures: [],
          eventDeadlineId: eventDeadlineText,
        };
        if (isNewTeamPlaying) {
          eventDeadlineIdCounter += 1;
        }
        currentGroup.fixtureDeadlines.push(currentFixtureDeadline);
      }
      currentFixtureDeadline.fixtures.push({ kickoffDate, ...fixture });
      if (eventDeadlineText === "Final Deadline") {
        currentFixtureDeadline.eventDeadlineId = eventDeadlineText;
        fixtureDeadlineUsed = true;
      }

      teamsAlreadyPlayed.add(fixture.team_a);
      teamsAlreadyPlayed.add(fixture.team_h);
    }
  });
  return groups;
};

export const getFixturesByEntrySubmissionDeadline = createSelector(
  [
    (state, eventId) =>
      getFixturesByFixtureDeadlineForEvent(
        state,
        eventId,
        getEventsById(state)[eventId].deadline_time
      ),
  ],
  (deadline) => {
    return deadline;
  }
);

export const getFixturesByKickOffDeadline = (
  state: RootState,
  eventId: number
) => {
  const fixturesOriginal = getFixturesForEvent(state, eventId);
  if (!fixturesOriginal.length) {
    return [];
  }

  const teamFirstFixtureMap: Record<number, string> = {};

  fixturesOriginal.forEach((fixture) => {
    if (fixture.kickoff_time) {
      const updateTeamFirstFixture = (teamId: number) => {
        const kickoffTime = fixture.kickoff_time;
        if (kickoffTime === null) {
          return;
        }

        if (
          !teamFirstFixtureMap[teamId] ||
          new Date(teamFirstFixtureMap[teamId]).getTime() >
            new Date(kickoffTime).getTime()
        ) {
          teamFirstFixtureMap[teamId] = kickoffTime;
        }
      };

      updateTeamFirstFixture(fixture.team_a);
      updateTeamFirstFixture(fixture.team_h);
    }
  });

  const earliestKickoffTimes = Object.values(teamFirstFixtureMap);
  if (!earliestKickoffTimes.length) {
    return [];
  }

  const lastKickoffTime = earliestKickoffTimes.reduce((latest, current) =>
    new Date(latest).getTime() > new Date(current).getTime() ? latest : current
  );

  return getFixturesByFixtureDeadlineForEvent(state, eventId, lastKickoffTime);
};

export const getFixturesForEvent = (state: RootState, eventId: number) =>
  state.fixtures.byEvent[eventId] || [];

export const getFixturesForEventById = (state: RootState, eventId: number) => {
  const fixtures = getFixturesForEvent(state, eventId);
  if (!fixtures.length) {
    return null;
  }
  return fixtures.reduce(
    (memo: FixturesByEvent, f: IFixture) => ({ ...memo, [f.id]: f }),
    {}
  );
};

export const getFixturesForEventByTeam = createSelector(
  [
    (state: RootState) => state.teams.byId,
    (state: RootState, eventId: number) => getFixturesForEvent(state, eventId),
  ],
  (teamsById, fixturesForEvent) => {
    const data: IFixturesForEventByTeam = Object.keys(teamsById).reduce(
      (memo, id) => ({
        ...memo,
        [id]: [],
      }),
      {}
    );
    fixturesForEvent.forEach((f: IFixture) => {
      data[f.team_h].push(f);
      data[f.team_a].push(f);
    });
    return data;
  }
);

export const getFixturesForNextEventByTeam = (state: RootState) => {
  const nextEvent = getNextEvent(state);
  return getFixturesForEventByTeam(state, nextEvent ? nextEvent.id : 0);
};

export const getEventFixtureGroups = createSelector(
  [
    (state: RootState, eventId) =>
      getFixturesByEntrySubmissionDeadline(state, eventId),
    (state: RootState, eventId) => getFixturesByKickOffDeadline(state, eventId),
    getPlayerData,
  ],
  (fixturesByEntrySubmissionDeadline, fixturesByKickOffDeadline, player) => {
    return player && player.entry
      ? fixturesByKickOffDeadline
      : fixturesByEntrySubmissionDeadline;
  }
);

export const getElementFixtureOpponent = createSelector(
  [
    (state) => state.teams.byId,
    (_: RootState, element) => element,
    (_: RootState, __: IElement, fixture) => fixture,
  ],
  (teamsById, element, fixture) => {
    const isHome = element.team === fixture.team_h;
    const neutralIds: number[] = [];
    const suffix =
      neutralIds.indexOf(fixture.id) > -1 ? "(N)" : isHome ? "(H)" : "(A)";
    const oppTeam = isHome
      ? teamsById[fixture.team_a]
      : teamsById[fixture.team_h];
    return `${oppTeam.short_name} ${suffix}`;
  }
);

export const getElementEventOpponents = (
  state: RootState,
  element: IElement,
  fixtures: IFixture[]
) =>
  fixtures.map((f: IFixture) => getElementFixtureOpponent(state, element, f));
