import { useContext, useEffect, useState } from "react";
import WebViewContext from "../../contexts/WebViewContext";
import { ScoutFooter, Widget, WidgetHeader } from "../../pages";
import { IScoutNews, getNews } from "../../utils/scout";
import BoldHyperlink from "../BoldHyperlink";
import ScoutCard, { ScoutItem, ScoutList } from "../ScoutCard";
import ScoutErrorBoundary from "../ScoutErrorBoundary";
import SubHeading from "../SubHeading";
import { IScoutNewsList } from "./types";

const ScoutNewsList = ({ numberOfItems }: IScoutNewsList) => {
  const [scoutData, setScoutData] = useState<IScoutNews | null>(null);

  useEffect(() => {
    (async () => {
      const data = await getNews(
        numberOfItems,
        "FPL%20Challenge%2C%20Challenge%20Homepage"
      );
      setScoutData(data);
    })();
  }, [numberOfItems]);

  const { isWebView } = useContext(WebViewContext);
  const webviewClass = isWebView ? "webview-hidden" : "";

  if (!scoutData || !scoutData.news) {
    return null;
  }

  return (
    <Widget>
      <div className="mb-3">
        <WidgetHeader>
          <SubHeading>From the Scout</SubHeading>
        </WidgetHeader>
      </div>
      <ScoutList>
        {scoutData.news.content.map((n) => (
          <ScoutItem key={n.id} $width={100 / numberOfItems}>
            <ScoutCard externalNews={scoutData.externalNews} newsItem={n} />
          </ScoutItem>
        ))}
      </ScoutList>
      <ScoutFooter>
        <BoldHyperlink className={webviewClass} href="/the-scout">
          More News
        </BoldHyperlink>
      </ScoutFooter>
    </Widget>
  );
};

const ScoutNewsListWrapper = ({ numberOfItems }: IScoutNewsList) => (
  <ScoutErrorBoundary>
    <ScoutNewsList numberOfItems={numberOfItems} />
  </ScoutErrorBoundary>
);

export default ScoutNewsListWrapper;
