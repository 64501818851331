import { ellipsis } from "polished";
import styled from "styled-components";

import { FC } from "react";
import { useAppSelector } from "../../rtk-core/src/app/hooks";
import { getElementTypesById } from "../../rtk-core/src/features/elementTypes";
import { IElement } from "../../rtk-core/src/features/elements";
import { getActiveEvent } from "../../rtk-core/src/features/events";
import { ITeam } from "../../rtk-core/src/features/teams";
import ElementShirt from "../ElementShirt";
import Media from "../Media";

const Info = styled.div`
  font-size: 1rem;
`;

const ElementInTableWrapper = styled.div`
  ${ellipsis()};
`;

const Name = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Team = styled.span`
  margin-right: ${({ theme }) => theme.space[2]};
`;

interface IProps {
  element: IElement;
  team: ITeam;
}

const ElementInTable: FC<IProps> = ({ element, team }) => {
  const activeEvent = useAppSelector(getActiveEvent);
  const elementTypesById = useAppSelector((state) =>
    getElementTypesById(state, activeEvent.id)
  );
  const Shirt = (
    <ElementShirt
      elementId={element.id}
      sizes="(min-width: 1220px) 24px, 20px"
      hasShadow={true}
    />
  );

  return (
    <ElementInTableWrapper>
      <Media img={Shirt} centred={true}>
        <Name>{element.web_name}</Name>
        <Info>
          <Team>{team.short_name}</Team>
          <span>
            {elementTypesById[element.element_type].singular_name_short}
          </span>
        </Info>
      </Media>
    </ElementInTableWrapper>
  );
};

export default ElementInTable;
