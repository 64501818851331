import { ICup } from "../cup";
import { IH2HMatch, LeagueScoring, LeagueTypes } from "../leagues";
import { SubStatus } from "../myTeam";
import { ITeam } from "../teams";

export interface IEntryState {
  activeEntry: number | null;
  byId: Readonly<Record<string, IEntry>>;
  entryIdByEventId: Readonly<Record<string, Record<string, IEntry>>>;
  eventHistoryById: Readonly<Record<string, IEntryEventHistory[]>>;
  eventPicksById: Readonly<
    Record<string, Readonly<Record<string, IEntryEvent>>>
  >;
  seasonHistoryById: Readonly<Record<string, IEntrySeasonHistory[]>>;
  chipHistoryById: Readonly<Record<string, IEntryChipHistory[]>>;
  transfersById: Readonly<Record<string, ITransfer[]>>;
  phaseStandingsById: Readonly<
    Record<
      number,
      Readonly<Record<number, IEntryPhaseStanding[] | undefined>> | undefined
    >
  >;
}

export interface IEntry extends IEloData {
  current_event: number | null;
  id: number;
  joined_time: string;
  name: string;
  name_change_blocked: boolean;
  started_event: number;
  favourite_team: number | null;
  player_first_name: string;
  player_last_name: string;
  player_region_id: number;
  player_region_name: string;
  player_region_iso_code_short: string;
  player_region_iso_code_long: string;
  summary_overall_points: number;
  summary_overall_rank: number | null;
  summary_elo_badge: number | null;
  summary_event_points: number;
  summary_event_rank: number | null;
  kit?: string | null;
  leagues: {
    classic: ILeagueEntry[];
    cup: ICup;
    h2h: ILeagueEntry[];
    cup_matches: IH2HMatch[];
  };
  last_deadline_bank: number | null;
  last_deadline_total_transfers: number;
  last_deadline_value: number | null;
  entered_events: number[];
  years_active: number;
}

export interface IEloData {
  elo_badge: number | null;
  elo_events_until_ranked: number | null;
}

export interface ILeagueEntry {
  id: number;
  name: string;
  short_name: string | null;
  created: string;
  closed: boolean;
  rank: number | null;
  max_entries: number | null;
  league_type: LeagueTypes;
  scoring: LeagueScoring;
  admin_entry: number | null;
  start_event: number;
  entry_rank: number | null;
  entry_last_rank: number | null;
  entry_can_leave: boolean;
  entry_can_admin: boolean;
  entry_can_invite: boolean;
  has_cup: boolean;
  cup_league: number | null;
  cup_qualified: boolean | null;
  rank_count: number | null;
  entry_percentile_rank: number | null;
  active_phases: IEntryPhaseStanding[];
}

export interface IEntryEventHistory {
  event: number;
  points: number;
  total_points: number;
  rank: number | null;
  rank_sort: number | null;
  overall_rank: number | null;
  percentile_rank: number | null;
  bank: number;
  value: number;
  event_transfers: number;
  event_transfers_cost: number;
  points_on_bench: number;
}

export interface IEntrySeasonHistory {
  season_name: string;
  total_points: number;
  rank: number;
}

export interface IEntryChipHistory {
  name: string;
  time: string;
  event: number;
}

export interface IUpdateEntryData {
  name: string;
  kit?: string;
  favourite_team: number | null;
  email: boolean;
  language?: string;
}

export interface IEntryEvent {
  active_chip: string | null;
  automatic_subs: IAutomaticSub[];
  entry_history: IEntryEventHistory;
  picks: IPickLight[];
}

export interface IAutomaticSub {
  entry: number;
  element_in: number;
  element_out: number;
  event: number;
}

export interface IPickLight {
  element: number;
  is_captain: boolean;
  is_vice_captain: boolean;
  multiplier: number;
  position: number;
  subStatus?: SubStatus;
  element_type: number;
}

export interface ITransfer {
  element_in: number;
  element_in_cost: number;
  element_out: number;
  element_out_cost: number;
  entry: number;
  event: number;
  time: string;
}

export interface IFanLeagueDetails {
  league: ILeagueEntry;
  team: ITeam;
}

export interface IEntryPhaseStanding {
  phase: number;
  rank: number;
  last_rank: number;
  rank_sort: number;
  total: number;
  league_id: number;
  rank_count: number | null;
  entry_percentile_rank: number | null;
}

export interface IEntryHistorySummaryData {
  current: IEntryEventHistory[];
  past: IEntrySeasonHistory[];
  chips: IEntryChipHistory[];
}

export interface IEntryHistorySummary {
  id: number;
  data: IEntryHistorySummaryData;
}

export interface IJoinPrivateLeague {
  entry: number;
  data: ILeagueEntry;
}

export interface IFetchEntrySummaryResponse {
  id: number;
  data: IEntry;
  eventId?: number;
}

export interface IFetchEntryPicksResponse {
  entry: number;
  event: number;
  data: IEntryEvent;
}

export interface IFetchEntryPicksData {
  entryId: number;
  eventId: number;
}

export interface IFetchEntryTransfersResponse {
  id: number;
  data: ITransfer[];
}

export interface IFetchEntryPhaseStandingsResponse {
  entry: number;
  phase: number;
  data: IEntryPhaseStanding[];
}

export interface IFetchEntryPhaseStandingsData {
  entryId: number;
  phaseId: number;
}

export interface IFetchEntryQueryParams {
  entryId: number;
  phaseId?: number;
  eventId?: number;
}

export interface ILeaveLeague {
  entry: number;
  league: number;
  data: {};
}

export type CupMatchesByLeagueId = Record<string, IH2HMatch>;

export enum IEntryEventStatus {
  CanEnter = "can_enter",
  CannotEnter = "cannot_enter",
  EnteredCanManage = "entered_can_manage",
  EnteredCannotManage = "entered_cannot_manage",
  // Unreleased will remain until we decide how we want to break this out
  // away from IEntryEventStatus to a more event specific status enum
  Unreleased = "unreleased",
}
