import { formatRawAsDate, formatRawAsTime } from "./datetime";

export const getShortNameFromId = (event: number, idOnly = false) => {
  const prefix = idOnly ? "" : "GW";
  return `${prefix}${event}`;
};

export const isBlankWeek = (eventId: number) => (eventId === 7 ? true : false);

export const getEventShortName = (eventName: string) => {
  const nameSubStrings = eventName.split(" ");
  return `GW${nameSubStrings[1]}`;
};

export const getEventGameweekId = (eventName: string) =>
  eventName.split(" ")[1];

export const getEventReleaseTimeVerbose = (releaseTime: string) =>
  `${formatRawAsTime(releaseTime, "h:mmaaa")} on ${formatRawAsDate(
    releaseTime,
    "EEEE do MMMM"
  )}`;

export const getEventReleaseDate = (releaseTime: string) =>
  `${formatRawAsDate(releaseTime, "EEEE do MMMM")}`;
