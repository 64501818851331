import React from "react";
import { useSelector } from "react-redux";
import {
  getElementControls,
  getElementTypeFilterOptions,
  getTeamFilterOptions,
} from "../../rtk-core/src/features/elements";
import { getPlayerData } from "../../rtk-core/src/features/player/playerSlice";
import { Option, SelectField } from "../FieldRenderers";

interface IProps {
  handleFilterChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const ElementFilter: React.FC<IProps> = ({ handleFilterChange }) => {
  const controls = useSelector(getElementControls);
  const elementTypeFilterOptions = useSelector(getElementTypeFilterOptions);
  const player = useSelector(getPlayerData);
  const teamFilterOptions = useSelector(getTeamFilterOptions);

  return (
    <SelectField
      id="filter"
      name="filter"
      label="View"
      value={controls.filter}
      onChange={handleFilterChange}
    >
      <optgroup label="Global">
        <Option value="all" aria-selected={controls.filter === "all"}>
          All players
        </Option>
        {player && player.entry && (
          <Option value="wl" aria-selected={controls.filter === "wl"}>
            Watchlist
          </Option>
        )}
      </optgroup>
      <optgroup label="By Position">
        {elementTypeFilterOptions.map((o) => (
          <Option
            key={o.value}
            value={o.value}
            aria-selected={controls.filter === o.value}
          >
            {o.label}
          </Option>
        ))}
      </optgroup>
      <optgroup label="By Team">
        {teamFilterOptions.map((o) => (
          <Option
            key={o.value}
            value={o.value}
            aria-selected={controls.filter === o.value}
          >
            {o.label}
          </Option>
        ))}
      </optgroup>
    </SelectField>
  );
};

export { ElementFilter as ElementFilterTest };

export default ElementFilter;
