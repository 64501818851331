import { isEqual } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { INavLink } from ".";
import { RootState } from "../../rtk-core/src/app/store";
import Dropdown from "../Dropdown";
import ListItem from "../ListItem";
// import { getEntry } from "../../rtk-core/src/features/entries/entriesSlice";
import WebViewContext from "../../contexts/WebViewContext";
import {
  IEntryEventStatus,
  getEntry,
} from "../../rtk-core/src/features/entries";
import {
  getActiveEvent,
  getLastEvent,
} from "../../rtk-core/src/features/events";
import { getServerError } from "../../rtk-core/src/features/global";
import { getPlayerData } from "../../rtk-core/src/features/player/playerSlice";
import { getEntryStatusForEvent } from "../../utils/entries";

const StyledNav = styled.nav`
  white-space: nowrap;
`;

const StyledUL = styled.ul`
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  padding: 0;
  display: flex;
`;

const getSize = () => {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
};

const useWindowSize = () => {
  let [windowSize, setWindowSize] = useState(getSize());
  const handleResize = () => {
    setWindowSize(getSize());
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
};

type Props = {
  links: INavLink[];
  offset?: number; //px
};

const usePriorityNav = ({ links, offset = 0 }: Props) => {
  const windowSize = useWindowSize();
  const [childrenWidths, setWidths] = useState<number[]>([]);
  const [visibleLinks, setVisibleLinks] = useState<INavLink[]>(links);
  const [nonVisibleLinks, setNonVisibleLinks] = useState<INavLink[]>([]);
  const [oldLinks, setLinks] = useState<INavLink[]>(links);

  // If the links have changed then we need to reset everything to force
  // the nav to be re-calculated
  if (oldLinks.length && !isEqual(oldLinks, links)) {
    setWidths([]);
    setVisibleLinks(links);
    setNonVisibleLinks([]);
    setLinks(links);
  }

  /**
   * Calc cumulative widths of ref elements
   *
   * Refs are non null on mount using callback
   * Callback is then only called afterwards
   * when an item is unmounted or remounted.
   */
  const measuredRef = useCallback(
    (node: HTMLLIElement) => {
      if (node !== null && childrenWidths.length !== links.length) {
        setWidths((childrenWidths) => {
          return childrenWidths.concat(
            node.getBoundingClientRect().right + offset
          );
        });
      }
    },
    [childrenWidths.length, links.length, offset]
  );

  /**
   * Compute visible and non visible ref elements
   */
  useLayoutEffect(() => {
    // called first before childrenWidths are computed
    if (childrenWidths.length === 0) {
      return;
    }

    // After orientation change window.screen.width
    // can be smaller than windowSize.innerWidth,
    // so select smallest of two.
    const width =
      window.screen.width < windowSize.innerWidth
        ? window.screen.width
        : windowSize.innerWidth;

    const filteredIndexedElements = childrenWidths.filter(
      (e) => e < width - offset
    );
    const newVisibleLinks = links.filter(
      (_, i) => i < filteredIndexedElements.length
    );
    const newNonVisibleLinks = links.filter(
      (_, i) => i >= filteredIndexedElements.length
    );

    // only update links if they are different to previous visible links
    const areEqual =
      isEqual(visibleLinks, newVisibleLinks) &&
      isEqual(nonVisibleLinks, newNonVisibleLinks);
    if (!areEqual) {
      setVisibleLinks(newVisibleLinks);
      setNonVisibleLinks(newNonVisibleLinks);
    }
  }, [
    childrenWidths,
    windowSize.innerWidth,
    links,
    visibleLinks,
    nonVisibleLinks,
    offset,
  ]);

  return [measuredRef, visibleLinks, nonVisibleLinks] as [
    typeof measuredRef,
    INavLink[],
    INavLink[]
  ];
};

interface IPropsFromState {
  links: INavLink[];
}

type INavigationProps = IPropsFromState;

const Navigation: React.FC<INavigationProps> = ({ links }) => {
  const { isWebView } = useContext(WebViewContext);
  const [measuredRef, visibleLinks, nonVisibleLinks] = usePriorityNav({
    links,
    offset: 40,
  });

  return (
    <StyledNav
      role="navigation"
      className="ism-nav"
      aria-labelledby="ism-game-title"
    >
      <StyledUL>
        {visibleLinks.map((link) => (
          <ListItem
            {...link}
            ref={measuredRef}
            key={link.text}
            isWebView={isWebView}
          />
        ))}
        {!!nonVisibleLinks.length && (
          <Dropdown
            text="More"
            links={nonVisibleLinks as INavLink[]}
            isWebView={isWebView}
          />
        )}
      </StyledUL>
    </StyledNav>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => {
  const activeEvent = getActiveEvent(state);
  const serverError = getServerError(state);
  const lastEvent = getLastEvent(state);

  const player = getPlayerData(state);
  const entry = player && player.entry ? getEntry(state, player.entry) : null;

  const logoutNavLink = {
    useRouter: false,
    href: `${
      import.meta.env.VITE_PLUSERS_BASE
    }/accounts/logout/?app=plw-web&redirect_uri=${window.location.protocol}//${
      window.location.host
    }/`,
    text: "Sign Out",
  };

  let links: INavLink[] = [];

  const useRouter = !serverError;
  const homeNavLink = {
    useRouter,
    href: `/home`,
    text: "Home",
  };
  if (activeEvent) {
    if (player && entry) {
      // Logged in with entry
      links = [homeNavLink];

      const eventStatus = getEntryStatusForEvent(entry, activeEvent);
      const manageNavLink = {
        useRouter,
        href: `/manage/${activeEvent.id}`,
        text: "Manage Team",
      };
      const pointsNavLink = {
        useRouter,
        href: `/entry/${player.entry}/event/${activeEvent.id}`,
        text: "Manage Team",
      };
      switch (eventStatus) {
        case IEntryEventStatus.CanEnter:
          links.push(manageNavLink);
          break;
        case IEntryEventStatus.CannotEnter:
          links.push(pointsNavLink);
          break;
        case IEntryEventStatus.EnteredCanManage:
          links.push(manageNavLink);
          break;
        case IEntryEventStatus.EnteredCannotManage:
          links.push(pointsNavLink);
          break;
        case IEntryEventStatus.Unreleased:
          links.push(manageNavLink);
          break;
      }
      links.push(
        {
          useRouter,
          href: `/leagues/${activeEvent.id}`,
          baseUrl: "/leagues",
          text: "Leagues",
        },
        {
          useRouter,
          href: `/fixtures/${activeEvent.id}`,
          baseUrl: "/fixtures",
          text: "Fixtures",
        }
      );
      links.push(
        { useRouter, href: "/the-scout", text: "Scout" },
        { useRouter, href: "/profile", text: "Profile" },
        { useRouter, href: "/statistics", text: "Stats" },
        { useRouter, href: "/prizes", text: "Prizes" },
        { useRouter, href: "/help", text: "Help" },
        logoutNavLink
      );
    } else if (
      player &&
      activeEvent.id === lastEvent?.id &&
      !activeEvent.can_enter
    ) {
      // Logged in, no entry and past last entry of season
      links = [
        homeNavLink,
        { useRouter, href: "/help", text: "Help" },
        logoutNavLink,
      ];
    } else if (player) {
      // Logged in, no entry
      links = [
        homeNavLink,
        {
          useRouter,
          href: "/manage/create",
          baseUrl: "/manage",
          text: "Manage Team",
        },
        { useRouter, href: "/help", text: "Help" },
        logoutNavLink,
      ];
    } else {
      // Logged Out
      links = [
        homeNavLink,
        { useRouter, href: "/prizes", text: "Prizes" },
        { useRouter, href: "/the-scout", text: "Scout" },
        { useRouter, href: "/help", text: "Help" },
        { useRouter, href: "/statistics", text: "Statistics" },
      ];
    }
  }
  return {
    links,
  };
};

export { Navigation as NavigationTest };
export default connect(mapStateToProps)(Navigation);
