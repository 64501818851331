import {
  IPulseNewsArticle,
  IPulseRelatedArticle,
  IPulseResponse,
  IPulseTag,
  fetchNews,
  fetchNewsArticle,
  fetchVideos,
} from "./pulse";

export interface IScoutNews {
  news: IPulseResponse | null;
  externalNews: Record<string, IPulseNewsArticle> | null;
}

export interface IScoutVideos {
  videos: IPulseResponse | null;
}

export type ScoutNewsWithVideos = IScoutNews & IScoutVideos;

export const getNews = async (
  numberOfItems: number,
  tag: string = "Fantasy%20Football%20Scout"
) => {
  let data: IScoutNews = { news: null, externalNews: null };
  await fetchNews(numberOfItems, tag).then((response) => {
    data = { ...data, news: response };

    const relatedTextContentIds = data.news?.content
      .slice(1)
      .reduce((acc, cur) => [...acc, ...cur.related.slice(0, 2)], [])
      .filter((r: IPulseRelatedArticle) => r.type === "TEXT")
      .map((r: IPulseRelatedArticle) => r.id);

    const newsRequests: [Promise<IPulseNewsArticle>] =
      relatedTextContentIds.map((id: number) => fetchNewsArticle(id));

    Promise.all(newsRequests).then((articles) => {
      const externalNewsData = articles.filter((a) =>
        a ? a.tags.some((tag: IPulseTag) => tag.label === "External") : false
      );

      if (externalNewsData.length > 0) {
        const externalNewsById: Record<string, IPulseNewsArticle> =
          externalNewsData.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.id]: curr,
            }),
            {}
          );
        data = { ...data, externalNews: externalNewsById };
      }
    });
  });
  return data;
};

const getVideos = async (
  numberOfItems: number,
  tag: string = "Fantasy%20Football%20Scout"
) => {
  let data: IScoutVideos = { videos: null };
  await fetchVideos(numberOfItems, tag).then((response) => {
    data = { ...data, videos: response };
  });
  return data;
};

export const getNewsAndVideos = async (
  numberOfNewsItems: number,
  numberOfVideoItems: number,
  tag: string = "Fantasy%20Football%20Scout"
) => {
  let data: ScoutNewsWithVideos = {
    news: null,
    videos: null,
    externalNews: null,
  };

  await Promise.all([
    getNews(numberOfNewsItems, tag),
    getVideos(numberOfVideoItems, tag),
  ]).then(([newsData, videosData]) => {
    data = {
      ...newsData,
      ...videosData,
    };
  });
  return data;
};
