import styled from "styled-components";
import AddPersonBase from "../../img/icons/add-person.svg?react";

export const AddPerson = styled(AddPersonBase)`
  display: inline-block;
  vertical-align: middle;
  fill: #fff;
`;

export const ElementPosition = styled.h4`
  margin-top: ${(props) => props.theme.space[2]};
  font-family: ${({ theme }) => theme.fonts.heavy};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  line-height: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-top: ${(props) => props.theme.space[3]};
    margin-bottom: ${(props) => props.theme.space[1]};
  }
`;
