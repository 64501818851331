import React from "react";
import styled, { css } from "styled-components";

interface IStyledDialogBodyProps {
  $isPadded?: boolean;
}

const StyledDialogBody = styled.div<IStyledDialogBodyProps>`
  max-height: calc(90vh - 50px);
  overflow-y: auto;
  ${(props) =>
    props.$isPadded &&
    css`
      margin: ${props.theme.space[5]} ${props.theme.space[3]};
    `};
`;

interface IDialogBodyProps {
  children: any;
  isPadded?: boolean;
}

const DialogBody: React.FC<IDialogBodyProps> = ({ children, isPadded }) => (
  <StyledDialogBody $isPadded={isPadded} className="ism-overflow-scroll">
    {children}
  </StyledDialogBody>
);

export default DialogBody;
