import * as React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { IButtonStyledProps, SharedButtonStyles } from "../Button";

const ButtonLinkStyles = css<IButtonStyledProps>`
  ${SharedButtonStyles}
  text-decoration: none;
`;

export const ButtonHyperlink = styled.a<IButtonStyledProps>`
  ${ButtonLinkStyles}
`;

export const StyledButtonLink = styled(Link)<IButtonStyledProps>`
  ${ButtonLinkStyles}
`;

interface IButtonLinkProps {
  children: React.ReactNode;
  onClick?: () => void;
  to: string;
}

type ButtonLinkProps = IButtonLinkProps & IButtonStyledProps;

const ButtonLink: React.FC<ButtonLinkProps> = ({
  children,
  $isFullWidth,
  onClick,
  to,
  $variant = "primary",
  $stretch = false,
}) => (
  <StyledButtonLink
    to={to}
    onClick={onClick}
    $variant={$variant}
    $isFullWidth={$isFullWidth}
    $stretch={$stretch}
  >
    {children}
  </StyledButtonLink>
);

export default ButtonLink;
