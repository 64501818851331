import { size } from "polished";
import styled, { css } from "styled-components";
import { IStyledRankBadge } from "./types";

export const StyledRankBadge = styled.img<IStyledRankBadge>`
  ${({ $size }) =>
    $size
      ? css`
          ${size($size)}
        `
      : css`
          max-width: 100%;
        `};
  display: block;
`;
