import { useAppSelector } from "../../rtk-core/src/app/hooks";
import { RootState } from "../../rtk-core/src/app/store";
import { getElement } from "../../rtk-core/src/features/elements";
import { getActiveEvent } from "../../rtk-core/src/features/events";
import { getFixturesForEventByTeam } from "../../rtk-core/src/features/fixtures";
import ElementFixtureOpponent from "../Opponent/ElementFixtureOpponent";
import { ElementFixtureOpponentList } from "./styles";
import { IElementOpponents } from "./types";

const ElementFixtureOpponents = ({ elementId }: IElementOpponents) => {
  const activeEvent = useAppSelector(getActiveEvent);
  const element = useAppSelector((state: RootState) =>
    getElement(state, elementId)
  );
  const fixturesForEventByTeam = useAppSelector((state) =>
    getFixturesForEventByTeam(state, activeEvent.id)
  );

  if (!element) {
    return null;
  }

  const fixtures = fixturesForEventByTeam[element.team];

  if (fixtures.length === 1) {
    return <ElementFixtureOpponent element={element} fixture={fixtures[0]} />;
  }

  return (
    <ElementFixtureOpponentList>
      {fixtures.map((f) => (
        <li key={f.id}>
          <ElementFixtureOpponent element={element} fixture={f} />
        </li>
      ))}
    </ElementFixtureOpponentList>
  );
};

export default ElementFixtureOpponents;
