import { useAppSelector } from "../../../rtk-core/src/app/hooks";
import { getElementTypesById } from "../../../rtk-core/src/features/elementTypes";
import { getActiveEvent } from "../../../rtk-core/src/features/events";
import { StatHeading, StatRank, StatValue, StyledStatItem } from "./styles";
import { IStatItem } from "./types";

const StatItem = ({ element, rankType, value, title }: IStatItem) => {
  const activeEvent = useAppSelector(getActiveEvent);
  const elementTypesById = useAppSelector((state) =>
    getElementTypesById(state, activeEvent.id)
  );

  return (
    <StyledStatItem>
      <StatHeading>{title}</StatHeading>
      <StatValue>{value}</StatValue>
      {rankType && (
        <StatRank aria-describedby="rank-title">
          <strong>{rankType}</strong> of{" "}
          {elementTypesById[element.element_type].element_count}
        </StatRank>
      )}
    </StyledStatItem>
  );
};

export default StatItem;
