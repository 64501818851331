import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { bootstrapApi } from "../api/bootstrap";
import { IGetBootstrapStaticResponse } from "../bootstrap";

import { IElementType, IElementTypesState } from "./types";

const initialState: IElementTypesState = {
  byId: {},
};

const elementTypes = createSlice({
  name: "elementTypes",
  initialState,
  reducers: {
    addElementTypes: (state, action: PayloadAction<IElementType[]>) => ({
      ...state,
      byId: Object.fromEntries(
        action.payload.map((elementType: IElementType) => [
          elementType.id,
          {
            ...elementType,
          },
        ])
      ),
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      bootstrapApi.endpoints.getBootstrapStatic.matchFulfilled,
      (state, action: PayloadAction<IGetBootstrapStaticResponse>) =>
        elementTypes.caseReducers.addElementTypes(state, {
          ...action,
          payload: action.payload.element_types,
        })
    );
  },
});

export const { addElementTypes } = elementTypes.actions;

export default elementTypes.reducer;
