import qs from "qs";
import { useEffect } from "react";
import { useSelector } from "react-redux";
// Thunk dispatch to be added
import { useAppDispatch } from "../../rtk-core/src/app/hooks";
import { RootState } from "../../rtk-core/src/app/store";
import { entriesApi } from "../../rtk-core/src/features/api/entries";
import { getEntry } from "../../rtk-core/src/features/entries";
import ContactUs from "../ContactUs/ContactUs";
import { Main, Wrapper } from "../Layout";
import OffensiveCopy from "../OffensiveCopy/OffensiveCopy";
import Title from "../Title";

const ReportName = () => {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const entryId = Number(query.entryId) || 0;
  const leagueId = Number(query.leagueId) || 0;
  const leagueName = query.leagueName || "";

  // Fetch entry in case of page reload / direct link
  const reduxDispatch = useAppDispatch();
  useEffect(() => {
    entryId &&
      reduxDispatch(
        entriesApi.endpoints.fetchEntrySummary.initiate({ entryId: entryId })
      );
  }, [entryId, reduxDispatch]);

  // Get entry summary for now. Delay rendering if not there.
  const entry = useSelector((state: RootState) =>
    entryId ? getEntry(state, entryId) : null
  );
  if (entryId && !entry) {
    return null;
  }

  let subject = "Report offensive name - ";
  entry
    ? (subject += `Manager name: ${entry.player_first_name} ${entry.player_last_name}, Team ID: ${entry.id}, Team name: ${entry.name}`)
    : (subject += `League name: ${leagueName}, League ID: ${leagueId}`);
  return (
    <Wrapper>
      <Main>
        <div className="mx-2">
          <Title>Report Offensive Name</Title>
          <div className="mb-4">
            <OffensiveCopy />
          </div>
          <ContactUs
            message="Please include any further details here"
            subject={subject}
            canCancel={true}
          />
        </div>
      </Main>
    </Wrapper>
  );
};

export default ReportName;
