import { StyledBench, StyledSubHeading } from "./styles";
import { IProps } from "./types";

const Bench: React.FC<React.PropsWithChildren<IProps>> = ({ children }) => (
  <StyledBench>
    <StyledSubHeading>Substitutes</StyledSubHeading>
    {children}
  </StyledBench>
);

export default Bench;
