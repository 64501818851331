import { RootState } from "../../app/store";

export const getBootstrapped = (state: RootState) =>
  Boolean(state.bootstrap.bootstrap.times.browser);

export const getBootstrapTime = (state: RootState) =>
  state.bootstrap.bootstrap.times.browser;

export const getEntryEventBootstrapped = (state: RootState) =>
  Boolean(state.bootstrap.entryEventBootstrap.times.browser) &&
  !state.bootstrap.entryEventBootstrap.loading;

export const getEntryEventBootstrapTime = (state: RootState) =>
  state.bootstrap.entryEventBootstrap.times.browser;
