import { rgba } from "polished";
import styled, { css } from "styled-components";

interface IProps {
  mine: boolean;
  didNotPlay: boolean;
}

const StandingsRow = styled.tr<IProps>`
  color: ${(props) => props.theme.colors.primary};

  ${(props) =>
    props.mine &&
    css`
      td {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
        > a {
          color: ${({ theme }) => theme.colors.white};
        }
      }
      &&& {
        td {
          position: relative;
          border-top: 1px solid ${({ theme }) => rgba(theme.colors.white, 0.2)};
          border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
          &:first-child  {
            border-left: 1px solid
              ${({ theme }) => rgba(theme.colors.white, 0.2)};
          }
          &:last-child  {
            border-right: 1px solid
              ${({ theme }) => rgba(theme.colors.white, 0.2)};
          }
          &:not(:first-child)::before {
            background-color: ${({ theme }) => rgba(theme.colors.white, 0.2)};
          }
        }
      }
    `}

  ${(props) =>
    props.didNotPlay &&
    !props.mine &&
    css`
      opacity: 0.6;
    `}

    td:nth-child(1), td:nth-child(3) {
    font-family: ${(props) => props.theme.fonts.bold};
    font-size: ${({ theme }) => theme.fontSizes[2]};
  }
`;

export default StandingsRow;
