import React from "react";
import EventNavigator from "../components/EventNavigator";
import Fixtures from "../components/Fixtures";
import FixturesNav from "../components/FixturesNav";
import HelmetHead from "../components/HelmetHead";
import { GridItem, GridWrapper, TwoCol50Grid } from "../components/Layout";
import Title from "../components/Title";
import { useAppSelector } from "../rtk-core/src/app/hooks";
import { IEvent, getActiveEvent } from "../rtk-core/src/features/events";

const FixturesWrapper: React.FC = () => {
  const activeEvent = useAppSelector(getActiveEvent);
  const getEventUrl = (event: IEvent) => {
    return `/fixtures/${event.id}`;
  };

  return (
    <GridWrapper>
      <HelmetHead
        title="View Latest Premier League Fixtures | Fantasy Premier League"
        description="To view the latest Gameweek fixtures and help choose your next Fantasy Premier League team, visit the official website of the Premier League."
      />
      <TwoCol50Grid $hasMargin={true}>
        <GridItem>
          <Title>Fixtures &amp; Results</Title>
        </GridItem>
        <GridItem>
          <div className="-mt-3">
            <FixturesNav event={activeEvent} />
          </div>
        </GridItem>
        <GridItem>
          <div className="mt-3 -mb-3">
            <EventNavigator getEventUrl={getEventUrl} />
          </div>
        </GridItem>
        <GridItem>
          <Fixtures eventId={activeEvent.id} useLinks />
        </GridItem>
      </TwoCol50Grid>
    </GridWrapper>
  );
};

export default FixturesWrapper;
