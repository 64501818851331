import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IPlayer, IPlayerState } from "./types";
import { bootstrapApi } from "../api/bootstrap";
import { playerApi } from "../api/player";
import { IGetBootstrapDynamicResponse } from "../bootstrap";
import { RootState } from "../../app/store";
import { IError } from "../../app/types";

const initialState: IPlayerState = {
  data: null,
  loginError: null,
  passwordChangeError: null,
  passwordResetError: null,
  passwordSetError: null,
  playerUpdateError: null,
  registerError: null,
  watched: [],
};

const player = createSlice({
  name: "player",
  initialState,
  reducers: {
    addLoginError: (state, action: PayloadAction<IError | null>) => ({
      ...state,
      loginError: action.payload,
    }),
    addPasswordChangeError: (state, action: PayloadAction<IError | null>) => ({
      ...state,
      passwordChangeError: action.payload,
    }),
    addPasswordResetError: (state, action: PayloadAction<IError | null>) => ({
      ...state,
      passwordResetError: action.payload,
    }),
    addPasswordSetError: (state, action: PayloadAction<IError | null>) => ({
      ...state,
      passwordSetError: action.payload,
    }),
    addPlayer: (state, action: PayloadAction<IPlayer | null>) => ({
      ...state,
      data: action.payload,
    }),
    addPlayerRegisterError: (state, action: PayloadAction<IError | null>) => ({
      ...state,
      registerError: action.payload,
    }),
    addPlayerUpdateError: (state, action: PayloadAction<IError | null>) => ({
      ...state,
      playerUpdateError: action.payload,
    }),
    addToWatched: (state, action: PayloadAction<number>) => ({
      ...state,
      watched:
        state.watched.indexOf(action.payload) === -1
          ? [...state.watched, action.payload]
          : state.watched,
    }),
    addWatched: (state, action: PayloadAction<number[]>) => ({
      ...state,
      watched: action.payload,
    }),
    removeFromWatched: (state, action: PayloadAction<number>) => ({
      ...state,
      watched: state.watched.filter((code) => code !== action.payload),
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      bootstrapApi.endpoints.getBootstrapDynamic.matchFulfilled,
      (state, action: PayloadAction<IGetBootstrapDynamicResponse>) =>
        player.caseReducers.addPlayer(state, {
          ...action,
          payload: action.payload.player,
        })
    );
    builder.addMatcher(
      bootstrapApi.endpoints.getBootstrapDynamic.matchFulfilled,
      (state, action: PayloadAction<IGetBootstrapDynamicResponse>) =>
        player.caseReducers.addWatched(state, {
          ...action,
          payload: action.payload.watched,
        })
    );
    builder.addMatcher(
      playerApi.endpoints.playerLogin.matchRejected,
      (state, action: PayloadAction<IError>) =>
        player.caseReducers.addLoginError(state, action)
    );
    builder.addMatcher(
      playerApi.endpoints.playerPasswordChange.matchRejected,
      (state, action: PayloadAction<IError>) =>
        player.caseReducers.addPasswordChangeError(state, action)
    );
    builder.addMatcher(
      playerApi.endpoints.playerPasswordReset.matchRejected,
      (state, action: PayloadAction<IError>) =>
        player.caseReducers.addPasswordResetError(state, action)
    );
    builder.addMatcher(
      playerApi.endpoints.playerPasswordSet.matchRejected,
      (state, action: PayloadAction<IError>) =>
        player.caseReducers.addPasswordSetError(state, action)
    );
    builder.addMatcher(
      playerApi.endpoints.playerRegister.matchRejected,
      (state, action: PayloadAction<IError>) =>
        player.caseReducers.addPlayerRegisterError(state, action)
    );
    builder.addMatcher(
      playerApi.endpoints.playerUpdate.matchRejected,
      (state, action: PayloadAction<IError>) =>
        player.caseReducers.addPlayerUpdateError(state, action)
    );
  },
});

export const {
  addLoginError,
  addPasswordChangeError,
  addPasswordResetError,
  addPasswordSetError,
  addPlayer,
  addPlayerRegisterError,
  addPlayerUpdateError,
  addToWatched,
  addWatched,
  removeFromWatched,
} = player.actions;

// State Selectors
export const getPlayerData = (state: RootState) => state.player.data;

export const getWatched = (state: RootState) => state.player.watched;

export const getLoginError = (state: RootState) => state.player.loginError;

export const getPasswordChangeError = (state: RootState) =>
  state.player.passwordChangeError;

export const getPasswordResetError = (state: RootState) =>
  state.player.passwordResetError;

export const getPasswordSetError = (state: RootState) =>
  state.player.passwordSetError;

export const getPlayerUpdateError = (state: RootState) =>
  state.player.playerUpdateError;

export const getRegisterError = (state: RootState) =>
  state.player.registerError;

export default player.reducer;
