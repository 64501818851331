import { getEloRank } from "../../../utils/ranks";
import RankBadge from "../../RankBadge";
import RankLabel from "../RankLabel/RankLabel";
import { IRankTypes } from "./types";
import {
  RankArrow,
  RankInfoBadgeWrapper,
  RankInfoWrapper,
  RankLevelsRanksWrapper,
} from "./styles";

const rankLevels = {
  ranked: [
    { elo_badge: 26, elo_events_until_ranked: 26 },
    { elo_badge: 27, elo_events_until_ranked: 27 },
    { elo_badge: 28, elo_events_until_ranked: 28 },
    { elo_badge: 29, elo_events_until_ranked: 29 },
    { elo_badge: 30, elo_events_until_ranked: 30 },
  ],
  unranked: [
    { elo_badge: null, elo_events_until_ranked: 5 },
    { elo_badge: null, elo_events_until_ranked: 4 },
    { elo_badge: null, elo_events_until_ranked: 3 },
    { elo_badge: null, elo_events_until_ranked: 2 },
    { elo_badge: null, elo_events_until_ranked: 1 },
  ],
};

const RankLevels = ({ rankType }: IRankTypes) => {
  const ranks = rankLevels[rankType];
  return (
    <RankInfoWrapper>
      <RankLevelsRanksWrapper>
        {ranks.map((level, index) => {
          const eloRank = level ? getEloRank(level) : "Unranked_5";
          const rankName = level.elo_badge
            ? getEloRank(level).split("_").join(" ")
            : `Unranked ${level.elo_events_until_ranked}`;

          return (
            <>
              <RankInfoBadgeWrapper>
                <RankBadge rank={eloRank} name={rankName} size={90} />
                <RankLabel rankName={rankName} />
              </RankInfoBadgeWrapper>
              {index < ranks.length - 1 && <RankArrow />}
            </>
          );
        })}
      </RankLevelsRanksWrapper>
    </RankInfoWrapper>
  );
};

export default RankLevels;
