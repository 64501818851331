import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export const getChipsById = (state: RootState) => state.chips.byId;

export const getChipByChipId = createSelector(
  [getChipsById, (_: RootState, chipId: number) => chipId],
  (chips, chipId) => chips[chipId]
);

export const getPotentialChips = (state: RootState) => state.chips.potential;

export const getPlayedChips = createSelector(getPotentialChips, (chips) =>
  chips.filter((c) => c.status_for_entry === "played")
);

export const getActiveChip = createSelector(getPotentialChips, (chips) => {
  const matches = chips.filter((c) => c.status_for_entry === "active");
  return matches.length ? matches[0] : null;
});

export const getActiveChipName = createSelector(getActiveChip, (chip) =>
  chip ? chip.name : ""
);

export const getProposedChip = createSelector(getPotentialChips, (chips) => {
  const matches = chips.filter((c) => c.status_for_entry === "proposed");
  return matches.length ? matches[0] : null;
});

export const getProposedChipName = createSelector(getProposedChip, (chip) =>
  chip ? chip.name : ""
);

// This is most relevent for transfer chips. On team chips we have to consider
// unavailable as well as they can become 'available' if an active chip is
// cancelled
export const getAvailableChipNames = createSelector(
  getPotentialChips,
  (chips) =>
    chips.filter((c) => c.status_for_entry === "available").map((c) => c.name)
);

export const teamChipsHaveChanged = createSelector(getPotentialChips, (chips) =>
  chips.some(
    (c) =>
      c.chip_type === "team" &&
      Boolean(c.status_for_entry.match(/^(cancelled|proposed)$/))
  )
);

export const getActiveOrProposedTeamChipName = createSelector(
  getPotentialChips,
  (chips) =>
    chips.reduce<string | null>(
      (memo, c) =>
        c.chip_type === "team" &&
        c.status_for_entry.match(/^(active|proposed)$/)
          ? c.name
          : memo,
      null
    )
);

export const getActiveOrProposedTransferChipName = createSelector(
  getPotentialChips,
  (chips) =>
    chips.reduce<string | null>(
      (memo, c) =>
        c.chip_type === "transfer" &&
        c.status_for_entry.match(/^(active|proposed)$/)
          ? c.name
          : memo,
      null
    )
);

export const getActiveOrProposedAllChipName = createSelector(
  getPotentialChips,
  (chips) =>
    chips.reduce<string | null>(
      (memo, c) =>
        c.status_for_entry.match(/^(active|proposed)$/) ? c.name : memo,
      null
    )
);
