import * as React from "react";
import styled from "styled-components";

const StyledLatestAlert = styled.div`
  padding: ${(props) => props.theme.space[1]} ${(props) => props.theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[1]};
  background: ${({ theme }) => theme.colors.purpleLight};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const StyledAnchor = styled.a`
  color: ${({ theme }) => theme.colors.white};
  text-decoration: underline;
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const LatestAlert: React.FC<any> = () => {
  const showAlert = false;
  if (!showAlert) {
    return null;
  }
  return (
    <div className="my-2">
      <StyledLatestAlert>
        <p>
          Gameweek 12: Navigating Arsenal v Man City postponement.{" "}
          <StyledAnchor href="https://www.premierleague.com/news/2847137">
            Learn more
          </StyledAnchor>
          .
        </p>
      </StyledLatestAlert>
    </div>
  );
};

export default LatestAlert;
