import { useAppDispatch, useAppSelector } from "../../rtk-core/src/app/hooks";
import { doEntryPerEventBootstrap } from "../../rtk-core/src/features/bootstrap";
import { getOverallLeagueEntryForEntry } from "../../rtk-core/src/features/entries";
import { getActiveEvent } from "../../rtk-core/src/features/events";
import {
  getDefaultEventForPhase,
  getEventPhase,
  getEventsInPhase,
  getMultiEventPhaseForEventId,
} from "../../rtk-core/src/features/phases";
import {
  getPlayerData,
  ILoggedInPlayer,
} from "../../rtk-core/src/features/player";
import { getTopPercentileString } from "../../utils/leagues";
import { getPhaseAssets } from "../../utils/phase";
import EventCarousel from "../EventCarousel";
import PhaseNavigator from "../PhaseNavigator";
import {
  PhaseDescription,
  PhaseEventsTitle,
  PhaseInfo,
  PhaseName,
  PhaseStat,
  PhaseStatHeading,
  PhaseStatMainHeading,
  PhaseStatMainValue,
  PhaseStats,
  PhaseStatsMain,
  PhaseStatsSecondary,
  PhaseStatValue,
  PhaseSummaryDivider,
  PhaseSummaryHeader,
  StyledPhaseSummary,
} from "./styles";

const PhaseSummary = () => {
  const dispatch = useAppDispatch();
  const activeEvent = useAppSelector(getActiveEvent);
  // activeEvent should equate to the 'current' event by default
  const currentEventPhase = useAppSelector((state) =>
    getMultiEventPhaseForEventId(state, activeEvent.id)
  );
  const eventsInCurrentEventPhase = useAppSelector((state) =>
    currentEventPhase ? getEventsInPhase(state, currentEventPhase.id) : []
  );

  const nextEventPhase = useAppSelector((state) =>
    currentEventPhase ? getEventPhase(state, currentEventPhase.id + 1) : null
  );

  const prevEventPhase = useAppSelector((state) =>
    currentEventPhase ? getEventPhase(state, currentEventPhase.id - 1) : null
  );

  const player = useAppSelector(getPlayerData) as ILoggedInPlayer;
  const overallLeagueEntry = useAppSelector((state) =>
    getOverallLeagueEntryForEntry(state, player.entry)
  );

  const defaultEventForNextPhase = useAppSelector((state) =>
    nextEventPhase ? getDefaultEventForPhase(state, nextEventPhase.id) : null
  );

  const defaultEventForPrevPhase = useAppSelector((state) =>
    prevEventPhase ? getDefaultEventForPhase(state, prevEventPhase.id) : null
  );

  if (!currentEventPhase || !eventsInCurrentEventPhase.length) {
    return null;
  }

  const overallEventPhaseData = overallLeagueEntry?.active_phases.find(
    (p) => p.phase === currentEventPhase.id
  );

  // More detail on the use of doEntryPerEventBootstrap to navigate to the desired
  // event can be seen here https://github.com/ismfg/games/pull/6507#discussion_r1785918191
  const handleNextClick = () => {
    if (defaultEventForNextPhase) {
      dispatch(
        doEntryPerEventBootstrap({
          entryId: player.entry.toString(),
          eventId: defaultEventForNextPhase.id.toString(),
        })
      );
    }
  };
  const handlePrevClick = () => {
    if (defaultEventForPrevPhase) {
      dispatch(
        doEntryPerEventBootstrap({
          entryId: player.entry.toString(),
          eventId: defaultEventForPrevPhase.id.toString(),
        })
      );
    }
  };

  const phaseAssets = getPhaseAssets(currentEventPhase.id);
  const phaseTitle = phaseAssets?.title || currentEventPhase.name;

  const phaseSubheading = `Gameweek ${currentEventPhase.start_event} - ${currentEventPhase.stop_event}`;
  return (
    <>
      <PhaseNavigator
        activePhaseId={currentEventPhase.id}
        nextOnClick={handleNextClick}
        prevOnClick={handlePrevClick}
      />
      <StyledPhaseSummary>
        {/* Phase header */}
        <PhaseInfo>
          <PhaseSummaryHeader>
            <h2>
              <PhaseEventsTitle>{phaseSubheading}</PhaseEventsTitle>
              <PhaseName>{phaseTitle}</PhaseName>
            </h2>
            <PhaseDescription>{phaseAssets?.description}</PhaseDescription>
          </PhaseSummaryHeader>
          {/* Phase scoreboard */}
          <PhaseStats>
            <PhaseStatsMain>
              <PhaseStatMainHeading>Event pts</PhaseStatMainHeading>
              <PhaseStatMainValue>
                {overallEventPhaseData && overallEventPhaseData.total
                  ? overallEventPhaseData.total
                  : "-"}
              </PhaseStatMainValue>
            </PhaseStatsMain>
            <PhaseStatsSecondary>
              <PhaseStat>
                <PhaseStatHeading>Global rank</PhaseStatHeading>
                <PhaseStatValue>
                  {overallEventPhaseData?.entry_percentile_rank
                    ? getTopPercentileString(
                        overallEventPhaseData.entry_percentile_rank
                      )
                    : "-"}
                </PhaseStatValue>
              </PhaseStat>
              <PhaseStat>
                <PhaseStatHeading>Highest points</PhaseStatHeading>
                <PhaseStatValue>
                  {currentEventPhase.highest_score !== null
                    ? currentEventPhase.highest_score
                    : "-"}
                </PhaseStatValue>
              </PhaseStat>
            </PhaseStatsSecondary>
          </PhaseStats>
        </PhaseInfo>
        <PhaseSummaryDivider />
        <EventCarousel events={eventsInCurrentEventPhase} />
      </StyledPhaseSummary>
    </>
  );
};

export default PhaseSummary;
