import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { entriesApi } from "../api/entries";
import {
  IEntry,
  IEntryHistorySummary,
  IEntryState,
  IFetchEntryPhaseStandingsResponse,
  IFetchEntryPicksResponse,
  IFetchEntrySummaryResponse,
  IFetchEntryTransfersResponse,
  IJoinPrivateLeague,
  ILeagueEntry,
  ILeaveLeague,
} from "./types";

const initialState: IEntryState = {
  activeEntry: null,
  byId: {},
  entryIdByEventId: {},
  eventHistoryById: {},
  eventPicksById: {},
  seasonHistoryById: {},
  chipHistoryById: {},
  transfersById: {},
  phaseStandingsById: {},
};

const entries = createSlice({
  name: "entries",
  initialState,
  reducers: {
    fetchEntrySummary: (
      state,
      action: PayloadAction<IFetchEntrySummaryResponse>
    ) =>
      action.payload.eventId
        ? {
            ...state,
            byId: {
              ...state.byId,
              [action.payload.id]: action.payload.data,
            },
            entryIdByEventId: {
              ...state.entryIdByEventId,
              [action.payload.eventId]: {
                ...state.entryIdByEventId[action.payload.eventId],
                [action.payload.id]: action.payload.data,
              },
            },
          }
        : {
            ...state,
            byId: {
              ...state.byId,
              [action.payload.id]: action.payload.data,
            },
          },
    joinPrivateLeague: (state, action: PayloadAction<IJoinPrivateLeague>) => {
      const newLeague = action.payload.data;
      const leaguesKey = newLeague.scoring === "c" ? "classic" : "h2h";
      const entry: IEntry = state.byId[action.payload.entry];
      if (entry) {
        const isAlreadyInLeague = entry.leagues[leaguesKey].some(
          (league) => league.id === newLeague.id
        );

        if (!isAlreadyInLeague) {
          const updatedEntry = {
            ...entry,
            leagues: {
              ...entry.leagues,
              [leaguesKey]: entry.leagues[leaguesKey].concat([newLeague]),
            },
          };
          state.byId[action.payload.entry] = updatedEntry;
        }
      }
    },
    leaveLeague: (state, action: PayloadAction<ILeaveLeague>) => {
      const updated_entry: IEntry = state.byId[action.payload.entry];
      if (updated_entry) {
        updated_entry.leagues.classic = updated_entry.leagues.classic.filter(
          (le: ILeagueEntry) => le.id !== action.payload.league
        );
        updated_entry.leagues.h2h = updated_entry.leagues.h2h.filter(
          (le: ILeagueEntry) => le.id !== action.payload.league
        );
        state = {
          ...state,
          [action.payload.entry]: updated_entry,
        };
      }
    },
    deleteLeague: (state, action: PayloadAction<ILeaveLeague>) => {
      const updated_entry: IEntry = state.byId[action.payload.entry];
      if (updated_entry) {
        updated_entry.leagues.classic = updated_entry.leagues.classic.filter(
          (le: ILeagueEntry) => le.id !== action.payload.league
        );
        updated_entry.leagues.h2h = updated_entry.leagues.h2h.filter(
          (le: ILeagueEntry) => le.id !== action.payload.league
        );
        state = {
          ...state,
          [action.payload.entry]: updated_entry,
        };
      }
    },
    fetchEntryHistory: (
      state,
      action: PayloadAction<IEntryHistorySummary>
    ) => ({
      ...state,
      eventHistoryById: {
        ...state.eventHistoryById,
        [action.payload.id]: action.payload.data.current,
      },
      seasonHistoryById: {
        ...state.seasonHistoryById,
        [action.payload.id]: action.payload.data.past,
      },
      chipHistoryById: {
        ...state.chipHistoryById,
        [action.payload.id]: action.payload.data.chips,
      },
    }),
    fetchEntryEventPicks: (
      state,
      action: PayloadAction<IFetchEntryPicksResponse>
    ) => ({
      ...state,
      eventPicksById: {
        ...state.eventPicksById,
        [action.payload.entry]: {
          ...state.eventPicksById[action.payload.entry],
          [action.payload.event]: action.payload.data,
        },
      },
    }),
    fetchEntryTransfers: (
      state,
      action: PayloadAction<IFetchEntryTransfersResponse>
    ) => ({
      ...state,
      transfersById: {
        ...state.transfersById,
        [action.payload.id]: action.payload.data,
      },
    }),
    fetchEntryPhaseStandings: (
      state,
      action: PayloadAction<IFetchEntryPhaseStandingsResponse>
    ) => ({
      ...state,
      phaseStandingsById: {
        ...state.phaseStandingsById,
        [action.payload.entry]: {
          ...state.phaseStandingsById[action.payload.entry],
          [action.payload.phase]: action.payload.data,
        },
      },
    }),
    setActiveEntry: (state, action: PayloadAction<number | null>) => ({
      ...state,
      activeEntry: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      entriesApi.endpoints.fetchEntrySummary.matchFulfilled,
      (state, action: PayloadAction<IFetchEntrySummaryResponse>) =>
        entries.caseReducers.fetchEntrySummary(state, {
          ...action,
          payload: action.payload,
        })
    );
    builder.addMatcher(
      entriesApi.endpoints.fetchEntryHistory.matchFulfilled,
      (state, action: PayloadAction<IEntryHistorySummary>) =>
        entries.caseReducers.fetchEntryHistory(state, {
          ...action,
          payload: action.payload,
        })
    );
    builder.addMatcher(
      entriesApi.endpoints.fetchEntryEventPicks.matchFulfilled,
      (state, action: PayloadAction<IFetchEntryPicksResponse>) =>
        entries.caseReducers.fetchEntryEventPicks(state, {
          ...action,
          payload: action.payload,
        })
    );
    builder.addMatcher(
      entriesApi.endpoints.fetchEntryTransfers.matchFulfilled,
      (state, action: PayloadAction<IFetchEntryTransfersResponse>) =>
        entries.caseReducers.fetchEntryTransfers(state, {
          ...action,
          payload: action.payload,
        })
    );
    builder.addMatcher(
      entriesApi.endpoints.fetchEntryPhaseStandings.matchFulfilled,
      (state, action: PayloadAction<IFetchEntryPhaseStandingsResponse>) =>
        entries.caseReducers.fetchEntryPhaseStandings(state, {
          ...action,
          payload: action.payload,
        })
    );
  },
});

export const {
  fetchEntrySummary,
  joinPrivateLeague,
  fetchEntryEventPicks,
  fetchEntryHistory,
  fetchEntryPhaseStandings,
  fetchEntryTransfers,
  leaveLeague,
  deleteLeague,
  setActiveEntry,
} = entries.actions;

export default entries.reducer;
