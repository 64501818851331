import {
  RankInfoDescription,
  RankInfoHeader,
  RankInfoHeading,
  StyledRankInfo,
} from "./styles";
import { IRankInfo } from "./types";

const RankInfo = ({ description, isPrimary, heading, children }: IRankInfo) => (
  <StyledRankInfo $isPrimary={isPrimary}>
    <RankInfoHeader>
      <RankInfoHeading>{heading}</RankInfoHeading>
      <RankInfoDescription>{description}</RankInfoDescription>
    </RankInfoHeader>
    {children}
  </StyledRankInfo>
);

export default RankInfo;
