import ChallengePromos from "../components/ChallengePromos";
import HelpNav from "../components/HelpNav";
import { GridItem, GridWrapper, TwoCol50Grid } from "../components/Layout";
import Title from "../components/Title";

const FantasyChallenge = () => (
  <GridWrapper>
    <TwoCol50Grid $hasMargin={true}>
      <GridItem>
        <Title>Help</Title>
      </GridItem>
      <GridItem>
        <div className="-mt-3">
          <HelpNav />
        </div>
      </GridItem>

      <ChallengePromos />
    </TwoCol50Grid>
  </GridWrapper>
);

export default FantasyChallenge;
