import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFixtureState, IFixture, IFixtureAndEvent } from "./types";
import { fixturesApi } from "../api/fixtures";

const initialState: IFixtureState = {
  byEvent: {},
};

const fixtures = createSlice({
  name: "fixtures",
  initialState,
  reducers: {
    addFixturesForEvent: (state, action: PayloadAction<IFixtureAndEvent>) => ({
      ...state,
      byEvent: {
        ...state.byEvent,
        [action.payload.event.toString()]: action.payload.data,
      },
    }),
    addFixturesFuture: (state, action: PayloadAction<IFixture[]>) =>
      action.payload.forEach((fixture) => {
        if (fixture.event && fixture.event in state.byEvent) {
          const existingIds = state.byEvent[fixture.event].map(
            (fixture) => fixture.id
          );
          if (!existingIds.includes(fixture.id)) {
            state.byEvent[fixture.event].push({ ...fixture });
          }
        } else if (fixture.event) {
          state.byEvent[fixture.event] = [{ ...fixture }];
        }
      }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      fixturesApi.endpoints.fetchFixtures.matchFulfilled,
      (state, action: PayloadAction<IFixtureAndEvent>) =>
        fixtures.caseReducers.addFixturesForEvent(state, {
          ...action,
          payload: action.payload,
        })
    );
    builder.addMatcher(
      fixturesApi.endpoints.fetchFixturesFuture.matchFulfilled,
      (state, action: PayloadAction<IFixture[]>) =>
        fixtures.caseReducers.addFixturesFuture(state, {
          ...action,
          payload: action.payload,
        })
    );
  },
});

export const { addFixturesForEvent, addFixturesFuture } = fixtures.actions;

export default fixtures.reducer;
