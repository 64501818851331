import {
  useAppDispatch,
  useAppSelector,
} from "../../../rtk-core/src/app/hooks";
import { getElementTypesById } from "../../../rtk-core/src/features/elementTypes";
import {
  getElement,
  getElementsEventDataById,
  showElementSummary,
} from "../../../rtk-core/src/features/elements";
import { getActiveEvent } from "../../../rtk-core/src/features/events";
import { getFixturesForEventByTeam } from "../../../rtk-core/src/features/fixtures";
import {
  actionSubstitution,
  getPicksSaved,
  substitutionStop,
} from "../../../rtk-core/src/features/manage";
import { integerToMoneyWithCurrency } from "../../../utils/money";
import ElementShirt from "../../ElementShirt";
import ElementTypeLabel from "../../ElementTypeLabel";
import {
  HiddenDescription,
  PitchCardButton,
  PitchPlaceholder,
} from "../styles";
import ElementFixtureBar from "./ElementFixtureBar";
import {
  Captain,
  DreamTeamIcon,
  DreamTeamLink,
  ElementCost,
  ElementName,
  PitchElementData,
  PitchElementIconsWrap,
  ShirtWrap,
  StatusIcon,
  StyledGameChanger,
  StyledPitchElement,
  ViceCaptain,
} from "./styles";
import { IPitchElement } from "./types";

const PitchElement: React.FC<IPitchElement> = ({ pick, onClick }) => {
  const dispatch = useAppDispatch();
  const activeEvent = useAppSelector(getActiveEvent);

  const savedPick = useAppSelector(getPicksSaved).find(
    (p) => p.element === pick.element
  );

  const element = useAppSelector((state) =>
    getElement(state, pick.element!, activeEvent.id)
  );

  const elementTypes = useAppSelector((state) =>
    getElementTypesById(state, activeEvent.id)
  );

  const fixturesForEventByTeam = useAppSelector((state) =>
    getFixturesForEventByTeam(state, activeEvent.id)
  );

  const copnr = element?.chance_of_playing_next_round;

  const elementsDataById = useAppSelector((state) =>
    getElementsEventDataById(state, activeEvent.id)
  );

  // To be implemented as part of https://github.com/ismfg/pl-issues/issues/279
  const inDreamTeam = false;

  if (pick.element === 0) {
    return (
      <PitchCardButton>
        <PitchPlaceholder>
          <ElementTypeLabel elementType={elementTypes[pick.elementType]} />
          <HiddenDescription>Revealed at kick off</HiddenDescription>
        </PitchPlaceholder>
      </PitchCardButton>
    );
  }

  const isGameChanger =
    elementsDataById && pick.element
      ? elementsDataById[pick.element].modified
      : false;

  if (!element) {
    return <PitchPlaceholder>Coming soon</PitchPlaceholder>;
  }

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else {
      if (pick) {
        if (pick.subStatus === "target") {
          dispatch(actionSubstitution(pick.position));
        } else if (pick.subStatus === "instigator") {
          dispatch(substitutionStop());
        } else {
          dispatch(showElementSummary(element.id));
        }
      } else {
        dispatch(showElementSummary(element.id));
      }
    }
  };

  const elementFixtures = fixturesForEventByTeam[element.team];
  const isStarted = elementFixtures.some((fixture) => fixture.started);

  return (
    <PitchCardButton onClick={handleOnClick}>
      <StyledPitchElement $variant={pick?.subStatus || ""}>
        {isStarted ? (
          <ShirtWrap>
            <ElementShirt
              elementId={element.id}
              sizes="(min-width: 1024px) 84px, 46px"
            />
          </ShirtWrap>
        ) : (
          <>
            <ElementCost>
              {savedPick
                ? integerToMoneyWithCurrency(savedPick.selling_price, 10)
                : pick && pick.selling_price
                ? integerToMoneyWithCurrency(pick.selling_price, 10)
                : integerToMoneyWithCurrency(element.now_cost, 10)}
            </ElementCost>
            <ElementShirt
              elementId={element.id}
              sizes="(min-width: 1024px) 76px, 42px"
            />
          </>
        )}
        <PitchElementIconsWrap>
          {pick && pick.is_captain && <Captain />}
          {pick && pick.is_vice_captain && <ViceCaptain />}
          {isGameChanger && <StyledGameChanger />}
          {inDreamTeam && (
            <DreamTeamLink to="">
              <DreamTeamIcon />
            </DreamTeamLink>
          )}
        </PitchElementIconsWrap>
        {element.news && copnr !== null && (
          <StatusIcon $copnr={copnr} title={element.news} />
        )}
        <PitchElementData>
          <ElementName $copnr={element.news ? copnr : null}>
            {element.web_name}
          </ElementName>
          {/* Secondary Stat */}
          {/* Secondary stat can be fixture(s), points or both for DGW. Needs to be a 
          single ElementFixtureData component */}
          {pick && (
            <ElementFixtureBar
              elementFixtures={elementFixtures}
              isStarted={isStarted}
              pick={pick}
            />
          )}
        </PitchElementData>
      </StyledPitchElement>
    </PitchCardButton>
  );
};

export default PitchElement;
