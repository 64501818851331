import styled from "styled-components";

export const Opp = styled.span`
  font-family: ${(props) => props.theme.fonts.heavy};
`;

export const BadgeWrap = styled.span`
  margin-right: ${(props) => props.theme.space[1]};
`;

export const HistoryResult = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
`;
