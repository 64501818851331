import { useAppSelector } from "../../rtk-core/src/app/hooks";
import {
  IEntryEventStatus,
  getEntry,
  getEntryEventHistoryForEvent,
  getEntryEventPoints,
  getOverallLeagueEntryForEntry,
} from "../../rtk-core/src/features/entries";
import {
  ILoggedInPlayer,
  getPlayerData,
} from "../../rtk-core/src/features/player";
import { getChallengeAssets } from "../../utils/challenge";
import {
  getEntryEnteredEvent,
  getEntryStatusForEvent,
} from "../../utils/entries";
import { getTopPercentileString } from "../../utils/leagues";
import ManageTeamCta from "../ManageTeamCta";
import EventStat from "./EventStat";
import {
  EventCardHeader,
  EventCardHeading,
  EventDescription,
  EventHeading,
  EventHeadingCopy,
  EventHeadingStatus,
  EventStatList,
  LockupImg,
  ManageTeamMsg,
  StyledEventCard,
} from "./styles";
import { IEventCard } from "./types";

const EventCard = ({ event }: IEventCard) => {
  const player = useAppSelector(getPlayerData) as ILoggedInPlayer;
  // We can non-null assert the entry object as the EventCarousel isn't shown until
  // the player has an entry
  const entry = useAppSelector((state) => getEntry(state, player.entry))!;

  const entryEnteredEvent = getEntryEnteredEvent(entry, event);

  const overallLeagueEntry = useAppSelector((state) =>
    entry ? getOverallLeagueEntryForEntry(state, entry.id) : null
  );

  const challenge = getChallengeAssets(event.id);

  const entryHistoryForEvent = useAppSelector((state) =>
    entry ? getEntryEventHistoryForEvent(state, entry.id, event.id) : null
  );
  const entryStatusForEvent = entry ? getEntryStatusForEvent(entry, event) : "";

  const entryEventPoints = useAppSelector((state) =>
    entryEnteredEvent ? getEntryEventPoints(state, entry!.id, event.id) : 0
  );

  if (!entry) {
    return null;
  }

  return (
    <StyledEventCard>
      {/* Event Card Top */}
      <div>
        <EventCardHeader>
          <EventHeading>
            <EventHeadingCopy>{event.name}</EventHeadingCopy>
            {event.released && (event.can_enter || event.can_manage) && (
              <EventHeadingStatus>Open</EventHeadingStatus>
            )}
          </EventHeading>
        </EventCardHeader>
        <>
          {challenge ? (
            <>
              <LockupImg src={challenge.lockupPath} alt="" />
              <EventCardHeading>{challenge.copy.title}</EventCardHeading>
              <EventDescription>{challenge.copy.description}</EventDescription>
            </>
          ) : (
            <>
              <LockupImg src="/img/challenge/lockup-fallback.png" alt="" />
              <EventCardHeading>Coming soon</EventCardHeading>
              <EventDescription>
                Check back soon to view the challenge
              </EventDescription>
            </>
          )}
        </>
      </div>
      {/* Event Card Bottom */}
      <div>
        <EventStatList>
          <EventStat
            heading="Global rank"
            value={
              entryHistoryForEvent?.percentile_rank
                ? getTopPercentileString(entryHistoryForEvent.percentile_rank)
                : "-"
            }
            url={
              overallLeagueEntry
                ? `/leagues/${overallLeagueEntry.id}/standings/c/${event.id}`
                : ""
            }
          />
          <EventStat
            $isPrimary={true}
            heading="Points"
            value={
              (event.finished || event.is_current) && entryEnteredEvent
                ? entryEventPoints.toString()
                : "-"
            }
          />
          <EventStat
            heading="Highest pts"
            url={
              event.highest_scoring_entry
                ? `/entry/${event.highest_scoring_entry}/event/${event.id}`
                : ""
            }
            value={
              event.highest_score ? event.highest_score.toLocaleString() : "-"
            }
          />
        </EventStatList>
        <div className="mt-6">
          {!event.released ? (
            <ManageTeamMsg>Pick team soon</ManageTeamMsg>
          ) : entryStatusForEvent === IEntryEventStatus.CannotEnter ? (
            <ManageTeamMsg>You did not play</ManageTeamMsg>
          ) : (
            <ManageTeamCta event={event} variant="dark" isFullWidth={true} />
          )}
        </div>
      </div>
    </StyledEventCard>
  );
};

export default EventCard;
