import { createContext, useState } from "react";
import { IManageTeamCreateData } from "../rtk-core/src/features/manage";

export type ISaveState = "initial" | "create" | "update" | "fail" | null;
interface IManageTeamContext {
  createEntryData: IManageTeamCreateData;
  isValid: () => boolean;
  saveState: ISaveState;
  setCreateEntryData: (data: IManageTeamCreateData) => void;
  setSaveState: (value: ISaveState) => void;
  errorMessage: string | null;
  setErrorMessage: (message: string | null) => void;
}

const ManageTeamContext = createContext<IManageTeamContext>({
  createEntryData: {
    email: false,
    favourite_team: 0,
    name: "",
    terms_agreed: false,
  },
  isValid: () => false,
  saveState: null,
  setCreateEntryData: (data: IManageTeamCreateData) => {},
  setSaveState: (value: ISaveState) => {},
  errorMessage: null,
  setErrorMessage: (message: string | null) => null,
});

export default ManageTeamContext;

interface IManageTeamProviderProps {
  children: React.ReactNode;
}

export const ManageTeamProvider: React.FC<IManageTeamProviderProps> = ({
  children,
}) => {
  const [createEntryData, setCreateEntryData] = useState<IManageTeamCreateData>(
    {
      email: false,
      favourite_team: 0,
      name: "",
      terms_agreed: false,
    }
  );

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const isValid = () => {
    const requiredFields: Array<keyof IManageTeamCreateData> = [
      "favourite_team",
      "name",
      "terms_agreed",
    ];
    return requiredFields.every((e) => {
      if (e === "favourite_team") {
        return createEntryData[e] === null || Boolean(createEntryData[e]);
      }
      return Boolean(createEntryData[e]);
    });
  };

  const [saveState, setSaveState] = useState<ISaveState>(null);

  return (
    <ManageTeamContext.Provider
      value={{
        createEntryData,
        isValid,
        saveState,
        setCreateEntryData,
        setSaveState,
        errorMessage,
        setErrorMessage,
      }}
    >
      {children}
    </ManageTeamContext.Provider>
  );
};
