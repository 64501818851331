import Copy from "../components/Copy";
import { GridItem, GridWrapper, SidebarGrid } from "../components/Layout";
// TODO: https://github.com/ismfg/pl-issues/issues/280
// import PrizesNav from "../components/PrizesNav/PrizesNav";
import HelmetHead from "../components/HelmetHead";
import SubHeading from "../components/SubHeading";
import Title from "../components/Title";

const Prizes = () => {
  return (
    <GridWrapper>
      <SidebarGrid $hasMargin={true}>
        <GridItem>
          <Title>Prizes</Title>
        </GridItem>
        {/* // TODO: https://github.com/ismfg/pl-issues/issues/280 */}
        {/* <GridItem>
          <div className="-mt-3">
            <PrizesNav />
          </div>
        </GridItem> */}
        <HelmetHead
          title="Fantasy Football Game Prizes | Fantasy Premier League"
          description="To find out about Fantasy Premier League prizes, including overall, monthly and weekly prizes, visit the official website."
        />

        <GridItem $colSpan={1} $gridColStart={1}>
          <Copy>
            <SubHeading>Overall</SubHeading>
            <h4>1st Prize – 2024/25 Fantasy Premier League Champion</h4>
            <ul>
              <li>
                7-night break in the UK inclusive of two VIP hospitality at two
                2025/26 Premier League matches
              </li>
              <li>
                A selection of experiences at popular UK tourist attractions
              </li>
              <li>Includes travel and 7 nights’ accommodation</li>
              <li>Hublot connected watch</li>
              <li>Official Premier League Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
              <li>Laptop computer OR SIM-free smartphone</li>
              <li>Noise-cancelling headphones (in-ear OR on-ear)</li>
              <li>
                Personalised FPL bundle consisting of a rucksack, T-shirt,
                travel mug, thermal bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
            <h4>Runner-up</h4>
            <ul>
              <li>
                Two hospitality tickets at one 2025/26 Premier League match
              </li>
              <li>Includes travel and 3 nights’ accommodation</li>
              <li>Official Premier League Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
              <li>Laptop computer OR SIM-free smartphone</li>
              <li>Noise-cancelling headphones (in-ear OR on-ear)</li>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, travel mug,
                thermal bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
            <h4>3rd Place</h4>
            <ul>
              <li>
                Two hospitality tickets at one 2025/26 Premier League match
              </li>
              <li>Includes travel and 3 nights’ accommodation</li>
              <li>Official Premier League Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
              <li>Tablet computer</li>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, travel mug,
                thermal bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
            <h4>4th Place</h4>
            <ul>
              <li>Official Premier League Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
              <li>Tablet computer</li>
              <li>Noise-cancelling headphones (in-ear OR on-ear)</li>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, travel mug,
                thermal bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
            <h4>5th Place</h4>
            <ul>
              <li>Official Premier League Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
              <li>Noise-cancelling headphones (in-ear OR on-ear)</li>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, travel mug,
                thermal bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
            <h4>6th-10th Place</h4>
            <ul>
              <li>Official Premier League Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, travel mug,
                thermal bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
            <h4>11th-20th Place</h4>
            <ul>
              <li>Official Premier League Nike Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, travel mug,
                thermal bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
          </Copy>
        </GridItem>

        <GridItem $colSpan={1} $gridColStart={1}>
          <Copy>
            <SubHeading>Weekly Prizes</SubHeading>
            <h4>1st Place</h4>
            <ul>
              <li>Official Premier League Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
              <li>
                FPL bundle consisting of a rucksack, t-shirt, travel mug,
                thermal bottle, stress ball, pen, pad, and key ring
              </li>
            </ul>
          </Copy>
        </GridItem>
      </SidebarGrid>
    </GridWrapper>
  );
};

export default Prizes;
