export const MAX_COLS = 7;
export const WRAPPER_PADDING = 10;
export const FIRST_COL_WIDTH_SM = 140;
export const FIRST_COL_WIDTH_LG = 220;
export const COL_WIDTH_SM = 100;
export const COL_WIDTH_LG = 127;

const UseTableColumns = (windowWidthInner: number) => {
  let tableWidth = windowWidthInner;
  let firstColWidth = FIRST_COL_WIDTH_SM;
  let secondColWidth = COL_WIDTH_SM;

  if (windowWidthInner > 1180) {
    tableWidth = 1180;
  }

  if (windowWidthInner > 700) {
    firstColWidth = FIRST_COL_WIDTH_LG;
    secondColWidth = COL_WIDTH_LG;
  }

  const colsCanFit =
    (tableWidth + WRAPPER_PADDING - (firstColWidth + WRAPPER_PADDING)) /
    (secondColWidth + WRAPPER_PADDING);
  const visibleColumns = colsCanFit > 7 ? 7 : colsCanFit;
  return [parseFloat(visibleColumns.toFixed(1))];
};

export default UseTableColumns;
