import { apiSlice } from "./apiSlice";
import { ITransfer } from "../entries";
import { ICreateEntry, IMakeTransferData } from "../squad";

export const squadApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createSquad: builder.query<void, ICreateEntry>({
      query: (entryData: ICreateEntry) => ({
        url: "entry-create/",
        method: "POST",
        body: entryData,
      }),
    }),
    fetchLatestTransfers: builder.query<ITransfer[], number>({
      query: (entry: number) => ({
        url: `entry/${entry}/transfers-latest/`,
        method: "GET",
      }),
    }),
    makeTransfers: builder.query<ITransfer[], IMakeTransferData>({
      query: (data: IMakeTransferData) => ({
        url: "transfers/",
        method: "POST",
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateSquadQuery,
  useFetchLatestTransfersQuery,
  useMakeTransfersQuery,
} = squadApi;
