import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddBan from "../components/AddBan";
import ChangeAdmin from "../components/ChangeAdmin";
import ClassicDetails from "../components/ClassicDetails";
import Code from "../components/Code";
import DeleteLeague from "../components/DeleteLeague";
import HelmetHead from "../components/HelmetHead";
import { GridItem, GridWrapper, SidebarGrid } from "../components/Layout";
import RemoveBan from "../components/RemoveBan";
import Title from "../components/Title";
import { useAppDispatch } from "../rtk-core/src/app/hooks";
import { RootState } from "../rtk-core/src/app/store";
import { useLazyFetchClassicLeagueForAdminQuery } from "../rtk-core/src/features/api/leagues";
import {
  IPrivateLeagueAdmin,
  IUpdateClassicLeagueData,
  getLeagueAdmin,
  updateClassicLeague,
} from "../rtk-core/src/features/leagues";

const AdminClassic = () => {
  const { leagueId } = useParams<{ leagueId: string }>();
  const leagueNumber = leagueId ? parseInt(leagueId, 10) : 0;
  const league = useSelector((state: RootState) =>
    leagueNumber
      ? (getLeagueAdmin(state, leagueNumber) as IPrivateLeagueAdmin)
      : null
  );
  const dispatch = useAppDispatch();

  const [fetchClassicAdminTrigger] = useLazyFetchClassicLeagueForAdminQuery();

  useEffect(() => {
    if (leagueNumber) {
      fetchClassicAdminTrigger(leagueNumber);
    }
  }, [dispatch, fetchClassicAdminTrigger, leagueNumber]);

  if (!league) {
    return null;
  }

  const updateLeagueHandler = (data: IUpdateClassicLeagueData) => {
    dispatch(updateClassicLeague({ leagueId: league.id, data: data }));
  };

  return (
    <GridWrapper>
      <SidebarGrid $hasMargin={true}>
        <HelmetHead
          title="Create & Join Fantasy Football Leagues | PL Challenges"
          description="To view all types of leagues, as well as creating & joining new leagues, visit the official website of the Premier League."
        />
        <GridItem>
          <Title>League Administration</Title>
        </GridItem>
        <GridItem>
          <Code league={league} />
        </GridItem>
        <GridItem $colSpan={1}>
          <ClassicDetails league={league} />
        </GridItem>
        <GridItem $colSpan={1} $gridColStart={1}>
          <AddBan league={league} />
        </GridItem>
        {league.bans.length > 0 && (
          <GridItem $colSpan={1} $gridColStart={1}>
            <RemoveBan league={league} />
          </GridItem>
        )}
        <GridItem $colSpan={1} $gridColStart={1}>
          <ChangeAdmin
            league={league}
            updateAdmin={(entryId: number) =>
              updateLeagueHandler({
                admin_entry: entryId,
                closed: league.closed,
                code_privacy: league.code_privacy,
                name: league.name,
                has_cup: league.has_cup,
              })
            }
          />
        </GridItem>
        <GridItem $colSpan={1} $gridColStart={1}>
          <DeleteLeague league={league} />
        </GridItem>
      </SidebarGrid>
    </GridWrapper>
  );
};

export default AdminClassic;
