import React from "react";
import styled from "styled-components";
import { IPulseNewsArticle } from "../../utils/pulse";
import SectionHeading from "../SectionHeading";
import NewsItem from "../NewsItem";
import NewsThumb from "../NewsThumb";

export const StyledNewsList = styled.div`
  padding: 0 ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-right: 0;
    padding-left: 0;
  }
`;

const NewsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

interface IProps {
  news: IPulseNewsArticle[];
  title: string;
}

const NewsList: React.FC<IProps> = ({ news, title }) => {
  return (
    <NewsListWrapper>
      <div className="pb-3 border-b border-purple10">
        <SectionHeading>{title}</SectionHeading>
      </div>
      <StyledNewsList>
        {news.map((n: IPulseNewsArticle) => (
          <div className="mb-2" key={n.id}>
            <NewsItem
              href={`https://www.premierleague.com/news/${n.id}`}
              img={
                <NewsThumb
                  news={n}
                  imgSizes={{
                    x1: { width: 104, height: 78 },
                    x2: { width: 208, height: 156 },
                  }}
                />
              }
              subtitle={n.subtitle}
              title={n.title}
            />
          </div>
        ))}
      </StyledNewsList>
    </NewsListWrapper>
  );
};

export default NewsList;
