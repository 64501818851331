import { createSelector } from "reselect";
import { RootState } from "../../app/store";
import { getElementsById, getTotalsByType, getFormation } from "../elements";
import { getElementTypesById } from "../elementTypes";
import { getRules, IRules } from "../game";
import { IPickProposed } from "./types";

export const getMyPicksSaved = (state: RootState) => state.myTeam.picksSaved;

export const getMyPicksProposed = (state: RootState) =>
  state.myTeam.picksProposed;

export const getMyTeamSavingState = (state: RootState) =>
  state.myTeam.savingState;

export const getMyStartersProposed = createSelector(
  getMyPicksProposed,
  getRules,
  (picks, rules) => picks.slice(0, rules ? rules.squad_squadplay : 0)
);

export const getMySubsProposed = createSelector(
  getMyPicksProposed,
  getRules,
  (picks, rules) =>
    picks.slice(rules ? rules.squad_squadplay : picks.length, picks.length)
);

export const getMyStartingTotalsByType = createSelector(
  getMyStartersProposed,
  getElementsById,
  (picks, elements) => getTotalsByType(picks.map((p) => elements[p.element]))
);

export const getMyFormation = createSelector(
  getMyStartersProposed,
  getElementsById,
  (picks, elements) => getFormation(picks.map((p) => elements[p.element]))
);

export const isSub = (state: RootState, pick: IPickProposed) => {
  const rules = getRules(state) as IRules;
  return pick.position > rules.squad_squadplay;
};

export const isLegalSwap = (
  state: RootState,
  p1: IPickProposed,
  p2: IPickProposed,
  totals: Record<string, number>
): boolean => {
  const elementsById = getElementsById(state);
  const elementTypesById = getElementTypesById(state);
  const p1Type = elementsById[p1.element].element_type;
  const p2Type = elementsById[p2.element].element_type;
  const p1Sub = isSub(state, p1);
  const p2Sub = isSub(state, p2);
  // const locks = getPositionTypeLocks(state);
  let validSwap = false;

  // Swapping starters disallowed
  if (!p1Sub && !p2Sub) {
    return false;
  }

  // Swapping self disallowed
  if (p1.element === p2.element) {
    return false;
  }

  // Can always swap like for like
  if (p1Type === p2Type) {
    return true;
  }

  // Can swap subs unless positions locked
  if (p1Sub && p2Sub) {
    validSwap = true;
  } else if (p1Sub) {
    // Bringing p1 in, p2 out
    validSwap =
      totals[p1Type] < elementTypesById[p1Type].squad_max_play &&
      totals[p2Type] > elementTypesById[p2Type].squad_min_play;
  } else {
    // Bringing p2 in, p1 out
    validSwap =
      totals[p2Type] < elementTypesById[p2Type].squad_max_play &&
      totals[p1Type] > elementTypesById[p1Type].squad_min_play;
  }

  // Check any subs are allowed to be in their new positions
  if (
    p2Sub && // p1 becoinng a sub
    elementTypesById[p1Type].sub_positions_locked.length && // locked type
    elementTypesById[p1Type].sub_positions_locked.indexOf(p2.position) === -1
  ) {
    return false;
  }
  if (
    p1Sub && // p2 becoming a sub
    elementTypesById[p2Type].sub_positions_locked.length && // locked type
    elementTypesById[p2Type].sub_positions_locked.indexOf(p1.position) === -1
  ) {
    return false;
  }

  return validSwap;
};

export const getMyPossibleReplacementsForPick = (
  state: RootState,
  pick1: IPickProposed
) => {
  const possibles: IPickProposed[] = [];
  const totals = getMyStartingTotalsByType(state);
  getMyPicksProposed(state).forEach((pick2) => {
    if (isLegalSwap(state, pick1, pick2, totals)) {
      possibles.push(pick2);
    }
  });
  return possibles;
};

export const isMyTeamValid = createSelector(getMyPicksProposed, (proposed) =>
  proposed.every((p) => p.subStatus === "")
);

export const hasMyTeamChanged = createSelector(
  getMyPicksSaved,
  getMyPicksProposed,
  (saved, proposed) =>
    saved.some(
      (p, i) =>
        p.element !== proposed[i].element ||
        p.is_captain !== proposed[i].is_captain ||
        p.is_vice_captain !== proposed[i].is_vice_captain
    )
);

export const toUpdateMyTeamAPI = createSelector(getMyPicksProposed, (picks) =>
  picks.map((p) => ({
    element: p.element,
    position: p.position,
    is_captain: p.is_captain,
    is_vice_captain: p.is_vice_captain,
  }))
);
