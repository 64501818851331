import React, { useEffect, useState } from "react";
import UseDimensions from "../../hooks/UseDimensions";
import { useAppSelector } from "../../rtk-core/src/app/hooks";
import { useLazyFetchFixturesFutureQuery } from "../../rtk-core/src/features/api/fixtures";
import { getActiveEvent, getEvents } from "../../rtk-core/src/features/events";
import { getFixturesByEvent } from "../../rtk-core/src/features/fixtures";
import { getTeams, getTeamsById } from "../../rtk-core/src/features/teams";
import Copy from "../Copy";
import Explain from "../Explain";
import FixturesNav from "../FixturesNav";
import HelmetHead from "../HelmetHead";
import { GridItem, GridWrapper, TwoCol50Grid } from "../Layout";
import SubHeading from "../SubHeading";
import Title from "../Title";
import FDRHeader from "./FDRHeader";
import FDRTable from "./FDRTable";
import UseTableColumns from "./UseTableColumns";
import { formatFixturesByEventAndTeam, sortTeamsByEventDiff } from "./helpers";

const FDRContainer: React.FC = () => {
  const activeEvent = useAppSelector(getActiveEvent);
  const events = useAppSelector(getEvents);
  const fixturesByEvent = useAppSelector(getFixturesByEvent);
  const teams = useAppSelector(getTeams);
  const teamsById = useAppSelector(getTeamsById);
  // Custom hooks
  const [tableRef, tableDimensions] = UseDimensions();
  const [visibleColumns] = UseTableColumns(tableDimensions.width);
  // Variables
  const nextEventIndex = events.findIndex((event) => event.is_next) + 1 || 0;
  const [currentEventIndex, setEventIndex] = useState(nextEventIndex);
  const [teamFixtures, setTeamFixtures] = useState({});
  const [sortedTeams, setSortedTeams] = useState(teams);
  const [activeHeadingIndex, setActiveEventId] = useState(0);
  const [fetchFutureFixturesTrigger] = useLazyFetchFixturesFutureQuery();

  useEffect(() => {
    fetchFutureFixturesTrigger();
  }, [fetchFutureFixturesTrigger]);

  useEffect(() => {
    if (Object.keys(fixturesByEvent).length) {
      setTeamFixtures(formatFixturesByEventAndTeam(fixturesByEvent));
    }
  }, [fixturesByEvent]);

  const handlePrevClick = () => setEventIndex(currentEventIndex - 1);

  const handleNextClick = () => setEventIndex(currentEventIndex + 1);

  function handleHeadingSort(eventId: number, sortType: string) {
    setActiveEventId(eventId);
    setSortedTeams(
      sortTeamsByEventDiff(teamFixtures, eventId, sortType, sortedTeams)
    );
  }

  const showPrevBtn = currentEventIndex > nextEventIndex;
  const showNextBtn = currentEventIndex + visibleColumns - 1 <= events.length;

  return (
    <GridWrapper>
      <TwoCol50Grid $hasMargin={true}>
        <GridItem>
          <Title>Fixtures &amp; Results</Title>
        </GridItem>
        <GridItem>
          <div className="-mt-3">
            <FixturesNav event={activeEvent} />
          </div>
        </GridItem>
        <GridItem>
          <div ref={tableRef}>
            <HelmetHead
              title="Fixture Difficulty Rating | Fantasy Premier League"
              description="For help planning your FPL transfer and team selection strategy, view our fixture difficulty rating (FDR). For more information, visit the official website of the Premier League."
            />
            {nextEventIndex ? (
              <>
                <FDRHeader
                  title="Fixture Difficulty Rating (FDR)"
                  showPrevBtn={showPrevBtn}
                  showNextBtn={showNextBtn}
                  onHandlePrevClick={handlePrevClick}
                  onHandleNextClick={handleNextClick}
                />
                <FDRTable
                  teams={sortedTeams}
                  teamsById={teamsById}
                  teamFixtures={teamFixtures}
                  currentEventIndex={currentEventIndex}
                  events={events}
                  activeHeadingIndex={activeHeadingIndex}
                  onHeadingSort={handleHeadingSort}
                />
              </>
            ) : (
              <GridItem>
                <Copy>
                  <Title>Fixture Difficulty Rating (FDR)</Title>
                  <p>
                    The FDR table will display here when next season's Fantasy
                    Premier League game is live.
                  </p>
                </Copy>
              </GridItem>
            )}
          </div>
        </GridItem>

        <GridItem>
          <Explain>
            <Copy>
              <SubHeading>FDR Explained</SubHeading>
              <p>
                The FDR is based on a complex algorithm developed by FPL
                experts.
              </p>
              <p>
                A set of formulas process key Opta data variables, along with
                each team's home and away form for the past six matches, to
                generate a rank for the perceived difficulty of each Gameweek
                opponent.
              </p>
              <p>
                The FDR is designed to help FPL managers plan their transfer and
                team selection strategy. It is reviewed on a weekly basis and
                updated as the season progresses.
              </p>
            </Copy>
          </Explain>
        </GridItem>
      </TwoCol50Grid>
    </GridWrapper>
  );
};

export default FDRContainer;
