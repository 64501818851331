import { SerializedError, createAsyncThunk } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { shuffle, values } from "lodash";
import { IThunkApi } from "../../app/types";
import { squadApi } from "../api/squad";
import { doBootstrap } from "../bootstrap";
import {
  cancelProposedChip,
  getActiveOrProposedTransferChipName,
  getProposedChip,
  getProposedChipName,
} from "../chips/";
import { getElementTypesBySquadPosition } from "../elementTypes";
import { getAverageCostByType, getElement, getElements } from "../elements";
import { getNextEvent } from "../events";
import { getRules } from "../game";
import { getPlayerData } from "../player/playerSlice";
import {
  getCountByTeam,
  getProposedElements,
  getProposedTransfers,
  getSavedPicks,
  getToSpend,
  getTransferCostsWithoutChip,
  getTypesNeeded,
  toCreateEntryAPI,
} from "./selectors";
import {
  proposeElementInPosition,
  removeElementInPosition,
  resetProposed,
} from "./squadSlice";
import { ICreateEntryData } from "./types";

export const createEntry = createAsyncThunk<void, ICreateEntryData, IThunkApi>(
  "squad/createEntry",
  async (data: ICreateEntryData, { dispatch, getState, rejectWithValue }) => {
    await dispatch(
      squadApi.endpoints.createSquad.initiate({
        ...data,
        picks: toCreateEntryAPI(getState()),
      })
    ).then(() => dispatch(doBootstrap()));
    // The navigaiton is likely to be removed later. The reason for this is that useNavigate is a hook.
    // This causes any use of it within tests for one to fail. In addition, this is likely to cause
    // issues in many ways. Therefore the only way to use this is to call it is using the lazy method provided
    // by Redux store. This will mean we have a trigger that we can call to initiate it. The decision may result in
    // this being removed and being called on a case by case basis
    // useNavigate()("/my-team");
  }
);

export const proposeElement = createAsyncThunk<boolean, number, IThunkApi>(
  "squad/proposeElement",
  async (elementId: number, { dispatch, getState }) => {
    const element = getElement(getState(), elementId);
    const proposed = getProposedElements(getState());
    const saved = getSavedPicks(getState());

    const elementTypesByPosition = getElementTypesBySquadPosition(getState());
    if (!element || !elementTypesByPosition) {
      return false;
    }

    // Element must not already be selected
    if (values(proposed).indexOf(element) > -1) {
      return false;
    }
    // Can we find a free position based on type?
    const freePosition = Object.keys(elementTypesByPosition).find((pos) => {
      return (
        elementTypesByPosition[pos].thisType.id === element.element_type &&
        !proposed[pos]
      );
    });
    if (!freePosition) {
      return false;
    }
    let position = Number(freePosition);

    // TODO - Look at bulk dispatching these with new react-redux
    const savedPositions = Object.keys(saved).filter(
      (p) => saved[p].element === elementId
    );
    // We already have this element
    if (savedPositions.length) {
      const savedPosition = Number(savedPositions[0]);
      // In a different position
      if (savedPosition !== position) {
        const proposedId =
          proposed[savedPosition] && proposed[savedPosition].id;
        // Someone is in saved position so remove them and put in this position
        if (proposedId) {
          dispatch(removeElementInPosition(proposedId, savedPosition));
          dispatch(proposeElementInPosition(proposedId, position));
        }
        position = savedPosition;
      }
    }
    dispatch(proposeElementInPosition(element.id, position));
    return true;
  }
);

export const removeElement = createAsyncThunk<boolean, number, IThunkApi>(
  "squad/removeElement",
  async (position: number, { dispatch, getState }) => {
    const proposed = getProposedElements(getState());
    if (!proposed[position]) {
      return false;
    }
    dispatch(removeElementInPosition(proposed[position].id, position));
    return true;
  }
);

export const restoreElement = createAsyncThunk<boolean, number, IThunkApi>(
  "squad/restoreElement",
  async (position: number, { dispatch, getState }) => {
    const saved = getSavedPicks(getState());
    if (!saved[position]) {
      return false;
    }
    dispatch(proposeElementInPosition(saved[position].element, position));
    // If proposing wildcard and no transfer costs cancel it
    if (
      getProposedChipName(getState()) === "wildcard" &&
      getTransferCostsWithoutChip(getState()) === 0
    ) {
      dispatch(cancelProposedChip("wildcard"));
    }
    return true;
  }
);

export const reset = createAsyncThunk<void, void, IThunkApi>(
  "squad/reset",
  async (_: void, { dispatch, getState }) => {
    const savedPicks = getSavedPicks(getState());
    dispatch(
      resetProposed(
        Object.keys(savedPicks).reduce<Record<string, number>>(
          (memo, i) => ({ ...memo, [i]: savedPicks[i].element }),
          {}
        )
      )
    );
    // Cancel any proposed transfer chips
    const chip = getProposedChip(getState());
    if (chip && chip.chip_type === "transfer") {
      dispatch(cancelProposedChip(chip.name));
    }
  }
);

export const autoComplete = createAsyncThunk<boolean, void, IThunkApi>(
  "squad/autoComplete",
  async (_: void, { dispatch, getState }) => {
    const typesByPosition = getElementTypesBySquadPosition(getState());
    const rules = getRules(getState());
    if (!rules || !typesByPosition) {
      return false;
    }
    const averageCostByType = getAverageCostByType(getState());
    const proposed = getProposedElements(getState());
    const elementsOwned = values(proposed)
      .map((e) => e.id)
      .concat(values(getSavedPicks(getState())).map((p) => p.element));
    const countByTeam = getCountByTeam(getState());
    let failures = 0;
    Object.keys(typesByPosition).forEach((pos) => {
      if (!proposed[pos]) {
        const typesNeeded = getTypesNeeded(getState());
        const avgToSpend = Object.keys(typesNeeded).reduce(
          (memo, et) => memo + typesNeeded[et] * averageCostByType[et],
          0
        );
        const weightingFactor = getToSpend(getState()) / avgToSpend;
        const invalidTeams = Object.keys(countByTeam)
          .filter((id) => countByTeam[id] >= rules.squad_team_limit)
          .map((id) => parseInt(id, 10));
        const possibles = getElements(getState()).filter(
          (e) =>
            // Correct type
            e.element_type === typesByPosition[pos].thisType.id &&
            // Cheap enough
            e.now_cost <=
              averageCostByType[typesByPosition[pos].thisType.id] *
                weightingFactor &&
            // Don't have
            elementsOwned.indexOf(e.id) === -1 &&
            // Available
            e.status === "a" &&
            // Within team limit
            invalidTeams.indexOf(e.team) === -1
        );
        if (possibles.length) {
          const choice = shuffle(possibles).sort(
            (a, b) =>
              parseFloat(b.form) - parseFloat(a.form) || b.now_cost - a.now_cost
          )[0];
          dispatch(proposeElementInPosition(choice.id, parseInt(pos, 10)));
          elementsOwned.push(choice.id);
          countByTeam[choice.team]++;
        } else {
          failures++;
        }
      }
    });
    return !failures;
  }
);

export const doFetchLatestTransfers = createAsyncThunk<void, void, IThunkApi>(
  "squad/fetchLatestTransfers",
  async (_: void, { dispatch, getState }) => {
    const player = getPlayerData(getState());
    if (!player || !player.entry) {
      return;
    }
    await dispatch(
      squadApi.endpoints.fetchLatestTransfers.initiate(player.entry)
    );
  }
);

export const makeTransfers = createAsyncThunk<void, void, IThunkApi>(
  "squad/makeTransfers",
  async (_: void, { dispatch, getState, rejectWithValue }) => {
    const nextEvent = getNextEvent(getState());
    const player = getPlayerData(getState());
    if (!nextEvent || !player || !player.entry) {
      return;
    }
    try {
      await dispatch(
        squadApi.endpoints.makeTransfers.initiate({
          chip: getActiveOrProposedTransferChipName(getState()),
          entry: player.entry,
          event: nextEvent.id,
          transfers: getProposedTransfers(getState()),
        })
      );
    } catch (e) {
      return rejectWithValue(e as FetchBaseQueryError | SerializedError);
    }
    // The navigaiton is likely to be removed later. The reason for this is that useNavigate is a hook.
    // This causes any use of it within tests for one to fail. In addition, this is likely to cause
    // issues in many ways. Therefore the only way to use this is to call it is using the lazy method provided
    // by Redux store. This will mean we have a trigger that we can call to initiate it. The decision may result in
    // this being removed and being called on a case by case basis
    // useNavigate()("/my-team");
  }
);
