import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../rtk-core/src/app/hooks";
import {
  getActiveEvent,
  getEventsById,
} from "../../rtk-core/src/features/events";
import Navigator from "../Navigator";
import { IEventNavigatorProps } from "./types";

const EventNavigator: React.FC<IEventNavigatorProps> = ({
  getEventUrl,
  hasPreviousEvent = true,
}) => {
  const navigate = useNavigate();

  const activeEvent = useAppSelector(getActiveEvent);
  const eventsById = useAppSelector(getEventsById);

  const setNextEvent = () => {
    const nextEvent = eventsById[activeEvent.id + 1];
    const nextUrl = getEventUrl(nextEvent);
    navigate(nextUrl);
  };

  const setPrevEvent = () => {
    const prevEvent = eventsById[activeEvent.id - 1];
    const prevUrl = getEventUrl(prevEvent);
    navigate(prevUrl);
  };

  const disablePrev = activeEvent.id === 1 || !hasPreviousEvent;
  const disableNext = activeEvent.id === Object.keys(eventsById).length;

  return (
    <Navigator
      title={activeEvent.name}
      nextOnClick={setNextEvent}
      prevOnClick={setPrevEvent}
      disableNext={disableNext}
      disablePrev={disablePrev}
    />
  );
};

export default EventNavigator;
