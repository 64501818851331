import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IRegion, IRegionState } from "./types";
import { regionsApi } from "../api/regions";
import { RootState } from "../../app/store";

const initialState: IRegionState = {
  byId: {},
};

const regions = createSlice({
  name: "regions",
  initialState,
  reducers: {
    addRegions: (state, action: PayloadAction<IRegion[]>) => ({
      ...state,
      byId: Object.fromEntries(
        action.payload.map((region: IRegion) => [
          region.id,
          {
            ...region,
          },
        ])
      ),
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      regionsApi.endpoints.fetchRegions.matchFulfilled,
      (state, action: PayloadAction<IRegion[]>) =>
        regions.caseReducers.addRegions(state, {
          ...action,
          payload: action.payload,
        })
    );
  },
});

export default regions.reducer;

export const { addRegions } = regions.actions;

// State Selectors
export const getRegionsById = (state: RootState) => state.regions.byId;

export const getRegions = (state: RootState) =>
  Object.keys(state.regions.byId).map((r) => state.regions.byId[r]);
