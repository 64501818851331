import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getFixturesForEvent } from "../fixtures";

export const getTeamsById = (state: RootState) => state.teams.byId;

export const getTeams = createSelector([getTeamsById], (teams) =>
  Object.keys(teams)
    .map((t) => teams[t])
    .sort((a, b) => a.name.localeCompare(b.name))
);

export const getTeamIdsPlayingInEvent = (state: RootState, eventId: number) => {
  return getFixturesForEvent(state, eventId).flatMap((obj) => [
    obj.team_a,
    obj.team_h,
  ]);
};

export const getTeamsWithUnstartedFixtures = createSelector(
  [getFixturesForEvent],
  (fixtures) => {
    return fixtures
      .filter((fixture) => !fixture.started)
      .flatMap((fixture) => [fixture.team_a, fixture.team_h]);
  }
);
