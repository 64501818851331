import styled from "styled-components";

export const ElementFixtureOpponentList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const ElementFixtureOpponentItem = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;
