import { rgba, size } from "polished";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { ISeasonStats } from "./types";

export const StyledPlayerOverview = styled.div`
  padding: ${(props) => props.theme.space[2]};
  border: 1px solid ${(props) => props.theme.colors.purple5};
  border-radius: ${(props) => props.theme.radii[2]};
`;

export const RankHeader = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: ${(props) => props.theme.space[2]};
`;

export const RankHeaderBadge = styled.div`
  ${size(94)}
  margin: auto;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    ${size(128)}
  }
`;

export const TeamName = styled.h2`
  margin-bottom: ${(props) => props.theme.space[1]};
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: 2.5rem;
  text-align: center;
  line-height: 1.2;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    font-size: 3rem;
  }
`;

export const PlayerDetailList = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: ${(props) => props.theme.space[6]};
  color: ${(props) => props.theme.colors.purple60};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    font-size: ${(props) => props.theme.fontSizes[3]};
  }
`;

export const PlayerDetailItem = styled.li`
  &:not(:first-child) {
    margin-left: 2rem;
    list-style-type: disc;
  }
`;

export const OverviewBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[3]};
`;

export const SeasonStatsWrap = styled.div`
  padding: ${(props) => props.theme.space[3]};
  border-radius: ${(props) => props.theme.space[2]};
  background-color: ${(props) => props.theme.colors.purple5};
  text-align: center;
`;

export const SeasonStats = styled.div<ISeasonStats>`
  display: flex;
  gap: ${(props) => props.theme.space[3]};

  ${({ $isSidebar, theme }) =>
    !$isSidebar &&
    css`
      @media (min-width: ${theme.breakpoints[4]}) {
        width: 40%;
        margin-right: auto;
        margin-left: auto;
      }
    `}
`;

export const SeasonStatsMain = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: ${(props) => props.theme.space[3]};
`;

export const SeasonStatsSecondary = styled.div`
  flex: 2;
  padding-right: ${(props) => props.theme.space[3]};
`;

export const SeasonStat = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => rgba(props.theme.colors.black, 0.1)};
  }
`;

export const SeasonStatHeading = styled.h3`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: ${(props) => props.theme.fontSizes[0]};
  line-height: 2;
`;

export const SeasonStatValue = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[3]};
`;

export const SeasonStatMainHeading = styled.h3`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: ${(props) => props.theme.fontSizes[0]};
  line-height: 1.2;
`;

export const SeasonStatMainValue = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 4rem;
  line-height: 1;
`;

export const StatLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};
  color: currentColor;
  text-decoration: none;
`;

export const LeagueLinks = styled.div`
  display: flex;
  gap: ${(props) => props.theme.space[2]};
`;

export const LeagueLinksItem = styled.div`
  flex: 1;
`;

export const LeagueLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};
  padding: ${(props) => props.theme.space[2]};
  border-radius: ${(props) => props.theme.radii[2]};
  background-color: ${(props) => props.theme.colors.purple5};
  font-family: ${(props) => props.theme.fonts.bold};
  color: currentColor;
  text-decoration: none;
`;

export const EntryFlag = styled.img`
  ${size(16, 24)}
  display: inline-block;
  margin: 4px 0;
  vertical-align: middle;
`;
