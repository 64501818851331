import { Link } from "react-router-dom";
import styled from "styled-components";
import { ChevronRight } from "../Icons";
import Table from "../Table";

export const EntryHistoryTable = styled(Table)`
  table-layout: fixed;
`;

export const GameWeek = styled.th`
  width: 15%;
`;
export const GlobalRank = styled.th`
  width: 18%;
`;
export const GlobalPosition = styled.th`
  width: 24%;
`;
export const TotalPlayers = styled.th`
  width: 25%;
`;
export const Points = styled.th`
  width: 18%;
`;

export const PointsLabel = styled.span`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: inline;
    font-family: ${({ theme }) => theme.fonts.heavy};
  }
`;

export const PointsValue = styled.span`
  font-family: ${({ theme }) => theme.fonts.heavy};
`;

export const StyledChevronRight = styled(ChevronRight)`
  margin-left: 20px;
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.heavy};
  font-size: ${({ theme }) => theme.fontSizes[6]};
`;

export const SubHeading = styled.h3`
  margin: ${({ theme }) => theme.space[2]} 0;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[5]};
`;

export const StyledMessage = styled.td`
  font-family: ${({ theme }) => theme.fonts.bold};
  text-align: center;
`;

export const PointsWrap = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
`;

export const TableHeaderContent = styled.abbr`
  font-family: ${({ theme }) => theme.fonts.heavy};
  vertical-align: middle;
  text-decoration: none;
  span {
    cursor: default;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    &:after {
      content: attr(title);
      cursor: default;
    }
    span {
      display: none;
    }
  }
`;
