import { createAsyncThunk } from "@reduxjs/toolkit";
import { IThunkApi } from "../../app/types";
import { myTeamApi } from "../api/myTeam";
import { doCheckGame } from "../bootstrap";
import { getPossibleFormations } from "../elementTypes";
import { getEntry } from "../entries";
import { getRules } from "../game/";
import {
  doInitialiseState,
  getMyFormationProposed,
  setActiveFormation,
} from "../manage";
import { getPlayerData } from "../player/playerSlice";
import { toCreateEntryAPI } from "../squad";
import {
  substitutionProcess,
  substitutionStart,
  substitutionStop,
} from "./myTeamSlice";
import {
  getMyPicksProposed,
  getMyPossibleReplacementsForPick,
} from "./selectors";
import { IPickProposed } from "./types";

export const doFetchMyTeam = createAsyncThunk<
  void,
  number | undefined,
  IThunkApi
>("myTeam/doFetchMyTeam", async (eventId, { dispatch, getState }) => {
  dispatch(doCheckGame());

  const player = getPlayerData(getState());
  if (player) {
    if (player.entry) {
      let toFetch = true;
      // if ENTRY_PER_EVENT game we want to check if the player
      // has an entry for that particular event and if not
      // we set toFetch to false
      if (getState().game.settings?.entry_per_event && eventId) {
        const entry = getEntry(getState(), player.entry);
        if (entry && !entry.entered_events.includes(eventId)) {
          toFetch = false;
        }
      }

      // if toFetch is true we call the fetchMyTeam endpoint
      if (toFetch) {
        await dispatch(
          myTeamApi.endpoints.fetchMyTeam.initiate(
            {
              entry: player.entry,
              event: eventId,
            },
            { forceRefetch: true }
          )
        );

        const initialFormation = getMyFormationProposed(getState());
        if (initialFormation) {
          dispatch(setActiveFormation(initialFormation));
        }
      } else {
        // if toFetch is false we want to reinitialise the state
        // to reset it to empty so a team for that event can be
        // picked
        const initialFormation = getPossibleFormations(getState())[0];
        if (initialFormation) {
          dispatch(setActiveFormation(initialFormation));
        }
        dispatch(doInitialiseState());
      }
    } else {
      // if no entry for the user they are creating a team so we need to
      // initialise the state
      const initialFormation = getPossibleFormations(getState())[0];
      if (initialFormation) {
        dispatch(setActiveFormation(initialFormation));
      }
      dispatch(doInitialiseState());
    }
  }
});

export const actionSubstitution = createAsyncThunk<void, number, IThunkApi>(
  "myTeam/actionSubstitution",
  async (elementId: number, { dispatch, getState }) => {
    const pick = getMyPicksProposed(getState()).reduce(
      (memo: IPickProposed | null, p) => (p.element === elementId ? p : memo),
      null
    );
    const rules = getRules(getState());
    if (pick && rules) {
      if (pick.subStatus === "") {
        dispatch(
          substitutionStart(
            pick,
            getMyPossibleReplacementsForPick(getState(), pick)
          )
        );
      } else if (pick.subStatus === "instigator") {
        dispatch(substitutionStop());
      } else if (pick.subStatus === "target") {
        dispatch(substitutionProcess(pick, rules.squad_squadplay + 1));
      }
    }
  }
);

export const doSaveMyTeam = createAsyncThunk<
  void,
  number | undefined,
  IThunkApi
>("myTeam/doSaveMyTeam", async (eventId, { dispatch, getState }) => {
  const player = getPlayerData(getState());
  if (!player || !player.entry) {
    return;
  }
  dispatch(
    myTeamApi.endpoints.saveMyTeam.initiate({
      entry: player.entry,
      event: eventId,
    })
  );
});

export const createEntryForEvent = createAsyncThunk<void, number, IThunkApi>(
  "myTeam/createEntryForEvent",
  async (eventId: number, { dispatch, getState, rejectWithValue }) => {
    const player = getPlayerData(getState());
    if (!player || !player.entry) {
      return;
    }
    await dispatch(
      myTeamApi.endpoints.createTeamForEvent.initiate({
        event: eventId,
        entry: player.entry,
        picks: toCreateEntryAPI(getState()),
      })
    );
    // TODO: want to then call doEventBootstrap using .then() with the eventId
  }
);
