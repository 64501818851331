import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CannotEnterEvent from "../../components/CannotEnterEvent";
import { EntryScoreboard } from "../../components/EntryScoreboard";
import EventNavigator from "../../components/EventNavigator";
import Fixtures from "../../components/Fixtures";
import {
  GridItem,
  GridWrapper,
  SidebarGrid,
  TwoCol50Grid,
} from "../../components/Layout";
import EntryLeagues from "../../components/Leagues/EntryLeagues/EntryLeagues";
import Loading from "../../components/Loading";
import PitchFormation from "../../components/PitchFormation";
import ReportNameButton from "../../components/ReportNameButton";
import { DividerLight } from "../../components/styles";
import { useAppDispatch, useAppSelector } from "../../rtk-core/src/app/hooks";
import { RootState } from "../../rtk-core/src/app/store";
import { useLazyFetchEntryEventPicksQuery } from "../../rtk-core/src/features/api/entries";
import {
  IEntryEventStatus,
  getActiveEntry,
  getEntry,
  getEntryEventFormation,
  getEntryEventPicks,
  getEntryEventPoints,
  getPrivateClassicLeaguesForEntry,
  getSystemClassicLeaguesForEntry,
} from "../../rtk-core/src/features/entries";
import { IEvent, getActiveEvent } from "../../rtk-core/src/features/events";
import { doFetchLatestEventData } from "../../rtk-core/src/features/events/thunks";
import { IManagePickProposed } from "../../rtk-core/src/features/manage";
import { getPlayerData } from "../../rtk-core/src/features/player";
import {
  getEntryEnteredEvent,
  getEntryStatusForEvent,
  getEventUrlForEntry,
} from "../../utils/entries";
import { ButtonWrap } from "./styles";

const EntryEvent: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { entryId } = useParams();

  const activeEvent = useAppSelector(getActiveEvent);
  const activeEntryId = useAppSelector(getActiveEntry);
  const entry = useAppSelector((state: RootState) =>
    activeEntryId ? getEntry(state, activeEntryId) : null
  );

  const player = useAppSelector(getPlayerData);
  const mine = player?.entry === entry?.id;

  const formation = useAppSelector((state) =>
    entry ? getEntryEventFormation(state, entry.id, activeEvent.id) : ""
  );
  const entryEventData = useAppSelector((state) =>
    entry ? getEntryEventPicks(state, entry.id, activeEvent.id) : null
  );
  const entryEnteredEvent = entry
    ? getEntryEnteredEvent(entry, activeEvent)
    : false;

  const entryEventPoints = useAppSelector((state) =>
    entry && entryEnteredEvent
      ? getEntryEventPoints(state, entry.id, activeEvent.id)
      : null
  );

  const [fetchEntryEventPicksTrigger] = useLazyFetchEntryEventPicksQuery();

  useEffect(() => {
    // this check prevent calls to fetchEntryEventPicks prior to the activeEntry
    // updating following navigation
    if (activeEntryId === Number(entryId)) {
      if (entryEnteredEvent) {
        fetchEntryEventPicksTrigger({
          entryId: entry!.id,
          eventId: activeEvent.id,
        });
      }
    }
  }, [
    activeEvent,
    activeEntryId,
    fetchEntryEventPicksTrigger,
    entry,
    entryEnteredEvent,
    entryId,
  ]);

  useEffect(() => {
    dispatch(doFetchLatestEventData());
  }, [dispatch]);

  const privateClassicLeagues = useAppSelector((state) =>
    activeEntryId ? getPrivateClassicLeaguesForEntry(state, activeEntryId) : []
  );
  const systemClassicLeagues = useAppSelector((state) =>
    activeEntryId ? getSystemClassicLeaguesForEntry(state, activeEntryId) : []
  );

  // The case for entry not existing is caught using the serverError value in the store.
  // If a serverError is caught the rendered content will be determined within App.tsx
  // so we can assume at this point that if entry is not null at this point we are simply
  // waiting for the selector to get the value
  if (!entry) {
    return <Loading />;
  }

  const eventStatus = getEntryStatusForEvent(entry, activeEvent);
  if (mine) {
    if (
      eventStatus ===
      (IEntryEventStatus.CanEnter ||
        IEntryEventStatus.EnteredCanManage ||
        IEntryEventStatus.Unreleased)
    ) {
      navigate(`/manage/${activeEvent.id}`);
    }
  }

  const getEventUrl = (event: IEvent) => {
    return getEventUrlForEntry(entry, event, player);
  };

  if (!entryEnteredEvent) {
    // Pass entry id as a prop because you may be looking at someone else's team/logged out
    return (
      <GridWrapper>
        <TwoCol50Grid>
          <GridItem>
            <div className="mt-3 -mb-3">
              <EventNavigator getEventUrl={getEventUrl} />
            </div>
          </GridItem>
          <GridItem>
            <CannotEnterEvent entryId={entry.id} />
          </GridItem>
          <GridItem>
            <Fixtures eventId={activeEvent.id} />
          </GridItem>
        </TwoCol50Grid>
      </GridWrapper>
    );
  }

  if (!entryEventData) {
    return <Loading />;
  }

  // to be removed as part of https://github.com/ismfg/pl-issues/issues/281
  const newPicks = entryEventData.picks.map(
    (e) =>
      ({
        element: e.element,
        elementType: e.element_type,
        is_captain: e.is_captain,
        is_vice_captain: e.is_vice_captain,
        multiplier: e.multiplier,
        position: e.position,
        subStatus: "",
      } as IManagePickProposed)
  );

  return (
    <GridWrapper>
      <SidebarGrid>
        <GridItem>
          <div className="mt-3 -mb-3">
            <EventNavigator getEventUrl={getEventUrl} />
          </div>
        </GridItem>
        <GridItem>
          <EntryScoreboard
            entry={entry}
            points={entryEventPoints}
            showChallengeStats={false}
          />
        </GridItem>
        <GridItem $colSpan={1}>
          <PitchFormation formation={formation || "1-4-4-2"} picks={newPicks} />
        </GridItem>
        <GridItem $colSpan={1}>
          {!mine && (
            <>
              <ButtonWrap>
                <ReportNameButton entryId={entry.id} />
              </ButtonWrap>
              <div className="my-4">
                <DividerLight />
              </div>
            </>
          )}
          <EntryLeagues
            privateLeagues={privateClassicLeagues}
            systemLeagues={systemClassicLeagues}
          />
        </GridItem>
        <GridItem $colSpan={1}>
          <Fixtures eventId={activeEvent.id} />
        </GridItem>
      </SidebarGrid>
    </GridWrapper>
  );
};

export default EntryEvent;
