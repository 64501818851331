import React from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import Alert from "../components/Alert";
import Collapsible from "../components/Collapsible";
import ContactUs from "../components/ContactUs";
import Copy from "../components/Copy";
import HelmetHead from "../components/HelmetHead";
import HelpNav from "../components/HelpNav";
import LatestAlert from "../components/LatestAlert";
import { GridItem, GridWrapper, SidebarGrid } from "../components/Layout";
import OffensiveCopy from "../components/OffensiveCopy";
import SubHeading from "../components/SubHeading";
import Title from "../components/Title";
import { RootState } from "../rtk-core/src/app/store";
import { IEventsById, getEventsById } from "../rtk-core/src/features/events";
import { IRules, getRules } from "../rtk-core/src/features/game";

const FAQHeading = styled.h6`
  font-size: inherit;
`;

const StyledSubHeading = styled(SubHeading)`
  font-size: ${({ theme }) => theme.fontSizes[3]};
`;
interface IPropsFromState {
  eventsById: IEventsById;
  rules: IRules | null;
}
type Props = IPropsFromState;

const Help: React.FC<Props> = ({ eventsById, rules }) => {
  let location = useLocation();

  return (
    rules && (
      <GridWrapper>
        <SidebarGrid $hasMargin={true}>
          <GridItem>
            <Title>Help</Title>
          </GridItem>

          <GridItem>
            <div className="-mt-3">
              <HelpNav />
            </div>
          </GridItem>

          <HelmetHead
            title="Fantasy Football Game Rules and Help | FPL Challenge"
            description="To find out about Fantasy Premier League game rules, including squad management and transfers, visit the official website of the Premier League"
          />

          {location!.search === "?sent" && (
            <GridItem $colSpan={1}>
              <Copy role="status" aria-live="polite">
                <Alert>
                  Thanks for contacting the FPL Challenge support team. Your
                  message has been sent and we’ll aim to respond to your query
                  as soon as possible.
                </Alert>
              </Copy>
            </GridItem>
          )}

          <GridItem $colSpan={1} $gridColStart={1}>
            <LatestAlert />
            <p>How can we help?</p>
          </GridItem>

          <GridItem $colSpan={1} $gridColStart={1}>
            <div className="my-3">
              <StyledSubHeading>FPL Challenge</StyledSubHeading>
            </div>
            <WhatIsIt />
            <HowIsItScored />
            <ChallengesAffectPoints />
            <ChallengesAffectOtherTeams />
            <Differences />
          </GridItem>

          <GridItem $colSpan={1} $gridColStart={1}>
            <div className="my-3">
              <StyledSubHeading>
                Your premierleague.com account
              </StyledSubHeading>
            </div>
            <CantSignIn />
          </GridItem>

          <GridItem $colSpan={1} $gridColStart={1}>
            <div className="my-3">
              <StyledSubHeading>Choosing your initial squad</StyledSubHeading>
            </div>
            <MoreThanOneTeam />
            <MakeChanges />
          </GridItem>

          <GridItem $colSpan={1} $gridColStart={1}>
            <div className="my-3">
              <StyledSubHeading>Managing your team</StyledSubHeading>
            </div>
            <WhatFormations />
            <HowChangeCaptain />
            <HowChangeTeamDetails />
          </GridItem>

          <GridItem $colSpan={1} $gridColStart={1}>
            <div className="my-3">
              <StyledSubHeading>Chips</StyledSubHeading>
            </div>
            <ChipsInChallenge />
          </GridItem>

          <GridItem $colSpan={1} $gridColStart={1}>
            <div className="my-3">
              <StyledSubHeading>Offensive names</StyledSubHeading>
            </div>
            <OffensiveNames />
          </GridItem>

          <GridItem $colSpan={1} $gridColStart={1}>
            <div className="my-3">
              <StyledSubHeading>Leagues</StyledSubHeading>
            </div>
            <MakingLeague />
            <RenewingLeague />
          </GridItem>

          <GridItem $colSpan={1} $gridColStart={1}>
            <div className="my-3">
              <StyledSubHeading>
                Gameweeks where teams play no fixtures or play more than once
              </StyledSubHeading>
            </div>
            <TeamPlayingTwice />
            <OnlyPlayedOnce />
            <NoFixtures />
          </GridItem>

          <GridItem $colSpan={1} $gridColStart={1}>
            <div className="my-3">
              <StyledSubHeading>Player data</StyledSubHeading>
            </div>
            <WhenUpdated />
            <ChangedData />
            <AssistRules />
            <PriceChangesWork />
            <FormCalculation />
            <WhatIsFDR />
            <WhatIsICT />
            <HowPositionsDecided />
          </GridItem>

          <GridItem $colSpan={1} $gridColStart={1}>
            <div className="my-3">
              <StyledSubHeading>Bonus points</StyledSubHeading>
            </div>
            <HowBonusCalculated />
            <WhenBonusPointsAdded />
            <BPSChanged />
          </GridItem>

          <GridItem $colSpan={1} $gridColStart={1}>
            <div className="my-3">
              <StyledSubHeading>Premier League transfers</StyledSubHeading>
            </div>
            <WhenTransferred />
            <NewPlayersAdded />
            <PlayerLeftPL />
          </GridItem>

          <GridItem $colSpan={1} $gridColStart={1}>
            <div className="my-3">
              <StyledSubHeading>Contact Us</StyledSubHeading>
            </div>
            <p>
              If your question isn't answered above or in the rules then you can
              contact us using the form below. We will reply to all queries as
              soon as we can.
            </p>
            <ContactUs />
          </GridItem>
        </SidebarGrid>
      </GridWrapper>
    )
  );
};

export { Help as HelpTest };

const mapStateToProps = (state: RootState) => ({
  eventsById: getEventsById(state),
  rules: getRules(state),
});

export default connect(mapStateToProps)(Help);

const WhatIsIt: React.FC = () => (
  <Collapsible headingText="What is FPL Challenge?">
    <p>
      FPL Challenge is a new way of playing Fantasy Premier League which allows
      managers to test their skills by picking a NEW team each Gameweek.
    </p>
    <p>
      Each Gameweek there will be a different Challenge that affects every
      player’s team that week. For example, the budget for your squad could be
      different, or certain players will receive double points.
    </p>
  </Collapsible>
);

const HowIsItScored: React.FC = () => (
  <Collapsible headingText="How is FPL Challenge scored?">
    <p>
      In the 'Gameweek' view of a league, your scores will reset to zero each
      Gameweek and not create a total across the season.
    </p>
    <p>
      In the 'Event' view of a league, your scoring will be the sum of all
      Gameweeks for the duration of the Event. Once the Event ends, your
      cumulative score will be reset to zero ahead of the next event.
    </p>
    <p>
      In the 'Season' view of a league, your scoring will be the sum of every
      Gameweek for the duration of the season.
    </p>
  </Collapsible>
);

const ChallengesAffectPoints: React.FC = () => (
  <Collapsible headingText="How do Challenges affect my points?">
    <p>
      Each Challenge explains what effect will take place that gameweek. This
      can be found at the top of the “Manage Team” page where you build your
      team for that gameweek.
    </p>
  </Collapsible>
);

const ChallengesAffectOtherTeams: React.FC = () => (
  <Collapsible headingText="Does FPL Challenge affect my other teams?">
    <p>
      Your FPL Challenge team is entirely separate from your team in the main
      FPL game or FPL Draft. Your choices, scores and performance don’t affect
      the main game or FPL Draft.
    </p>
  </Collapsible>
);

const Differences: React.FC = () => (
  <Collapsible headingText="What other differences are there in FPL Challenge?">
    <p>
      When setting up your team you will be able to pick up to 5 players from
      each Premier League club (instead of the usual 3 players). You’ll also be
      able to enter and edit your team at any point up until the final deadline.
      You can read more about Gameweek deadlines in the&nbsp;
      <Link to="/help/rules">Rules</Link>.
    </p>
  </Collapsible>
);

const CantSignIn: React.FC = () => (
  <Collapsible headingText="I can't sign in. What do I need to do?">
    <p>
      Please ensure you are using your registered e-mail address and correct
      password. Note that the password is case sensitive.
    </p>
    <p>
      If you’ve registered for the first time this season, you will need to
      activate your account before entering a FPL Challenge team. An email will
      be sent to the email address you’ve registered with and contains a link
      you’ll need to click in order to activate your account. If you haven’t
      received an email, please check your spam or junk mail folders.
    </p>
    <p>
      Try{" "}
      <a href={`${import.meta.env.VITE_PLUSERS_BASE}/accounts/password-reset/`}>
        resetting your password
      </a>
      . A link to reset your password will be sent to your registered email
      address.
    </p>
    <p>
      If you do not receive the password reminder, please check your Junk folder
      in your email inbox and add noreply@mailout.users.premierleague.com to
      your address book.
    </p>
  </Collapsible>
);

const MoreThanOneTeam: React.FC = () => (
  <Collapsible headingText="Can I have more than one team in FPL Challenge?">
    <p>
      In the interest of fair play each person may only enter one team. Your
      team will be entered into FPL Challenge leagues by default, and you may
      also enter this team in mini-leagues and compete against different groups
      of friends.
    </p>
  </Collapsible>
);

const MakeChanges: React.FC = () => (
  <Collapsible headingText="Can I make changes to my squad after the deadline?">
    <p>
      In FPL Challenge players become locked when their first match of that
      gameweek begins. Transfers of that player can be made at any time before
      this.
    </p>
  </Collapsible>
);

const WhatFormations: React.FC = () => (
  <Collapsible headingText="What formation can I play in?">
    <p>
      You can play in any formation that fits within the rules of that
      Gameweek's challenge.
    </p>
  </Collapsible>
);

const HowChangeCaptain: React.FC = () => (
  <Collapsible headingText="How do I change my captain?">
    <p>
      On the Manage Team page, use the menu which appears when clicking on a
      player.
    </p>
  </Collapsible>
);

const HowChangeTeamDetails: React.FC = () => (
  <Collapsible headingText="How do I change my team name?">
    <p>
      You can change your team name on your{" "}
      <Link to="/profile"> FPL Challenge manager profile</Link>.
    </p>
  </Collapsible>
);

const ChipsInChallenge: React.FC = () => (
  <Collapsible headingText="Are there chips in FPL Challenge?">
    <p>There are no chips or wildcards in FPL Challenge.</p>
  </Collapsible>
);

const OffensiveNames: React.FC = () => (
  <Collapsible headingText="How do I report an inappropriate or offensive name?">
    <OffensiveCopy />
  </Collapsible>
);

const MakingLeague: React.FC = () => (
  <Collapsible headingText="Can I make a league for FPL Challenge?">
    <p>
      Yes, you can make a league in FPL Challenge and invite whoever you want.
    </p>
  </Collapsible>
);

const RenewingLeague: React.FC = () => (
  <Collapsible headingText="Will my league renew each week or do I need to make a new one?">
    <p>
      All your leagues will renew each week and support new players joining at
      any time.
    </p>
  </Collapsible>
);

const TeamPlayingTwice: React.FC = () => (
  <Collapsible headingText="What happens when a team is playing twice in one Gameweek?">
    <p>
      Any players you have from the team playing twice will earn two sets of
      points, assuming that the player plays in both fixtures.
    </p>
    <p>
      Any team captains playing in two fixtures will also earn double points for
      each match he plays in.
    </p>
  </Collapsible>
);

const OnlyPlayedOnce: React.FC = () => (
  <Collapsible
    headingText="One of my players was scheduled to play twice in one Gameweek, but only
      played in one match. Why wasn’t he substituted in the match he didn’t
      play?"
  >
    <p>
      If a player plays any minutes in any scheduled matches in a Gameweek, then
      he does not qualify for an automatic substitution.
    </p>
    <p>
      The same rule applies to captains. If your captain only plays in one of
      the two matches, he will not be replaced as captain in the match he
      doesn’t play.
    </p>
  </Collapsible>
);

const NoFixtures: React.FC = () => (
  <Collapsible headingText="What happens if a team does not have a fixture in a Gameweek?">
    <p>
      Any players you have from that team will score 0 for that Gameweek.
      Players will play twice in a future Gameweek, when the match is
      rescheduled.
    </p>
    <p>
      Points are only awarded to teams who have players selected in their team
      in the Gameweek the fixture is actually played, irrespective of if you had
      them selected for the original fixture.
    </p>
  </Collapsible>
);

const WhenUpdated: React.FC = () => (
  <Collapsible headingText="When is the game updated?">
    <p>
      Player points are updated as the matches take place, whilst team points
      and league tables are usually updated 2 hours after the final whistle in
      the last match of the day.
    </p>
    <p>
      Bonus points will be awarded one hour after the final whistle of the last
      match of any given day.
    </p>
  </Collapsible>
);

const ChangedData: React.FC = () => (
  <Collapsible
    headingText="A yellow/red card decision or goalscorer has been changed in an earlier
      match. Are you going to alter the points?"
  >
    <p>
      Once the points have all been updated after the last day of the Gameweek
      has been marked as final, no further adjustments to points will be made.
    </p>
  </Collapsible>
);

const AssistRules: React.FC = () => (
  <Collapsible headingText="Why was/wasn’t an assist given to...?">
    <p>
      All assists are reviewed before the points are updated at the end of each
      day. If an assist still hasn’t been given after this time, please refer to
      the assist definition in the rules for further clarification.
    </p>
  </Collapsible>
);

const PriceChangesWork: React.FC = () => (
  <Collapsible headingText="How do player prices change?">
    <p>
      Once the season has started, player prices may change by £0.1m a day,
      based on activity in the transfer market.
    </p>
    <p>
      Both the formula used to calculate this change and the time of the change
      include variable factors, and won’t be revealed to game players.
    </p>
  </Collapsible>
);

const FormCalculation: React.FC = () => (
  <Collapsible headingText="How do you calculate form?">
    <p>
      Form is a player’s average score per match, calculated from all matches
      played by his club in the last 30 days.
    </p>
  </Collapsible>
);

const WhatIsFDR: React.FC = () => (
  <Collapsible headingText="What is the Fixture Difficulty Rating (FDR)?">
    <p>
      The Fixture Difficulty Rating (FDR) is based on a complex algorithm
      developed by Fantasy Premier League experts. A set of formulas process key
      Opta data variables, along with each team’s home and away form (past six
      matches), in order to generate a rank for the perceived difficulty of each
      Gameweek opponent. The FDR is designed to help FPL managers plan their
      transfer and team selection strategy and is reviewed on a weekly basis.
    </p>
  </Collapsible>
);

const WhatIsICT: React.FC = () => (
  <Collapsible headingLevel={4} headingText="What is the ICT Index?">
    <p>
      The ICT Index is a football statistical index developed specifically to
      assess a player as an FPL asset. It uses match event data to generate a
      single score for three key areas – Influence, Creativity and Threat. These
      figures then combine to create an individual’s ICT Index score. It
      condenses more than 40 match event statistics into four distinct scores.
      These offer a view on player performance for factors that are known to
      produce FPL points.
    </p>

    <ol>
      <li>
        <h5>Influence</h5>
        <p>
          Influence is the first measurement - this evaluates the degree to
          which that player has made an impact on a match, or matches over the
          season. It takes into account events and actions that could directly
          or indirectly effect the match outcome. At the very top level these
          are decisive actions like goals and assists. However, the Influence
          score also processes significant defensive actions to analyse the
          effectiveness of defenders and goalkeepers.
        </p>
      </li>
      <li>
        <h5>Creativity</h5>
        <p>
          Creativity assesses player performance in terms of producing goal
          scoring opportunities for others. It can be used as a guide to
          identify the players most likely to supply assists. While this
          analyses frequency of passing and crossing, it also considers pitch
          location and the incisiveness of the final ball.
        </p>
      </li>
      <li>
        <h5>Threat</h5>
        <p>
          Threat is the third measure, producing a value that examines a
          player’s threat on goal; it therefore gauges those individuals most
          likely to score goals. While attempts are the key action, the Index
          looks at pitch location, giving greater weight to actions that are
          regarded as the best openings to register a goal. All three of these
          scores are then combined to create an overall ICT Index score. That
          then offers a single figure that presents a view on that player as an
          FPL asset.
        </p>
      </li>
    </ol>
  </Collapsible>
);

const HowPositionsDecided: React.FC = () => (
  <Collapsible headingText="How does FPL decide which position a player should be categorised?">
    <p>
      The players have been positioned in the game based on the following
      position definitions:
    </p>
    <FAQHeading>Goalkeeper</FAQHeading>
    <p>
      The player in goal who is permitted to use their hands inside the penalty
      area.
    </p>
    <p>
      It is worth noting that if an outfield player takes over goalkeeping
      responsibility (due to no substitute goalkeeper being available), he will
      be able to collect points for saves, but will score points as per their
      position classification (e.g. if a midfielder assumes goalkeeping
      responsibilities, he will only receive one point for a clean sheet) in the
      game.
    </p>
    <FAQHeading>Defender</FAQHeading>
    <p>
      Lining up in front of the goalkeeper, these players are charged with
      defending their goal.
    </p>
    <p>
      Central defenders will often be dominant figures, amassing clearances,
      blocks and interceptions (CBI).
    </p>
    <p>
      Full-backs and wing-backs will often record more tackles while also
      contributing in attack. They provide crosses and create chances for
      team-mates.
    </p>
    <FAQHeading>Midfielder</FAQHeading>
    <p>
      A player positioned in front of the defence who either plays centrally or
      in a wide position.
    </p>
    <p>
      They are charged with breaking up opponents’ attacks and providing the
      passes and crosses to create chances for their own team.
    </p>
    <p>
      This position also includes attacking midfielders who can play behind the
      forward line as a No 10, or as wide players and wingers and therefore
      includes players on the left and right of a central striker in a 4-3-3
      formation. Their role is to provide goals, either by creating chances or
      by converting opportunities themselves.
    </p>
    <FAQHeading>Forward</FAQHeading>
    <p>
      The forward (also known as a striker) is deployed ahead of the midfield
      and in a central area rather than out wide.
    </p>
    <p>
      They often operate either as a lone striker or as part of a front pairing,
      e.g. a 4-4-2 or 3-5-2 formation.
    </p>
    <p>
      Their primary role is to score goals rather than create chances, and this
      is reflected in the Bonus Points System (BPS) with forwards rewarded more
      significantly for goals scored.
    </p>
  </Collapsible>
);

const HowBonusCalculated: React.FC = () => (
  <Collapsible headingText="How are bonus points calculated?">
    <p>
      The three best performing players in each match according to the{" "}
      <Link to="/help/rules">Bonus Points System (BPS)</Link> will receive
      additional bonus points. 3 points will be awarded to the highest scoring
      player, 2 to the second best and 1 to the third.
    </p>
    <p>Examples of how bonus point ties will be resolved are as follows:</p>
    <ul>
      <li>
        If there is a tie for first place, Players 1 & 2 will receive 3 points
        each and Player 3 will receive 1 point.
      </li>
      <li>
        If there is a tie for second place, Player 1 will receive 3 points and
        Players 2 and 3 will receive 2 points each.
      </li>
      <li>
        If there is a tie for third place, Player 1 will receive 3 points,
        Player 2 will receive 2 points and Players 3 & 4 will receive 1 point
        each.
      </li>
    </ul>
  </Collapsible>
);

const WhenBonusPointsAdded: React.FC = () => (
  <Collapsible headingText="When are bonus points added?">
    <p>
      Bonus points will be awarded one hour after the final whistle of the last
      match of any given day.
    </p>
  </Collapsible>
);

const BPSChanged: React.FC = () => (
  <Collapsible headingText="My player's BPS has changed since the end of his match. Why?">
    <p>
      Some BPS statistics are updated after the end of the match so players may
      rise / fall until the last day of the Gameweek has been marked as final.
    </p>
  </Collapsible>
);

const WhenTransferred: React.FC = () => (
  <Collapsible headingText="What happens if a player is transferred to another Premier League team?">
    <p>
      If a player is transferred to another team in the Premier League, and this
      transfer takes you over the players per team limit, then you will need to
      go back under the limit when making your next transfers.
    </p>
  </Collapsible>
);

const NewPlayersAdded: React.FC = () => (
  <Collapsible headingText="When will new players be added?">
    <p>
      New players will be added within 7 days of their transfer being completed.
    </p>
  </Collapsible>
);

const PlayerLeftPL: React.FC = () => (
  <Collapsible headingText="My player has left the Premier League. What do I do now?">
    <p>
      You can leave the player in your squad, where he will continue to earn 0
      points. To remove him permanently, you must use a transfer.
    </p>
  </Collapsible>
);

export const SampleFAQ: React.FC = () => (
  <Collapsible headingText="Here is a sample question?">
    <p>And here is the sample answer.</p>
    <FAQHeading>Sample heading</FAQHeading>
    <p>
      And here is some more sample text. Maybe it's on the{" "}
      <Link to="/">home page</Link>?
    </p>
  </Collapsible>
);
