import React from "react";
import { StyledResult } from "./styles";
import { IResult } from "./types";

const Result: React.FC<IResult> = ({ resultChar }) => (
  <StyledResult $resultChar={resultChar.toLowerCase()}>
    {resultChar}
  </StyledResult>
);

export default Result;
