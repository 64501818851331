import styled from "styled-components";
import { ExtraStat, gap, timeScoreWidth } from "../styles";
import { ElementFixtureTeam, FixtureDifficulty } from "./types";

export const Teams = styled.div`
  display: flex;
  align-items: center;
  gap: ${gap};
`;

export const Team = styled.div<ElementFixtureTeam>`
  flex: 0 0 calc((50%) - (${timeScoreWidth} / 2) - (${gap}));
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};
  flex-direction: ${({ $isHome = false }) => ($isHome ? "row-reverse" : "row")};
`;

export const TeamName = styled.span`
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[2]};
  line-height: 1;
`;

export const KOTime = styled.time`
  flex: 0 0 ${timeScoreWidth};
  display: block;
  padding: 0.3rem ${(props) => props.theme.space[2]};
  border: 1px solid ${(props) => props.theme.colors.purple10};
  border-radius: ${(props) => props.theme.radii[1]};
  font-size: ${(props) => props.theme.fontSizes[2]};
  text-align: center;
`;

export const StatWrap = styled.div`
  width: ${timeScoreWidth};
  margin: 0.3rem auto 0;
`;

export const Difficulty = styled.div<FixtureDifficulty>`
  ${ExtraStat}
  background-color: ${({ $difficulty, theme }) =>
    theme.colors.difficulties[$difficulty].bg};
  color: ${({ $difficulty, theme }) =>
    theme.colors.difficulties[$difficulty].color};
  text-transform: capitalize;
`;
