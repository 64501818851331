import React from "react";
import { useAppDispatch } from "../../rtk-core/src/app/hooks";
import {
  unbanLeagueEntry,
  IBanEntryData,
  IPrivateLeagueAdmin,
} from "../../rtk-core/src/features/leagues";
import Button from "../Button";
import SubHeading from "../SubHeading";

interface IOwnProps {
  league: IPrivateLeagueAdmin;
}

const RemoveBan: React.FC<IOwnProps> = ({ league }) => {
  const dispatch = useAppDispatch();

  const unban = (leagueId: number, data: IBanEntryData) => {
    dispatch(unbanLeagueEntry({ leagueId, data }));
  };

  if (!league.bans.length) {
    return null;
  }

  return (
    <>
      <SubHeading>Remove League Suspensions</SubHeading>
      <p>
        If a player has a suspension removed, they will need to join the league
        again.
      </p>
      <ul>
        {league.bans.map((ban) => (
          <li key={ban.entry}>
            {ban.player_name}
            <Button onClick={() => unban(league.id, { entry: ban.entry })}>
              Remove suspension
            </Button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default RemoveBan;
