import LeagueRow from "../LeagueRow";
import { LeaguesHeader, LeaguesWrapper } from "./styles";
import { IEntryLeaguesListProps } from "./types";

const EntryLeaguesList: React.FC<IEntryLeaguesListProps> = ({
  heading,
  leagues,
}) => {
  if (leagues.length === 0) return null;
  return (
    <div>
      <LeaguesHeader>{heading}</LeaguesHeader>
      <LeaguesWrapper>
        {leagues.map((league) => (
          <LeagueRow
            key={league.id}
            invitational={heading === "Invitational Leagues"}
            league={league}
            leave={(id: number) => null}
            hideOptions={true}
            canUnWrap={false}
          />
        ))}
      </LeaguesWrapper>
    </div>
  );
};

export default EntryLeaguesList;
