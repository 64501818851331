import { useAppSelector } from "../../rtk-core/src/app/hooks";
import {
  getEntry,
  IEntryEventStatus,
} from "../../rtk-core/src/features/entries";
import {
  getActiveEvent,
  getLastEvent,
} from "../../rtk-core/src/features/events";
import { getPlayerData } from "../../rtk-core/src/features/player";
import { getChallengeAssets } from "../../utils/challenge";
import { getEntryStatusForEvent } from "../../utils/entries";
import { getEventReleaseDate } from "../../utils/events";
import ChallengeHeading from "../ChallengeHeading";
import ClosedEventHero from "../ClosedEventHero";
import { EventEntryCopy } from "../styles";
import HeroActions from "./HeroActions";
import {
  ChallengeDetail,
  ChallengeHeroBlock,
  ChallengeHeroBlockBody,
  StyledHero,
} from "./styles";
import { IChallengeHero } from "./types";

const ChallengeHero = ({ entryId = 0 }: IChallengeHero) => {
  const activeEvent = useAppSelector(getActiveEvent);
  const player = useAppSelector(getPlayerData);
  // Set to entryId if passed (e.g. from EntryEvent)
  // player.id if its not passed and you're logged in (e.g. Home dashboard)
  // otherwise logged out.
  const entryNumber = entryId || player?.entry || 0;
  const entry = useAppSelector((state) =>
    entryNumber ? getEntry(state, Number(entryNumber)) : null
  );
  const entryStatusForEvent = entry
    ? getEntryStatusForEvent(entry, activeEvent)
    : "";

  const challenge = getChallengeAssets(activeEvent.id);

  const lastEvent = useAppSelector(getLastEvent);

  const isLastEvent = activeEvent.id === lastEvent?.id;

  if (!challenge) {
    return null;
  }

  const { copy, lockupPath } = challenge;

  if (isLastEvent && !player?.entry && !activeEvent.can_enter) {
    return (
      <ClosedEventHero
        heading={"The 2024/25 Season has finished"}
        subHeading={"Fantasy Challenge will be back in July 2025."}
      />
    );
  }

  return (
    <ChallengeHeroBlock>
      <StyledHero $lockupPath={lockupPath}>
        <ChallengeHeroBlockBody>
          <div>
            <ChallengeHeading
              challengeTitle={copy.title}
              eventName={activeEvent.name}
            />
            <p>{copy.description}</p>
            {copy.detailTitle && (
              <ChallengeDetail>
                <h4>{copy.detailTitle}</h4>
                <p>{copy.detailDescription}</p>
              </ChallengeDetail>
            )}
          </div>
          {/* For now, assume all unreleased events with challenge info have a release time */}
          {/* TODO: Revisit as part of wider ChallengeHero refactor - https://github.com/ismfg/pl-issues/issues/182 */}
          {entryStatusForEvent === IEntryEventStatus.Unreleased &&
          activeEvent.release_time ? (
            <EventEntryCopy>
              {`Team selection for this Gameweek will open on ${getEventReleaseDate(
                activeEvent.release_time
              )}`}
            </EventEntryCopy>
          ) : entryStatusForEvent === IEntryEventStatus.CannotEnter ? (
            <EventEntryCopy>
              {entry
                ? `${entry.player_first_name} ${entry.player_last_name} did`
                : "Did"}{" "}
              not play this Gameweek
            </EventEntryCopy>
          ) : (
            <HeroActions />
          )}
        </ChallengeHeroBlockBody>
      </StyledHero>
    </ChallengeHeroBlock>
  );
};

export default ChallengeHero;
