import { useAppSelector } from "../../../rtk-core/src/app/hooks";
import {
  getElement,
  getHistory,
} from "../../../rtk-core/src/features/elements";
import { getTeamsById } from "../../../rtk-core/src/features/teams";
import { formatRawAsISO, formatRawAsTime } from "../../../utils/datetime";
import { difficultyLabels } from "../../../utils/fixtures";
import Badge from "../../Badge";
import { Points, Score } from "../styles";
import { Difficulty, KOTime, StatWrap, Team, TeamName, Teams } from "./styles";
import { ElementInfoFixture } from "./types";

const ElementFixture = ({ elementId, fixture }: ElementInfoFixture) => {
  const teams = useAppSelector(getTeamsById);
  const element = useAppSelector((state) => getElement(state, elementId));
  const elementHistory = useAppSelector((state) =>
    getHistory(state, elementId)
  );

  const fixtureHistory = elementHistory.find(
    (hist) => hist.fixture === fixture.id
  );

  if (!element) {
    return null;
  }

  const teamH = teams[fixture.team_h];
  const teamA = teams[fixture.team_a];
  const elementIsHome = element.team === fixture.team_h;
  const difficulty = elementIsHome
    ? fixture.team_h_difficulty
    : fixture.team_a_difficulty;

  return (
    <div>
      <Teams>
        <Team $isHome={true}>
          <Badge sizes="32px" team={teamH} isPresentation={true} />
          <TeamName>{teamH.name}</TeamName>
        </Team>
        {fixture.started && fixture.team_h_score !== null ? (
          <Score $isLive={!fixture.finished_provisional}>
            <span>{fixture.team_h_score}</span>
            <span>-</span>
            <span>{fixture.team_a_score}</span>
          </Score>
        ) : fixture.kickoff_time ? (
          <KOTime dateTime={formatRawAsISO(fixture.kickoff_time)}>
            {formatRawAsTime(fixture.kickoff_time)}
          </KOTime>
        ) : (
          <span>-</span>
        )}
        <Team>
          <Badge sizes="32px" team={teamA} isPresentation={true} />
          <TeamName>{teamA.name}</TeamName>
        </Team>
      </Teams>
      <StatWrap>
        {fixture.started ? (
          <Points $isSpecial={element.special}>
            {fixtureHistory ? fixtureHistory.total_points : 0} pts
          </Points>
        ) : (
          difficulty && (
            <Difficulty $difficulty={difficultyLabels[difficulty]}>
              {difficultyLabels[difficulty]}
            </Difficulty>
          )
        )}
      </StatWrap>
    </div>
  );
};

export default ElementFixture;
