import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { sortBy } from "lodash";
import { RootState } from "../../app/store";
import { getActiveOrProposedTeamChipName } from "../chips";
import { getElementsById } from "../elements";
import { getEntry } from "../entries";
import {
  IManageTeamRequestData,
  IManageTeamResponseData,
  getPicksProposed,
  getProposedTransfers,
} from "../manage";
import { IPlayer, getPlayerData } from "../player";
import { apiSlice } from "./apiSlice";

export const manageApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    postManageTeam: builder.query<
      IManageTeamResponseData,
      IManageTeamRequestData
    >({
      queryFn: async (
        { eventId, createEntryData },
        queryApi,
        extraOptions,
        baseQuery
      ) => {
        const state = queryApi.getState() as RootState;
        const player = getPlayerData(state) as IPlayer;
        const entry =
          player && player.entry ? getEntry(state, player.entry) : null;

        const requestData = {
          entry: entry ? entry.id : null,
          event: eventId,
          chip: getActiveOrProposedTeamChipName(state),
          picks: Object.values(
            sortBy(getPicksProposed(state), "elementType")
          ).map((p) => ({
            element: p.element,
            position: p.position,
            is_captain: p.is_captain,
            is_vice_captain: p.is_vice_captain,
            purchase_price: p.purchase_price,
          })),
          transfers: getProposedTransfers(state),
        };

        const url = `manage-team/`;
        const response = await baseQuery({
          url: url,
          method: "POST",
          body: createEntryData
            ? {
                ...createEntryData,
                ...requestData,
              }
            : {
                ...requestData,
              },
        });
        return response.data
          ? {
              data: {
                ...response.data,
                elementsById: getElementsById(state),
              } as IManageTeamResponseData,
            }
          : {
              error: response.error as FetchBaseQueryError,
            };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useLazyPostManageTeamQuery } = manageApi;
