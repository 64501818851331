import * as React from "react";
import styled, { css } from "styled-components";

import shadow from "../../img/shirt-shadow.png";
import { ITeam } from "../../rtk-core/src/features/teams/types";

interface IStyledProps {
  $hasShadow?: boolean;
}

const StyledShirt = styled.img<IStyledProps>`
  display: inline-block;
  margin: auto;
  vertical-align: middle;

  ${(props) =>
    props.$hasShadow &&
    css`
      padding-bottom: 20%;
      background: url(${shadow}) no-repeat center bottom;
      background-size: contain;

      /* TODO Remove these media queries if background size and % padding work with pitch */
      @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
        /* padding-bottom: 0.7rem; */
        /* background-size: 44px 8px; */
      }

      @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
        /* padding-bottom: 0.9rem; */
        /* background-size: 55px 10px; */
      }
    `}
`;

interface IProps {
  hasShadow?: boolean;
  path: string;
  sizes: string;
  team?: ITeam;
}

const Shirt: React.FC<IProps> = ({ hasShadow, path, sizes, team }) => (
  <picture>
    <source
      type="image/webp"
      srcSet={`
        ${path}-66.webp 66w,
        ${path}-110.webp 110w,
        ${path}-220.webp 220w
        `}
      sizes={sizes}
    />
    <StyledShirt
      src={`${path}-66.png`}
      srcSet={`
        ${path}-66.png 66w,
        ${path}-110.png 110w,
        ${path}-220.png 220w
        `}
      sizes={sizes}
      alt={team ? team.name : ""}
      $hasShadow={hasShadow}
    />
  </picture>
);

export default Shirt;
