import { useAppSelector } from "../../rtk-core/src/app/hooks";
import { getActiveEvent } from "../../rtk-core/src/features/events";
import { getChallengeAssets } from "../../utils/challenge";
import { getEventReleaseDate } from "../../utils/events";
import ChallengeHero from "../ChallengeHero";
import ClosedEventHero from "../ClosedEventHero";
import { ICannotEnterEvent } from "./types";
// Don't initialise optional entryId prop to 0
// because we only want to pass the entryId to ChallengeHero
// when it's possible you are looking at other entries
// e.g. EntryEvent
const CannotEnterEvent = ({ entryId }: ICannotEnterEvent) => {
  const activeEvent = useAppSelector(getActiveEvent);
  const challenge = getChallengeAssets(activeEvent.id);
  return (
    <>
      {challenge ? (
        <ChallengeHero entryId={entryId} />
      ) : (
        <ClosedEventHero
          heading={`${activeEvent.name} opens soon.`}
          subHeading={`${activeEvent.name} will be announced ${
            activeEvent.release_time
              ? `on ${getEventReleaseDate(activeEvent.release_time)}`
              : "soon"
          }
          .`}
        />
      )}
    </>
  );
};

export default CannotEnterEvent;
