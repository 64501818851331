import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IServerState, IServerError } from "./types";

const initialState: IServerState = {
  serverError: null,
};

const global = createSlice({
  name: "globals",
  initialState,
  reducers: {
    addServerError: (state, action: PayloadAction<IServerError>) => ({
      ...state,
      serverError: action.payload,
    }),
  },
});

export const { addServerError } = global.actions;

export default global.reducer;
