import qs from "qs";
import { useEffect } from "react";
import ChallengeHero from "../components/ChallengeHero";
import ChallengePromos, {
  Promo1,
  Promo2,
  Promo3,
  Promo4,
} from "../components/ChallengePromos/ChallengePromos";
import RanksExplained from "../components/RanksExplained";
import Fixtures from "../components/Fixtures";
import {
  GridItem,
  GridWrapper,
  SidebarGrid,
  TwoCol50Grid,
} from "../components/Layout";
import Login from "../components/Login";
import PhaseSummary from "../components/PhaseSummary";
import PlayerOverview from "../components/PlayerOverview";
import ScoutNewsListWrapper from "../components/ScoutNewsList/ScoutNewsList";
import { useAppDispatch, useAppSelector } from "../rtk-core/src/app/hooks";
import { getEntry } from "../rtk-core/src/features/entries";
import {
  getActiveEvent,
  getCurrentEvent,
} from "../rtk-core/src/features/events";
import { doFetchLatestEventData } from "../rtk-core/src/features/events/thunks";
import { getPlayerData } from "../rtk-core/src/features/player";
import { getEntryEnteredEvent } from "../utils/entries";
import {
  useLazyFetchEntryEventPicksQuery,
  useLazyFetchEntryHistoryQuery,
} from "../rtk-core/src/features/api/entries";

const Home = () => {
  const dispatch = useAppDispatch();

  const activeEvent = useAppSelector(getActiveEvent);
  const player = useAppSelector(getPlayerData);
  const entry = useAppSelector((state) =>
    player?.entry ? getEntry(state, player.entry) : null
  );
  const currentEvent = useAppSelector(getCurrentEvent);
  const entryEnteredCurrentEvent =
    entry && currentEvent ? getEntryEnteredEvent(entry, currentEvent) : false;

  useEffect(() => {
    dispatch(doFetchLatestEventData());
  }, [dispatch]);

  const [fetchEntryEventPicksTrigger] = useLazyFetchEntryEventPicksQuery();

  useEffect(() => {
    entryEnteredCurrentEvent &&
      entry &&
      currentEvent &&
      fetchEntryEventPicksTrigger({
        entryId: entry.id,
        eventId: currentEvent.id,
      });
  }, [
    fetchEntryEventPicksTrigger,
    entry,
    entryEnteredCurrentEvent,
    currentEvent,
  ]);

  const [fetchEntryHistoryTrigger] = useLazyFetchEntryHistoryQuery();

  useEffect(() => {
    if (entry) {
      fetchEntryHistoryTrigger(entry.id);
    }
  }, [entry, fetchEntryHistoryTrigger]);

  // Look for a login error from the users server
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  let usersError = "";
  if (query.state) {
    switch (query.state) {
      case "fail":
        usersError = "Incorrect email or password";
        break;
      default:
        break;
    }
  }

  // logged out
  if (!player) {
    return (
      <GridWrapper>
        <TwoCol50Grid $hasMargin={true}>
          <GridItem>
            <Promo1 />
          </GridItem>
          <GridItem>
            <Login usersError={usersError} />
          </GridItem>
          <GridItem>
            <Promo2 />
          </GridItem>
          <GridItem>
            <Promo3 />
          </GridItem>
          <GridItem>
            <Promo4 />
          </GridItem>
          <GridItem>
            <ChallengeHero />
          </GridItem>
          <GridItem>
            <RanksExplained />
          </GridItem>
        </TwoCol50Grid>
      </GridWrapper>
    );
  }

  // Logged in, never played
  if (!entry) {
    return (
      <GridWrapper>
        <TwoCol50Grid $hasMargin={true}>
          <GridItem>
            <ChallengeHero />
          </GridItem>
          <ChallengePromos />
          <GridItem>
            <ScoutNewsListWrapper numberOfItems={4} />
          </GridItem>
          <GridItem>
            <Fixtures eventId={activeEvent.id} />
          </GridItem>
        </TwoCol50Grid>
      </GridWrapper>
    );
  }

  return (
    <GridWrapper>
      <SidebarGrid $hasMargin={true}>
        <GridItem $colSpan={1}>
          <PhaseSummary />
        </GridItem>
        <GridItem $colSpan={1}>
          <div>
            <PlayerOverview hasProfileLink={true} isSidebar={true} />
          </div>
        </GridItem>
        <GridItem>
          <ScoutNewsListWrapper numberOfItems={4} />
        </GridItem>
        {/* TODO Add Dream Team back in when we know how to handle 5-a-side etc - https://github.com/ismfg/pl-issues/issues/672 */}
        {/* {(activeEvent.is_current || activeEvent.finished) && (
          <GridItem>
            <DreamTeam />
          </GridItem>
        )} */}
        <GridItem>
          <Fixtures eventId={activeEvent.id} />
        </GridItem>
      </SidebarGrid>
    </GridWrapper>
  );
};

export default Home;
