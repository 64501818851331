import styled from "styled-components";

export const HeroActionBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[3]};
  margin-top: ${(props) => props.theme.space[8]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex-direction: row;
  }
`;

export const HeroActionItem = styled.div`
  flex: 1;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: initial;
  }
`;
