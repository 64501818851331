import { Bullet, BulletBorder, PadlockIcon } from "./styles";
import { IRankProps } from "./types";

const RankBullet = ({ $currentRank = false, locked = true }: IRankProps) => {
  return locked ? (
    <PadlockIcon />
  ) : (
    <BulletBorder $currentRank={$currentRank}>
      <Bullet />
    </BulletBorder>
  );
};

export default RankBullet;
