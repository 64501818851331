import { size } from "polished";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import Dialog from "../components/Dialog";
import DialogBody from "../components/DialogBody";
import DialogHeader from "../components/DialogHeader";
import Close from "../img/icons/close.svg?react";
import Tick from "../img/icons/tick.svg?react";
import { useAppDispatch, useAppSelector } from "../rtk-core/src/app/hooks";
import { getActiveEvent } from "../rtk-core/src/features/events";
import {
  IPrivateLeagueAdmin,
  deleteLeague,
} from "../rtk-core/src/features/leagues";

export const LeagueDeleteDiaglogControls = styled.div`
  margin-top: ${({ theme }) => theme.space[5]};
`;

export const LeagueDeleteDiaglogClose = styled(Close)`
  ${size("15px")};
  margin-left: 0.5rem;
`;

export const LeagueDeleteDiaglogConfirm = styled(Tick)`
  ${size("15px")};
  margin-left: 0.5rem;
`;

export const LeagueDeleteDiaglogCopy = styled.div`
  font-size: ${(props) => props.theme.fontSizes[3]};
`;

export interface ILeagueDeleteDiaglogProps {
  handleHide: () => void;
  league: IPrivateLeagueAdmin;
}

const LeagueDeleteDiaglog: React.FC<ILeagueDeleteDiaglogProps> = ({
  handleHide,
  league,
}) => {
  const activeEvent = useAppSelector(getActiveEvent);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const remove = async (leagueId: number) => {
    await dispatch(deleteLeague(leagueId));
    navigate(`/leagues/${activeEvent.id}`);
  };

  return (
    <Dialog closeDialog={handleHide}>
      <DialogHeader closeDialog={handleHide}>Delete league</DialogHeader>
      <DialogBody isPadded={true}>
        <LeagueDeleteDiaglogCopy>
          Are you sure you want to delete {league.name}?
        </LeagueDeleteDiaglogCopy>
        <LeagueDeleteDiaglogControls>
          <div className="mb-2">
            <Button
              $isFullWidth
              $stretch
              $variant="secondary"
              onClick={() => remove(league.id)}
            >
              Confirm
              <LeagueDeleteDiaglogConfirm />
            </Button>
          </div>
          <div className="mb-2">
            <Button
              $isFullWidth
              $stretch
              $variant="tertiary"
              onClick={handleHide}
            >
              Cancel
              <LeagueDeleteDiaglogClose />
            </Button>
          </div>
        </LeagueDeleteDiaglogControls>
      </DialogBody>
    </Dialog>
  );
};

export default LeagueDeleteDiaglog;
